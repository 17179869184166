import {Component, Input, OnInit} from '@angular/core';
import {AlertModule} from "../../../../global/alert/alert.module";
import {TranslateModule} from "@ngx-translate/core";
import {SimpleTextInputModule} from "../../../../global/input/simple-text-input/simple-text-input.module";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {CopyTextModule} from "../../../../global/input/copy-text/copy-text.module";
import {CommonModule} from "@angular/common";
import {MatRippleModule} from "@angular/material/core";
import {
	OrganizationSettingGeneralComponent
} from "./organization-setting-general/organization-setting-general.component";

@Component({
	selector: 'app-organization-settings',
	templateUrl: './organization-settings.component.html',
	standalone: true,
	imports: [
		CommonModule,
		AlertModule,
		TranslateModule,
		SimpleTextInputModule,
		DesignSystemModule,
		CopyTextModule,
		MatRippleModule,
		OrganizationSettingGeneralComponent
	],
	styleUrls: ['./organization-settings.component.scss']
})
export class OrganizationSettingsComponent implements OnInit {
	@Input() settings: OrganizationSettingsMode = OrganizationSettingsMode.NONE;

	openGeneral = false;

	ngOnInit(): void {
		this.checkOpenPanels();
	}

	private checkOpenPanels(): void {
		this.openGeneral = [OrganizationSettingsMode.GENERAL, OrganizationSettingsMode.ALL].includes(this.settings);
	}
}

export enum OrganizationSettingsMode {
	NONE = 0,
	GENERAL = 1,
	ALL = 2
}
