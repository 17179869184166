<div class="select-trigger-content flex-space-between flex-align-center bkg-light-grey c-p pad-0-S border-box-full"
	 [style.min-height.px]="minHeight ? minHeight : undefined"
	 [style.border]="border ? border : undefined"
     [class]="'appearance-' + appearance">
    <div class="select-trigger-text font-M fw-500 text-overflow-ellipsis">
        <ng-content></ng-content>
    </div>
    <img class="select-trigger-icon" [ngClass]="{'filter-grey': appearance === 'chips'}" style="transform: rotate(90deg)"
         ngSrc="assets/icons/arrow-right-black.svg" height="11" width="11" alt="">
</div>
