import {ApplicationCatalog} from 'src/app/services/model/new-application.model';
import {Organization} from 'src/app/services/organization.service';

export interface ApplicationAutodiscover {
  autodiscoverId: string;
  application: ApplicationCatalog;
  dailyUsage: number|null;
  businessUnits: Organization[];
  status: DiscoveredAppStatus;
  totalUniqueUser: number|null;
  fqdns: string[];
  discoveredAt: string;
}

export interface AppCount {
  // TODO @TAN move to other file
  count: number;
}

export interface ApplicationAutodiscoverStatusForm {
  status: DiscoveredAppStatus;
}

export enum OrganizationType {
  // TODO @TAN move to other file
  ORGANIZATION = 'organization',
  AFFILIATE = 'affiliate',
  BUSINESS_UNIT = 'business_unit',
  PROCESS = 'process',
}

export enum DiscoveredAppStatus {
  ACTIVE = 'active',
  ACCEPTED = 'accepted',
  DISMISSED = 'dismissed',
}
