import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "src/app/services/organization.service";
import {ApplicationInstanceService} from "src/app/services/application-instance.service";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {TenantService} from "src/app/services/tenant.service";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {distinctUntilChanged, Subscription, tap} from "rxjs";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {SnackbarModule} from "../../../../../global/snackbar/snackbar.module";
import {ChipsModule} from "../../../../../global/chips/chips/chips.module";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {CommonModule} from "@angular/common";
import {MatCheckboxModule} from "@angular/material/checkbox";

@Component({
	selector: 'app-add-apps-to-team',
	templateUrl: './add-apps-to-team.component.html',
	standalone: true,
	imports: [
		CommonModule,
		SnackbarModule,
		ChipsModule,
		DesignSystemModule,
		DeckContentLoaderModule,
		TranslateModule,
		MatCheckboxModule
	],
	styleUrls: ['./add-apps-to-team.component.scss']
})
export class AddAppsToTeamComponent implements OnInit, OnDestroy {
	constructor(public dialog: MatDialogRef<AddAppsToTeamComponent>,
							@Inject(MAT_DIALOG_DATA) public dialogData: { organizationId: string, apps: AddAppsToTeamDialogData[]},
							protected organizationService: OrganizationService,
							protected applicationService: ApplicationInstanceService,
							protected currentTenantService: CurrentTenantService,
							protected tenantService: TenantService,
							protected translate: TranslateService,
							protected snackbarService: SnackbarService) {
	}

	searchInput: FormControl;

	data: AddAppsToTeamDialogData[];

	searchLoading = false;
	_saving = false;

	updatedApps: { appId: string, checked: boolean }[] = [];

	tenantId: string;

	subscriptions: Subscription = new Subscription();

	ngOnInit() {
		this.subscriptions.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(
				tap(tenantId => this.tenantId = tenantId)
			).subscribe());

		this.data = this.dialogData.apps.sort((a, b) => {
			if (a.autodiscovered && !b.autodiscovered) {
				return -1;
			} else if (!a.autodiscovered && b.autodiscovered) {
				return 1;
			}
			return a.name.localeCompare(b.name);
		});
		this.searchInput = new FormControl();
		if (this.data.length > 0) {
			this.searchInput.valueChanges
				.pipe(
					tap(() => this.searchLoading = true),
					debounceTime(500),
					distinctUntilChanged(),
					tap(value => {
						return this.data = this.dialogData.apps.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
					}),
					tap(() => this.searchLoading = false)
				)
				.subscribe()
		}
	}

	onConfirm() {
		const updatedApps = this.updatedApps.filter(item => {
			const app = this.data.find(app => app.id === item.appId);
			return app && app.selected !== item.checked;
		});

		if (updatedApps.length > 0) {
			this._saving = true;
			this.subscriptions.add(this.organizationService.changeTeamApplications(this.tenantId, this.dialogData.organizationId, updatedApps)
				.subscribe({
					next: () => {
						this._saving = false;
						this.dialog.close({ success: true });
					},
					error: error => {
						this._saving = false;
						this.snackbarService.show(this.translate.instant('global.announcements.error'), 'danger-snack')
					}
				}));
		} else {
			this.dialog.close();
		}
	}

	onDismiss() {
		this.dialog.close();
	}

	onAppSelectChange(application: AddAppsToTeamDialogData, event: any) {
		const index = this.updatedApps.findIndex(item => item.appId === application.id);
		if (index > -1) {
			this.updatedApps[index].checked = event.checked;
		} else {
			this.updatedApps.push({appId: application.id, checked: event.checked});
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}

export interface AddAppsToTeamDialogData {
	id: string;
	name: string;
	logo: string;
	selected: boolean;
	autodiscovered: boolean;
}
