import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MiniButtonModule} from "../../../../global/button/mini-button/mini-button.module";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {FormControl, Validators} from "@angular/forms";
import {PREFIX_REGEXP, SUFFIX_DOMAIN_REGEXP, SUFFIX_PORT_REGEXP, URL_REGEXP} from "src/app/utils/forms.utils";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {finalize, Observable, of, Subscription, switchMap} from "rxjs";
import {ApplicationDetailData, ApplicationDetailService} from "src/app/services/front/application-detail.service";
import {filter, tap} from "rxjs/operators";
import {NewApplicationService} from "src/app/services/back/new-application.service";
import {MatDialogRef} from "@angular/material/dialog";
import {ApplicationSettingGeneralForm} from 'src/app/services/model/new-application.model';

@Component({
  selector: 'app-add-url-to-app',
  standalone: true,
	imports: [CommonModule, MiniButtonModule, DesignSystemModule, NgOptimizedImage, TranslateModule],
  templateUrl: './add-url-to-app.component.html',
  styleUrl: './add-url-to-app.component.scss',
})
export class AddUrlToAppComponent implements OnInit, OnDestroy {
	constructor(private dialogRef: MatDialogRef<AddUrlToAppComponent>,
				private translateService: TranslateService,
				private applicationDetailService: ApplicationDetailService,
				private applicationService: NewApplicationService) {}
	subscription: Subscription = new Subscription();

	error?: string;
	_initializing: boolean;
	_loading: boolean;
	application: ApplicationDetailData;
	initialized: boolean = false;

	url: FormControl;
	cleanUrl?: URL;

	ngOnInit(): void {
		this.buildForm();
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	private buildForm(): void {
		this.url = new FormControl(undefined, [Validators.required]);
	}

	private setApplicationDetailData(application: ApplicationDetailData) {
		this.application = application;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.fixMarkAsTouched()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private fixMarkAsTouched(): void {
		this.url.markAsTouched();
	}

	protected submit(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.error = undefined),
				tap(() => this.formatUrl()),
				filter(() => this.cleanUrl !== undefined && !this.error),
				switchMap(() => this.updateGeneralSettings()),
				switchMap(() => this.updateUsageSettings()),
				switchMap(() => this.applicationService.setApplicationUrlModalDismissed(this.application.tenantId, this.application.instance.applicationId)),
				finalize(() => this.switchLoading()))
			.subscribe({
				next: () => this.dialogRef.close(true),
				error: () => this.error = this.translateService.instant('page.appDetails.settings.error.update'),
			}));
	}

	private formatUrl(): void {
		this.url.markAsTouched();

		const input = this.url.value;
		let cleanUrl: string;

		if (PREFIX_REGEXP.test(input)) {
			if (SUFFIX_DOMAIN_REGEXP.test(input.split('?')[0]) || SUFFIX_PORT_REGEXP.test(input.split('?')[0].split('/').slice(0, 3).join('/'))) {
				cleanUrl = input.split('?')[0];
			} else {
				cleanUrl = `${input.split('?')[0]}.com`;
			}
		} else {
			if (SUFFIX_DOMAIN_REGEXP.test(`https://${input}`.split('?')[0]) || SUFFIX_PORT_REGEXP.test(`https://${input}`.split('?')[0])) {
				cleanUrl = `https://${input}`.split('?')[0];
			} else {
				cleanUrl = `${`https://${input}`.split('?')[0]}.com`;
			}
		}

		if (URL_REGEXP.test(cleanUrl)) {
			try {
				this.cleanUrl = new URL(cleanUrl);
				this.error = undefined;
			} catch (e) {
				this.error = this.translateService.instant('page.appDetails.addUrl.error');
				this.cleanUrl = undefined;
			}
		} else {
			this.error = this.translateService.instant('page.appDetails.addUrl.error');
			this.cleanUrl = undefined;
		}

	}

	private updateGeneralSettings(): Observable<boolean> {
		// TODO create specific API call for updating the accessUrl, usageDomain and usagePath 1/2
		const form: ApplicationSettingGeneralForm = {
			name: this.application.instance.name,
			description: this.application.instance.description ? this.application.instance.description : undefined,
			accessUrl: this.cleanUrl!.href,
			supportPhone: this.application.instance.supportPhone ? this.application.instance.supportPhone : undefined,
			supportEmail: this.application.instance.supportEmail ? this.application.instance.supportEmail : undefined,
			supportUrl: this.application.instance.supportUrl ? this.application.instance.supportUrl : undefined,
		}
		return this.applicationService.updateApplicationSettingGeneral(this.application.tenantId, this.application.instance.applicationId, form);
	}

	private updateUsageSettings(): Observable<boolean> {
		// TODO create specific API call for updating the accessUrl, usageDomain and usagePath 2/2
		return this.applicationService.updateApplicationSettingUsage(this.application.tenantId, this.application.instance.applicationId, {
			usageDomain: this.cleanUrl!.hostname,
			usagePath: this.cleanUrl!.pathname === '/' ? null : this.cleanUrl!.pathname.slice(1),
			usageActivated: true,
			desktopApplicationNames: this.application.instance.desktopApplicationNames
		});
	}

	protected dismiss(): void {
		this.subscription.add(this.applicationService.setApplicationUrlModalDismissed(this.application.tenantId, this.application.instance.applicationId)
			.subscribe({
				next: () => this.dialogRef.close(false),
				error: () => this.dialogRef.close(false),
			}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
