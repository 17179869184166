import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {distinctUntilChanged, finalize, forkJoin, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {debounceTime, filter, map} from 'rxjs/operators';
import {CriticalityLevel, TenantService} from 'src/app/services/tenant.service';
import {TranslateService} from '@ngx-translate/core';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {
	ApplicationCategoryApplicationListForm,
	ApplicationCriticalityForm,
	ApplicationOverview,
	ApplicationStatus,
	ApplicationTypeForm, ApplicationVendorCreateForm, ApplicationVendorForm,
	HostingType,
	Vendor
} from 'src/app/services/model/new-application.model';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {ApplicationCategoryService} from 'src/app/services/back/application-category.service';
import {ApplicationCategoryForm, Category} from 'src/app/services/model/application-category.model';
import {FormControl} from '@angular/forms';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';
import {TenantAccount} from 'src/app/services/model/account.model';
import {ApplicationLifeCycle, ApplicationLifeCycleForm} from 'src/app/services/model/application-life-cycle.model';
import {ApplicationLifeCycleService} from 'src/app/services/back/application-life-cycle.service';
import {DatePipe} from '@angular/common';
import {VendorService} from "../../../../../../services/back/vendor.service";

@Component({
	selector: 'app-application-information-form',
	templateUrl: './application-information-form.component.html',
	styleUrls: ['./application-information-form.component.scss']
})
export class ApplicationInformationFormComponent implements OnInit, OnDestroy {

	tenantId: string;
	isEditor: boolean;
	applicationId: string;
	overview: ApplicationOverview;
	lifeCycle: ApplicationLifeCycle | null;

	_initializing: boolean;
	_refreshing: boolean;
	_loading: boolean;
	_saving: boolean;
	_savingNewCategory: boolean;
	_savingNewVendor: boolean;
	initialized: boolean = false;

	scrollSubject = new Subject<void>();
	page = 1;
	limitReached = false;
	_scrolling = false;

	advancementPercent: number = 0;

	dateControl: FormControl<Date | null>;

	vendorControl: FormControl<Vendor | null> = new FormControl(null);
	vendorSearchControl: FormControl<string | null> = new FormControl(null);

	filteredVendorList: Vendor[] = [];

	criticalityControl: FormControl<CriticalityLevel | null>;
	criticalityList: CriticalityLevel[] = [];

	appStatus: ApplicationStatus | null = null;
	status: typeof ApplicationStatus = ApplicationStatus;

	typeControl: FormControl<HostingType>;
	typeList: HostingType[] = [];

	categoryControl: FormControl<Category | null>;
	categoryList: Category[] = [];
	filteredCategoryList: Category[] = [];
	searchCategoryControl: FormControl<string | null>;

	subscription = new Subscription();

	scrollUpEvent: Subject<void> = new Subject<void>();

	constructor(private applicationDetailService: ApplicationDetailService,
				private applicationCategoryService: ApplicationCategoryService,
				private applicationLifeCycleService: ApplicationLifeCycleService,
				private newApplicationService: NewApplicationService,
				private tenantAccountService: TenantAccountService,
				private translateService: TranslateService,
				private snackBarService: SnackbarService,
				private tenantService: TenantService,
				private vendorService: VendorService,
				private datePipe: DatePipe) {
	}

	ngOnInit() {
		this.initScrollHandler();
		this.createForms();
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getRefreshingChanges()
			.subscribe(refreshing => this._refreshing = refreshing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	scrollUp() {
		this.scrollUpEvent.next();
	}

	private initScrollHandler(): void {
		this.scrollSubject.pipe(
			debounceTime(300),
			filter(() => !this._scrolling && !this.limitReached && this.filteredVendorList.length % 10 === 0),
			tap(() => {
				this._scrolling = true;
				this.page++;
			}),
			switchMap(() =>
				this.vendorService.search(this.tenantId, this.vendorSearchControl.value || '', this.page).pipe(
					finalize(() => this._scrolling = false)
				)
			)
		).subscribe(results => {
			if (results.length > 0) {
				this.filteredVendorList = this.filteredVendorList.concat(results);
			} else {
				this.limitReached = true;
			}
		});
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.applicationId = data.instance.applicationId;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.buildDropdowns()),
				// TODO @TAN rework using applicationDetailService
				switchMap(() => forkJoin([
					this.newApplicationService.getApplicationOverview(this.tenantId, this.applicationId),
					this.applicationLifeCycleService.getApplicationLifeCycle(this.tenantId, this.applicationId)
				])),
				tap(([overview, lifeCycle]) => this.setDefaultData(overview, lifeCycle)),
				tap(() => this.buildVendorDropdown()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private createForms(): void {
		this.searchCategoryControl = new FormControl('');
		this.subscription.add(this.searchCategoryControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(text => this.filterCategoryList(text)));

		this.dateControl = new FormControl(null);
		this.subscription.add(this.dateControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(date => this.updateApplicationLifeCycle(date)));

		this.criticalityControl = new FormControl(null);
		this.subscription.add(this.criticalityControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(criticality => this.updateApplicationCriticality(criticality)));

		this.categoryControl = new FormControl(null);
		this.subscription.add(this.categoryControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(category => this.updateApplicationCategory(category?.categoryId)));

		this.typeControl = new FormControl();
		this.subscription.add(this.typeControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(type => this.updateApplicationType(type)));

		this.subscription.add(this.vendorSearchControl.valueChanges.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => this.page = 1),
			switchMap(term => this.vendorService.search(this.tenantId, term || '', this.page)),
			tap(() => this.scrollUp())
		).subscribe(results => {
			this.filteredVendorList = results;
			if (this.overview.vendor && !this.filteredVendorList.find(v => v.id === this.overview.vendor?.id)) {
				if (this.filteredVendorList.length === 10) this.filteredVendorList.pop();
				this.filteredVendorList.unshift(this.overview.vendor!);
			}
		}));

		this.subscription.add(this.vendorControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(vendor => this.updateVendor(vendor || undefined)));
	}

	setDefaultData(overview: ApplicationOverview, lifeCycle: ApplicationLifeCycle | null): void {
		this.overview = overview;
		this.lifeCycle = lifeCycle;
		const values: any[] = [this.overview.instance.criticality, this.overview.category, this.lifeCycle?.deployedDate, this.overview.vendor];
		this.advancementPercent = (values.filter(v => !!v).length / values.length) * 100;
		this.dateControl.setValue(!this.lifeCycle?.deployedDate ? null : new Date(this.lifeCycle.deployedDate), {emitEvent: false});
		const criticality: CriticalityLevel | undefined = this.criticalityList.find(c => c === this.overview.instance.criticality);
		this.criticalityControl.setValue(!criticality ? null : criticality, {emitEvent: false});
		const category: Category | undefined = this.categoryList.find(c => c.categoryId === this.overview.category?.categoryId);
		this.categoryControl.setValue(!category ? null : category, {emitEvent: false});
		const type: HostingType = this.typeList.find(c => c === this.overview.instance!.hostingType)!;
		this.typeControl.setValue(type, {emitEvent: false});
		this.appStatus = this.overview.status;
		this.vendorControl.setValue(this.overview.vendor, {emitEvent: false});
	}

	private buildDropdowns(): Observable<{}> {
		if (!this.initialized) {
			return forkJoin([
				this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId),
				this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId),
				this.vendorService.search(this.tenantId, '', 1)
			]).pipe(tap(([categories, users, vendors]) => this.setDropdownData(categories, users, vendors)));
		} else {
			return of({});
		}
	}

	private buildVendorDropdown(): void {
		if (this.overview.vendor) {
			if (!this.filteredVendorList.find(v => v.id === this.overview.vendor?.id)) {
				this.filteredVendorList.unshift(this.overview.vendor);
			} else {
				// TODO : don't know why this is needed but it doesn't work without it
				this.filteredVendorList = this.filteredVendorList.filter(v => v.id !== this.overview.vendor?.id);
				this.filteredVendorList.unshift(this.overview.vendor);
			}
			if (this.filteredVendorList.length > 10) {
				this.filteredVendorList.pop();
			}
			this.vendorControl.setValue(this.overview.vendor, {emitEvent: false});
		}
	}

	private setDropdownData(categories: Category[], users: TenantAccount[], vendors: Vendor[]): void {
		this.categoryList = categories;
		this.filteredVendorList = vendors;
		this.filterCategoryList();
		this.criticalityList = Object.values(CriticalityLevel);
		this.typeList = Object.values(HostingType);
	}

	filterCategoryList(filter?: string | null): void {
		this.filteredCategoryList = this.categoryList
			.filter(value => !filter || value.name.toLowerCase().includes(filter.toLowerCase()))
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	private updateApplicationLifeCycle(deployedDate: Date | null): void {
		const form: ApplicationLifeCycleForm = {
			phaseInDate: !this.lifeCycle?.phaseInDate
				? undefined
				: new Date(this.datePipe.transform(this.lifeCycle.phaseInDate, "yyyy-MM-dd")!),
			deployedDate: !deployedDate
				? undefined
				: new Date(this.datePipe.transform(deployedDate, "yyyy-MM-dd")!),
			phaseOutDate: !this.lifeCycle?.phaseOutDate
				? undefined
				: new Date(this.datePipe.transform(this.lifeCycle.phaseOutDate, "yyyy-MM-dd")!),
			retiredDate: !this.lifeCycle?.retiredDate
				? undefined
				: new Date(this.datePipe.transform(this.lifeCycle.retiredDate, "yyyy-MM-dd")!),
		};
		this.switchSaving()
			.pipe(
				switchMap(() => this.applicationLifeCycleService.updateApplicationLifeCycle(this.tenantId, this.applicationId, form)),
				filter(success => !!success),
				tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
				finalize(() => this.switchSaving()))
			.subscribe(() => this.applicationDetailService.refreshApplicationInstance());
	}

	private updateApplicationCriticality(criticality: CriticalityLevel | null): void {
		const form: ApplicationCriticalityForm = {
			criticality: criticality
		};
		this.switchSaving()
			.pipe(
				switchMap(() => this.newApplicationService.updateApplicationCriticalityForm(this.tenantId, this.applicationId, form)),
				filter(success => !!success),
				tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
				finalize(() => this.switchSaving()))
			.subscribe(() => this.applicationDetailService.refreshApplicationInstance());
	}

	private updateApplicationType(type: HostingType): void {
		const form: ApplicationTypeForm = {
			type: type
		};
		this.switchSaving()
			.pipe(
				switchMap(() => this.newApplicationService.updateApplicationTypeForm(this.tenantId, this.applicationId, form)),
				filter(success => !!success),
				tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
				finalize(() => this.switchSaving()))
			.subscribe(() => this.applicationDetailService.refreshApplicationInstance());
	}

	createCategory(newCategory: string): void {
		const form: ApplicationCategoryForm = {
			name: newCategory
		};
		this.switchSavingNewCategory()
			.pipe(
				switchMap(() => this.applicationCategoryService.createApplicationCategoryForTenant(this.tenantId, form)),
				finalize(() => this.switchSavingNewCategory()))
			.subscribe(categoryId => {
				const newData: Category = {
					categoryId: categoryId,
					name: newCategory
				};
				this.categoryList.push(newData);
				this.categoryControl.setValue(newData);
				this.searchCategoryControl.reset('');
			});
	}

	createVendor(newVendor: string): void {
		const form: ApplicationVendorCreateForm = {
			name: newVendor
		}
		this.switchSavingNewVendor()
			.pipe(
				switchMap(() => this.vendorService.createTenantVendor(this.tenantId, form)),
				finalize(() => this.switchSavingNewVendor()))
			.subscribe(vendor => {
				this.filteredVendorList = this.filteredVendorList.filter(v => v.id !== this.overview.vendor?.id);
				this.vendorControl.setValue(vendor);
				this.vendorSearchControl.reset('');
			});
	}

	private updateApplicationCategory(categoryId: string | undefined): void {
		const form: ApplicationCategoryApplicationListForm = {
			categories: !categoryId ? [] : [{categoryId: categoryId}]
		};
		this.switchSaving()
			.pipe(
				switchMap(() => this.newApplicationService.updateApplicationCategoryList(this.tenantId, this.applicationId, form)),
				filter(success => !!success),
				tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
				finalize(() => this.switchSaving()))
			.subscribe(() => this.applicationDetailService.refreshApplicationInstance());
	}

	private updateVendor(vendor: Vendor | undefined): void {
		const form: ApplicationVendorForm = {
			vendorId: vendor?.id
		}
		this.switchSaving()
			.pipe(
				filter(() => this.overview.vendor?.id !== vendor?.id),
				switchMap(() => this.newApplicationService.updateApplicationVendor(this.tenantId, this.applicationId, form)),
				filter(success => !!success),
				tap(() => {
					if (vendor && !this.filteredVendorList.find(v => v.id === vendor?.id)) {
						if (this.filteredVendorList.length === 10) this.filteredVendorList.pop();
						this.filteredVendorList.unshift(vendor!);
					}
				}),
				tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
				finalize(() => this.switchSaving()))
			.subscribe(() => this.applicationDetailService.refreshApplicationInstance());
	}

	filterDeployed = (date: Date | null): boolean => {
		const phaseIn: number | undefined = !this.lifeCycle?.phaseInDate ? undefined : new Date(this.lifeCycle.phaseInDate).getTime();
		const phaseOut: number | undefined = !this.lifeCycle?.phaseOutDate ? undefined : new Date(this.lifeCycle.phaseOutDate).getTime();
		const retired: number | undefined = !this.lifeCycle?.retiredDate ? undefined : new Date(this.lifeCycle.retiredDate).getTime();
		return !!date
			&& date.getTime() >= (phaseIn ?? Number.NEGATIVE_INFINITY)
			&& date.getTime() <= (phaseOut ?? retired ?? Number.POSITIVE_INFINITY);
	}

	onBottomScroll(): void {
		this.scrollSubject.next();
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	switchSavingNewCategory(): Observable<{}> {
		this._savingNewCategory = !this._savingNewCategory;
		return of({});
	}

	switchSavingNewVendor(): Observable<{}> {
		this._savingNewVendor = !this._savingNewVendor;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	protected readonly Array = Array;
}
