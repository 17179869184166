<app-snackbar></app-snackbar>
<div class="pl-l pr-l pt-l pb-l primary">
	<div class="disp-f a-i-c j-c-s f-d-r mb-m">
		<app-chips [square]="true" [size]="'xl'" >
			<img src="assets/icons/grid.svg" class="filter-grey" height="25px">
		</app-chips>
		<div class="flex-column gap-XS ml-s">
			<div class="font-L fw-700">{{ 'page.organization.linkAnApp' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.organization.linkAnAppSubtitle' | translate }}</div>
		</div>
	</div>

	<div class="mb-m">
		<app-text-input [placeholder]="'page.applicationCatalog.searchPlaceholder' | translate" [input]="searchInput"></app-text-input>
	</div>

	<div class="bg-rounded" style="max-height: calc(80vh - 300px); min-height: calc(80vh - 300px); overflow: auto">
		<div *ngIf="searchLoading" class="pt-m pb-m pr-m pl-m"><app-list-loader></app-list-loader></div>
		<div *ngIf="!searchLoading">
			<div *ngFor="let app of data; let index = index">
				<div class="pl-m pr-m pt-m pb-m disp-f a-i-c j-c-sb">
                    <div class="disp-f a-i-c j-c-c gap-S overflow-hidden">
                        <app-display-logo [logo]="app.logo" [size]="38"></app-display-logo>
                        <span class="font-M fw-700 text-overflow-ellipsis">{{ app.name }}</span>
                    </div>
					<div class="flex-align-center gap-M">
						<img [tooltip]="'page.organization.apps.autodiscoveredApp' | translate" tooltipPosition="left" [tooltipOffset]="5" *ngIf="app.autodiscovered" src="assets/icons/autodiscover.svg" class="filter-accent-secondary">
						<mat-checkbox [checked]="app.selected" (change)="onAppSelectChange(app ,$event)"></mat-checkbox>
					</div>
				</div>
				<hr *ngIf="index < data.length - 1">
			</div>
			<div *ngIf="data.length === 0" style="height: calc(80vh - 300px);">
				<div class="disp-f a-i-c j-c-c h-100">
                    <span class="font-M fw-500 grey">{{ 'page.usageDashboard.noApps' | translate }}</span>
                </div>
			</div>
		</div>
	</div>

	<div class="w-100 disp-f f-d-r j-c-sb a-i-c mt-l gap-M">
		<app-text-button class="w-50" type="stroked"
                         (click)="onDismiss()">{{ 'button.cancel' | translate }}</app-text-button>
		<app-text-button class="w-50" type="flat" [loading]="_saving"
                         (click)="onConfirm()">{{ 'button.add' | translate }}</app-text-button>
	</div>
</div>

