<form *ngIf="!_initializing" class="flex-column gap-S" [formGroup]="generalForm">
	<div class="flex-align-center flex-column gap-S">
		<div *ngIf="organization.type === OrganizationType.BUSINESS_UNIT" class="square-icon-accent" style="height: 67px; width: 67px">
			<img [src]="!!iconValue ? ('/assets/icons/team_icon/' + iconValue + '.svg') : 'assets/icons/team.svg'" style="height: 45px!important; width: 45px!important;">
		</div>

		<div *ngIf="organization.type === OrganizationType.AFFILIATE" class="square-icon-accent" style="height: 67px; width: 67px">
			<img src="../../../../../../../assets/icons/building.svg" style="height: 45px!important; width: 45px!important;">
		</div>

		<div *ngIf="organization.type === OrganizationType.BUSINESS_UNIT" class="c-p mb-s mt-s"
			 (click)="iconSelection = !iconSelection">
			<span class="font-M fw-500 grey text-underline">{{'page.organization.creationPopup.iconSwap' | translate }}</span>
		</div>
		<div *ngIf="iconSelection" class="iconChoiceWrapper disp-f a-i-c j-c-sb pl-m pr-m pb-l pt-l gap-L">
			<div class="iconChoice disp-f a-i-c j-c-c c-p" [class.selected]="icon == iconValue"
				 *ngFor="let icon of icons"
				 (click)="onUpdateIcon(icon)">
				<img [src]="'assets/icons/team_icon/' + icon + '.svg'" class="filter-accent">
			</div>
		</div>
	</div>
	<div class="flex-column w-100">
		<div class="grey">{{'page.customApp.form.name'|translate}}</div>
		<app-text-input [input]="nameFormControl" (blur)="updateGeneralOnBlur.emit(formName.name)" (keydown.enter)="updateGeneralOnBlur.emit(formName.name)"></app-text-input>
	</div>
	<div class="flex-column w-100">
		<div class="flex-align-center gap-XS">
			<span class="grey">{{'page.organization.creationPopup.organizationId'|translate}}</span>
			<img src="assets/icons/help-2.svg" tooltipPosition="right" [tooltip]="'page.organization.creationPopup.organizationIdHint' | translate">
		</div>
		<app-copy-text style="flex-grow: 1" [text]="organization.organizationId"></app-copy-text>
	</div>
</form>
