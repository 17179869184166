<form *ngIf="!_initializing" class="flex-column gap-S" [formGroup]="generalForm">
	<div class="flex-column flex-align-center gap-S mb-m">
		<app-display-logo [loading]="uploading" [logo]="application.logo" [size]="65"></app-display-logo>
		<div (click)="uploadImage = !uploadImage" class="grey c-p">{{ 'page.organization.creationPopup.iconSwap' | translate }}</div>
	</div>
	<div *ngIf="uploadImage" class="flex-column flex-align-center gap-S mb-m">
		<div class="upload-file-container c-p"
			 (click)="selectFile()" (drop)="onDrop($event)" (dragover)="$event.preventDefault()" (dragleave)="$event.preventDefault()">
			<img ngSrc="assets/icons/upload-image.svg" class="filter-accent" height="35" width="35" alt="">
			<div class="font-S fw-500 mt-s">{{ 'page.appDetails.settings.dropYourImageHereOr' | translate }} <span class="font-S fw-700 accent">{{ 'page.appDetails.finance.contracts.browse' | translate }}</span></div>
			<div class="font-S fw-500 grey">{{ 'page.appDetails.settings.imageSupports' | translate }}</div>
			<div class="font-S fw-500 grey">{{ 'page.appDetails.settings.10MoMax' | translate }}</div>
		</div>
	</div>
	<div class="flex-column gap-M">
		<div class="flex gap-M w-100">
			<div class="flex-column w-100">
				<div class="grey">{{'page.customApp.form.name'|translate}}</div>
				<app-text-input [placeholder]="application.name"
								[input]="nameFormControl"
								(blur)="updateGeneralOnBlur.emit(formName.name)"
								(keyup.enter)="updateGeneralOnBlur.emit(formName.name)">
				</app-text-input>
			</div>
		</div>
		<div class="flex-column">
			<div class="grey">{{'page.appDetails.description'|translate}}</div>
			<app-text-input [placeholder]="'page.appDetails.descriptionHint'|translate"
							[input]="descriptionFormControl"
							(blur)="updateGeneralOnBlur.emit(formName.description)"
							(keyup.enter)="updateGeneralOnBlur.emit(formName.description)">
			</app-text-input>
		</div>
		<div class="flex-column">
			<div class="grey">{{'page.appDetails.accessUrl'|translate}}</div>
			<app-text-input [placeholder]="'page.appDetails.accessUrlPlaceholder'|translate"
							[input]="accessUrlFormControl"
							(blur)="updateGeneralOnBlur.emit(formName.accessUrl)"
							(keyup.enter)="updateGeneralOnBlur.emit(formName.accessUrl)">
			</app-text-input>
		</div>
		<div class="flex gap-M w-100">
			<div class="flex-column w-100">
				<div class="grey">{{'page.appDetails.settings.supportPhone'|translate}}</div>
				<app-text-input [placeholder]="'page.appDetails.settings.supportPhonePlaceholder'|translate"
								[input]="supportPhoneFormControl"
								(blur)="updateGeneralOnBlur.emit(formName.supportPhone)"
								(keyup.enter)="updateGeneralOnBlur.emit(formName.supportPhone)">
				</app-text-input>
			</div>
			<div class="flex-column w-100">
				<div class="grey">{{'page.appDetails.settings.supportEmail'|translate}}</div>
				<app-text-input [placeholder]="'page.appDetails.settings.supportEmailPlaceholder'|translate"
								[input]="supportEmailFormControl"
								(blur)="updateGeneralOnBlur.emit(formName.supportEmail)"
								(keyup.enter)="updateGeneralOnBlur.emit(formName.supportEmail)">
				</app-text-input>
			</div>
			<div class="flex-column w-100">
				<div class="grey">{{'page.appDetails.settings.supportUrl'|translate}}</div>
				<app-text-input [placeholder]="'page.appDetails.settings.supportUrlPlaceholder'|translate"
								[input]="supportUrlFormControl"
								(blur)="updateGeneralOnBlur.emit(formName.supportUrl)"
								(keyup.enter)="updateGeneralOnBlur.emit(formName.supportUrl)">
				</app-text-input>
			</div>
		</div>
	</div>
</form>
