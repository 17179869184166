<div class="primary disp-f f-d-col j-c-sb w-100 organizationCreationWrapper" [class.openedIconChoice]="iconSelection">
	<div class="pad-L">
		<div class="disp-f a-i-c j-c-s f-d-r mb-xl">
			<app-chips [square]="true" [size]="'xl'" >
				<img src="assets/icons/team.svg" class="filter-grey" height="20px" alt="">
			</app-chips>
			<div class="flex-column gap-XS ml-s">
				<div class="font-L fw-700">{{ 'page.organization.creationPopup.newBusinessUnitPlaceholder' | translate }}</div>
				<div class="font-M fw-300">{{ 'page.organization.creationPopup.organizationModel' | translate }}</div>
			</div>
		</div>
		<div class="flex-column gap-M">
			<div class="flex-column flex-center">
				<div class="square-icon-accent" style="height: 48px; width: 48px">
					<img [src]="!!iconFormValue ? 'assets/icons/team_icon/' + iconFormValue + '.svg' : 'assets/icons/team.svg'" style="height: 20px;width: 20px;object-fit: contain;">
				</div>
				<div (click)="iconSelection = !iconSelection" class="text-underline c-p grey mb-s mt-s">{{'page.organization.creationPopup.iconSwap' | translate}}</div>
				<div *ngIf="iconSelection" class="iconChoiceWrapper disp-f a-i-c j-c-sb pl-s pr-s pb-s pt-s gap-S">
					<div class="iconChoice disp-f a-i-c j-c-c c-p" [class.selected]="icon.id == iconFormValue"
						 *ngFor="let icon of iconChoices"
						 (click)="onUpdateIcon(icon)">
						<img [src]="'assets/icons/team_icon/' + icon.name + '.svg'" class="filter-accent">
					</div>
				</div>
			</div>
			<div class="flex-column gap-XS">
				<div class="font-M fw-500 grey">Nom</div>
				<app-text-input [input]="nameFormControl" [placeholder]="'Mon équipe'"></app-text-input>
			</div>
		</div>
	</div>
	<div class="disp-f f-d-r j-c-sb a-i-c gap-M pad-L" style="width: auto">
		<app-text-button class="w-50" type="stroked"
						 (click)="onNoClick()">{{ 'button.cancel' | translate }}</app-text-button>
		<app-text-button class="w-50" type="flat" [disabled]="formGroup.invalid"
						 (click)="onCreate()">{{ 'button.add' | translate }}</app-text-button>
	</div>
</div>
