<div class="flex-column gap-widget">
	<!-- Users rates-->
	<div class="flex-space-between w-100 gap-L">
		<div class="w-100">
            <div class="bg-rounded w-100 top-widget-height"
                 [tooltip]="'page.appDetails.usage.tooltipDeactivated'|translate"
                 [tooltipDisabled]="!!application && application.usageActivated">
                <application-mini-widget
                        [class.c-p]="!!application && !application.usageActivated"
                        [widget]="applicationUsersWidget"
                        [type]="'users'"
                        [title]="'menu.users' | translate"
                        (click)="navigateToUsageParameter()"
                ></application-mini-widget>
            </div>
		</div>

		<div class="w-100">
            <div class="bg-rounded w-100 top-widget-height"
                 [tooltip]="'page.appDetails.usage.tooltipDeactivated'|translate"
                 [tooltipDisabled]="!!application && application.usageActivated">
                <application-mini-widget
                        [class.c-p]="!!application && !application.usageActivated"
                        [widget]="activityTimeWidget"
                        [type]="'activity'"
                        [title]="'page.appDetails.activityTimePerDay' | translate"
                        (click)="navigateToUsageParameter()"
                ></application-mini-widget>
            </div>
		</div>

		<div class="w-100">
            <div class="bg-rounded w-100 top-widget-height"
                 [tooltip]="'page.appDetails.usage.tooltipDeactivated'|translate"
                 [tooltipDisabled]="!!application && application.usageActivated">
                <application-mini-widget
                        [class.c-p]="!!application && !application.usageActivated"
                        [widget]="satisfactionWidget"
                        [type]="'satisfaction'"
                        [title]="'Satisfaction'"
                        [subtitle]="!applicationUsage || !applicationUsage.satisfaction.rating.value ? undefined : applicationUsage.satisfaction.votersCount + ' avis'"
                        (click)="navigateToUsageParameter()"
                ></application-mini-widget>
            </div>
		</div>
	</div>

	<!-- Activity histogram -->
	<div class="bg-rounded pad-M">
		<div *ngIf="!initialized" style="margin-bottom: 4px;">
			<app-linechart-loader></app-linechart-loader>
		</div>
		<app-usage-histogram *ngIf="initialized"
							 [application]="application"
							 [usagePerMonth]="applicationUsage.usagePerMonth"
							 [usagePerDay]="applicationUsage.usagePerDay"
		                     [usagePerHour]="applicationUsage.usagePerHour">
		</app-usage-histogram>
	</div>

	<!-- Teams -->
	<div class="flex-column bg-rounded gap-S pad-M">
		<div class="flex-align-center flex-space-between p-relative">
			<div class="font-MM fw-700 flex-align-center gap-S">
				<span>{{'global.teams'|translate}}</span>
			</div>
			<div class="flex-align-center gap-M">
				<div *ngIf="initialized && !applicationUsage.incognito && getTotalUsersCount(applicationUsage.teams) > 0" class="flex-align-center search-bar-container bkg-white b-radius-9">
					<app-text-button type="default"
									 color="primary"
									 class="search-btn"
									 panelClass="h-100-imp pad-0-imp"
									 [disabled]="_initializing"
									 (click)="openSearchPanel(input)">
						<div class="flex-center">
							<img src="assets/icons/search-md.svg" width="16" height="16" alt=""/>
						</div>
					</app-text-button>
					<div class="flex-space-between">
						<input #input class="search-input" [ngClass]="{'display-input': openSearch}"
							   [formControl]="searchUserFormControl" [placeholder]="openSearch ? ('page.appDetails.usage.searchUser'|translate) : ''">
						<app-text-button (click)="clearInput()" *ngIf="openSearch && searchValue" type="default" color="primary" panelClass="h-100-imp pad-0-imp" class="delete-text-button">
							<div class="flex-center">
								<img src="assets/icons/close.svg" alt="">
							</div>
						</app-text-button>
						<div *ngIf="openSearch && !searchValue" style="width: 30px; height: 30px;">
						</div>
					</div>
				</div>
				<app-mini-button size="s"
								 color="accent"
								 [disable]="_initializing || _loading"
								 (onClick)="onChangeTeams()">
					<img src="assets/icons/add.svg" class="filter-accent">
				</app-mini-button>
			</div>
			<div class="auto-discover-badge flex-center" *ngIf="autodiscoveredTeamsCount > 0">
				<span class="font-S fw-500">{{ autodiscoveredTeamsCount }}</span>
			</div>
		</div>
		<div *ngIf="!initialized">
			<app-list-loader ></app-list-loader>
		</div>
		<div *ngIf="initialized && applicationUsage.teams.length > 0" class="flex-column gap-S">
			<app-team-card *ngFor="let team of filteredTeams"
						   [team]="team" [canDevelop]="!applicationUsage.incognito">
			</app-team-card>
			<div *ngIf="filteredTeams.length <= 0" class="flex-center flex-column gap-L" style="height: 200px;">
				<img class="mr-xs" src="assets/illustrations/no_announcement.svg" height="100" alt="">
				<span class="font-M fw-500 grey">{{ 'page.organization.overview.noUserSearch' | translate }}</span>
			</div>
		</div>
		<div *ngIf="initialized && applicationUsage.teams.length <= 0" class="flex-center flex-column gap-L" style="height: 200px;">
			<img class="mr-xs" src="assets/illustrations/no_announcement.svg" height="100" alt="">
			<span class="font-M fw-500 grey">{{ 'page.appDetails.usage.noTeamLinked' | translate }}</span>
		</div>
	</div>
</div>
