<div class="flex-column gap-widget">
	<div class="flex-space-between w-100 gap-L">
		<div class="w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="bg-rounded w-100 top-widget-height">
				<application-mini-widget
					[widget]="availabilityWidget"
					[type]="'availability'"
					[title]="'menu.applicationAvailability' | translate"
				></application-mini-widget>
			</div>
		</div>
		<div class="w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="bg-rounded w-100 top-widget-height">
				<application-mini-widget
					[widget]="usageWidget"
					[type]="'users'"
					[title]="'menu.users' | translate"
				></application-mini-widget>
			</div>
		</div>
		<div class="bg-rounded w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<application-mini-widget
				[widget]="tcoWidget"
				[type]="'tco'"
				[title]="'page.appDetails.overview.tco'|translate"
			></application-mini-widget>
		</div>
	</div>
	<div *ngIf="organization && organization.type === OrganizationType.BUSINESS_UNIT" class="bg-rounded">
		<app-team-responsible [data]="teamResponsibleData"></app-team-responsible>
	</div>
	<div class="bg-rounded pad-M">
		<app-organization-users-list [incognito]="data?.incognito" [users]="data?.users"></app-organization-users-list>
	</div>
</div>
