import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {finalize, forkJoin, mergeMap, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {Organization, OrganizationService, OrganizationTree} from "src/app/services/organization.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Announcement, AnnouncementService, PostAnnouncement} from "src/app/services/announcement.service";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {CommonModule, DatePipe, NgOptimizedImage} from "@angular/common";
import {ApplicationIconModule} from "../../global/application-icon/application-icon.module";
import {CheckModule} from "../../global/check/check.module";
import {ChipsModule} from "../../global/chips/chips/chips.module";
import {DeckContentLoaderModule} from "../../global/title-content-loader/deck-content-loader.module";
import {MatRippleModule} from "@angular/material/core";
import {SimpleTextInputModule} from "../../global/input/simple-text-input/simple-text-input.module";
import {TextareaModule} from "../../global/input/textarea/textarea.module";
import {SnackbarModule} from "../../global/snackbar/snackbar.module";
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {map} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";
import {ApplicationGeneric} from "../../../services/model/new-application.model";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {RoverService} from "../../../services/rover.service";
import {TenantExtensionSettings, TenantService} from "../../../services/tenant.service";

@Component({
	selector: 'app-announcement',
	templateUrl: './announcement.component.html',
	styleUrls: ['./announcement.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		ApplicationIconModule,
		CheckModule,
		ChipsModule,
		DeckContentLoaderModule,
		MatRippleModule,
		SimpleTextInputModule,
		TextareaModule,
		TranslateModule,
		NgOptimizedImage,
		ReactiveFormsModule,
		SnackbarModule,
		DesignSystemModule,
		MatDatepickerModule
	]
})
export class AnnouncementComponent implements OnInit {
	announcementForm: FormGroup;
	form: typeof AnnouncementForm = AnnouncementForm;

	announcementTypeChoices: AnnouncementTypeChoice[] = [];

	organization: OrganizationTree;
	applicationList: ApplicationGeneric[] = [];

	filteredOrganizations: OrganizationTree[] = [];
	filteredApps: ApplicationGeneric[] = [];

	tenantSettings?: TenantExtensionSettings;

	searchAppControl: FormControl;
	searchTeamControl: FormControl;

	subscriptions: Subscription = new Subscription();

	_loading: boolean = false;
	_saving: boolean = false;

	tenantId: string;

	constructor(protected currentTenantService: CurrentTenantService,
				protected organizationService: OrganizationService,
				protected applicationService: NewApplicationService,
				protected announcementService: AnnouncementService,
				protected snackbarService: SnackbarService,
				public dialogRef: MatDialogRef<any>,
				protected translate: TranslateService,
				protected cdr: ChangeDetectorRef,
				protected datePipe: DatePipe,
				private tenantService: TenantService,
				@Inject(MAT_DIALOG_DATA) public data: { announcement?: Announcement, appId?: string }
	) {
	}

	ngOnInit() {
		this.buildForm();
		this.buildSelectionList();
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => this.currentTenantService.getCurrentTenantIdChanges()),
				tap(tenantId => this.tenantId = tenantId),
				mergeMap(() => forkJoin([
					this.tenantService.getTenantExtensionSettings(this.tenantId),
					this.organizationService.getOrganizationTreeByTenantId(this.tenantId),
					this.applicationService.getAllApplication(this.tenantId)
				])),
				tap(([tenantSettings, organization, applications]) => {
					this.tenantSettings = tenantSettings;
					this.organization = organization;
					this.applicationList = applications
					this.filteredOrganizations = organization.children;
					this.filteredApps = applications;
				})
			)
			.subscribe(() => {
				this.switchLoading()
				this.setDefaultData()
			})
		)
	}

	buildForm(): void {
		this.announcementForm = new FormGroup({
			[AnnouncementForm.title]: new FormControl(undefined, [Validators.required]),
			[AnnouncementForm.content]: new FormControl(undefined, [Validators.required]),
			[AnnouncementForm.type]: new FormControl(undefined, [Validators.required]),
			[AnnouncementForm.teams]: new FormControl([], [this.data.announcement?.type === 'general' || this.data.announcement?.type === 'network' ? Validators.required : Validators.nullValidator]),
			[AnnouncementForm.app]: new FormControl(undefined, [this.data.announcement?.type === 'maintenance' ? Validators.required : Validators.nullValidator]),
			[AnnouncementForm.start_date]: new FormControl(new Date(), [Validators.required]),
			[AnnouncementForm.end_date]: new FormControl(undefined)
		})

		this.cdr.detectChanges();

		this.subscriptions.add(this.announcementForm.get(AnnouncementForm.type)?.valueChanges
			.subscribe((value: AnnouncementTypeChoice) => {
				this.updateTeamAndAppValidators(value.id)
			}))

		this.searchAppControl = new FormControl('');
		this.subscriptions.add(this.searchAppControl.valueChanges
			.subscribe(value => {
				this.filteredApps = this.applicationList
					.filter(app => app.name.toLowerCase().includes(value.toLowerCase()))
					.sort((a, b) => a.name.localeCompare(b.name))
			}))

		this.searchTeamControl = new FormControl('');
		this.subscriptions.add(this.searchTeamControl.valueChanges
			.subscribe(value => {
				if (!value) {
					this.filteredOrganizations = this.organization.children;
				} else {
					const lowercaseValue = value.toLowerCase();
					this.filteredOrganizations = this.organization.children
						.reduce((acc: OrganizationTree[], org) => {
							const matchingChildren = org.children.filter(team =>
								team.organization.name.toLowerCase().includes(lowercaseValue)
							);

							if (org.organization.name.toLowerCase().includes(lowercaseValue) || matchingChildren.length > 0) {
								acc.push({
									...org,
									children: org.organization.name.toLowerCase().includes(lowercaseValue)
										? org.children
										: matchingChildren
								});
							}
							return acc;
						}, [])
						.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
				}
			}))
	}

	buildSelectionList(): void {
		this.announcementTypeChoices = [
			{id: AnnouncementType.GENERAL, name: this.translate.instant('global.announcements.general')},
			{id: AnnouncementType.NETWORK, name: this.translate.instant('global.announcements.network')},
			{id: AnnouncementType.MAINTENANCE, name: this.translate.instant('global.announcements.maintenance')},
		]
	}

	setDefaultData(): void {
		if (this.data.announcement) {
			const teams = this.filteredOrganizations
				.flatMap(org => org.children)
				.filter(team => this.data.announcement?.teams?.find(teamId => teamId.id === team.organization.organizationId))
				.map(team => team.organization)
			const app = this.applicationList.find(app => app.id === this.data.announcement?.app?.id);

			this.announcementForm.get(AnnouncementForm.title)?.setValue(this.data.announcement.title)
			this.announcementForm.get(AnnouncementForm.start_date)?.setValue(this.data.announcement.startDate)
			this.announcementForm.get(AnnouncementForm.end_date)?.setValue(this.data.announcement.endDate)
			this.announcementForm.get(AnnouncementForm.content)?.setValue(this.data.announcement.content)
			this.announcementForm.get(AnnouncementForm.type)?.setValue(this.announcementTypeChoices.find(choice => choice.id === this.data.announcement!.type))
			this.announcementForm.get(AnnouncementForm.teams)?.setValue(teams)
			this.announcementForm.get(AnnouncementForm.app)?.setValue(app)
		} else {
			this.announcementForm.get(this.form.type)?.setValue(this.announcementTypeChoices.find(choice => choice.id === AnnouncementType.GENERAL)!)
		}

		if (this.data.appId) {
			this.announcementForm.get(AnnouncementForm.type)?.setValue(this.announcementTypeChoices.find(choice => choice.id === AnnouncementType.MAINTENANCE)!)
			this.announcementForm.get(AnnouncementForm.app)?.setValue(this.applicationList.find(app => app.id === this.data.appId))
		}
	}

	updateTeamAndAppValidators(value: AnnouncementType): void {
		if (value === AnnouncementType.NETWORK || value === AnnouncementType.GENERAL) {
			this.announcementForm.get(AnnouncementForm.teams)?.setValidators([Validators.required]);
			this.announcementForm.get(AnnouncementForm.app)?.clearValidators();

			this.announcementForm.get(AnnouncementForm.teams)?.enable();
			this.announcementForm.get(AnnouncementForm.app)?.disable();

			this.announcementForm.get(AnnouncementForm.app)?.setValue(undefined);
			(this.announcementForm.get(AnnouncementForm.teams))?.setValue([]);
			this.announcementForm.get(AnnouncementForm.teams)?.setValue([]);
		} else {
			this.announcementForm.get(AnnouncementForm.app)?.setValidators([Validators.required]);
			this.announcementForm.get(AnnouncementForm.teams)?.clearValidators();

			this.announcementForm.get(AnnouncementForm.app)?.enable();
			this.announcementForm.get(AnnouncementForm.teams)?.disable();

			this.announcementForm.get(AnnouncementForm.app)?.setValue(undefined);
			(this.announcementForm.get(AnnouncementForm.teams))?.setValue([]);
			this.announcementForm.get(AnnouncementForm.teams)?.setValue([]);
		}
		this.announcementForm.updateValueAndValidity({emitEvent: false})
	}

	onClose() {
		this.dialogRef.close()
	}

	save() {
		if (this.announcementForm.valid) {
			const form: PostAnnouncement = this.buildPostAnnouncement();
			this.switchSaving()
				.pipe(
					switchMap(() => !this.data.announcement
						? this.announcementService.createAnnouncement(this.tenantId, form)
						: this.announcementService.updateAnnouncement(this.tenantId, form, this.data.announcement.id)
							.pipe(map(() => this.data.announcement!.id))),
					map(id => this.buildNewAnnouncement(id, form)),
					finalize(() => this.switchSaving()))
				.subscribe({
					next: (announcement) => {
						this.dialogRef.close({success: true, data: announcement});
					},
					error: () => {
						this.snackbarService.show(this.translate.instant('global.announcements.error'), 'danger-snack')
					}
				})
		}
	}

	private buildPostAnnouncement(): PostAnnouncement {
		return {
			title: this.titleFormValue!,
			content: this.contentFormValue!,
			type: this.typeFormValue!.id,
			teams: this.typeFormValue!.id === AnnouncementType.NETWORK || this.typeFormValue!.id === AnnouncementType.GENERAL
				? this.teamsFormControl.value.map((org: Organization) => org.organizationId)
				: undefined,
			app: this.typeFormValue!.id === AnnouncementType.MAINTENANCE
				? this.appFormValue!.id
				: undefined,
			startDate: new Date(this.datePipe.transform(this.startDateFormControl.value, "yyyy-MM-dd")!),
			endDate: !this.endDateFormControl.value
				? undefined
				: new Date(this.datePipe.transform(this.endDateFormControl.value, "yyyy-MM-dd")!),
		}
	}

	private buildNewAnnouncement(id: string, form: PostAnnouncement): Announcement {
		const responseData: Announcement = form as Announcement;
		responseData.id = id;
		responseData.createdAt = this.data.announcement?.createdAt ?? new Date();
		responseData.updatedAt = new Date();
		responseData.seenCount = this.data.announcement?.seenCount ?? 0;
		return responseData;
	}

	get titleFormControl(): FormControl {
		return this.announcementForm.get(AnnouncementForm.title) as FormControl;
	}

	get titleFormValue(): string | undefined {
		return this.titleFormControl.value
	}

	get typeFormControl(): FormControl {
		return this.announcementForm.get(AnnouncementForm.type) as FormControl;
	}

	get typeFormValue(): AnnouncementTypeChoice | undefined {
		return this.typeFormControl.value;
	}

	get contentFormControl(): FormControl {
		return this.announcementForm.get(AnnouncementForm.content) as FormControl;
	}

	get contentFormValue(): string | undefined {
		return this.contentFormControl.value
	}

	get teamsFormControl(): FormControl {
		return this.announcementForm.get(AnnouncementForm.teams) as FormControl;
	}

	get appFormControl(): FormControl {
		return this.announcementForm.get(AnnouncementForm.app) as FormControl;
	}

	get appFormValue(): ApplicationGeneric | undefined {
		return this.appFormControl.value;
	}

	get startDateFormControl(): FormControl {
		return this.announcementForm.get(AnnouncementForm.start_date) as FormControl;
	}

	get endDateFormControl(): FormControl {
		return this.announcementForm.get(AnnouncementForm.end_date) as FormControl;
	}

	get minStartDate(): Date {
		return new Date();
	}

	get maxStartDate(): Date | undefined {
		if (this.endDateFormControl.value) {
			return this.endDateFormControl.value
		}
		return undefined
	}

	get minEndDate(): Date {
		return this.startDateFormControl.value
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected readonly Array = Array;
	protected readonly FormControl = FormControl;
	protected readonly environment = environment;
	protected readonly AnnouncementType = AnnouncementType;
}

enum AnnouncementForm {
	title = 'title',
	content = 'content',
	type = 'type',
	teams = 'teams',
	app = 'app',
	start_date = 'start_date',
	end_date = 'end_date',
}

interface AnnouncementTypeChoice {
	id: AnnouncementType;
	name: string;
}

enum AnnouncementType {
	GENERAL = 'general',
	NETWORK = 'network',
	MAINTENANCE = 'maintenance',
}
