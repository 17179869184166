<div class="flex-column gap-M fix-select-height">
	<!-- GENERAL PANEL -->
	<div class="expansion-panel">
		<div class="flex-align-center flex-space-between pad-M" style="border-radius: 10px;">
			<div class="font-MM fw-700 primary">{{'menu.general'|translate}}</div>
		</div>
		<div class="pad-M">
			<app-application-setting-general></app-application-setting-general>
		</div>
	</div>
	<!-- USAGE PANEL -->
	<div class="expansion-panel">
		<app-application-setting-usage></app-application-setting-usage>
	</div>
</div>
