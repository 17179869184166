<div style="touch-action: none;"  class="diagram-tooltip-container" [style.top]="positionTop + 'px'" [style.left]="positionLeft + 'px'">
	<div class="diagram-tooltip">
		<div class="diagram-tooltip-inner">
			<div
				[class.mb-l]="data.mappingDataList.length > 0 || data.description">
				<div class="flex-space-between flex-align-center">
					<span class="fw-700 font-MM">{{ 'page.appDetails.architecture.dialog.flowDetails' | translate }}</span>
					<app-icon-button
						size="m"
						[img]="'assets/icons/edit.svg'"
						(click)="openApplicationMappingFormDialog(data)"
						type="default"
						color="accent"
						[panelClass]="'fix-edit-margin'">
					</app-icon-button>
				</div>
				<div class="wrapper mt-m">
					<div class="first first-col-table-cell flex-align-center flex-space-between">
						<div style="border-right: 1px solid #F2F2F8; padding-right: 10px" class="flex-align-center flex-space-between w-100">
							<div class="flex gap-XS flex-align-center">
								<img class="filter-grey" src="assets/icons/protocole.svg" alt="">
								<span class="grey">{{ 'page.appDetails.architecture.dialog.protocol' | translate }}</span>
							</div>
							<span *ngIf="data.protocol" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.protocol.' + data.protocol | translate }}</span>
							<span *ngIf="!data.protocol">-</span>
						</div>
					</div>
					<div class="second second-col-table-cell flex-align-center flex-space-between">
						<div class="flex-align-center flex-space-between w-100" style="padding-left: 10px">
							<div class="flex gap-XS flex-align-center">
								<img class="filter-grey" src="assets/icons/format.svg" alt="">
								<span class="grey">{{ 'page.appDetails.architecture.dialog.format' | translate }}</span>
							</div>
							<span *ngIf="data.format" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.format.' + data.format | translate }}</span>
							<span *ngIf="!data.format">-</span>
						</div>
					</div>
					<div class="third first-col-table-cell flex-align-center flex-space-between">
						<div style="border-right: 1px solid #F2F2F8; padding-right: 10px" class="flex-align-center flex-space-between w-100">
							<div class="flex gap-XS flex-align-center">
								<img class="filter-grey" src="assets/icons/port-type.svg" alt="">
								<span class="grey">{{ 'page.appDetails.architecture.dialog.portType' | translate }}</span>
							</div>
							<span *ngIf="data.portType" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.portType.' + data.portType | translate }}</span>
							<span *ngIf="!data.portType">-</span>
						</div>
					</div>
					<div class="fourth second-col-table-cell flex-align-center flex-space-between">
						<div class="flex-align-center flex-space-between w-100" style="padding-left: 10px">
							<div class="flex gap-XS flex-align-center">
								<img class="filter-grey" src="assets/icons/port-type.svg" alt="">
								<span class="grey">{{ 'page.appDetails.architecture.dialog.port' | translate }}</span>
							</div>
							<span *ngIf="data.portType" class="fw-700 text-overflow-ellipsis" style="max-width: 120px;">{{ data.port }}</span>
							<span *ngIf="!data.port">-</span>
						</div>
					</div>
					<div class="fifth first-col-table-cell flex-align-center flex-space-between">
						<div style="border-right: 1px solid #F2F2F8; padding-right: 10px" class="flex-align-center flex-space-between w-100">
							<div class="flex gap-XS flex-align-center">
								<img class="filter-grey" src="assets/icons/frequency.svg" alt="">
								<span class="grey">{{ 'page.appDetails.architecture.dialog.exchangeFrequencyShort' | translate }}</span>
							</div>
							<span *ngIf="data.exchangeFrequency" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.exchangeFrequency.' + data.exchangeFrequency | translate }}</span>
							<span *ngIf="!data.exchangeFrequency">-</span>
						</div>
					</div>
					<div class="sixth second-col-table-cell flex-align-center flex-space-between">
						<div class="flex-align-center flex-space-between w-100" style="padding-left: 10px">
							<div class="flex gap-XS flex-align-center">
								<img class="filter-grey" src="assets/icons/encryption.svg" alt="">
								<span class="grey">{{ 'page.appDetails.architecture.dialog.flowEncryption' | translate }}</span>
							</div>
							<span *ngIf="data.flowEncryption !== undefined && data.flowEncryption !== null" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.flowEncryption.' + data.flowEncryption | translate }}</span>
							<span *ngIf="data.flowEncryption === undefined || data.flowEncryption === null">-</span>
						</div>
					</div>
				</div>
			</div>
			<!-- DATA -->
			<div *ngIf="data.mappingDataList.length > 0" [class.mb-l]="data.description">
				<span class="fw-700 font-MM">{{ 'page.appDetails.architecture.dialog.data' | translate }}</span>
				<div class="flex mt-m gap-M flex-wrap">
					<div *ngFor="let flowData of data.mappingDataList" class="data-bubble flex-center">
						<span class="text-overflow-ellipsis">{{ flowData.name }}</span>
					</div>
				</div>
			</div>
			<!-- COMMENTS -->
			<div *ngIf="data.description">
				<span class="fw-700 font-MM">{{ 'page.appDetails.architecture.dialog.comments' | translate }}</span>
				<div class="mt-m pad-M comment-box">
					{{ data.description }}
				</div>
			</div>
		</div>
	</div>
</div>
