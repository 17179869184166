<div class="team-card">
	<div (click)="onTeamClick(team)" class="pl-s pr-m pt-s pb-s flex-space-between flex-align-center gap-S"
		 [class.c-p]="canDevelop && team.users.length > 0"
		 [class.greyed-hover-2]="canDevelop && team.users.length > 0">
		<div class="flex-align-center team-title c-p" (click)="$event.preventDefault(); goToTeam(team)">
			<div style="user-select: none" class="disp-f justify-center a-i-c gap-S">
				<div class="list-icon flex-center p-relative">
					<img [src]="!!team.icon ? ('/assets/icons/team_icon/' + team.icon + '.svg') : 'assets/icons/team.svg'" class="filter-accent">
				</div>
				<div class="flex-justify-center flex-column" [style.max-width]="team.autodiscovered ? '280px' : '300px'">
					<div class="business-unit text-overflow-ellipsis hovered-underline">{{ team.businessUnitName }}</div>
					<div class="structure text-overflow-ellipsis hovered-underline">{{ team.structureName }}</div>
				</div>
				<img *ngIf="team.autodiscovered"
					 [tooltip]="'page.appDetails.usage.tooltip'|translate"
					 tooltipPosition="right"
					 alt="caution"
					 src="assets/icons/attention.svg"
					 height="18">
			</div>
		</div>
		<div class="flex-align-center gap-XL">
			<div *ngIf="team.userTotalCount">
				<app-chips-count [value]="!team.userCount ? '0' + ' / ' + team.userTotalCount : team.userCount + ' / ' + team.userTotalCount" type="user" color="accent"></app-chips-count>
			</div>
			<div *ngIf="!team.userTotalCount">
				<app-chips-count [value]="undefined" type="user" bold></app-chips-count>
			</div>
			<div *ngIf="canDevelop && team.users.length > 0" style="margin-left: auto">
				<div class="disp-f justify-center a-i-c">
					<img src="assets/icons/arrow-right.svg" [@arrowOpenClose]="team.opened ? 'open' : 'closed'">
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="canDevelop && team.users.length > 0" class="pl-m pr-m pt-m pb-m flex-align-start gap-M overflow-hidden" [@openClose]="team.opened ? 'open' : 'closed'">
		<img src="assets/icons/arrow-unfolded.svg" class="filter-lightgrey pl-s" width="25"
			 alt="">
		<div class="pt-s flex-justify-center gap-M flex-column" style="width: 88%;">
			<div *ngFor="let user of team.users" [style.user-select]="isInactiveFor30Days(user.lastActivity) ? 'none' : 'auto'">
				<div class="flex-align-center flex-space-between">
					<div [style.filter]="isInactiveFor30Days(user.lastActivity) ? 'opacity(0.4)' : undefined" class="flex-align-center gap-S">
						<div class="user-bubble flex-center">
							<img *ngIf="(user.userName || user.deviceName) && !isInactiveFor30Days(user.lastActivity)" src="assets/icons/userss.svg" class="filter-accent">
							<img *ngIf="(user.userName || user.deviceName) && isInactiveFor30Days(user.lastActivity)" src="assets/icons/inactive-user.svg" class="filter-accent">
							<img *ngIf="!user.userName && !user.deviceName" src="assets/icons/anonymous-user.svg" class="filter-accent">
						</div>
						<div *ngIf="user.userName || user.deviceName" class="flex-column flex-align-start flex-justify-center gap-XS">
							<span *ngIf="user.userName" class="fw-700 font-M">{{ user.userName }}</span>
							<span *ngIf="user.deviceName" class="font-S grey">{{ user.deviceName }}</span>
						</div>
						<div *ngIf="!user.userName && !user.deviceName" class="flex-column flex-align-start flex-justify-center gap-XS">
							<span class="fw-700 font-M">{{ 'page.appDetails.usage.anonymousUser' | translate }}</span>
						</div>
					</div>
					<div class="inactive-bubble inactive-30-days" *ngIf="isInactiveFor30Days(user.lastActivity)">
						{{ 'page.appDetails.usage.inactiveFor30Days' | translate }}
					</div>
					<div class="inactive-bubble inactive-10-days" *ngIf="isInactiveFor10Days(user.lastActivity)">
						{{ "page.appDetails.usage.inactiveForXDays" | translate: { days: getDiffDays(user.lastActivity) } }}
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

