<div *ngIf="!customTrigger" class="select-trigger-container" [class.select-disabled]="disabled" #triggerContainer
     [matMenuTriggerFor]="selectMenu" (menuOpened)="onOpen()">
    <ng-content select="[select-trigger], app-select-trigger"></ng-content>
</div>
<div *ngIf="customTrigger" class="select-trigger-container" [class.select-disabled]="disabled" #triggerContainer
	 [matMenuTriggerFor]="selectMenu" (menuOpened)="onOpen()">
	<ng-content select="[custom-trigger]"></ng-content>
</div>
<mat-menu #selectMenu class="mat-menu-custom"
          xPosition="after" yPosition="below"
          (click)="$event.stopPropagation()"
          (closed)="onClose()">
    <div class="flex-column gap-S pad-S-0" [style.min-width.px]="minPanelWidth"
         (click)="$event.stopPropagation()">
        <div class="select-search-container" [class.select-disabled]="loading || disabled">
            <ng-content select="[select-search], app-select-search"></ng-content>
        </div>
        <div class="select-insert-container" [class.select-disabled]="loading || disabled">
            <ng-content select="[select-insert], app-select-insert"></ng-content>
        </div>
        <div *ngIf="!loading" class="flex-column select-option-container" #optionContainer
			 (scroll)="onScroll($event)"
             [class.select-disabled]="disabled" [class.mr-s]="hasScroll">
            <ng-content select="app-select-option"></ng-content>
            <ng-content select="app-select-option-group"></ng-content>
            <div *ngIf="isEmpty" class="flex-center pad-L">
                <span class="font-M fw-500 grey text-center" [style.max-width.px]="minPanelWidth - 56">{{'dropdown.noResult'|translate}}</span>
            </div>
        </div>
        <div *ngIf="loading" class="flex-center select-loader-container" [class.select-disabled]="disabled">
            <mat-spinner color="accent" [diameter]="16"></mat-spinner>
        </div>
        <div *ngIf="!loading && multiple" class="flex-center pad-S-S-0 border-light-grey-top">
            <app-text-button type="flat" class="w-100" (click)="onConfirm()">{{'Appliquer'}}</app-text-button>
        </div>
    </div>
</mat-menu>
