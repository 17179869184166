import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from '@angular/common';
import {ContractInfo, DurationOfUse, DurationUnit} from 'src/app/services/model/application-contract.model';
import {TempLicenseForm, TempServiceFrom} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/application-contract-form-dialog.component';
import {Organization, OrganizationTree} from 'src/app/services/organization.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-licence-form',
	templateUrl: './licence-form.component.html',
	styleUrls: ['./licence-form.component.scss']
})
export class LicenceFormComponent implements OnInit {

	@Input() organization: OrganizationTree;
	@Input() info?: ContractInfo;

	@Output() onChange: EventEmitter<TempLicenseForm> = new EventEmitter<TempLicenseForm>();

	licenceForm: FormGroup;
	amortizationUnitChoices: AmortizationChoice[] = [];
	durationOfUseChoices: DurationChoice[] = [];
	filteredOrganizations: OrganizationTree[] = [];
	searchOrganizationControl: FormControl;
	currencySymbol: string;
	form: typeof Form = Form;
	subscription: Subscription = new Subscription();

	constructor(private translate: TranslateService,
							private datePipe: DatePipe) {
	}

	ngOnInit() {
		this.buildSelectionLists();
		this.buildForm();
		this.setDefaultData();
		this.currencySymbol = localStorage.getItem('currency')!;
	}

	private buildSelectionLists(): void {
		this.amortizationUnitChoices = [
			{id: DurationUnit.MONTH, name: this.translate.instant('page.appDetails.finance.contracts.month')},
			{id: DurationUnit.YEAR, name: this.translate.instant('page.appDetails.finance.contracts.year')}
		];
		this.durationOfUseChoices = [
			{id: DurationOfUse.PERPETUAL, name: this.translate.instant('page.appDetails.finance.contracts.perpetual')},
			{id: DurationOfUse.ONE_YEAR, name: '1 ' + this.translate.instant('page.appDetails.finance.contracts.yearLittle')},
			{id: DurationOfUse.TWO_YEAR, name: '2 ' + this.translate.instant('page.appDetails.finance.contracts.yearLittlePlural')},
			{id: DurationOfUse.THREE_YEAR, name: '3 ' + this.translate.instant('page.appDetails.finance.contracts.yearLittlePlural')},
			{id: DurationOfUse.FOUR_YEAR, name: '4 ' + this.translate.instant('page.appDetails.finance.contracts.yearLittlePlural')},
			{id: DurationOfUse.FIVE_YEAR, name: '5 ' + this.translate.instant('page.appDetails.finance.contracts.yearLittlePlural')}
		];
		this.filteredOrganizations = this.organization.children;
	}

	private buildForm(): void {
		this.licenceForm = new FormGroup({
			[Form.organizations]: new FormControl([]),
			[Form.amount]: new FormControl(undefined, Validators.min(0)),
			[Form.amortizationValue]: new FormControl(undefined, Validators.min(1)),
			[Form.amortizationUnit]: new FormControl(undefined, Validators.required),
			[Form.subscriptionDate]: new FormControl(undefined, Validators.required),
			[Form.durationOfUse]: new FormControl(undefined, Validators.required),
		});
		this.subscription.add(this.licenceForm.valueChanges
			.subscribe(() => this.emitChanges()));
		this.searchOrganizationControl = new FormControl('');
		this.subscription.add(this.searchOrganizationControl.valueChanges
			.subscribe(search => this.searchOrganizationFilter(search)));
	}

	private setDefaultData(): void {
		if (this.info) {
			const organizations: string[] = this.info!.contract.organizations.map(o => o.organizationId);
			this.licenceForm.setValue({
				[Form.organizations]: this.organization.children
					.flatMap(group => group.children)
					.filter(team => organizations.includes(team.organization.organizationId))
					.map(team => team.organization),
				[Form.amount]: this.info.amount,
				[Form.amortizationValue]: this.info.paymentDurationValue,
				[Form.amortizationUnit]: this.amortizationUnitChoices
					.find(choice => choice.id === this.info!.paymentDurationUnit)!,
				[Form.subscriptionDate]: this.info.contract.startDate,
				[Form.durationOfUse]: this.durationOfUseChoices
					.find(choice => choice.id === this.info!.licenseInfo!.durationOfUse)!
			}, { emitEvent: false });
		} else {
			this.licenceForm.setValue({
				[Form.organizations]: [],
				[Form.amount]: null,
				[Form.amortizationValue]: null,
				[Form.amortizationUnit]: this.amortizationUnitChoices
					.find(choice => choice.id === DurationUnit.MONTH)!,
				[Form.subscriptionDate]: new Date(),
				[Form.durationOfUse]: this.durationOfUseChoices
					.find(choice => choice.id === DurationOfUse.PERPETUAL)!
			}, { emitEvent: false });
		}
		// Emit changes to set default values on parent component
		this.emitChanges();
	}

	private searchOrganizationFilter(search?: string): void {
		if (!search) {
			this.filteredOrganizations = this.organization.children;
		} else {
			const lowercaseValue = search.toLowerCase();
			this.filteredOrganizations = this.organization.children
				.reduce((acc: OrganizationTree[], org) => {
					const matchingChildren = org.children.filter(team =>
						team.organization.name.toLowerCase().includes(lowercaseValue)
					);

					if (org.organization.name.toLowerCase().includes(lowercaseValue) || matchingChildren.length > 0) {
						acc.push({
							...org,
							children: org.organization.name.toLowerCase().includes(lowercaseValue)
								? org.children
								: matchingChildren
						});
					}
					return acc;
				}, [])
				.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		}
	}

	emitChanges(): void {
		const form: TempServiceFrom = this.buildApplicationContractOfLicenceForm();
		this.onChange.emit(form);
	}

	private buildApplicationContractOfLicenceForm(): TempLicenseForm {
		return {
			amount: this.licenceForm.get(Form.amount)!.value,
			startDate: new Date(this.datePipe.transform(this.licenceForm.get(Form.subscriptionDate)!.value, "yyyy-MM-dd")!),
			paymentDurationValue: this.licenceForm.get(Form.amortizationValue)!.value,
			paymentDurationUnit: (this.licenceForm.get(Form.amortizationUnit)!.value as AmortizationChoice).id,
			durationOfUse: (this.licenceForm.get(Form.durationOfUse)!.value as DurationChoice).id,
			organizationIds: (this.licenceForm.get(Form.organizations)!.value as Organization[]).map(o => o.organizationId),
		}
	}
}

interface AmortizationChoice {
	id: DurationUnit;
	name: string;
}

interface DurationChoice {
	id: DurationOfUse;
	name: string;
}

enum Form {
	organizations = 'organizations',
	amount = 'amount',
	amortizationValue = 'amortizationValue',
	amortizationUnit = 'amortizationUnit',
	subscriptionDate = 'subscriptionDate',
	durationOfUse = 'durationOfUse',
}
