import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {ApplicationUrlService} from 'src/app/services/front/application-url.service';

@Component({
  selector: 'app-application-contact-preview',
  templateUrl: './application-contact-preview.component.html',
  styleUrls: ['./application-contact-preview.component.scss']
})
export class ApplicationContactPreviewComponent implements OnInit, OnDestroy {

  tenantId: string;

  _initializing: boolean;

  phone: string|undefined|null;
  email: string|undefined|null;
  website: string|undefined|null;
  advancementPercent: number = 0;

  subscription = new Subscription();

  constructor(private applicationDetailService: ApplicationDetailService,
              private applicationUrlService: ApplicationUrlService) {
  }

  ngOnInit() {
    this.subscription.add(this.applicationDetailService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
      .pipe(tap(data => this.setApplicationDetailData(data)))
      .subscribe());
  }

  private setApplicationDetailData(data: ApplicationDetailData): void {
    this.tenantId = data.tenantId;
    this.phone = !data.instance.supportPhone ? data.instance.catalog?.supportPhone : data.instance.supportPhone;
    this.email = !data.instance.supportEmail ? data.instance.catalog?.supportEmail : data.instance.supportEmail;
    this.website = !data.instance.supportUrl ? data.instance.catalog?.supportUrl : data.instance.supportUrl;
    const values: any[] = [this.phone, this.email, this.website];
    this.advancementPercent = (values.filter(v => !!v).length / values.length) * 100;
  }

  get phoneLabel(): string {
    return !this.phone ? 'N/A' : this.phone.replace(/\s/g, '');
  }

  get emailLabel(): string {
    return !this.email ? 'N/A' : this.email;
  }

  get websiteLabel(): string {
    return !this.website ? 'N/A' : this.website.replace(/^https?:\/\//, '');
  }

  navigateToGeneralParameter(): void {
    this.applicationUrlService.navigateInAndOutOfParameter(true);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
