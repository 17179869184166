import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {ApplicationCompliance} from 'src/app/services/tenant.service';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {
	ApplicationCategoryApplicationListForm,
	ApplicationOrganizationListForm,
	ApplicationOverview,
	ApplicationCriticalityForm,
	ApplicationSettingGeneralForm,
	ApplicationSettingUsageForm,
	ApplicationTableData,
	ApplicationTagListForm,
	ApplicationGeneric,
	ApplicationInstance,
	ApplicationCreation,
	ApplicationTableFilterForm,
	ApplicationElasticTableData,
	ApplicationForComparison,
	ApplicationTypeForm,
	ApplicationExport,
	ApplicationOptimization,
	ApplicationOptimizationStatistic,
	ApplicationOptimizationForm,
	ApplicationAuthenticationTypeForm, ApplicationResponsibleForm, ApplicationResponsible, ApplicationVendorForm
} from 'src/app/services/model/new-application.model';
import {CatalogTag} from 'src/app/services/model/catalog-tag.model';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {ApplicationStatistic} from 'src/app/services/application-instance.service';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {Organization} from 'src/app/services/organization.service';
import {TenantAccount} from "../model/account.model";

@Injectable()
export class NewApplicationService {

  private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/applications`;

  constructor(protected httpClient: HttpClient,
              private currentTenantService: CurrentTenantService) {
  }

  getAllApplication(tenantId: string, organizationId?: string): Observable<ApplicationGeneric[]> {
    return this.httpClient.get<ApplicationGeneric[]>(`${this.serviceUrl(tenantId)}`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: !organizationId ? new HttpParams() : new HttpParams().append('organizationId', organizationId)
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllApplicationInstance(tenantId: string, organizationId?: string): Observable<ApplicationInstance[]> {
    return this.httpClient.get<ApplicationInstance[]>(`${this.serviceUrl(tenantId)}/instances`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: !organizationId ? new HttpParams() : new HttpParams().append('organizationId', organizationId)
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllApplicationElasticTableData(tenantId: string): Observable<ApplicationElasticTableData[]> {
    return this.httpClient.get<ApplicationElasticTableData[]>(`${this.serviceUrl(tenantId)}/elastic-table-data`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllApplicationTableData(tenantId: string, form?: ApplicationTableFilterForm): Observable<ApplicationTableData[]> {
    return this.httpClient.post<ApplicationTableData[]>(`${this.serviceUrl(tenantId)}/table-data`, !form ? {} : form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getApplication(tenantId: string, applicationId: string): Observable<ApplicationGeneric> {
    return this.httpClient.get<ApplicationInstance>(`${this.serviceUrl(tenantId)}/${applicationId}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  getApplicationInstance(tenantId: string, applicationId: string): Observable<ApplicationInstance> {
    return this.httpClient.get<ApplicationInstance>(`${this.serviceUrl(tenantId)}/${applicationId}/instance`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  getApplicationOverview(tenantId: string, applicationId: string): Observable<ApplicationOverview> {
    return this.httpClient.get<ApplicationOverview>(`${this.serviceUrl(tenantId)}/${applicationId}/overview`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  getApplicationResponsibles(tenantId: string, applicationId: string): Observable<TenantAccount[]> {
	  return this.httpClient.get<TenantAccount[]>(`${this.serviceUrl(tenantId)}/${applicationId}/responsibles`, {
		  headers: SecurityUtils.getAuthHttpHeaders()
	  }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getApplicationCompliance(tenantId: string, applicationId: string): Observable<ApplicationCompliance> {
    return this.httpClient.get<ApplicationCompliance>(`${this.serviceUrl(tenantId)}/${applicationId}/compliance`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  getAllApplicationTag(tenantId: string, applicationId: string): Observable<CatalogTag[]> {
    return this.httpClient.get<CatalogTag[]>(`${this.serviceUrl(tenantId)}/${applicationId}/tags`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  createApplicationInstance(tenantId: string, form: ApplicationCreation): Observable<string> {
    return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(tap(() => this.currentTenantService.refreshApplicationCount()), catchError(error => HttpClientUtils.handleError(error, null)));
  }

  updateApplicationSettingGeneral(tenantId: string, applicationId: string, form: ApplicationSettingGeneralForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/setting-general`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  setApplicationUrlModalDismissed(tenantId: string, applicationId: string): Observable<boolean> {
	  return this.httpClient.post<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/url-modal-dismissed`, {}, {
		  headers: SecurityUtils.getAuthHttpHeaders()
	  }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  updateApplicationSettingUsage(tenantId: string, applicationId: string, form: ApplicationSettingUsageForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/setting-usage`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  updateApplicationCriticalityForm(tenantId: string, applicationId: string, form: ApplicationCriticalityForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/criticality`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

	updateApplicationAuthenticationForm(tenantId: string, applicationId: string, form: ApplicationAuthenticationTypeForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/authentication`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

  updateApplicationTypeForm(tenantId: string, applicationId: string, form: ApplicationTypeForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/type`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  updateApplicationCategoryList(tenantId: string, applicationId: string, form: ApplicationCategoryApplicationListForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/category-list`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  updateApplicationVendor(tenantId: string, applicationId: string, form: ApplicationVendorForm): Observable<boolean> {
	  return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/vendor`, form, {
		  headers: SecurityUtils.getAuthHttpHeaders()
	  }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  addApplicationResponsible(tenantId: string, applicationId: string, form: ApplicationResponsibleForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/add-responsible`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

	deleteApplicationResponsible(tenantId: string, applicationId: string, form: ApplicationResponsibleForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/delete-responsible`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

  updateApplicationOrganizationList(tenantId: string, applicationId: string, form: ApplicationOrganizationListForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/organization-list`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  updateApplicationTagList(tenantId: string, applicationId: string, form: ApplicationTagListForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/tag-list`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  deleteApplicationInstance(tenantId: string, applicationId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(tap(() => this.currentTenantService.refreshApplicationCount()), catchError(error => HttpClientUtils.handleError(error, false)));
  }

  uploadApplicationIcon(tenantId: string, applicationId: string, file: File): Observable<boolean> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/icon`, formData, {
      headers: SecurityUtils.getRawAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  updateApplicationOptimization(tenantId: string, applicationId: string, form: ApplicationOptimizationForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${applicationId}/optimization`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  getApplicationForComparison(tenantId: string, applicationId: string): Observable<ApplicationForComparison> {
    return this.httpClient.get<ApplicationForComparison>(`${this.serviceUrl(tenantId)}/${applicationId}/comparison`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  getApplicationStatistic(tenantId: string, applicationId: string, costRange?: QueryRangeType, elasticRange?: QueryRangeType): Observable<ApplicationStatistic> {
    let params: HttpParams = new HttpParams();
    if (costRange) params = params.append('costRange', costRange);
    if (elasticRange) params = params.append('elasticRange', elasticRange);
    return this.httpClient.get<ApplicationStatistic>(`${this.serviceUrl(tenantId)}/${applicationId}/statistic`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  getAllOrganizationByApplicationId(tenantId: string, applicationId: string): Observable<Organization[]> {
    return this.httpClient.get<Organization[]>(`${this.serviceUrl(tenantId)}/${applicationId}/organizations`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllApplicationTagByTenantId(tenantId: string): Observable<CatalogTag[]> {
    return this.httpClient.get<CatalogTag[]>(`${this.serviceUrl(tenantId)}/tags`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllApplicationExportDataByIds(tenantId: string, applicationIds: string[]): Observable<ApplicationExport[]> {
    const request = { applicationIds: applicationIds };
    return this.httpClient.post<ApplicationExport[]>(`${this.serviceUrl(tenantId)}/exports`, request, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllApplicationOptimizationByTenantId(tenantId: string, organizationId?: string): Observable<ApplicationOptimization[]> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append('organizationId', organizationId);
    return this.httpClient.get<ApplicationOptimization[]>(`${this.serviceUrl(tenantId)}/optimizations`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getApplicationOptimizationStatisticByTenantId(tenantId: string, organizationId?: string): Observable<ApplicationOptimizationStatistic> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append('organizationId', organizationId);
    return this.httpClient.get<ApplicationOptimizationStatistic>(`${this.serviceUrl(tenantId)}/optimization-statistic`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }
}
