<div *ngIf="data"
	 (mouseenter)="hovered = true"
	 (mouseleave)="hovered = false"
	 class="flex-space-between pad-M">
	<div class="flex-column gap-S w-60">
		<span class="fw-700 font-MM">{{ 'page.organization.overview.teamManager' | translate }}</span>
		<span>{{ 'page.organization.overview.teamManagerSubtitle' | translate }}</span>
	</div>
	<div class="flex-align-center gap-S">
		<div class="flex-align-center translate-transition gap-S" style="z-index: 1" [style.transform]="!hovered && data.selected.length < 4 ? 'translateX(50px)' : undefined">
			<div class="p-relative translate-transition" *ngFor="let user of data!.selected; let index = index"
				 [style.z-index]="getInvertedIndex(index)"
				 [style.transform]="index + 1 < data!.selected.length && !hovered ? 'translateX('+ getInvertedIndex(index) * 25 +'px)' : undefined">
				<div [style.opacity]="hovered ? 1 : 0" (click)="removeResponsible(user)" class="delete-responsible-btn">
					<img width="12" src="assets/icons/cross-black.svg" class="filter-white">
				</div>
				<app-display-user-logo [tooltipPosition]="'left'"
									   [tooltipTriggerFor]="responsibleTooltip"
									   style="cursor:default;"
									   [size]="40"
									   [fontSize]="16"
									   [user]="user"></app-display-user-logo>
				<app-tooltip #responsibleTooltip>
					<span *ngIf="user.pending">
						{{ user.account.email }}
					</span>
					<div *ngIf="!user.pending" class="flex-column" style="gap: 2px">
						<span class="fw-700">{{ user.account.firstName }} {{ user.account.lastName }}</span>
						<span class="fw-400">{{ user.account.email }}</span>
					</div>
				</app-tooltip>
			</div>
		</div>
		<app-select-or-invite-user-input class="border-box select-or-invite-user"
										 *ngIf="data!.selected.length < 4"
										 [list]="data!.data"
										 [values]="data!.selected"
										 [multiselect]="true"
										 (onAdd)="addResponsible($event)"
										 (onRemove)="removeResponsible($event)"
										 (onInvite)="onInviteResponsible($event)">
			<div class="flex-align-center flex-justify-end h-100">
				<div class="add-responsible-no-selected">
					<img src="assets/icons/add.svg" class="filter-grey">
				</div>
			</div>
		</app-select-or-invite-user-input>
	</div>
</div>
<div class="pad-M" *ngIf="!data">
	<list-content-loader></list-content-loader>
</div>
