<div class="flex-align-center gap-S">
    <app-display-user-logo [user]="user" [size]="size"></app-display-user-logo>
    <div class="flex-column flex-space-evenly" [ngStyle]="{'width': width ? width : 'calc(100% - 10px - ' + size + 'px)', 'height': size + 'px'}">
        <span class="font-M text-overflow-ellipsis" [ngClass]="{'fw-500': !bold, 'fw-700': bold}">
            {{!user ? 'Kabeen' : (user.pending ? user.account.email : user.account.firstName + ' ' + user.account.lastName)}}
        </span>
        <span *ngIf="user && email && !user.pending" class="font-S fw-500 text-overflow-ellipsis">
            {{user.account.email}}
        </span>
    </div>
</div>
