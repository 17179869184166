@if (_initializing || (_loading && chartData.length === 0)) {
<div class="border-box-full">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
} @else {
<div>
	<div [style.margin-bottom.px]="displayRange ? 0 : 20" class="flex-space-between pad-0-M pad-top-M gap-S">
		<span class="font-MM fw-700">{{ widgetTitle }}</span>
		<app-multi-select *ngIf="displayRange" class="w-100 w-fit-content" [inputControl]="rangeControl">
			<app-select-trigger [minHeight]="31" appearance="field">
				<span>{{ 'global.queryTypeSelector.' + rangeControl.value | translate }}</span>
			</app-select-trigger>
			<app-select-option [value]="QueryRangeType.PAST_1_DAY">
				<span>{{ 'global.queryTypeSelector.past_1_day' | translate }}</span>
			</app-select-option>
			<app-select-option [value]="QueryRangeType.PAST_1_MONTH">
				<span>{{ 'global.queryTypeSelector.past_1_month' | translate }}</span>
			</app-select-option>
			<app-select-option [value]="QueryRangeType.PAST_1_YEAR">
				<span>{{ 'global.queryTypeSelector.past_1_year' | translate }}</span>
			</app-select-option>
		</app-multi-select>
	</div>
	<div class="pl-m pr-m pb-m" [class.pb-m]="displayRange" [class.pb-l]="!displayRange" (mouseout)="tooltip.display = 'none'">
		<canvas [height]="height"
				baseChart
				[data]="lineChartData"
				[type]="barChartType"
				[options]="barOptions"
		></canvas>
		<div class="tooltip" style="padding: 3px 7px;" [style.top]="tooltip.top" [style.left]="tooltip.left" [style.display]="tooltip.display">
			<div class="flex-space-between font-M fw-700">
				<div>
					<span *ngIf="rangeControl.value === QueryRangeType.PAST_1_DAY">{{ tooltip.hour }}h</span>
					<span *ngIf="rangeControl.value === QueryRangeType.PAST_1_MONTH">{{ tooltip.day }} {{ 'global.monthShort.' + tooltip.month | translate }}</span>
					<span *ngIf="rangeControl.value === QueryRangeType.PAST_1_YEAR">{{ 'global.month.' + tooltip.month | translate }}</span>
				</div>
				<div>
					<span style="color:#3DC0FF;">{{ tooltip.value }} {{ 'page.usageDashboard.users' | translate }}</span>
				</div>
			</div>
		</div>
	</div>
</div>
}
