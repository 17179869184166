<div class="flex-column gap-M">
	<div class="flex-align-center flex-space-between">
		<span class="font-MM fw-700">{{'page.appDetails.architecture.title'|translate}}</span>
		<app-mini-button size="add" color="accent"
						 [disable]="_initializing || _loading || _loadingDialog || !!_deleting"
						 (onClick)="openApplicationMappingFormDialog()">
			<img src="assets/icons/add.svg" class="filter-accent" alt=""/>
		</app-mini-button>
	</div>
	<div class="flex-column gap-M w-100" *ngIf="applicationMappingList.length > 0">
		<div class="flex w-100" *ngFor="let appMapping of applicationMappingList">
			<div class="flex-align-center flex-space-between w-100"
				 *ngVar="{ left: appMapping.source.id === applicationId ? appMapping.source : appMapping.target, right: appMapping.target.id === applicationId ? appMapping.source : appMapping.target } as app">
				<app-display-logo [logo]="app.left.logo" [size]="35" [tooltip]="app.left.name" tooltipPosition="right"></app-display-logo>
				<div class="fluxDataWrapper">
					<div class="fluxDataLeftCircle"></div>
					<div class="fluxDataLine"></div>
					<div class="fluxDataRightCircle"></div>
					<div class="flex-center gap-M w-100 h-100 dataTag">
                        <div (mouseover)="displayTooltip(appMapping)" (mouseleave)="hideTooltip()" *ngFor="let data of appMapping.mappingDataList|slice:0:3" class="flex-center gap-XS horizontal-bar-L bkg-accent-light accent border-accent"
                             [ngClass]="{'pad-left-S pad-right-XS': app.right.id === appMapping.target.id,'pad-left-XS pad-right-S': app.left.id === appMapping.target.id}">
                            <img *ngIf="app.left.id === appMapping.target.id" class="filter-accent" ngSrc="assets/icons/arrow-left-2.svg" height="16" width="16" alt="">
                            <span class="font-S fw-700">{{data.name}}</span>
                            <img *ngIf="app.right.id === appMapping.target.id" class="filter-accent" ngSrc="assets/icons/arrow-right-2.svg" height="16" width="16" alt="">
						</div>
                        <div (mouseover)="displayTooltip(appMapping)" (mouseleave)="hideTooltip()" *ngIf="appMapping.mappingDataList.length > 3"  class="flex-center gap-XS pad-0-XS horizontal-bar-L bkg-accent-light accent border-accent"
                             [tooltip]="getDataTooltip(appMapping.mappingDataList|slice:3:appMapping.mappingDataList.length)">
                            <span>+ {{ appMapping.mappingDataList.length - 3 }}</span>
						</div>
					</div>
				</div>
				<app-display-logo [logo]="app.right.logo" [size]="35" [tooltip]="app.right.name" tooltipPosition="left"></app-display-logo>
			</div>
			<div class="flex-align-center">
				<div *ngIf="appMapping.documents && appMapping.documents.length > 1">
					<app-icon-button [size]="'s'" [img]="'assets/icons/download-01.svg'"
									 [panelClass]="'img-size-70'"
									 [tooltip]="'button.download' | translate"
									 [disabled]="_initializing || _loading || !!_deleting"
									 [dropdownTrigger]="downloadDropdown">
					</app-icon-button>
					<app-dropdown #downloadDropdown>
						<div style="width: 300px">
							<div *ngFor="let file of appMapping.documents; let index = index">
								<div *ngIf="file.type === fileType.FILE" class="uploading-container" [ngClass]="{'mb-l': index < appMapping.documents.length - 1}">
									<div class="flex gap-M w-100">
										<div class="icon-bg" style="background-color: #ECF9FF">
											<img ngSrc="assets/icons/document-outline.svg" class="filter-accent" height="24" width="24" alt="">
										</div>
										<div class="flex-column flex-justify-center a-i-b w-100">
											<div class="flex-align-center flex-space-between" style="max-width: 136px;">
												<span class="text-overflow-ellipsis">{{ file.name }}</span>
											</div>
											<span *ngIf="file.size" class="font-S fw-500 grey">{{ sizePrettily(file.size) }}</span>
										</div>
									</div>
									<!-- DOWNLOAD BUTTON -->
									<div class="flex-center radius-half pb-s pt-s pr-s pl-s">
										<app-icon-button [size]="'s'" [img]="'assets/icons/download-01.svg'"
														 [panelClass]="'img-size-70'"
														 (click)="downloadFile(file)"></app-icon-button>
									</div>
								</div>
								<div *ngIf="file.type === fileType.LINK" class="uploading-container" [ngClass]="{'mb-l': index < appMapping.documents.length - 1}">
									<div class="flex gap-M w-100">
										<div class="icon-bg" style="background-color: #ECF9FF">
											<img ngSrc="assets/icons/link.svg" class="filter-accent" height="20" width="20" alt="">
										</div>
										<div class="flex-column flex-justify-center w-100">
											<div class="flex-align-center flex-space-between" style="max-width: 200px;">
												<a class="text-overflow-ellipsis text-underline accent" target="_blank"
												   [href]="file.link">{{ file.name }}</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</app-dropdown>
				</div>
				<div *ngIf="appMapping.documents && appMapping.documents.length === 1">
					<app-icon-button [size]="'s'" [img]="appMapping.documents[0].type == fileType.FILE ? 'assets/icons/download-01.svg' : 'assets/icons/link.svg'"
									 [panelClass]="'img-size-70'"
									 [tooltip]="appMapping.documents[0].type == fileType.FILE ? ('button.downloadOneFile' | translate) : ('button.openLink' | translate)"
									 [disabled]="_initializing || _loading || !!_deleting"
									 (click)="appMapping.documents[0].type == fileType.FILE ? downloadFile(appMapping.documents[0]) : openLink(appMapping.documents[0].link!)">
					</app-icon-button>
				</div>
				<app-icon-button color="accent" size="s" [img]="'assets/icons/edit.svg'" [tooltip]="'button.edit'|translate" [panelClass]="'fix-edit-margin'"
								 [disabled]="_initializing || _loading || _loadingDialog || !!_deleting"
								 (click)="openApplicationMappingFormDialog(appMapping)">
				</app-icon-button>
				<app-icon-button color="danger" size="s" [img]="'assets/icons/trash.svg'" [tooltip]="'button.delete'|translate"
								 [disabled]="_initializing || _loading || _loadingDialog || !!_deleting"
								 [loading]="_deleting === appMapping.flowId"
								 (click)="deleteApplicationMapping(appMapping.flowId)">
				</app-icon-button>
			</div>
			<app-mapping-drawer-tooltip *ngIf="displayedTooltip === appMapping" [data]="displayedTooltip"></app-mapping-drawer-tooltip>
		</div>
	</div>
</div>
