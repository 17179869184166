import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from './security.utils';
import {catchError, map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {ApplicationTechnology} from 'src/app/services/model/application-technology.model';
import {Category} from 'src/app/services/model/application-category.model';
import {
	ApplicationCreation,
	ApplicationGeneric,
	ApplicationInstance, ApplicationStatus,
	Health
} from 'src/app/services/model/new-application.model';
import {ApplicationLifeCycle} from 'src/app/services/model/application-life-cycle.model';
import {AppCount} from 'src/app/services/model/autodiscover.model';
import {ApplicationMapping, ProtocolType} from 'src/app/services/model/application-mapping.model';
import {ContractTypeCost, Cost, Currency, Usage} from 'src/app/services/model/application-contract.model';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {Rating} from 'src/app/services/usage.service';
import {ApplicationPerformance} from "./back/performance.service";

@Injectable({
	providedIn: 'root',
})
export class TenantService {
	// TODO @TAN replace APIs in right services, create func serviceUrl and then move it to package services/back

	constructor(protected httpClient: HttpClient,
							protected activatedRoute: ActivatedRoute,
							protected authenticationService: AuthenticationService) {
	}

	getApplicationCount(tenantId: string, organizationId?: string): Observable<AppCount> {
		return this.httpClient.get<AppCount>(`${environment.engineURL}/tenants/${tenantId}/app-count`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: !organizationId ? new HttpParams() : new HttpParams().append('organizationId', organizationId)
		}).pipe(catchError(error => HttpClientUtils.handleError(error, 0)));
	}

	createTenant(createTenant: CreateTenant): Observable<string> {
		return this.httpClient.post<string>(environment.engineURL + '/tenants', JSON.stringify(createTenant), {
			headers: SecurityUtils.getAuthHttpHeaders(),
		})
	}

	getTenants(): Observable<TenantOverview[]> {
		// TODO @TAN rework
		return this.httpClient.get<{tenants:{configuration: TenantConfiguration, plan: TenantPlan}[]}>(environment.engineURL + '/tenants', {headers: SecurityUtils.getAuthHttpHeaders()})
			.pipe(map(tenantWrapper => {
				return tenantWrapper.tenants.map(tenant => ({configuration: tenant.configuration, plan: this.buildPlanStatus(tenant.plan)}));
			}));
	}

	inviteUsers(tenantId: string, invitationsEmails: Array<string>): Observable<boolean> {
		// TODO @TAN move to account service
		return this.httpClient.post(environment.engineURL + '/tenants/' + tenantId + '/users', JSON.stringify({emails: invitationsEmails}), {headers: SecurityUtils.getAuthHttpHeaders()})
			.pipe(map((response: any) => true));
	}

	deleteTenant(tenantId: string): Observable<any> {
		return this.httpClient.delete<void>(environment.engineURL + '/tenants/' + tenantId,
			{headers: SecurityUtils.getAuthHttpHeaders()});
	}

	getBillingPortalUrl(tenantId: string): Observable<string | undefined> {
		return this.httpClient.get<string|undefined>(environment.engineURL + '/tenants/' + tenantId + '/billingPortal', {headers: SecurityUtils.getAuthHttpHeaders()});
	}

	protected buildPlanStatus(plan: TenantPlan): PlanStatus {
		switch (plan.planStatus) {
			case 'trialing': return new TrialPlan(new Date(plan.endAt));
			case 'active': return new ActivePlan();
			case 'incomplete': return new InactivePlan('Plan has been cancelled.');
			case 'incomplete_expire': return new InactivePlan('Plan has been cancelled.');
			case 'past_due': return new InactivePlan('Plan has been cancelled.');
			case 'canceled': return new InactivePlan('Plan has been cancelled.');
			case 'unpaid': return new InactivePlan('Something wrong happened while proceeding payment.');
			default : return new TrialPlan(new Date(plan.endAt));
		}
	}

	getAllApplicationCategoryByTenantId(tenantId: string, organizationId?: string): Observable<ApplicationCategory[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		return this.httpClient.get<ApplicationCategory[]>(`${environment.engineURL}/tenants/${tenantId}/category-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllApplicationComplexityByTenantId(tenantId: string, organizationId?: string, limit?: number, range?: QueryRangeType): Observable<ApplicationComplexity[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (limit) params = params.append('limit', limit);
		if (range) params = params.append('range', range);
		return this.httpClient.get<ApplicationComplexity[]>(`${environment.engineURL}/tenants/${tenantId}/complexity-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllApplicationDocumentationByTenantId(tenantId: string, organizationId?: string, limit?: number, range?: QueryRangeType): Observable<ApplicationDocumentation[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (limit) params = params.append('limit', limit);
		if (range) params = params.append('range', range);
		return this.httpClient.get<ApplicationDocumentation[]>(`${environment.engineURL}/tenants/${tenantId}/documentation-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllApplicationObsolescenceByTenantId(tenantId: string, organizationId?: string, limit?: number, range?: QueryRangeType): Observable<ApplicationObsolescence[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (limit) params = params.append('limit', limit);
		if (range) params = params.append('range', range);
		return this.httpClient.get<ApplicationObsolescence[]>(`${environment.engineURL}/tenants/${tenantId}/obsolescence-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantOverlapStatistic(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<TenantOverlapStatistic> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append("organizationId", organizationId);
		if (range) params = params.append('range', range);
		return this.httpClient.get<TenantOverlapStatistic>(`${environment.engineURL}/tenants/${tenantId}/overlap-statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		});
	}

	getTenantOperationStatistic(tenantId: string, organizationId?: string, costRange?: QueryRangeType, elasticRange?: QueryRangeType): Observable<TenantOperationStatistic> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (costRange) params = params.append('costRange', costRange);
		if (elasticRange) params = params.append('elasticRange', elasticRange);
		return this.httpClient.get<TenantOperationStatistic>(`${environment.engineURL}/tenants/${tenantId}/operation-statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantFinanceStatistic(tenantId: string, organizationId?: string, costRange?: QueryRangeType, elasticRange?: QueryRangeType): Observable<TenantFinanceStatistic> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (costRange) params = params.append('costRange', costRange);
		if (elasticRange) params = params.append('elasticRange', elasticRange);
		return this.httpClient.get<TenantFinanceStatistic>(`${environment.engineURL}/tenants/${tenantId}/finance-statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantUsageStatistic(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<TenantUsageStatistic> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (range) params = params.append('range', range);
		return this.httpClient.get<TenantUsageStatistic>(`${environment.engineURL}/tenants/${tenantId}/usage-statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantArchitectureStatistic(tenantId: string, organizationId?: string): Observable<TenantArchitectureStatistic> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		return this.httpClient.get<TenantArchitectureStatistic>(`${environment.engineURL}/tenants/${tenantId}/architecture-statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantDocumentStatistic(tenantId: string, organizationId?: string): Observable<TenantDocumentStatistic> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		return this.httpClient.get<TenantDocumentStatistic>(`${environment.engineURL}/tenants/${tenantId}/document-statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllApplicationTechnologyByTenantId(tenantId: string, organizationId?: string): Observable<ApplicationTechnology[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		return this.httpClient.get<ApplicationTechnology[]>(`${environment.engineURL}/tenants/${tenantId}/technology-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllApplicationLifeCycleByTenantId(tenantId: string, organizationId?: string): Observable<ApplicationLifeCycle[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		return this.httpClient.get<ApplicationLifeCycle[]>(`${environment.engineURL}/tenants/${tenantId}/life-cycles`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getAllDiagramApplicationLifeCycle(tenantId: string, form: DiagramApplicationLifeCycleFilterForm = {}): Observable<DiagramApplicationLifeCycle[]> {
		return this.httpClient.post<DiagramApplicationLifeCycle[]>(`${environment.engineURL}/tenants/${tenantId}/diagrams/life-cycles`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getAllDiagramApplicationMapping(tenantId: string, form: DiagramApplicationMappingFilterForm = {}): Observable<DiagramApplicationMapping[]> {
		return this.httpClient.post<DiagramApplicationMapping[]>(`${environment.engineURL}/tenants/${tenantId}/diagrams/mappings`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getAllDiagramApplicationMatrix(tenantId: string, form: DiagramApplicationMatrixFilterForm = {}): Observable<DiagramApplicationMatrix[]> {
		return this.httpClient.post<DiagramApplicationMatrix[]>(`${environment.engineURL}/tenants/${tenantId}/diagrams/matrices`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getAllDiagramApplicationQuadrant(tenantId: string, form: DiagramApplicationQuadrantFilterForm = {}): Observable<DiagramApplicationQuadrant[]> {
		return this.httpClient.post<DiagramApplicationQuadrant[]>(`${environment.engineURL}/tenants/${tenantId}/diagrams/quadrants`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getTenantExtensionSettings(tenantId: string): Observable<TenantExtensionSettings> {
		return this.httpClient.get<TenantExtensionSettings>(`${environment.engineURL}/tenants/${tenantId}/rover-settings`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateTenantExtensionSettings(tenantId: string, form: TenantExtensionSettingsForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${environment.engineURL}/tenants/${tenantId}/rover-settings`, form,{
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	uploadExtensionLogo(tenantId: string, file: File): Observable<boolean> {
		const formData = new FormData();
		formData.append('file', file);
		return this.httpClient.post<boolean>(`${environment.engineURL}/tenants/${tenantId}/rover-settings/icon`, formData, {
			headers: SecurityUtils.getRawAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	deleteExtensionLogo(tenantId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${environment.engineURL}/tenants/${tenantId}/rover-settings/icon`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	updateTenantName(tenantId: string, name: string): Observable<any> {
		return this.httpClient.post<any>(`${environment.engineURL}/tenants/${tenantId}/_rename`, {name}, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	checkTenantExtensionInstalled(tenantId: string): Observable<boolean> {
		return this.httpClient.get<boolean>(`${environment.engineURL}/tenants/${tenantId}/check-extension-installed`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	setOnboardingCompleted(tenantId: string): Observable<boolean> {
		return this.httpClient.put<boolean>(`${environment.engineURL}/tenants/${tenantId}/onboarding-completed`, null, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	addOnboardingApplicationsToTenant(tenantId: string, body: ApplicationCreation[]): Observable<boolean> {
		return this.httpClient.post<boolean>(`${environment.engineURL}/tenants/${tenantId}/onboarding-applications`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	sendOnboardingRedirectionEmail(tenantId: string, accountId: string): Observable<boolean> {
		return this.httpClient.get<boolean>(`${environment.engineURL}/tenants/${tenantId}/onboarding-redirect-email`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: new HttpParams().append('accountId', accountId)
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}

export interface TenantOverview {
	// TODO @TAN Rework
	configuration: TenantConfiguration;
	plan: PlanStatus;
}

export interface TenantConfiguration {
	id: string;
	name: string;
	role: string;
	isDefault: boolean;
	publicLogoUrl?: string;
	supportUrl?: string;
	onboardingCompleted: boolean;
	currency: Currency;
}

export interface TenantPlan {
	planStatus: string;
	endAt: string;
}

export interface CreateTenant {
	name: string;
	accountExpectations?: string;
	organizationSector?: string;
	organizationEmployees?: string;
	userType?: string;
	companyType?: string;
	onboardingCompleted: boolean;
}

export interface DiagramApplicationLifeCycle {
	application: ApplicationGeneric;
	lifeCycle: ApplicationLifeCycle|null;
}

export interface DiagramApplicationLifeCycleFilterForm {
	criticality?: CriticalityLevel[];
	organizations?: string[];
	categories?: string[];
	withNoData?: boolean;
	status?: ApplicationStatus[];
	tag?: string[];
}

export interface DiagramApplicationMapping {
	application: ApplicationGeneric;
	category: Category|null;
	mappingList: ApplicationMapping[];
}

export interface DiagramApplicationMappingFilterForm {
	criticality?: CriticalityLevel[];
	organizations?: string[];
	applications?: string[];
	categories?: string[];
	withNoData?: boolean;
	withExternal?: boolean;
	protocols?: ProtocolType[];
	ports?: string[];
	status?: ApplicationStatus[];
	tag?: string[];
}

export interface DiagramApplicationMatrix {
	application: ApplicationGeneric;
	categoryId: string|null;
	organizationIds: string[];
}

export interface DiagramApplicationMatrixFilterForm {
	criticality?: CriticalityLevel[];
	organizations?: string[];
	categories?: string[];
	status?: ApplicationStatus[];
	tag?: string[];
}

export interface DiagramApplicationQuadrant {
	application: ApplicationGeneric;
	usage: number|null;
	cost: number|null;
	health: number|null;
	lcp: number|null;
	fcp: number|null;
	obsolescence: number|null;
}

export interface DiagramApplicationQuadrantFilterForm {
	criticality?: CriticalityLevel[];
	organizations?: string[];
	categories?: string[];
	applications?: string[];
	status?: ApplicationStatus[];
	tag?: string[];
}

export interface ApplicationCategory {
	application: ApplicationInstance;
	category: Category|null;
}

export interface ApplicationComplexity {
	application: ApplicationGeneric;
	complexity: Compliance;
	usage: Usage;
}

export interface ApplicationDocumentation {
	application: ApplicationGeneric;
	documentationPercent: number;
	lastDocumentationDate: Date;
}

export interface ApplicationObsolescence {
	application: ApplicationGeneric;
	obsolescence: Compliance;
	usage: Usage;
}

export interface ApplicationCompliance {
	application: ApplicationGeneric;
	complexity: Compliance;
	obsolescence: Compliance;
}

export interface TenantOverlapStatistic {
	nbDuplicates: number;
	potentialEarnings: Cost;
	overlapPercent: number;
}

export interface TenantOperationStatistic {
	totalApplication: number;
	totalCost: Cost;
	usage: Usage;
	serverCount: number;
}

export interface TenantFinanceStatistic {
	costByTypes: ContractTypeCost[];
	usage: Usage;
}

export interface TenantUsageStatistic {
	usage: Usage;
	satisfaction: Rating;
	discoveredCount: AppCount;
	extensionCount: number;
}

export interface TenantArchitectureStatistic {
	totalApplication: number;
	totalMapping: number;
	totalDocument: number;
	totalServer: number;
	averageAge: number;
}

export interface TenantDocumentStatistic {
	totalDocument: number;
	averageCompletion: number;
}

export interface TenantExtensionSettingsForm {
	displayExtensionCheck: boolean;
	supportUrlCheck: boolean;
	supportUrl: string|null
	feedbackCheck: boolean;
	feedback: string|null;
	incognito: boolean;
}

export interface TenantExtensionSettings {
	token: string,
	satisfactionActivated: boolean,
	satisfactionFrequency: string,
	supportUrlActivated: boolean,
	supportUrl: string|null,
	displayRover: boolean,
	publicLogoUrl: string|null,
	incognito: boolean,
}

export interface Compliance {
	percent: number;
	level: ComplianceLevel;
}

export enum ComplianceLevel {
	A = 'A',
	B = 'B',
	C = 'C',
	D = 'D'
}

export enum CriticalityLevel {
	HIGH = 'HIGH',
	MEDIUM = 'MEDIUM',
	LOW = 'LOW'
}

export enum AuthenticationType {
	LOCAL = 'local',
	SSO = 'sso',
	SSO_AZURE = 'sso_azure',
	SSO_GOOGLE = 'sso_google',
	SSO_KEYCLOAK = 'sso_keycloak',
	SSO_OKTA = 'sso_okta',
	SSO_GITHUB = 'sso_github',
	SSO_SLACK = 'sso_slack',
	OTHER = 'other'
}

export enum AuthenticationPrimaryFactor {
	LOGIN_PASSWORD = 'login_password',
	SMART_CARD = 'smart_card',
	CERTIFICATE = 'certificate',
	FIDO = 'fido',
	PIN = 'pin',
	BIOMETRY = 'biometry'
}

export enum AuthenticationSecondaryFactor {
	BIOMETRY = 'biometry',
	OTP = 'otp',
	SMART_CARD = 'smart_card',
	CERTIFICATE = 'certificate',
	FIDO = 'fido',
	PIN = 'pin',
	APPLICATION = 'application'
}

export enum AuthenticationProtocol {
	KERBEROS = 'kerberos',
	OAUTH = 'oauth',
	SAMLV2 = 'samlv2',
	OPENID_CONNECT = 'openid_connect',
	OTHER = 'other'
}

export interface PlanStatus {
	// TODO @TAN Rework
	isActivePlan(): boolean;
	asActivePlan(): ActivePlan;

	isTrialPlan(): boolean;
	asTrialPlan(): TrialPlan;

	isInactivePlan(): boolean;
	asInactivePlan(): InactivePlan;
}

export abstract class BasePlanStatus implements PlanStatus {
	// TODO @TAN Rework
	asActivePlan(): ActivePlan {
		throw new Error("Not an active plan.");
	}

	asInactivePlan(): InactivePlan {
		throw new Error("Not an inactive plan.");
	}

	asTrialPlan(): TrialPlan {
		throw new Error("Not a trial plan.");
	}

	isActivePlan(): boolean {
		return false;
	}

	isInactivePlan(): boolean {
		return false;
	}

	isTrialPlan(): boolean {
		return false;
	}

}

export class ActivePlan extends BasePlanStatus {
	isActivePlan(): boolean {
		return true;
	}

	asActivePlan(): ActivePlan {
		return this;
	}
}

export class TrialPlan extends BasePlanStatus {
	constructor(public endAt: Date) {
		super();
	}
	isTrialPlan(): boolean {
		return true;
	}

	asTrialPlan(): TrialPlan {
		return this;
	}
}

export class InactivePlan extends BasePlanStatus {
	constructor(public reason:string) {
		super();
	}
	isInactivePlan(): boolean {
		return true;
	}

	asInactivePlan(): InactivePlan {
		return this;
	}
}
