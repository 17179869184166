import {Observable, Subject} from "rxjs";
import jsPDF, {Point} from "jspdf";
import {CanvasHandler} from "./canvas-handler";
import {ClickableObject, HoverableObject, HoverableObjectStacked} from "./diagram";
import {PdfGenerator} from "./pdf/pdf-generator";
import {ApplicationMapping} from "../../../../services/model/application-mapping.model";

export abstract class BaseEngine {
	protected renderer: CanvasHandler;
	protected animationFPS = 60;
	protected objectClickSubject = new Subject<undefined | {id: string, position: Point}>();
	protected objectHoveredSubject = new Subject<undefined | {sourceId: string, targetId: string, position: Point}>();
	protected objectHoveredStackedSubject = new Subject<undefined | {sourceId: string, targetId: string, flowId: string, position: Point}>();
	protected currentWidth = 0;
	protected currentHeight = 0;
	protected lastAnimationTime = 0;
	protected isShuttingDown = false;
	protected lastObjectSelected: {event: {id: string, position: Point}, originalCoordinates: Point} | undefined;
	protected lastObjectHovered: {event: {sourceId: string, targetId: string, position: Point}, originalCoordinates: Point} | undefined;
	protected lastObjectHoveredStacked: {event: {sourceId: string, targetId: string, flowId: string, position: Point}, originalCoordinates: Point} | undefined;
	protected lastGrabClickTime = 0;
	protected canvasTransformState: {width: number, height: number, styleWidth: string, styleHeight: string, transform: DOMMatrix} | undefined;

	protected constructor(
		protected context2D: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
		protected canvas:HTMLCanvasElement,
		protected height: number,
		protected width: number
	) {}

	protected abstract onInit(): void;
	protected abstract onUpdate(): void;
	protected abstract onDraw(): void ;
	protected abstract onClick(point: Point): void;
	protected abstract onMouseMove(point: Point): void;
	protected abstract getClickableObjects(): ClickableObject[];
	protected abstract getHoverableObjects(): HoverableObject[];
	protected abstract getStackedHoverableObjects(): HoverableObjectStacked[];

	warmUp() {
		this.renderer = new CanvasHandler(this.canvas, this.context2D)

		this.renderer.subscribesToEvents().subscribe(event => {
			if (event.type === 'mouseMove') {
				this.mouseMove(event.coordinates);
			} else if (event.type === 'clickRelease') {
				this.mouseClickRelease(event.coordinates, event.originalWindowCoordinates);
			} else if (event.type === 'clickPress') {
				this.mouseClickPress(event.coordinates);
			} else if (event.type === 'isDragging') {
				this.isDragging(event.coordinates);
			}
		});

		// Recenter
		this.recenter();

		// First execution : flows has to be loaded
		this.onInit();

		this.startLifecycle();
	}

	shutdown() {
		this.isShuttingDown = true;
	}

	onResize(height: number, width: number) {
		if (this.renderer) {
			this.renderer.onResize(height, width);
		}
	}

	onFocusOut() {
		this.renderer.onFocusOut();
	}

	zoomIn() {
		this.renderer.zoomIn();
	}

	zoomOut() {
		this.renderer.zoomOut()
	}

	recenter() {
		this.renderer.recenter();
	}

	getZoomPercent() {
		return this.renderer.getZoomPercent();
	}

	getTransform() {
		return this.renderer.getTransform();
	}

	setTransform(transform: DOMMatrix) {
		this.renderer.setTransform(transform);
	}

	protected setCurrentHeight(height: number) {
		this.currentHeight = height;
	}

	protected setCurrentWidth(width: number) {
		this.currentWidth = width;
	}

	exportToPDF(title: string, subtitle: string, icon: string): Observable<jsPDF> {
		const currentWidth = this.currentWidth;
		const currentHeight = this.currentHeight;
		let dpi = Math.max(2, window.devicePixelRatio);

		const maxPixels = 268435456;
		const currentPixels = currentWidth * dpi * currentHeight * dpi;
		if (currentPixels > maxPixels) {
			const ratio = Math.sqrt(maxPixels / currentPixels);
			dpi = dpi * ratio;
		}

		const sourceCanvas = this.copyOriginalCanvasToAnotherCanvas(currentWidth, currentHeight, dpi);

		return new PdfGenerator(sourceCanvas, currentWidth * dpi, currentHeight * dpi).buildPdf(title, subtitle, icon);
	}

	get onObjectClicked(): Observable<{id: string, position: Point} | undefined> {
		return this.objectClickSubject.asObservable();
	}

	get onObjectHovered(): Observable<{sourceId: string, targetId: string, position: Point} | undefined> {
		return this.objectHoveredSubject.asObservable();
	}

	get onObjectHoveredStacked(): Observable<{sourceId: string, targetId: string, flowId: string, position: Point} | undefined> {
		return this.objectHoveredStackedSubject.asObservable();
	}

	protected startLifecycle(canRun: boolean = true) {
		this.onUpdate();

		if (canRun) {
			this.onDraw();
		}

		requestAnimationFrame((time) => {
			const canRun = time - this.lastAnimationTime > 1000 / this.animationFPS;
			this.lastAnimationTime = canRun ? time : this.lastAnimationTime;
			if (!this.isShuttingDown) {
				this.startLifecycle(canRun)
			}
		})
	}

	protected mouseClickPress(point: Point) {
		const hasClickedOnObject = this.getClickableObjects().some(object => object.isAtCoordinates(point));

		if (!hasClickedOnObject) {
			this.canvas.style.cursor = 'grabbing'
			this.renderer.canDrag(true, point);
			this.lastGrabClickTime = Date.now();
		} else {
			this.lastGrabClickTime = Date.now();
		}
	}

	protected mouseClickRelease(point: Point, originalWindowCoordinates: Point) {
		this.renderer.canDrag(false);
		this.canvas.style.cursor = 'grab';

		if (Date.now() - this.lastGrabClickTime > 200) {
			// Nothing to do while grabbing
		} else {
			this.onClick(point);
		}
		this.lastGrabClickTime = 0;
	}

	protected mouseMove(point: Point) {
		const hoveredObject = this.getHoverableObjects().find(object => object.isAtCoordinates(point) && object.isActive());
		const hoveredStackedObject = this.getStackedHoverableObjects().find(object => object.isAtCoordinates(point).isAt && object.isActive());

		this.canvas.style.cursor = 'grab'

		// Handle regular objects
		if (hoveredObject) {
			this.canvas.style.cursor = 'default';
			this.renderer.canDrag(false);
			const dpi = Math.max(2, window.devicePixelRatio);
			const position = CanvasHandler.canvasToWindowCoordinates(this.context2D, dpi, point);
			this.lastObjectHovered = {
				event: { sourceId: hoveredObject.getSourceId(), targetId: hoveredObject.getTargetId(), position },
				originalCoordinates: point
			};
			this.objectHoveredSubject.next(this.lastObjectHovered.event);
		} else {
			this.lastObjectHovered = undefined;
			this.objectHoveredSubject.next(undefined);
		}

		// Handle stacked objects
		if (hoveredStackedObject) {
			this.canvas.style.cursor = 'default';
			this.renderer.canDrag(false);
			const dpi = Math.max(2, window.devicePixelRatio);
			const position = CanvasHandler.canvasToWindowCoordinates(this.context2D, dpi, point);
			const { isAt, flowId } = hoveredStackedObject.isAtCoordinates(point);
			this.lastObjectHoveredStacked = {
				event: { sourceId: hoveredStackedObject.getSourceId(), targetId: hoveredStackedObject.getTargetId(), flowId, position },
				originalCoordinates: point
			};
			this.objectHoveredStackedSubject.next(this.lastObjectHoveredStacked.event);
		} else {
			this.lastObjectHoveredStacked = undefined;
			this.objectHoveredStackedSubject.next(undefined);
		}

		this.onMouseMove(point);

		if (this.lastObjectSelected) {
			const dpi = Math.max(2, window.devicePixelRatio);
			this.lastObjectSelected.event.position = CanvasHandler.canvasToWindowCoordinates(this.context2D, dpi, this.lastObjectSelected.originalCoordinates);
			this.objectClickSubject.next(this.lastObjectSelected.event);
		}
	}

	protected isDragging(point: Point) {
		if (this.lastObjectSelected) {
			const dpi = Math.max(2, window.devicePixelRatio);
			this.lastObjectSelected.event.position = CanvasHandler.canvasToWindowCoordinates(this.context2D, dpi, this.lastObjectSelected.originalCoordinates);
			this.objectClickSubject.next(this.lastObjectSelected.event);
		}
	}

	protected pushCanvasTransformState() {
		this.canvasTransformState = {
			width: this.canvas.width,
			height: this.canvas.height,
			styleWidth: this.canvas.style.width,
			styleHeight: this.canvas.style.height,
			transform: this.getTransform()
		};
	}


	protected popCanvasTransformState() {
		if (!this.canvasTransformState) {
			return;
		} else {
			this.canvas.width = this.canvasTransformState.width;
			this.canvas.height = this.canvasTransformState.height;
			this.canvas.style.width = this.canvasTransformState.styleWidth;
			this.canvas.style.height = this.canvasTransformState.styleHeight
			this.setTransform(this.canvasTransformState.transform);
		}
	}

	protected copyOriginalCanvasToAnotherCanvas(width: number, height: number, dpi: number): HTMLCanvasElement {
		// Save canvas state
		this.pushCanvasTransformState();
		this.canvas.width = width * dpi;
		this.canvas.height = height * dpi;
		this.canvas.style.width = width + 'px';
		this.canvas.style.height = height + 'px';


		// Reset transform
		const domMatrix = new DOMMatrix().scale(dpi,  dpi).translate(0,0);
		this.setTransform(domMatrix);

		this.onDraw();

		//Copy to another canvas
		const newCanvas =  document.createElement('canvas')
		newCanvas.width = this.canvas.width;
		newCanvas.height = this.canvas.height;
		const sourceContext = newCanvas.getContext('2d') as CanvasRenderingContext2D;
		sourceContext.drawImage(this.canvas, 0, 0, this.canvas.width, this.canvas.height,0, 0, this.canvas.width, this.canvas.height)

		// Reset  canvas to its size
		this.popCanvasTransformState();
		return newCanvas;
	}
}
