<div class="flex-column gap-widget">
	<!-- Users rates-->
	<div class="flex-space-between w-100 gap-L">
		<div class="w-100">
			<div class="bg-rounded w-100 top-widget-height">
				<application-mini-widget
					[widget]="usageWidget"
					[type]="'users'"
					[title]="'menu.users' | translate"
				></application-mini-widget>
			</div>
		</div>

		<div class="w-100">
			<div class="bg-rounded w-100 top-widget-height">
				<div *ngIf="_loading" class="border-box-full">
					<app-dashboard-page-loader></app-dashboard-page-loader>
				</div>

				<div *ngIf="!_loading" class="flex-column flex-space-between border-box-full pad-M">
					<div class="flex-align-center flex-space-between gap-S">
						<div class="flex-space-between font-MM fw-700">
							{{ 'page.organization.usage.remoteWorking' | translate }}
						</div>
					</div>

					<div class="flex-justify-end w-100">
						<div class="flex-column flex-align-end gap-XS mr-xs" style="max-width: 150px;">
							<div class="flex-justify-center flex-align-end flex-column gap-XS">
								<img src="assets/icons/coming-soon.svg" width="24">
								<span class="font-S fw-500 grey">Coming soon !</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="w-100">
			<div class="bg-rounded w-100 top-widget-height">
				<application-mini-widget
					[widget]="satisfactionWidget"
					[type]="'satisfaction'"
					[title]="'Satisfaction'"
					[subtitle]="data && data.satisfaction.votersCount ? data!.satisfaction.votersCount + ' avis' : undefined"
				></application-mini-widget>
			</div>
		</div>
	</div>

	<!-- Activity histogram -->
	<div class="bg-rounded pad-M">
		<div *ngIf="_initializing || _loading" style="margin-bottom: 4px;">
			<app-linechart-loader></app-linechart-loader>
		</div>
		<app-usage-histogram *ngIf="!_initializing && !_loading"
							 [fromApplication]="false"
							 [usagePerMonth]="data?.monthlyUsage || []"
							 [usagePerDay]="data?.dailyUsage || []"
							 [usagePerHour]="data?.hourlyUsage || []">
		</app-usage-histogram>
	</div>

	<div class="bg-rounded pad-M">
		<app-organization-hourly-amplitude-donut [appsAmplitudes]="data?.applicationsHourlyAmplitude" [hourlyAmplitude]="data?.hourlyAmplitude"></app-organization-hourly-amplitude-donut>
	</div>
</div>
