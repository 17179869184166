@if (_initializing || _loading) {
<div class="border-box-full">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
} @else {
<div>
	<div class="flex-align-center pl-m pr-m pt-m pb-m">
        <span class="font-MM fw-700">{{ 'page.appDetails.finance.distributionOfCosts' | translate }}</span>
	</div>
	<div class="flex-column flex-justify-center gap-M pl-xl pr-xl" style="height: 237px;">
        @if (totalCost && totalCost > 0) {
		<div class="flex-center pr-xs" style="gap: 30px;">
			<!-- Doughnut with data -->
			<div>
				<div class="disp-b" style="width: 200px; height: 200px;">
					<canvas baseChart style="width: 200px; height: 200px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
				</div>
				<div class="doughnut-center">
					<div class="doughnut-flex">
						<div class="doughnut-total">
							{{ totalCost|cost:'default' }}
						</div>
						<div class="doughnut-label">
							{{ 'page.appDetails.finance.contracts.totalCostForYear' | translate:{"year": year } }}
						</div>
					</div>
				</div>
			</div>
			<div class="flex-column flex-justify-center gap-XL w-100 h-100">
				@for (item of listFiltered; track item.id) {
					<div class="flex-column gap-XS">
						<div class="flex-space-between">
							<div class="font-M fw-500">
								<span *ngIf="item.id">{{ item.name }}</span>
								<span *ngIf="!item.id">{{ 'page.financeDashboard.others' | translate }}</span>
							</div>
							<div class="font-M fw-700">
								<span *ngIf="!item.percentage || item.percentage < 1"> < 1%</span>
								<span *ngIf="item.percentage && item.percentage >= 1">{{ item.percentage }}%</span>
							</div>
						</div>
						<div style="position: relative;">
							<div class="progression-bg"></div>
							<div [ngClass]="'progression-'+$index" [ngStyle]="{ 'width': item.percentage+'%' }"></div>
						</div>
					</div>
				}
				@for (number of [1,2,3,4]; track number) {
					@if (listLength < number) {
						<div class="flex-column">
							<div class="flex-space-between">
								<div class="donut-placeholder"></div>
								<span class="font-M fw-700">-</span>
							</div>
							<div style="position: relative;">
								<div class="progression-bg"></div>
							</div>
						</div>
					}
				}
			</div>
		</div>
        } @else {
		<div class="flex-center" style="gap: 70px;">
			<!-- Doughnut with no data -->
			<div>
				<div class="disp-b" >
					<canvas baseChart style="width: 150px; height: 150px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
				</div>
				<div class="doughnut-center">
					<div class="doughnut-flex">
						<div class="doughnut-total-no-data">
							-
						</div>
						<div class="doughnut-label">
							{{ 'page.appDetails.finance.contracts.totalCostForYear' | translate:{"year": year } }}
						</div>
					</div>
				</div>
			</div>
			<div class="flex-column flex-justify-center w-100 gap-XXL" style="height: 100%;">
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="donut-placeholder"></div>
						<span class="font-M fw-700">-</span>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
					</div>
				</div>
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="donut-placeholder"></div>
						<span class="font-M fw-700">-</span>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
					</div>
				</div>
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="donut-placeholder"></div>
						<span class="font-M fw-700">-</span>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
					</div>
				</div>
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="donut-placeholder"></div>
						<span class="font-M fw-700">-</span>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
					</div>
				</div>
			</div>
		</div>
        }
	</div>
</div>
}
