<span class="font-L fw-700">{{ 'page.settings.menu.tenant' | translate }}</span>
<!-- Tenant info -->
<div class="mt-xl grey">
	<div>{{'page.tenant.name'|translate}}</div>
	<app-text-input [placeholder]="'page.tenant.namePlaceholder'|translate" [input]="nameControl" (blur)="onChangeTenantName()"></app-text-input>
</div>

<div class="divider"></div>
<div class="flex-space-between flex-align-center">
	<div class="flex-column">
		<span class="font-MM fw-700">{{ 'page.settings.nominativeTracking' | translate }}</span>
		<span class="font-M mt-xs">{{ 'page.settings.nominativeTrackingSubtitle' | translate }}</span>
	</div>
	<app-toggle-button [toggleInput]="incognitoControl"></app-toggle-button>
</div>

<!-- Change currency -->
<div class="divider"></div>
<div class="font-MM fw-700 mt-l">{{ 'page.account.currencyTitle' | translate }}</div>
<div class="font-M mt-xs mb-s">{{ 'page.account.currencySubtitle' | translate }}</div>
<app-multi-select [inputControl]="currencyControl" [disabled]="!currencies.length">
    <app-select-trigger appearance="field">{{currencyControl.value?.name + ' - ' + currencyControl.value?.symbol}}</app-select-trigger>
    <app-select-option *ngFor="let currency of currencies" [value]="currency">{{currency.name + ' - ' + currency.symbol}}</app-select-option>
</app-multi-select>

<!-- Users -->
<div class="divider mt-s"></div>
<app-settings-users></app-settings-users>

<!-- Delete tenant -->
<div class="divider" style="margin-bottom: 5px"></div>
<app-text-button color="danger" [disabled]="!tenant || tenant.configuration.role !== 'admin'" (click)="onDeleteTenant()" >{{ 'page.tenant.deleteButton' | translate }}</app-text-button>
