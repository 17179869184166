<form class="flex-column gap-M" [formGroup]="formGroup">
    <div class="flex-column gap-XS">
        <div class="font-M fw-500 grey">{{'page.appDetails.files.dialog.fileTitle'|translate}}</div>
        <app-text-input [input]="titleFormControl"
                        [placeholder]="'page.appDetails.files.dialog.fileTitlePlaceholder'|translate"></app-text-input>
    </div>
    <div class="flex-column gap-XS">
        <div class="font-M fw-500 grey">{{'page.appDetails.files.dialog.documentType'|translate}}</div>
        <app-multi-select class="w-100" [inputControl]="documentTypeFormControl"
                          [disabled]="_saving">
            <app-select-trigger appearance="field">{{documentTypeFormControl.value.name}}</app-select-trigger>
            <app-select-option *ngFor="let type of documentTypeChoices" [value]="type">{{type.name}}</app-select-option>
        </app-multi-select>
    </div>
    <div class="flex-column gap-XS">
        <div class="flex-space-between w-100">
            <div class="font-M fw-500 grey">{{'page.appDetails.files.dialog.comment'|translate}}</div>
            <div class="font-S fw-500 grey">{{descriptionFormControl.value?.length ?? 0}} / 200</div>
        </div>
        <textarea maxlength="200" class="textarea" [formControl]="descriptionFormControl"
                  [placeholder]="'page.appDetails.files.dialog.commentPlaceholder'|translate"></textarea>
    </div>
	<div class="flex-space-between flex-align-center">
		<div class="flex-align-center">
			<span>{{'page.appDetails.files.dialog.published'|translate}}</span>
			<div class="flex-center" style="height: 25px; width: 25px;"
				 [tooltip]="'page.appDetails.files.dialog.publishedTooltip'|translate" tooltipPosition="right">
				<img src="assets/icons/help-2.svg" width="14" height="14" alt=""/>
			</div>
		</div>
		<app-toggle-button [toggleInput]="publishedFormControl" [scale]="0.8"></app-toggle-button>
	</div>
    <div class="w-100 border-light-grey-top"></div>
    <app-upload-file [defaultFiles]="!data.defaultData ? [] : [data.defaultData!.fileLink]" [limit]="1"
                     (filesChange)="fileLinkIdFormControl.setValue($event[0])"
                     (uploading)="_uploading = $event"></app-upload-file>
    <div class="flex gap-M">
        <app-text-button type="stroked" class="w-50" [disabled]="_saving"
                         (click)="cancel.emit()">
            {{'page.appDetails.finance.contracts.cancel'|translate}}
        </app-text-button>
        <app-text-button type="flat" class="w-50" [disabled]="formGroup.invalid" [loading]="_saving || _uploading"
                         (click)="save()">
            {{(!data.defaultData ? 'page.appDetails.files.dialog.add' : 'page.appDetails.files.dialog.save')|translate}}
        </app-text-button>
    </div>
</form>
