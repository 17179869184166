import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {finalize, Observable, of, Subscription, switchMap} from "rxjs";
import {map, tap} from "rxjs/operators";
import {ServerForm} from "../../../../../../services/model/resource.model";
import {ServerService} from "../../../../../../services/back/server.service";
import {CurrentTenantService} from "../../../../../../services/front/current-tenant.service";
import {IP_ADDRESS_REGEXP} from "../../../../../../utils/forms.utils";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-add-server-manually',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './add-server-manually.component.html',
  styleUrl: './add-server-manually.component.scss',
	animations: [
		trigger('validators-appear', [
			transition(':enter', [
				style({ opacity: 0, display: 'none', transform: 'translateY(-10px)'}),
				animate('300ms ease-in-out', style({ opacity: 1, display: 'block', transform: 'translateY(0)' })),
			])
		])
	]
})
export class AddServerManuallyComponent implements OnInit, OnDestroy {
	@Output() onCloseDialog: EventEmitter<void> = new EventEmitter<void>();
	@Output() onGoBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() onAddServer: EventEmitter<void> = new EventEmitter<void>();

	_initializing: boolean = false;
	_saving: boolean = false;

	formGroup: FormGroup;

	tenantId: string;

	osList: { id: string, name: string }[] = [
		{ id: 'windows', name: 'Windows' },
		{ id: 'suse', name: 'Suse' },
		{ id: 'redhat', name: 'Red Hat' },
		{ id: 'ubuntu', name: 'Ubuntu' },
		{ id: 'debian', name: 'Debian' },
		{ id: 'centos', name: 'CentOS' },
		{ id: 'fedora', name: 'Fedora' },
		{ id: 'ibmi', name: 'IBMi' },
		{ id: 'other', name: 'Other' },
	];

	subscription: Subscription = new Subscription();

	constructor(private serverService: ServerService,
				private currentTenantService: CurrentTenantService) {
	}

	ngOnInit() {
		this.createForm();
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(
				tap(tenantId => this.tenantId = tenantId)
			).subscribe());
	}

	closeDialog() {
		this.onCloseDialog.emit();
	}

	createForm() {
		this.formGroup = new FormGroup({
			[Form.name]: new FormControl(undefined, [Validators.required]),
			[Form.system]: new FormControl('windows', [Validators.required]),
			[Form.ip_address]: new FormControl(undefined, [Validators.pattern(IP_ADDRESS_REGEXP)]),
		});
	}

	save() {
		this.switchSaving()
			.pipe(
				map(() => this.buildServerForm()),
				switchMap(serverForm => this.serverService.createManualServer(this.tenantId, serverForm)),
				finalize(() => this.switchSaving())
			).subscribe(() =>this.onAddServer.emit());
	}

	buildServerForm(): ServerForm {
		return {
			name: this.nameFormControl.value,
			os: this.osFormControl.value,
			ipAddress: this.ipAddressFormControl.value,
		}
	}

	get osFormControl(): FormControl {
		return this.formGroup.get(Form.system) as FormControl;
	}

	get nameFormControl(): FormControl {
		return this.formGroup.get(Form.name) as FormControl;
	}

	get ipAddressFormControl(): FormControl {
		return this.formGroup.get(Form.ip_address) as FormControl;
	}

	get osFormValue(): string {
		return <string>this.osList.find(os => os.id === this.osFormControl.value)?.name;
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe()
	}
}

enum Form {
	name = 'name',
	system = 'system',
	ip_address = 'ip_address',
}
