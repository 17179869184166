import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatRippleModule} from "@angular/material/core";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ApplicationMapping, ApplicationMappingData} from "../../../../services/model/application-mapping.model";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter, map} from "rxjs/operators";
import {
	ApplicationMappingApplicationFormDialogComponent
} from "../../applications/application-detail/architecture-tab/application-mapping-application-form/dialog/application-mapping-application-form-dialog.component";
import {ApplicationGeneric} from "../../../../services/model/new-application.model";
import {
	ApplicationMappingApplicationFormData
} from "../../applications/application-detail/architecture-tab/application-mapping-application-form/application-mapping-application-form.component";
import {CurrentTenantService} from "../../../../services/front/current-tenant.service";
import {SnackbarService} from "../../../../services/front/snackbar.service";
import {NewApplicationService} from "../../../../services/back/new-application.service";
import {ApplicationMappingService} from "../../../../services/back/application-mapping.service";
import {MatDialog} from "@angular/material/dialog";
import {DesignSystemModule} from "../../../design-system/design-system.module";

@Component({
  selector: 'app-diagram-tooltip',
  standalone: true,
	imports: [CommonModule, MatRippleModule, TranslateModule, DesignSystemModule],
  templateUrl: './diagram-tooltip.component.html',
  styleUrl: './diagram-tooltip.component.scss',
})
export class DiagramTooltipComponent implements OnInit {
	constructor(private currentTenantService: CurrentTenantService,
				private snackBar: SnackbarService,
				private applicationService: NewApplicationService,
				private applicationMappingService: ApplicationMappingService,
				private dialog: MatDialog,
				private translate: TranslateService) {
	}

	@Input() positionTop: number = 0;
	@Input() positionLeft: number = 0;
	@Input() data: ApplicationMapping;

	@Output() updated: EventEmitter<void> = new EventEmitter<void>();

	tenantId: string;

	_loadingDialog: boolean;

	formData?: {
		applicationList: ApplicationGeneric[],
		mappingDataList: ApplicationMappingData[]
	};

	subscription: Subscription = new Subscription();

	ngOnChanges(changes: SimpleChanges) {
		if (changes.postionTop) {
			this.positionTop = changes.positionTop.currentValue;
		}

		if (changes.positionLeft) {
			this.positionLeft = changes.positionLeft.currentValue;
		}
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges().subscribe(tenantId => this.tenantId = tenantId));
	}

	openApplicationMappingFormDialog(defaultData: ApplicationMapping): void {
		this.switchLoadingDialog()
			.pipe(
				switchMap(() => this.getApplicationMappingFormData()),
				map(formData => this.buildApplicationMappingApplicationFormData(formData, defaultData)),
				switchMap(data => this.dialog.open(ApplicationMappingApplicationFormDialogComponent, { width: '475px', data: data }).afterClosed()),
				filter(updated => !!updated),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()),
				finalize(() => this.switchLoadingDialog()))
			.subscribe();
	}

	private switchLoadingDialog(): Observable<{}> {
		this._loadingDialog = !this._loadingDialog;
		return of({});
	}

	private getApplicationMappingFormData(): Observable<{ applicationList: ApplicationGeneric[], mappingDataList: ApplicationMappingData[] }> {
		return of(this.formData).pipe(
			switchMap(formData => !formData
				? forkJoin([
					this.applicationService.getAllApplication(this.tenantId),
					this.applicationMappingService.getAllApplicationMappingDataByTenantId(this.tenantId)])
					.pipe(map(([applicationList, mappingDataList]) => ({ applicationList, mappingDataList })))
				: of(formData)),
			tap(formData => this.formData = formData));
	}

	private buildApplicationMappingApplicationFormData(formData: { applicationList: ApplicationGeneric[], mappingDataList: ApplicationMappingData[] }, defaultData?: ApplicationMapping): ApplicationMappingApplicationFormData {
		return {
			tenantId: this.tenantId,
			applicationId: this.data!.source.id,
			defaultData: defaultData,
			preloaded: {
				applicationList: formData.applicationList,
				mappingDataList: formData.mappingDataList
			}
		}
	}
}
