import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ApplicationHourlyAmplitude, HourlyAmplitude} from "../../../../../../services/usage.service";
import {BaseChartDirective, NgChartsModule} from "ng2-charts";
import {ChartData, ChartOptions} from "chart.js";
import {Subscription} from "rxjs";
import {ColorEnum} from "../../../../../../../_variables";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {CostPipe} from "../../../../../../pipes/number/cost.pipe";
import {TranslateModule} from "@ngx-translate/core";
import {ApplicationGeneric} from "../../../../../../services/model/new-application.model";

@Component({
  selector: 'app-organization-hourly-amplitude-donut',
  standalone: true,
	imports: [CommonModule, ContentLoaderModule, CostPipe, NgChartsModule, TranslateModule],
  templateUrl: './organization-hourly-amplitude-donut.component.html',
  styleUrl: './organization-hourly-amplitude-donut.component.scss'
})
export class OrganizationHourlyAmplitudeDonutComponent implements OnChanges {
	@Input() appsAmplitudes: ApplicationHourlyAmplitude[] | undefined;
	@Input() hourlyAmplitude: HourlyAmplitude | undefined;

	otherData: OtherData | undefined;
	topData: TopData[] = [];
	timeDiff?: {hour: number, minute: number};

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	doughnutChartData: ChartData<'doughnut'>;
	chartOptions: ChartOptions<'doughnut'>;

	subscriptions: Subscription = new Subscription();

	ngOnChanges(changes: SimpleChanges) {
		if (changes.appsAmplitudes && changes.appsAmplitudes.currentValue) {
			this.setChartData();
			this.setDonutData(this.appsAmplitudes!);
		}

		if (changes.hourlyAmplitude) {
			if (this.hourlyAmplitude && (this.hourlyAmplitude.beginning || this.hourlyAmplitude.beginning === 0) && (this.hourlyAmplitude.end || this.hourlyAmplitude.end === 0)) {
				this.timeDiff = {
					hour: this.hourlyAmplitude.end?.hour - this.hourlyAmplitude.beginning?.hour,
					minute: this.hourlyAmplitude.end?.minute - this.hourlyAmplitude.beginning?.minute
				};
				if (this.timeDiff.minute < 0) {
					this.timeDiff.hour -= 1;
					this.timeDiff.minute += 60;
				}
			} else {
				this.timeDiff = undefined;
			}
		}
	}

	private setChartData() {
		this.doughnutChartData = {
			labels: [],
			datasets: [
				{
					data: [ 0, 0 ],
					backgroundColor: [ColorEnum.accent, ColorEnum.chart_yellow],
					borderColor: [ColorEnum.accent, ColorEnum.chart_yellow],
					borderRadius: 5,
					spacing: 2
				}
			],
		};
		this.chartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			events: [],
			cutout: '65%',
			elements: {
				arc: {
					borderWidth: 1,
				}
			}
		};
	}

	private setDonutData(data: ApplicationHourlyAmplitude[]) {
		this.otherData = undefined;
		this.topData = [];

		this.topData = data.sort((a, b) => b.minutes - a.minutes).slice(0, 3).map((d, i) => ({
			application: d.application,
			value: d.minutes,
			percentage: Math.round(((d.minutes / data.reduce((acc, d) => acc + d.minutes, 0)) * 100))
		}));

		if (data.length > 3) {
			this.otherData = {
				value: data.slice(3).reduce((acc, d) => acc + d.minutes, 0),
				percentage: Math.round(((data.slice(3).reduce((acc, d) => acc + d.minutes, 0) / data.reduce((acc, d) => acc + d.minutes, 0)) * 100))
			};
		}

		this.doughnutChartData.datasets[0].data = [];
		this.clearDoughnutColor();

		this.topData.forEach((d, i) => {
			this.doughnutChartData.datasets[0].data.push(d.value);
			this.addDoughnutColor(i === 0 ? ColorEnum.doughnut_colors_0 : i === 1 ? ColorEnum.doughnut_colors_1 : ColorEnum.doughnut_colors_2);
		});

		if (this.otherData) {
			this.doughnutChartData.datasets[0].data.push(this.otherData.value);
			this.addDoughnutColor(ColorEnum.doughnut_colors_3);
		}

		if (data.length === 0) {
			this.doughnutChartData.datasets[0].data.push(1);
			this.addDoughnutColor(ColorEnum.medium_grey);
		}

		if (this.chart) {
			this.chart.update();
		}
	}

	private minutesToHourAndMinutes(minutes: number): string {
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
		return `${hours}h${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`;
	}

	private addDoughnutColor(color:string) {
		(this.doughnutChartData.datasets[0]?.backgroundColor as string[]).push(color);
		(this.doughnutChartData.datasets[0]?.borderColor as string[]).push(color);
	}

	private clearDoughnutColor() {
		this.doughnutChartData.datasets[0].backgroundColor = [];
		this.doughnutChartData.datasets[0].borderColor = [];
	}
}

interface TopData {
	application: ApplicationGeneric;
	value: number;
	percentage: number;
}

interface OtherData {
	value: number;
	percentage: number;
}
