<div class="flex-align-center flex-space-between" style="height: 34px;">
	<div class="font-MM fw-700">{{ 'page.organization.usage.activityRepartition' | translate }}</div>
</div>

<div class="flex-column flex-justify-center gap-M pl-xl pr-xl" style="height: 280px;">
	<div class="flex-center w-100" style="gap: 70px;">
		<!-- Doughnut with data -->
		<div>
			<div class="disp-b" >
				<canvas baseChart style="width: 250px; height: 250px;"
						[data]="doughnutChartData"
						[type]="'doughnut'"
						[options]="chartOptions"
				></canvas>
			</div>
			<div class="doughnut-center">
				<div class="doughnut-flex">
					<div class="doughnut-total">
						<span *ngIf="timeDiff">{{ timeDiff.hour }}h{{ timeDiff.minute < 10 ? '0' + timeDiff.minute : timeDiff.minute }}</span>
						<span class="grey" *ngIf="!timeDiff">-</span>
					</div>
					<div class="doughnut-label">
						{{ 'page.organization.usage.activityPerDay' | translate }}
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="topData.length > 0" class="flex-column flex-justify-center w-100 h-100 gap-XXL">
			<div *ngFor="let top of topData; let index = index" class="flex-column">
				<div class="flex-space-between mb-xs">
					<div class="font-M fw-500">{{ top.application.name }}</div>
					<div class="font-M fw-700">{{ top.percentage }}%</div>
				</div>
				<div style="position: relative;">
					<div class="progression-bg"></div>
					<div [class]="'progression-' + index" [ngStyle]="{ 'width': top.percentage+'%' }"></div>
				</div>
			</div>
			<div *ngIf="otherData">
				<div class="flex-space-between mb-xs">
					<div class="font-M fw-500">Other</div>
					<div class="font-M fw-700">{{ otherData.percentage }}%</div>
				</div>
				<div style="position: relative;">
					<div class="progression-bg"></div>
					<div class="progression-other" [ngStyle]="{ 'width': otherData.percentage+'%' }"></div>
				</div>
			</div>
		</div>
		<div class="flex-column flex-justify-center w-100 h-100 gap-XXL" *ngIf="topData.length <= 0">
			<div class="flex-column">
				<div class="flex-space-between">
					<div class="donut-placeholder"></div>
					<span class="font-M fw-700">-</span>
				</div>
				<div style="position: relative;">
					<div class="progression-bg"></div>
				</div>
			</div>
			<div class="flex-column">
				<div class="flex-space-between">
					<div class="donut-placeholder"></div>
					<span class="font-M fw-700">-</span>
				</div>
				<div style="position: relative;">
					<div class="progression-bg"></div>
				</div>
			</div>
			<div class="flex-column">
				<div class="flex-space-between">
					<div class="donut-placeholder"></div>
					<span class="font-M fw-700">-</span>
				</div>
				<div style="position: relative;">
					<div class="progression-bg"></div>
				</div>
			</div>
			<div class="flex-column">
				<div class="flex-space-between">
					<div class="donut-placeholder"></div>
					<span class="font-M fw-700">-</span>
				</div>
				<div style="position: relative;">
					<div class="progression-bg"></div>
				</div>
			</div>
		</div>
	</div>
</div>
