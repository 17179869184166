@if (_initializing || _loading) {
<div class="border-box-full">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
} @else {
<div class="disp-f f-d-col" style="height: 100%; gap: 15px;">
	<div class="pl-m pr-m pt-m pb-m">
        <span class="font-MM fw-700">{{ 'page.usageDashboard.hourlyAmplitude' | translate }}</span>
	</div>

	<div class="disp-f justify-center a-i-c f-d-col">
		<div>
			<svg height="240" viewBox="-54.5 -50 110 70">
				<g>
					<linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
						<stop offset="0%" stop-color="#56c4fb" />
						<stop offset="100%" stop-color="#3DC0FF" />
					</linearGradient>

					<!-- ARCS -->
					<path class="grey" [attr.d]="describeArc(0, 0, 40, -90, 90)" fill="none" stroke-width="5"></path>
					<path *ngIf="data.beginning && data.end && (data.beginning.hour <= data.end.hour || (data.beginning.hour == data.end.hour && data.beginning.minute < data.end.minute))" id="curve" class="blue" [attr.d]="describeBlueArc(0, 0, 40, startAngle - 90, endAngle - 90)" fill="none" stroke-width="5"></path>

					<!-- DOTS -->
					<circle *ngIf="data.beginning" id="startDot" fill="white" r="2" [attr.cx]="circlePos(0,0,40,startAngle - 90,AngleType.X)" [attr.cy]="circlePos(0,0,40,startAngle - 90,AngleType.Y)">
					</circle>
					<circle *ngIf="data.end" id="endDot" fill="white" r="2" [attr.cx]="circlePos(0,0,40,endAngle - 90,AngleType.X)" [attr.cy]="circlePos(0,0,40,endAngle - 90,AngleType.Y)">
					</circle>

					<!-- TEXT -->
					<text *ngIf="data.beginning && data.end && (data.beginning != data.end)" [attr.x]="textPos(0,0,40,startAngle-90,AngleType.X, PosType.LEFT)" [attr.y]="textPos(0,0,40,startAngle-90, AngleType.Y, PosType.LEFT)" style="font-size: 5px; fill: #3DC0FF; font-weight: 700;">{{ data.beginning.hour }}h{{ data.beginning.minute < 10 ? '0' + data.beginning.minute : data.beginning.minute }}</text>
					<text *ngIf="data.end && data.beginning && (data.beginning != data.end)" [attr.x]="textPos(0,0,40,endAngle-90,AngleType.X, PosType.RIGHT)" [attr.y]="textPos(0,0,40,endAngle-90, AngleType.Y, PosType.RIGHT)" style="font-size: 5px; fill: #3DC0FF; font-weight: 700;">{{ data.end.hour }}h{{ data.end.minute < 10 ? '0' + data.end.minute : data.end.minute }}</text>

					<text *ngIf="data.beginning && data.end && (data.beginning.hour == data.end.hour && data.beginning.minute == data.end.minute)" [attr.x]="textPos(0,0,40,startAngle-90,AngleType.X, PosType.CENTER)" [attr.y]="textPos(0,0,40,startAngle-90, AngleType.Y, PosType.CENTER)" style="font-size: 5px; fill: #3DC0FF; font-weight: 700;">{{ data.beginning.hour }}h{{ data.beginning.minute < 10 ? '0' + data.beginning.minute : data.beginning.minute }}</text>

					<text y="-13" dominant-baseline="middle" text-anchor="middle" style="font-size:11px; fill: #3DC0FF; font-weight: 700;" *ngIf="timeDiff">{{ timeDiff.hour }}h{{ timeDiff.minute < 10 ? '0' + timeDiff.minute : timeDiff.minute }}</text>
					<text y="-13" dominant-baseline="middle" text-anchor="middle" style="font-size:11px; fill: black; font-weight: 700;" *ngIf="!timeDiff">-</text>
					<text y="-5" dominant-baseline="middle" text-anchor="middle" style="font-size:4px;">{{ 'page.usageDashboard.onLast30Days' | translate }}</text>
				</g>
			</svg>
		</div>
	</div>
</div>
}
