import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DailyHealth} from 'src/app/services/back/health.service';
import {BaseChartDirective, NgChartsModule} from 'ng2-charts';
import {ChartData, ChartOptions} from 'chart.js';
import {ColorEnum} from 'src/_variables';
import {DeckContentLoaderModule} from '../../../global/title-content-loader/deck-content-loader.module';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-health-graph',
  standalone: true,
	imports: [CommonModule, DeckContentLoaderModule, NgChartsModule, TranslateModule],
  templateUrl: './health-graph.component.html',
  styleUrl: './health-graph.component.scss'
})
export class HealthGraphComponent implements OnInit {
	// TODO @TAN include initialization, with optional applicationId in input ?

	@Input() data: DailyHealth[];
	@Input() height: number = 320;
	@Input() fromDrawer: boolean = false;
	@Input() health: number | undefined;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	tooltip: HealthTooltip = {display: "none", top: "0px", left: "0px", day: 0, month: 0, year: 0, value: 0, errors: 0}
	chartData: ChartData<any, {date: Date, amount: number, errors: number} []> = {
		labels: [],
		datasets: []
	};
	chartOptions: ChartOptions<any>;

	constructor(private cdr: ChangeDetectorRef,
							private translate: TranslateService) {
	}

	ngOnInit() {
		this.createOptions();
		this.createLabels();
		this.createHealthDataset();
		this.createErrorsDataset();
		this.updateChart();
	}

	createOptions(): void {
		this.chartOptions = {
			interaction: {
				intersect: false,
				mode: 'index',
			},
			animation: false,
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					enabled: false,
					external: (context: { chart: any; tooltip: any; }) => {
						const {chart, tooltip} = context;

						if (tooltip.opacity === 0) {
							if (this.tooltip.display !== "none") {
								this.tooltip.display = "none"
								this.cdr.detectChanges();
							}
							return
						}
						const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

						const canvasWidth = chart.canvas.getBoundingClientRect().width;

						const canvasHeight = chart.canvas.getBoundingClientRect().height;

						let xPosition = positionX + tooltip.caretX;
						if (xPosition + 200 > canvasWidth) {
							xPosition = positionX + tooltip.caretX + 200 - canvasWidth
							xPosition = positionX + tooltip.caretX - xPosition
						}

						let yPosition = positionY + tooltip.caretY;
						if (yPosition > canvasHeight) {
							yPosition = positionY + tooltip.caretY - canvasHeight
							yPosition = positionY + tooltip.caretY - yPosition
						}

						const dataPoint = tooltip.dataPoints[0].raw as { date: Date, amount: number, errors: number}

						const day: number  = dataPoint.date.getDate();
						const month: number = dataPoint.date.getMonth() + 1;
						const year: number = dataPoint.date.getFullYear();

						const value = dataPoint.amount;

						const errors: number = dataPoint.errors;

						this.tooltip = {
							display: "block",
							left: xPosition + 'px',
							top: yPosition + 'px',
							day,
							month,
							year,
							value,
							errors
						}

						this.cdr.detectChanges();
					},
				},

			},
			scales: {
				y: {
					min: () => {
						const max = Math.round(Math.max(...this.data.map(data => data.health.percent!)))
						const min = Math.trunc(Math.min(...this.data.map(data => data.health.percent!)))

						if (min === max) return 0

						const minY = Math.round(max - ((max - min) * 3))

						if (minY < 0) return 0

						return minY
					},
					suggestedMax: 100,
					display: true,
					grid: {
						lineWidth: this.fromDrawer ? 1.5 : 2,
						borderDash: [5,5],
						drawTicks: false,
						drawBorder: false,
						color: ColorEnum.medium_grey,
					},
					ticks: {
						callback: function(value: any) {if (typeof value == "number" && value % 1 === 0) {return value + " %";} else {return undefined}},
						maxTicksLimit: () => {
							const max = Math.max(...this.data.map(data => data.health.percent!))
							const min = Math.min(...this.data.map(data => data.health.percent!))

							if (min === max && !this.fromDrawer)
								return 6;
							else if (min === max && this.fromDrawer)
								return 3
							return undefined
						},
						autoSkip: true,
						padding: 6,
						font: {
							family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
						},
						color: "#1D3B58"
					}
				},
				y2: {
					max: Math.max(...this.data.map(data => data.errorCount)) * 2,
					min: 0,
					display: false,
				},
				x: {
					ticks: {
						font: {
							family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
							size: 9
						},
						color: "#B5B5C3"
					},
					grid: {
						lineWidth: 0,
						drawBorder: false
					}
				},
				x2: {
					display: false,
				}
			}
		};
	}

	createLabels(): void {
		this.chartData.datasets = [];
		this.chartData.labels = [];

		this.chartData.labels = this.data.map(health =>  {
			return new Date(health.date).getDate()
		});
	}

	createHealthDataset(): void {
		this.chartData.datasets.push(
			{
				data: this.data.map(data => {
					return {
						date: new Date(data.date),
						amount: Math.round((data.health.percent! + Number.EPSILON) * 100) / 100,
						errors: data.errorCount
					}
				}),
				backgroundColor: (context: { chart: any; }) => {
					const chart = context.chart;
					const {ctx, chartArea} = chart;

					if (!chartArea) {
						return;
					}
					return this.getGradient(ctx, chartArea);
				},
				parsing: {
					xAxisKey: 'date',
					yAxisKey: 'amount'
				},
				tension: 0.3,
				borderColor: "#3DC0FF",
				hoverBackgroundColor: "#3DC0FF",
				fill: true,
				pointStyle: "circle",
				pointRadius: 0.01,
				pointBorderColor: "#FFFFFF",
				pointBorderWidth: 1.5,
				pointBackgroundColor: "#FFFFFF",
				pointHoverBackgroundColor: "#3DC0FF",
				pointHoverRadius: 6,
				pointHoverBorderColor: "#FFFFFF",
				pointHoverBorderWidth: 2,
				borderWidth: 1.5,
				cubicInterpolationMode: "monotone",
				type: "line",
				stack: "combined",
				yAxisID: "y",
				xAxisID: "x",
			}
		)
	}

	createErrorsDataset(): void {
		this.chartData.datasets.push(
			{
				data: this.data.map(data => {
					return {
						date: new Date(data.date),
						amount: data.errorCount,
					}
				}),
				parsing: {
					xAxisKey: 'date',
					yAxisKey: 'amount'
				},
				type: "bar",
				yAxisID: "y2",
				xAxisID: "x",
				borderRadius: 10,
				backgroundColor: ColorEnum.red,
				barThickness: () => {
					this.data.map(data => {
						return data.errorCount === 0 ? 0 : 10
					})
				},
				barPercentage: 0.5,
				hoverBackgroundColor: ColorEnum.red_hovered,
			}
		)
	}

	updateChart(): void {
		if (this.chart) this.chart.update();
	}

	getGradient(ctx: any, chartArea: any): any {
		let width, height, gradient;
		const chartWidth = chartArea.right - chartArea.left;
		const chartHeight = chartArea.bottom - chartArea.top;
		if (gradient === null || width !== chartWidth || height !== chartHeight) {
			gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
			gradient.addColorStop(0, 'rgba(61, 192, 255, 0)');
			gradient.addColorStop(0.5, 'rgba(61, 192, 255, 0.1)');
			gradient.addColorStop(1, 'rgba(61, 192, 255, 0.3)');
		}

		return gradient;
	}

	getHealth(value: number): string {
		return value >= 99
			? this.translate.instant('page.dashboard.typology.good')
			: (value >= 95 && value < 99
				? this.translate.instant('page.dashboard.typology.needs_improvement')
				: this.translate.instant('page.dashboard.typology.poor'));
	}
}

export interface HealthTooltip {
	display: "block" | "none",
	top: string,
	left: string,
	day: number,
	month: number,
	year: number,
	value: number,
	errors: number
}

