<div class="flex-align-center gap-S">
	@if (appStatus === ApplicationStatus.PHASE_IN) {
		<div class="b-radius-half bkg-accent-secondary" style="height: 11px; width: 11px;"></div>
		<span>{{'page.appDetails.lifeCycle.phase_in'|translate}}</span>
	} @else if (appStatus === ApplicationStatus.DEPLOYED) {
		<div class="b-radius-half bkg-success" style="height: 11px; width: 11px;"></div>
		<span>{{'page.appDetails.lifeCycle.deployed'|translate}}</span>
	} @else if (appStatus === ApplicationStatus.PHASE_OUT) {
		<div class="b-radius-half bkg-warning" style="height: 11px; width: 11px;"></div>
		<span>{{'page.appDetails.lifeCycle.phase_out'|translate}}</span>
	} @else if (appStatus === ApplicationStatus.RETIRED) {
		<div class="b-radius-half bkg-danger" style="height: 11px; width: 11px;"></div>
		<span>{{'page.appDetails.lifeCycle.retired'|translate}}</span>
	}
</div>
