import {Injectable} from '@angular/core';
import {mergeMap, Observable, of, switchMap} from 'rxjs';
import jsPDF from 'jspdf';
import {observableFromSync} from 'src/app/utils/observable.utils';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {catchError, tap} from 'rxjs/operators';
import html2canvas from 'html2canvas';

@Injectable()
export class PdfService {

	buildFromHtml(elementId: string, title: string, subtitle: string, icon: string, w?: number, h?: number): Observable<jsPDF | null> {
		const element: HTMLElement | null = document.getElementById(elementId);
		if (!element) {
			return of(null);
		} else {
			return of(element)
				.pipe(
					switchMap(elt => html2canvas(element, {
						allowTaint: true,
						useCORS: true,
						windowWidth: w ?? element.scrollWidth,
						windowHeight: h ?? element.scrollHeight + headerHeight + footerHeight
					})),
					tap(canvas => this.setCanvasProperties(canvas)),
					tap(canvas => document.body.appendChild(canvas)),
					switchMap(canvas => this.buildPdfFromCanvas(canvas, title, subtitle, icon, w ?? element.scrollWidth, h ?? element.scrollHeight)),
					tap(() => document.body.removeChild(document.getElementById('canvas')!)))
		}
	}

	private setCanvasProperties(canvas: HTMLCanvasElement): void {
		canvas.setAttribute('id', 'canvas');
		canvas.setAttribute('style', 'position: fixed');
	}

	private buildPdfFromCanvas(canvas: HTMLCanvasElement, title: string, subtitle: string, icon: string, w: number, h: number): Observable<jsPDF | null> {
		const img: string = canvas.toDataURL("image/jpeg", 1);
		const doc: jsPDF = new jsPDF('l', 'px', [w, h + headerHeight + footerHeight]);
		return this.buildHeader(doc, title, subtitle, icon)
			.pipe(
				tap(pdf => pdf.addImage(img, 'JPEG', 0, headerHeight, w, h)),
				switchMap(pdf => this.buildFooter(pdf)))
	}

	private buildHeader(pdf: jsPDF, title: string, subtitle: string, icon: string): Observable<jsPDF> {
		return observableFromSync(() => {
			// Add title
			pdf.setFontSize(16);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.setTextColor('#0C2633');
			pdf.text(title, padding + 50, padding + 17);

			// Add subtitle
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');
			pdf.text(subtitle, padding + 50, padding + 32);

			// Add date
			pdf.setFontSize(14);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.setTextColor('#0C2633');
			const currentDate = new Date().toLocaleDateString('fr-FR', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			});
			const textSize = pdf.getTextWidth(currentDate)
			pdf.text(currentDate, pdf.internal.pageSize.width - padding - textSize, padding + 15);

			// Add date comment
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');

			// Bottom line
			pdf.setLineWidth(1);
			pdf.setDrawColor('#F2F2F8');
			pdf.line(padding, 70, pdf.internal.pageSize.width - padding, 70);

			return pdf;
		}).pipe(
			mergeMap(pdf => this.buildHeaderIcon(pdf, icon))
		);
	}

	private buildHeaderIcon(pdf: jsPDF, icon: string): Observable<jsPDF> {
		return observableFromSync(() => {
			// Draw main icon background
			pdf.setFillColor('#EEF9FE');
			pdf.roundedRect(padding, padding, 40, 40, 12, 12, 'F');
			return pdf;
		}).pipe(
			mergeMap(() => {
				return fromPromise<jsPDF>(new Promise((resolve, reject) => {
					const image = new Image();
					image.src = icon;
					image.onload = () => {
						pdf.addImage(image, 'PNG', padding + 10, padding + 10, 20, 20, undefined, 'FAST');
						resolve(pdf);
					}
					image.onerror = () => {
						reject();
					}
				}))
			}),
			catchError(err => {
				console.error('Error while loading image', err);
				return of(pdf)
			})
		);
	}

	private buildFooter(pdf: jsPDF): Observable<jsPDF> {
		return observableFromSync(() => {
			// Bottom line
			pdf.setLineWidth(1);
			pdf.setDrawColor('#F2F2F8');
			pdf.line(padding, pdf.internal.pageSize.height - (2 * padding + 30), pdf.internal.pageSize.width - padding, pdf.internal.pageSize.height - (2 * padding + 30));

			const leftText1 = 'Criticality :'
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.setTextColor('#0C2633');
			pdf.text(leftText1, padding, pdf.internal.pageSize.height - (padding + 15));

			pdf.setFont('Helvetica', 'normal', '400');
			// Create 3 rounded rectangles for low, medium and high criticality
			pdf.setFillColor('#F7F8FA');
			pdf.roundedRect(padding + 60, pdf.internal.pageSize.height - (padding + 28), 70, 20, 10, 10, 'F');
			pdf.roundedRect(padding + 140, pdf.internal.pageSize.height - (padding + 28), 70, 20, 10, 10, 'F');
			pdf.roundedRect(padding + 220, pdf.internal.pageSize.height - (padding + 28), 70, 20, 10, 10, 'F');

			// Add text inside rectangles
			pdf.setTextColor('#0C2633');
			pdf.text('Low', padding + 80, pdf.internal.pageSize.height - (padding + 15));
			pdf.text('Medium', padding + 160, pdf.internal.pageSize.height - (padding + 15));
			pdf.text('High', padding + 240, pdf.internal.pageSize.height - (padding + 15));

			// Add page number
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');

			// First text part
			const text1 = 'Generated by'
			pdf.text(text1, pdf.internal.pageSize.width - padding - 180 - pdf.getTextWidth(text1), pdf.internal.pageSize.height - (padding + 15));

			// Second text part (link to Kabeen)
			const text2 = 'Kabeen'
			pdf.setDrawColor('#B5B5C3');
			pdf.setLineWidth(1);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.line(pdf.internal.pageSize.width - padding - 123 - pdf.getTextWidth(text2), pdf.internal.pageSize.height - (padding + 12), pdf.internal.pageSize.width - padding - 123, pdf.internal.pageSize.height - (padding + 12));
			pdf.textWithLink(text2, pdf.internal.pageSize.width - padding - 123 - pdf.getTextWidth(text2), pdf.internal.pageSize.height - (padding + 15), {url: 'https://kabeen.io'});

			// Third text part
			pdf.setFont('Helvetica', 'normal', '400');
			const text3 = 'on ' + new Date().toLocaleDateString('fr-FR', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}) + ' at ' + new Date().toLocaleTimeString('fr-FR', {hour: '2-digit', minute: '2-digit'});
			pdf.text(text3, pdf.internal.pageSize.width - padding - 89 - pdf.getTextWidth(text2), pdf.internal.pageSize.height - (padding + 15));
			return pdf;
		}).pipe(
			mergeMap(pdf => this.buildFooterIcon(pdf)),
			mergeMap(pdf => this.buildFooterLowCriticalityIcon(pdf)),
			mergeMap(pdf => this.buildFooterMediumCriticalityIcon(pdf)),
			mergeMap(pdf => this.buildFooterHighCriticalityIcon(pdf)),
		);
	}

	private buildFooterIcon(pdf: jsPDF): Observable<jsPDF> {
		return fromPromise<jsPDF>(new Promise((resolve, reject) => {
			const image = new Image();
			image.src = 'assets/new-logo.png';
			image.onload = () => {
				// put image between text1 and text 2
				pdf.addImage(image, 'PNG', pdf.internal.pageSize.width - padding - 175, pdf.internal.pageSize.height - (padding + 26), 15, 15, undefined, 'FAST');
				resolve(pdf);
			}
			image.onerror = () => {
				reject();
			}
		}));
	}

	private buildFooterLowCriticalityIcon(pdf: jsPDF): Observable<jsPDF> {
		return fromPromise<jsPDF>(new Promise((resolve, reject) => {
			const image = new Image();
			image.src = 'assets/icons/diagram-low-criticality.png';
			image.onload = () => {
				// put image between text1 and text 2
				pdf.addImage(image, 'PNG', padding + 69, pdf.internal.pageSize.height - (padding + 24), 5.79, 11, undefined, 'FAST');
				resolve(pdf);
			}
			image.onerror = () => {
				reject();
			}
		}));
	}

	private buildFooterMediumCriticalityIcon(pdf: jsPDF): Observable<jsPDF> {
		return fromPromise<jsPDF>(new Promise((resolve, reject) => {
			const image = new Image();
			image.src = 'assets/icons/diagram-medium-criticality.png';
			image.onload = () => {
				// put image between text1 and text 2
				pdf.addImage(image, 'PNG', padding + 149, pdf.internal.pageSize.height - (padding + 24), 5.79, 11, undefined, 'FAST');
				resolve(pdf);
			}
			image.onerror = () => {
				reject();
			}
		}));
	}

	private buildFooterHighCriticalityIcon(pdf: jsPDF): Observable<jsPDF> {
		return fromPromise<jsPDF>(new Promise((resolve, reject) => {
			const image = new Image();
			image.src = 'assets/icons/diagram-high-criticality.png';
			image.onload = () => {
				// put image between text1 and text 2
				pdf.addImage(image, 'PNG', padding + 229, pdf.internal.pageSize.height - (padding + 24), 5.79, 11, undefined, 'FAST');
				resolve(pdf);
			}
			image.onerror = () => {
				reject();
			}
		}));
	}
}

const padding: number = 15;
const headerHeight: number = 80;
const footerHeight: number = 70;
