import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {ApplicationOptimizationStatistic} from 'src/app/services/model/new-application.model';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {ApplicationOptimizationsDrawerComponent, ApplicationOptimizationsDrawerData} from 'src/app/modules/home/finance-dashboard/application-optimizations/drawer/application-optimizations-drawer.component';

@Component({
  selector: 'app-application-optimizations',
  templateUrl: './application-optimizations.component.html',
  styleUrls: ['./application-optimizations.component.scss']
})
export class ApplicationOptimizationsComponent implements OnInit, OnDestroy {

  @Input() filter: Subject<Organization|null|undefined>;

  tenantId: string;
  lastFilter?: Organization|null;

  _initializing: boolean;
  _loading: boolean;

  progress: number;
  percentage: number;
  potentialEarnings: number;

  initializeSub: Subscription;
  subscription: Subscription = new Subscription();

  constructor(private currentTenantService: CurrentTenantService,
              private applicationService: NewApplicationService,
              private rightSliderService: RightSliderService) {
  }

  ngOnInit() {
    this.subscription.add(this.currentTenantService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
      .pipe(tap(tenantId => this.tenantId = tenantId))
      .subscribe(() => this.initialize()));
    this.subscription.add(this.filter
      .pipe(tap(filter => this.lastFilter = filter))
      .subscribe(filter => this.initialize(filter)));
  }

  initialize(org?: Organization|null): void {
    this.initializeSub?.unsubscribe();
    this.initializeSub = this.switchLoading()
      .pipe(
        switchMap(() => this.applicationService.getApplicationOptimizationStatisticByTenantId(this.tenantId, org?.organizationId)),
        tap(data => this.setApplicationOptimizationStatistic(data)),
        finalize(() => this.switchLoading()))
      .subscribe();
  }

  setApplicationOptimizationStatistic(statistic: ApplicationOptimizationStatistic) {
    const chartRatio: number = 148 / 100;
    const reversedPercent: number = 100 - statistic.usagePercent;
    this.progress = reversedPercent * chartRatio;
    this.percentage = statistic.usagePercent;
    this.potentialEarnings = statistic.potentialEarnings.value ?? 0;
  }

  openDrawer(): void {
    const data: ApplicationOptimizationsDrawerData = {
      filter: this.lastFilter
    };
    this.rightSliderService.openComponent(ApplicationOptimizationsDrawerComponent, data)
      .subscribe(() => this.filter.next(this.lastFilter));
  }

  private switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.initializeSub?.unsubscribe();
  }
}
