<div class="flex-column gap-widget">
	<div class="flex-space-between w-100 gap-L">
		<div class="w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="bg-rounded w-100 top-widget-height">
				<application-mini-widget
					[widget]="licenceCostWidget"
					[type]="'tco'"
					[title]="'Licence'"
				></application-mini-widget>
			</div>
		</div>
		<div class="w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="bg-rounded w-100 top-widget-height">
				<application-mini-widget
					[widget]="serviceCostWidget"
					[type]="'tco'"
					[title]="'Service'"
				></application-mini-widget>
			</div>
		</div>
		<div class="bg-rounded w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<application-mini-widget
				[widget]="costPerUserWidget"
				[type]="'tco'"
				[title]="'page.organization.finance.costPerUser' | translate"
			></application-mini-widget>
		</div>
	</div>
	<div class="bg-rounded pad-M">
		<app-organization-cost-distribution [data]="data?.costs"></app-organization-cost-distribution>
	</div>
	<app-organization-contract-preview [data]="data?.contracts"></app-organization-contract-preview>
</div>
