import jsPDF from "jspdf";
import {forkJoin, merge, mergeMap, Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {observableFromSync} from 'src/app/utils/observable.utils';

export interface BlockProperties {x: number, y: number, width: number, height: number}

const blockSize = 1024;
const padding = 15;
const headerHeight = 80;
const footerHeight = 70;

export class PdfGenerator {
	constructor(private sourceCanvas: HTMLCanvasElement, private currentWidth: number, private currentHeight: number) {}

	protected buildCanvasBlock(): BlockProperties[] {
		// Cut canvas to small canvas
		const blocks : BlockProperties[] = [];
		for (let i=0; i < this.sourceCanvas.height; i += blockSize) {
			for (let j=0; j < this.sourceCanvas.width; j += blockSize) {
				const width = Math.min(blockSize, this.sourceCanvas.width - j);
				const height = Math.min(blockSize, this.sourceCanvas.height - i);
				blocks.push({y: i, x: j, width, height})
			}
		}
		return blocks;
	}

	protected buildCanvas(): HTMLCanvasElement {
		const canvas =  document.createElement('canvas')
		canvas.width = blockSize;
		canvas.height = blockSize;
		return canvas;
	}

	protected buildBasePdf(): {pdf: jsPDF, scaleFactor: number} {
		let pdf = new jsPDF({
			orientation: 'landscape',
			unit: 'px',
			userUnit: 96,
			format: 'a3'
		});

		// Calculate scale factor
		const scaleFactor = Math.min(pdf.internal.pageSize.getWidth() / this.currentWidth, (pdf.internal.pageSize.getHeight() - headerHeight - footerHeight) / this.currentHeight);

		return {pdf, scaleFactor};
	}

	protected writeCanvasToPdf(pdf: jsPDF, scaleFactor: number, canvas: HTMLCanvasElement,  blocks: BlockProperties[]): Observable<jsPDF> {
		const context = canvas.getContext('2d') as CanvasRenderingContext2D;

		const imagePromises = blocks.map(block => {
			return observableFromSync(() => {
				context.clearRect(0, 0, blockSize, blockSize);
				context.drawImage(this.sourceCanvas, block.x, block.y, block.width, block.height, 0, 0, blockSize, blockSize)
				const data = canvas.toDataURL('image/png')
				return {data, block};
			}).pipe(
				mergeMap((picture) => {
					return observableFromSync(() => {
						pdf.addImage(picture.data, 'PNG', picture.block.x * scaleFactor + padding, picture.block.y * scaleFactor + headerHeight, picture.block.width * scaleFactor, picture.block.height * scaleFactor, undefined, 'FAST');
						return pdf;
					});
				})
			)
		});

		return forkJoin(imagePromises).pipe(map(() => pdf));
	}

	buildHeaderIcon(pdf: jsPDF, icon: string): Observable<jsPDF> {
		return observableFromSync(() => {
			// Draw main icon background
			pdf.setFillColor('#EEF9FE');
			pdf.roundedRect(padding, padding, 40, 40, 12, 12, 'F');
			return pdf;
		}).pipe(
			mergeMap(() =>{
				return fromPromise<jsPDF>(new Promise((resolve, reject) => {
					const image = new Image();
					image.src = icon;
					image.onload = () => {
						pdf.addImage(image, 'PNG', padding + 10, padding + 10, 20, 20, undefined, 'FAST');
						resolve(pdf);
					}
					image.onerror = () => {
						reject();
					}
				}))
			}),
			catchError(err => {
				console.error('Error while loading image', err);
				return of(pdf)
			})
		);
	};

	buildHeader(pdf: jsPDF, title: string, subtitle: string, icon: string): Observable<jsPDF> {
		return observableFromSync(() => {
			// Add title
			pdf.setFontSize(16);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.setTextColor('#0C2633');
			pdf.text(title, padding + 50, padding + 17);

			// Add subtitle
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');
			pdf.text(subtitle, padding + 50, padding + 32);

			// Add date
			pdf.setFontSize(14);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.setTextColor('#0C2633');
			const currentDate = new Date().toLocaleDateString('fr-FR', {year: 'numeric', month: '2-digit', day: '2-digit'});
			const textSize = pdf.getTextWidth(currentDate)
			pdf.text(currentDate, pdf.internal.pageSize.width - padding - textSize, padding + 15);

			// Add date comment
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');

			// Bottom line
			pdf.setLineWidth(1);
			pdf.setDrawColor('#F2F2F8');
			pdf.line(padding, 70, pdf.internal.pageSize.width - padding, 70);

			return pdf;
		}).pipe(
			mergeMap(pdf => this.buildHeaderIcon(pdf, icon))
		);
	}

	buildFooter(pdf: jsPDF): Observable<jsPDF> {
		return observableFromSync(() => {
			// Bottom line
			pdf.setLineWidth(1);
			pdf.setDrawColor('#F2F2F8');
			pdf.line(padding, pdf.internal.pageSize.height - (2*padding + 30), pdf.internal.pageSize.width - padding, pdf.internal.pageSize.height - (2*padding + 30));

			const leftText1 = 'Criticality :'
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.setTextColor('#0C2633');
			pdf.text(leftText1, padding, pdf.internal.pageSize.height - (padding + 15));

			pdf.setFont('Helvetica', 'normal', '400');
			// Create 3 rounded rectangles for low, medium and high criticality
			pdf.setFillColor('#F7F8FA');
			pdf.roundedRect(padding + 60, pdf.internal.pageSize.height - (padding + 28), 70, 20, 10, 10, 'F');
			pdf.roundedRect(padding + 140, pdf.internal.pageSize.height - (padding + 28), 70, 20, 10, 10, 'F');
			pdf.roundedRect(padding + 220, pdf.internal.pageSize.height - (padding + 28), 70, 20, 10, 10, 'F');

			// Add text inside rectangles
			pdf.setTextColor('#0C2633');
			pdf.text('Low', padding + 80, pdf.internal.pageSize.height - (padding + 15));
			pdf.text('Medium', padding + 160, pdf.internal.pageSize.height - (padding + 15));
			pdf.text('High', padding + 240, pdf.internal.pageSize.height - (padding + 15));


			// Add page number
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');

			// First text part
			const text1 = 'Generated by'
			pdf.text(text1, pdf.internal.pageSize.width - padding - 180 - pdf.getTextWidth(text1), pdf.internal.pageSize.height - (padding + 15));

			// Second text part (link to Kabeen)
			const text2 = 'Kabeen'
			pdf.setDrawColor('#B5B5C3');
			pdf.setLineWidth(1);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.line(pdf.internal.pageSize.width - padding - 123 - pdf.getTextWidth(text2), pdf.internal.pageSize.height - (padding + 12), pdf.internal.pageSize.width - padding - 123, pdf.internal.pageSize.height - (padding + 12));
			pdf.textWithLink(text2, pdf.internal.pageSize.width - padding - 123 - pdf.getTextWidth(text2), pdf.internal.pageSize.height - (padding + 15), {url: 'https://kabeen.io'});

			// Third text part
			pdf.setFont('Helvetica', 'normal', '400');
			const text3 = 'on ' + new Date().toLocaleDateString('fr-FR', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}) + ' at ' + new Date().toLocaleTimeString('fr-FR', {hour: '2-digit', minute: '2-digit'});
			pdf.text(text3, pdf.internal.pageSize.width - padding - 89 - pdf.getTextWidth(text2), pdf.internal.pageSize.height - (padding + 15));
			return pdf;
		}).pipe(
			mergeMap(pdf => this.buildIconFooterIcon(pdf)),
			mergeMap(pdf => this.buildFooterLowCriticalityIcon(pdf)),
			mergeMap(pdf => this.buildFooterMediumCriticalityIcon(pdf)),
			mergeMap(pdf => this.buildFooterHighCriticalityIcon(pdf)),
		);
	}

	private buildIconFooterIcon(pdf: jsPDF): Observable<jsPDF> {
		return fromPromise<jsPDF>(new Promise((resolve, reject) => {
			const image = new Image();
			image.src = 'assets/new-logo.png';
			image.onload = () => {
				// put image between text1 and text 2
				pdf.addImage(image, 'PNG', pdf.internal.pageSize.width - padding - 175, pdf.internal.pageSize.height - (padding + 26), 15, 15, undefined, 'FAST');
				resolve(pdf);
			}
			image.onerror = () => {
				reject();
			}
		}));
	}

	private buildFooterLowCriticalityIcon(pdf: jsPDF): Observable<jsPDF> {
		return fromPromise<jsPDF>(new Promise((resolve, reject) => {
			const image = new Image();
			image.src = 'assets/icons/diagram-low-criticality.png';
			image.onload = () => {
				// put image between text1 and text 2
				pdf.addImage(image, 'PNG', padding + 69, pdf.internal.pageSize.height - (padding + 24), 5.79, 11, undefined, 'FAST');
				resolve(pdf);
			}
			image.onerror = () => {
				reject();
			}
		}));
	}

	private buildFooterMediumCriticalityIcon(pdf: jsPDF): Observable<jsPDF> {
		return fromPromise<jsPDF>(new Promise((resolve, reject) => {
			const image = new Image();
			image.src = 'assets/icons/diagram-medium-criticality.png';
			image.onload = () => {
				// put image between text1 and text 2
				pdf.addImage(image, 'PNG', padding + 149, pdf.internal.pageSize.height - (padding + 24), 5.79, 11, undefined, 'FAST');
				resolve(pdf);
			}
			image.onerror = () => {
				reject();
			}
		}));
	}

	private buildFooterHighCriticalityIcon(pdf: jsPDF): Observable<jsPDF> {
		return fromPromise<jsPDF>(new Promise((resolve, reject) => {
			const image = new Image();
			image.src = 'assets/icons/diagram-high-criticality.png';
			image.onload = () => {
				// put image between text1 and text 2
				pdf.addImage(image, 'PNG', padding + 229, pdf.internal.pageSize.height - (padding + 24), 5.79, 11, undefined, 'FAST');
				resolve(pdf);
			}
			image.onerror = () => {
				reject();
			}
		}));
	}

	buildPdf(title: string, subtitle: string, icon: string):Observable<jsPDF> {
		const {pdf, scaleFactor} = this.buildBasePdf();

		const canvas = this.buildCanvas();
		const blocks = this.buildCanvasBlock();

		return this.buildHeader(pdf, title, subtitle, icon).pipe(
			mergeMap(pdf => this.writeCanvasToPdf(pdf, scaleFactor, canvas, blocks)),
			mergeMap(pdf => this.buildFooter(pdf)),
		)
	}
}
