<div class="h-100">
    <div class="flex-space-between pad-L-0 pad-0-XL border-grey-bottom bkg-white">
        <div class="flex-align-center gap-S w-100">
            <div class="flex-center bkg-accent-light b-radius-9" style="height: 45px; width: 45px">
                <img class="filter-accent" src="/assets/icons/piggy-bank-01.svg" height="24" alt="">
            </div>
            <div class="flex-column gap-XS">
                <div class="font-L fw-700">{{'page.financeDashboard.potentialOptimization'|translate}}</div>
                <div class="font-M fw-300">{{'page.financeDashboard.potentialOptimizationDesc'|translate}}</div>
            </div>
        </div>
        <div class="flex-align-center gap-S">
            <app-icon-button type="stroked" background [img]="'assets/icons/archive.svg'"
                             [focused]="displayArchived"
                             (click)="displayArchived = !displayArchived">
            </app-icon-button>
            <app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
                             (click)="close()">
            </app-icon-button>
        </div>
    </div>

    <div class="flex-column pad-XL gap-XL bkg-light-grey-2 overflow-y" style="height: calc(100% - 136px);">
        <div class="flex gap-M" *ngIf="displayArchived">
            <div class="flex-align-center border-grey bkg-white b-radius-9" style="padding: 3px; border-width: 0.5px !important;">
                <app-text-button type="default" color="primary"  style="width: 43px; height: 43px;" panelClass="h-100-imp pad-0-imp"
                                 (click)="displayArchived = !displayArchived">
                    <div class="flex-center">
                        <img src="assets/icons/arrow-left.svg" class="filter-grey" height="14" alt=""/>
                    </div>
                </app-text-button>
            </div>
            <div class="flex-align-center bkg-white border-grey b-radius-9 pad-0-M gap-M" style="height: 50px; width: calc(100% - 98px); border-width: 0.5px !important;">
                <span class="font-MM fw-500">{{'page.financeDashboard.archivedOptimization'|translate}}</span>
            </div>
        </div>

        @if (_initializing || _loading) {
            <div class="flex-column bkg-white border-grey b-radius-14" style="border-width: 0.5px !important;">
                <app-dashboard-page-loader></app-dashboard-page-loader>
            </div>
        } @else {
            @for (app of applications; track app.application.id) {
                @if ((app.archived && displayArchived) || (!app.archived && !displayArchived)) {
                    <div class="flex-column bkg-white border-grey b-radius-14 card-hover" style="border-width: 0.5px !important;">
                        <div class="flex-align-center flex-space-between pad-M border-light-grey-bottom">
                            <app-display-application [application]="app.application" [description]="app.application.description" [maxWidth]="'450px'" size="L" bold bigger></app-display-application>
                            @if (app.archived) {
                                <div class="flex-center b-radius-14 bkg-danger-very-light pad-0-L hidden-hover" style="height: 30px;">
                                    <span class="font-MM fw-700 danger">{{'page.financeDashboard.archived'|translate}}</span>
                                </div>
                                <app-text-button color="primary" class="displayed-hover" style="height: 38px;"
                                                 (click)="updateApplicationOptimization(app)">
                                    <div class="flex-align-center gap-S">
                                        <span class="font-M fw-500 grey">{{'page.financeDashboard.addOptimization'|translate}}</span>
                                        <img src="assets/icons/add.svg" class="filter-grey-imp" height="10" alt="">
                                    </div>
                                </app-text-button>
                            } @else {
                                <app-text-button color="primary" class="displayed-hover" style="height: 38px;"
                                                 (click)="updateApplicationOptimization(app)">
                                    <div class="flex-align-center gap-S">
                                        <span class="font-M fw-500 grey">{{'page.financeDashboard.removeOptimization'|translate}}</span>
                                        <img src="assets/icons/close.svg" class="filter-grey-imp" height="10" alt="">
                                    </div>
                                </app-text-button>
                            }
                        </div>
                        <div class="flex-column gap-M pad-M">
                            <div class="flex-space-between">
                                <div class="flex-align-center gap-S c-p"
                                     (click)="expand[app.application.id] = !expand[app.application.id]">
                                    <img src="assets/icons/arrow-right-black.svg" class="filter-grey" [class.rotate-90]="expand[app.application.id]" height="10" alt="">
                                    <span class="font-MM fw-500">{{'page.financeDashboard.licenseUsage'|translate}}</span>
                                </div>
                                <span class="font-MM fw-700">{{app.usage.value ?? 0}}&nbsp;{{('global.users'|translate)|lowercase}}</span>
                            </div>
                            <div class="bkg-light-grey-3 radius-S overflow-hidden ml-m" [style.height.px]="10">
                                <div class="bkg-accent radius-S h-100" style="max-width: 100%"
                                     [style.width.%]="100 * ((app.usage.value ?? 0) / minUser[app.application.id])">
                                </div>
                            </div>
                            <div class="flex-column gap-M pad-left-M overflow-hidden" [style.height]="expand[app.application.id] ? 'auto' : '0px'">
                                <div class="flex">
                                    <span class="font-MM fw-500">{{'page.financeDashboard.associatedContracts'|translate}}</span>
                                </div>
                                @for (contract of app.contracts; track contract.contract.contractId) {
                                    <div class="flex-align-center flex-space-between border-grey b-radius-9 gap-M contract-hover" style="border-width: 0.5px !important; padding: 12px;">
                                        <div class="flex gap-S">
                                            <div class="flex-center b-radius-6 bkg-accent-light" style="height: 38px; width: 38px;">
                                                <img src="assets/icons/document-outline.svg" class="filter-accent" height="24" alt=""/>
                                            </div>
                                            <div class="flex-column flex-space-evenly">
                                                <span class="font-M fw-700">{{('page.appDetails.finance.contracts.' + contract.type)|translate}}</span>
                                                <span class="font-M fw-500 grey">{{contract.contract.startDate|date:'dd/MM/YYYY'}}</span>
                                            </div>
                                        </div>
                                        <!--div class="p-relative">
                                            <div class="p-absolute flex-center edit-hover" (click)="editContract(app.application.id, contract)">
                                                <mat-icon class="grey">edit</mat-icon>
                                            </div>
                                        </div-->
                                        <div class="flex-center b-radius-14 bkg-light-grey pad-0-S" style="height: 30px;">
                                            <span class="font-MM"><span class="fw-700">{{contract.licensedUsers}}</span>&nbsp;<span class="fw-500">{{('page.appDetails.license'|translate)|lowercase}}s</span></span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="flex-space-between pad-M border-light-grey-top">
                            <span class="font-MM fw-500">{{'page.financeDashboard.potentialEarnings'|translate}}</span>
                            <span class="font-MM fw-700">{{app.potentialEarnings.value|cost:'default':'year'}}</span>
                        </div>
                    </div>
                }
            }
        }
    </div>
</div>
