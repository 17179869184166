import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {FocusInputService} from "../../../services/front/focus-input.service";

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent {
	constructor(private focusInputService: FocusInputService) {
	}
	@Input() input: FormControl;
	@Input() type: 'text'|'password'|'email'|'number'|'tel'|'url'|'search' = 'text';
	@Input() leftBorder: boolean = true;
	@Input() label: string;
	@Input() placeholder: string;
	@Input() panelClass: string;
	@Input() disabled: boolean = false;
	@Input() fromSearch: boolean = false;

	@Output() blur = new EventEmitter<void>();
	@Output() enter = new EventEmitter<void>();

	@ViewChild('inputElement') inputElement: ElementRef;

	ngOnInit() {
		if (this.fromSearch) {
			this.focusInputService.getFocusMessage().subscribe(() => {
				if (!this.input.disabled)
					this.inputElement.nativeElement.focus();
			});
		}
	}
}
