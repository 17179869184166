import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {ApplicationTechnologyService} from "../../../../../../services/back/application-technology.service";
import {CatalogTechnologyService} from "../../../../../../services/back/catalog-technology.service";
import {
	ApplicationDetailData,
	ApplicationDetailService
} from "../../../../../../services/front/application-detail.service";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {MatDialog} from "@angular/material/dialog";
import {
	ApplicationAuthenticationTypeForm,
	ApplicationCriticalityForm,
	ApplicationInstance
} from "../../../../../../services/model/new-application.model";
import {Form, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {
	AuthenticationPrimaryFactor, AuthenticationProtocol,
	AuthenticationSecondaryFactor,
	AuthenticationType
} from "../../../../../../services/tenant.service";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {MiniButtonModule} from "../../../../../global/button/mini-button/mini-button.module";
import {NewApplicationService} from "../../../../../../services/back/new-application.service";
import {MatDatepickerModule} from "@angular/material/datepicker";

@Component({
  selector: 'app-application-authentication',
  standalone: true,
	imports: [CommonModule, TranslateModule, DesignSystemModule, MiniButtonModule, MatDatepickerModule, ReactiveFormsModule],
  templateUrl: './application-authentication.component.html',
  styleUrl: './application-authentication.component.scss'
})
export class ApplicationAuthenticationComponent implements OnInit, OnDestroy{
	tenantId: string;
	application: ApplicationInstance;

	_initializing: boolean;
	_loading: boolean;
	_saving: boolean = false;
	_loadingDialog: boolean;
	_editing: boolean = false;

	hovered: boolean = false;

	advancementPercent: number = 0;

	authenticationTypeChoices: AuthenticationTypeChoice[];
	authenticationPrimaryFactorChoices: AuthenticationPrimaryFactorTypeChoice[];
	authenticationSecondaryFactorChoices: AuthenticationSecondaryFactorTypeChoice[];
	authenticationProtocolChoices: AuthenticationProtocolTypeChoice[];

	form: FormGroup;

	subscription: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
				private newApplicationService: NewApplicationService,
				private translate: TranslateService,
				private snackBar: SnackbarService) {
	}

	ngOnInit(): void {
		this.buildForm();
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)))
			.subscribe(() => this.setDefaultData()));
	}

	setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.application = data.instance;
	}

	buildForm() {
		this.form = new FormGroup({
			authenticationType: new FormControl<AuthenticationType|null>(null),
			authenticationPrimaryFactor: new FormControl<AuthenticationPrimaryFactor|null>(null),
			authenticationSecondaryFactor: new FormControl<AuthenticationSecondaryFactor|null>(null),
			authenticationProtocol: new FormControl<AuthenticationProtocol|null>(null)
		});

		this.authenticationTypeChoices = [
			{id: AuthenticationType.LOCAL, name: this.translate.instant('page.application.authentication.type.local')},
			{id: AuthenticationType.SSO, name: this.translate.instant('page.application.authentication.type.sso')},
			{id: AuthenticationType.SSO_AZURE, name: this.translate.instant('page.application.authentication.type.sso_azure')},
			{id: AuthenticationType.SSO_GOOGLE, name: this.translate.instant('page.application.authentication.type.sso_google')},
			{id: AuthenticationType.SSO_KEYCLOAK, name: this.translate.instant('page.application.authentication.type.sso_keycloak')},
			{id: AuthenticationType.SSO_OKTA, name: this.translate.instant('page.application.authentication.type.sso_okta')},
			{id: AuthenticationType.SSO_GITHUB, name: this.translate.instant('page.application.authentication.type.sso_github')},
			{id: AuthenticationType.SSO_SLACK, name: this.translate.instant('page.application.authentication.type.sso_slack')},
			{id: AuthenticationType.OTHER, name: this.translate.instant('page.application.authentication.type.other')}
		];

		this.authenticationPrimaryFactorChoices = [
			{id: AuthenticationPrimaryFactor.LOGIN_PASSWORD, name: this.translate.instant('page.application.authentication.primary_factor.login_password')},
			{id: AuthenticationPrimaryFactor.SMART_CARD, name: this.translate.instant('page.application.authentication.primary_factor.smart_card')},
			{id: AuthenticationPrimaryFactor.CERTIFICATE, name: this.translate.instant('page.application.authentication.primary_factor.certificate')},
			{id: AuthenticationPrimaryFactor.FIDO, name: this.translate.instant('page.application.authentication.primary_factor.fido')},
			{id: AuthenticationPrimaryFactor.PIN, name: this.translate.instant('page.application.authentication.primary_factor.pin')},
			{id: AuthenticationPrimaryFactor.BIOMETRY, name: this.translate.instant('page.application.authentication.primary_factor.biometry')},
		];

		this.authenticationSecondaryFactorChoices = [
			{id: AuthenticationSecondaryFactor.BIOMETRY, name: this.translate.instant('page.application.authentication.secondary_factor.biometry')},
			{id: AuthenticationSecondaryFactor.OTP, name: this.translate.instant('page.application.authentication.secondary_factor.otp')},
			{id: AuthenticationSecondaryFactor.SMART_CARD, name: this.translate.instant('page.application.authentication.secondary_factor.smart_card')},
			{id: AuthenticationSecondaryFactor.CERTIFICATE, name: this.translate.instant('page.application.authentication.secondary_factor.certificate')},
			{id: AuthenticationSecondaryFactor.FIDO, name: this.translate.instant('page.application.authentication.secondary_factor.fido')},
			{id: AuthenticationSecondaryFactor.PIN, name: this.translate.instant('page.application.authentication.secondary_factor.pin')},
			{id: AuthenticationSecondaryFactor.APPLICATION, name: this.translate.instant('page.application.authentication.secondary_factor.application')},
		];

		this.authenticationProtocolChoices = [
			{id: AuthenticationProtocol.KERBEROS, name: this.translate.instant('page.application.authentication.protocol.kerberos')},
			{id: AuthenticationProtocol.OAUTH, name: this.translate.instant('page.application.authentication.protocol.oauth')},
			{id: AuthenticationProtocol.SAMLV2, name: this.translate.instant('page.application.authentication.protocol.samlv2')},
			{id: AuthenticationProtocol.OPENID_CONNECT, name: this.translate.instant('page.application.authentication.protocol.openid_connect')},
			{id: AuthenticationProtocol.OTHER, name: this.translate.instant('page.application.authentication.protocol.other')},

		];
	}

	setDefaultData(): void {
		this.form.patchValue({
			authenticationType: this.authenticationTypeChoices.find((type) => this.application.authenticationType === type.id) || null,
			authenticationPrimaryFactor: this.authenticationPrimaryFactorChoices.find((type) => this.application.authenticationPrimaryFactor === type.id) || null,
			authenticationSecondaryFactor: this.authenticationSecondaryFactorChoices.find((type) => this.application.authenticationSecondaryFactor === type.id) || null,
			authenticationProtocol: this.authenticationProtocolChoices.find((type) => this.application.authenticationProtocol === type.id) || null
		}, { emitEvent: false });
		this.setAdvancementPercent();
	}

	setAdvancementPercent(): void {
		this.advancementPercent = 0;
		if (this.authenticationTypeFormControl.value) {
			this.advancementPercent += 33.33;
		}
		if (this.authenticationPrimaryFactorFormControl.value) {
			this.advancementPercent += 33.33;
		}
		if (this.authenticationProtocolFormControl.value) {
			this.advancementPercent += 33.33;
		}
	}

	updateApplicationAuthentication(): void {
		if (this._editing && this._saving)
			return;
		const form: ApplicationAuthenticationTypeForm = {
			authenticationType: this.form.get('authenticationType')?.value?.id || null,
			authenticationPrimaryFactor: this.form.get('authenticationPrimaryFactor')?.value?.id || null,
			authenticationSecondaryFactor: this.form.get('authenticationSecondaryFactor')?.value?.id || null,
			authenticationProtocol: this.form.get('authenticationProtocol')?.value?.id || null
		};
		this.switchSaving()
			.pipe(
				filter(() => this.checkIfValueChanged(this.application, form) && this.form.valid),
				switchMap(() => this.newApplicationService.updateApplicationAuthenticationForm(this.tenantId, this.application.applicationId, form)),
				filter(success => !!success),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				finalize(() => {
					this.switchSaving();
					this.switchEditing();
				}))
			.subscribe(() => {
				this.application.authenticationType = form.authenticationType;
				this.application.authenticationPrimaryFactor = form.authenticationPrimaryFactor;
				this.application.authenticationSecondaryFactor = form.authenticationSecondaryFactor;
				this.application.authenticationProtocol = form.authenticationProtocol;
				this.setAdvancementPercent();
			});
	}

	checkIfValueChanged(application: ApplicationInstance, form: ApplicationAuthenticationTypeForm): boolean {
		return application.authenticationType !== form.authenticationType
			|| application.authenticationPrimaryFactor !== form.authenticationPrimaryFactor
			|| application.authenticationSecondaryFactor !== form.authenticationSecondaryFactor
			|| application.authenticationProtocol !== form.authenticationProtocol;
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	switchEditing(): void {
		this._editing = !this._editing;
	}

	get authenticationTypeFormControl(): FormControl<AuthenticationTypeChoice|null> {
		return this.form.get('authenticationType') as FormControl<AuthenticationTypeChoice|null>;
	}

	get authenticationPrimaryFactorFormControl(): FormControl<AuthenticationPrimaryFactorTypeChoice|null> {
		return this.form.get('authenticationPrimaryFactor') as FormControl<AuthenticationPrimaryFactorTypeChoice|null>;
	}

	get authenticationSecondaryFactorFormControl(): FormControl<AuthenticationSecondaryFactorTypeChoice|null> {
		return this.form.get('authenticationSecondaryFactor') as FormControl<AuthenticationSecondaryFactorTypeChoice|null>;
	}

	get authenticationProtocolFormControl(): FormControl<AuthenticationProtocolTypeChoice|null> {
		return this.form.get('authenticationProtocol') as FormControl<AuthenticationProtocolTypeChoice|null>;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

interface AuthenticationTypeChoice {
	id: AuthenticationType;
	name: string;
}

interface AuthenticationPrimaryFactorTypeChoice {
	id: AuthenticationPrimaryFactor;
	name: string;
}

interface AuthenticationSecondaryFactorTypeChoice {
	id: AuthenticationSecondaryFactor;
	name: string;
}

interface AuthenticationProtocolTypeChoice {
	id: AuthenticationProtocol;
	name: string;
}
