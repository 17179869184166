<div *ngIf="data">
	<div class="flex-align-center flex-space-between" style="height: 34px;">
		<div class="font-MM fw-700">{{ 'page.appDetails.finance.distributionOfCosts' | translate }}</div>
	</div>

	<div class="flex-column flex-justify-center gap-M pl-xl pr-xl" style="height: 280px;">
		<div class="flex-center w-100" style="gap: 70px;" *ngIf="getTotalCost(data) > 0">
			<!-- Doughnut with data -->
			<div>
				<div class="disp-b" >
					<canvas baseChart style="width: 250px; height: 250px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
				</div>
				<div class="doughnut-center">
					<div class="doughnut-flex">
						<div class="doughnut-total">
							{{getTotalCost(data)|cost}}
						</div>
						<div class="doughnut-label">
							{{ 'page.appDetails.finance.contracts.totalCostForYear' | translate:{"year": year } }}
						</div>
					</div>
				</div>
			</div>
			<div class="flex-column flex-justify-center w-100 h-100 gap-XXL">
				<div class="flex-column" *ngIf="getLicencePercentage(data) > 0">
					<div class="flex-space-between mb-xs">
						<div class="font-M fw-500">{{'page.appDetails.license'|translate}}</div>
						<div class="font-M fw-700">{{ getLicencePercentage(data) }}%</div>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
						<div class="progression-licence" [ngStyle]="{ 'width': getLicencePercentage(data)+'%' }"></div>
					</div>
				</div>
				<div class="flex-column" *ngIf="getServicePercentage(data) > 0">
					<div class="flex-space-between">
						<div class="font-M fw-500 mb-xs">{{ 'page.appDetails.finance.contracts.service' | translate }}</div>
						<div class="font-M fw-700">{{ getServicePercentage(data) }}%</div>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
						<div class="progression-services" [ngStyle]="{ 'width': getServicePercentage(data)+'%' }"></div>
					</div>
				</div>
				<div class="flex-column" *ngIf="getLicencePercentage(data) <= 0 && getTotalCost(data) > 0">
					<div class="flex-space-between mb-xs">
						<div class="font-M fw-500">{{'page.appDetails.license'|translate}}</div>
						<div class="font-M fw-700">0%</div>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
						<div class="progression-licence"></div>
					</div>
				</div>
				<div class="flex-column" *ngIf="getServicePercentage(data) <= 0 && getTotalCost(data) > 0">
					<div class="flex-space-between">
						<div class="font-M fw-500 mb-xs">{{ 'page.appDetails.finance.contracts.service' | translate }}</div>
						<div class="font-M fw-700">0%</div>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
						<div class="progression-services"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-center w-100" style="gap: 70px;" *ngIf="getTotalCost(data) <= 0">
			<!-- Doughnut with data -->
			<div>
				<div class="disp-b" >
					<canvas baseChart style="width: 250px; height: 250px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
				</div>
				<div class="doughnut-center">
					<div class="doughnut-flex">
						<div class="font-XL fw-700 text-center grey">-</div>
						<div class="font-S fw-700 text-center">
							{{ 'page.appDetails.finance.contracts.totalCostForYear' | translate:{"year": year } }}
						</div>
					</div>
				</div>
			</div>
			<div class="flex-column flex-justify-center w-100 h-100 gap-XXL">
				<div class="flex-column">
					<div class="flex-space-between mb-xs">
						<div class="font-M fw-500">{{'page.appDetails.license'|translate}}</div>
						<div class="font-M fw-700">- %</div>
					</div>
					<div class="no-data-bar w-100"></div>
				</div>
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="font-M fw-500 mb-xs">{{ 'page.appDetails.finance.contracts.service' | translate }}</div>
						<div class="font-M fw-700">- %</div>
					</div>
					<div class="no-data-bar w-100"></div>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!data" style="height: 280px;">
	<list-content-loader></list-content-loader>
</div>
