import {ActivatedRoute, Router} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable()
export class ApplicationUrlService {
	constructor(protected activatedRoute: ActivatedRoute, protected router: Router) {
	}

	// Selected application tabs

	public getCurrentApplicationTab(): number {
		let index = this.activatedRoute.snapshot.queryParamMap.get('selectedApplicationTab');
		if (index != null)
			return parseInt(index)
		return 0;
	}
	public getParameter(): boolean {
		let settingsPresent = this.activatedRoute.snapshot.queryParamMap.get('settings');
		return settingsPresent != null && settingsPresent != 'false';
	}

	public getCommentParameter(): boolean {
		let commentPresent = this.activatedRoute.snapshot.queryParamMap.get('comments');
		return commentPresent != null && commentPresent != 'false';
	}

	public changeCurrentApplicationTab(index: number | null) {
		this.router.navigate([this.router.url.split('?')[0]], {queryParams: {'selectedApplicationTab': index}, queryParamsHandling: "merge"})
	}

	// Selected application

	public getCurrentApplication(): string | null {
		return this.activatedRoute.snapshot.queryParamMap.get('applicationId');
	}

	public changeCurrentApplication(id: string | null) {
		if (id !== null)
			this.router.navigate([], {queryParams: {applicationId: id}, queryParamsHandling: "merge"})
		else
			this.router.navigate([], {queryParams: {applicationId: null, selectedApplicationTab: null, settings: null, comments: null}, queryParamsHandling: "merge"})
	}

	navigateInAndOutOfParameter(parameter: boolean) {
		this.navigateInAndOut(parameter, false);
	}

	navigateInAndOutOfComments(parameter: boolean) {
		this.navigateInAndOut(false, parameter);
	}

	private navigateInAndOut(setting: boolean, comment: boolean): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: { settings: setting, comments: comment },
			queryParamsHandling: 'merge'
		});
	}
}
