import {Injectable} from "@angular/core";
import {TenantOverview} from "../tenant.service";
import {BehaviorSubject, distinctUntilChanged, finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {CurrentTenantService} from './current-tenant.service';
import {ApplicationInstance} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {Organization, OrganizationService} from "../organization.service";

@Injectable()
export class OrganizationDetailService {

	private tenantId: string;
	private organizationId: string;

	private dataBS = new BehaviorSubject<OrganizationDetailData|undefined>(undefined);
	private initializingBS = new BehaviorSubject<boolean>(false);
	private refreshingBS = new BehaviorSubject<boolean>(false);
	private refreshingStack: {}[] = [];
	private subscription: Subscription;

	constructor(private organizationService: OrganizationService,
				private currentTenantService: CurrentTenantService) {
	}

	initialize(organizationId: string): void {
		this.organizationId = organizationId;
		this.subscription = new Subscription();
		this.subscription.add(this.switchInitializing()
			.pipe(
				switchMap(() => this.currentTenantService.getCurrentTenant()),
				tap(tenant => this.tenantId = tenant.configuration.id),
				switchMap(tenant => this.organizationService.get(this.tenantId, organizationId)
					.pipe(tap(organization => this.setOrganizationDetailData(tenant, organization)))),
				finalize(() => this.switchInitializing()))
			.subscribe());
	}

	private setOrganizationDetailData(tenant: TenantOverview, organization: Organization): void {
		const data: OrganizationDetailData = {
			tenantId: tenant.configuration.id,
			isEditor: tenant.configuration.role !== 'read_only',
			organization: organization,
			isInactivePlan: tenant.plan.isInactivePlan()
		};
		this.dataBS.next(data);
	}

	getOrganizationDetailDataChanges(): Observable<OrganizationDetailData> {
		return this.dataBS.asObservable().pipe(
			filter(data => data !== undefined),
			switchMap(data => of(data!)));
	}

	refreshOrganization(): void {
		this.subscription.add(this.switchRefreshing(true)
			.pipe(
				switchMap(() => this.organizationService.get(this.tenantId, this.organizationId)),
				tap(organization => this.dataBS.value!.organization = organization),
				tap(() => this.dataBS.next(this.dataBS.value)),
				finalize(() => this.switchRefreshing(false)))
			.subscribe());
	}

	getInitializingChanges(): Observable<boolean> {
		return this.initializingBS.asObservable();
	}

	getRefreshingChanges(): Observable<boolean> {
		return this.refreshingBS.asObservable()
			.pipe(distinctUntilChanged());
	}

	clearOrganizationDetailData(): void {
		this.refreshingStack = [];
		this.subscription.unsubscribe();
		this.dataBS.next(undefined);
	}

	private switchInitializing(): Observable<{}> {
		this.initializingBS.next(!this.initializingBS.value);
		return of({});
	}

	private switchRefreshing(refreshing: boolean): Observable<{}> {
		if (refreshing) {
			this.refreshingStack.push({});
		} else {
			this.refreshingStack.pop();
		}
		this.refreshingBS.next(this.refreshingStack.length > 0);
		return of({});
	}
}

export interface OrganizationDetailData {
	tenantId: string;
	isEditor: boolean;
	organization: Organization;
	isInactivePlan: boolean;
}

/*
 * TODO : use this enum to filter data changes
 *  private dataBS = new BehaviorSubject<{data: ApplicationDetailData, type: AppRefreshType}|undefined>(undefined);
 *  this.applicationDetailService.getApplicationDetailDataChanges()
 *      .pipe(
 * 			filter(data => [AppRefreshType.INIT, AppRefreshType.INSTANCE].includes(data.type)),
 * 			tap(data => this.setApplicationDetailData(data))
 *      .subscribe(data => this.initialize())
 */
export enum AppRefreshType {
	INIT = 'init',
	INSTANCE = 'instance'
}
