import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {
	ContractApplicationInfo,
	ContractInfo,
	ContractType, DurationUnit, ServiceType
} from "../../../../../../services/model/application-contract.model";
import {OrganizationService, OrganizationTree} from "../../../../../../services/organization.service";
import {FileLink, FileType} from "../../../../../../services/model/tenant-file.model";
import {Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter, map} from "rxjs/operators";
import {ApplicationGeneric} from "../../../../../../services/model/new-application.model";
import {DeleteDialogComponent} from "../../../../../design-system/delete-dialog/delete-dialog.component";
import {ApplicationDetailService} from "../../../../../../services/front/application-detail.service";
import {ApplicationContractService} from "../../../../../../services/back/application-contract.service";
import {TenantFileService} from "../../../../../../services/back/tenant-file.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {
	ApplicationContractFormDialogComponent,
	ContractFormDialogData
} from "../../../../applications/application-detail/finance-tab/application-contract-form-dialog/application-contract-form-dialog.component";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../../../services/front/organization-detail.service";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {CostPipe} from "../../../../../../pipes/number/cost.pipe";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {MiniButtonModule} from "../../../../../global/button/mini-button/mini-button.module";
import {DropdownTriggerDirective} from "../../../../../design-system/dropdown/dropdown-trigger.directive";

@Component({
  selector: 'app-organization-contract-preview',
  standalone: true,
	imports: [CommonModule, ContentLoaderModule, CostPipe, DesignSystemModule, MiniButtonModule, NgOptimizedImage, TranslateModule, DropdownTriggerDirective],
  templateUrl: './organization-contract-preview.component.html',
  styleUrl: './organization-contract-preview.component.scss'
})
export class OrganizationContractPreviewComponent implements OnChanges {
	@Input() data: ContractApplicationInfo[] | undefined;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;
	_deleting: string|undefined;

	contracts: ContractTableData[] = [];

	organizationTree?: OrganizationTree;
	fileType: typeof FileType = FileType;

	subscriptions: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
				private applicationContractService: ApplicationContractService,
				private organizationService: OrganizationService,
				private tenantFileService: TenantFileService,
				private translate: TranslateService,
				private dialog: MatDialog,
				private organizationDetailService: OrganizationDetailService) {
	}

	ngOnInit() {
		this.subscriptions.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscriptions.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap((data) => this.tenantId = data.tenantId))
			.subscribe());
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.currentValue) {
			this.setAllContractInfo(this.data!);
		}
	}

	private setAllContractInfo(contractInfos: ContractApplicationInfo[]): void {
		this.contracts = contractInfos.map((info): ContractTableData => ({
			application: info.application,
			contractId: info.contract.contractId,
			type: info.type,
			status: this.getContractStatus(info),
			expiryDate: info.contract.endDate,
			totalAmount: info.amount,
			recurrencePeriod: info.isRecurringPayment ? info.paymentDurationUnit : null,
			files: info.contract.documents,
			updatedAt: info.contract.updatedAt
		}))
			.sort((a, b) => {
				if (a.status !== b.status) {
					return a.status === 'active' ? -1 : a.status === 'waiting' ? b.status === 'expired' ? -1 : 1 : 1;
				}
				return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
			});
	}

	private getContractStatus(info: ContractInfo): 'active'|'expired'|'waiting' {
		const today: number = new Date().getTime();
		const startDate: number = new Date(info.contract.startDate).getTime();
		const expiryDate: number|null = !info.contract.endDate ? null : new Date(info.contract.endDate).getTime();
		if (startDate > today) return 'waiting';
		if (expiryDate && expiryDate < today) return 'expired';
		return 'active';
	}

	deleteContract(contract: ContractTableData): void {
		this.dialog.open(DeleteDialogComponent, {
			width: '475px',
			position: {
				right: '162.5px'
			},
			data: {
				icon: '/assets/illustrations/delete/contract.svg',
				title: this.translate.instant('page.appDetails.finance.contracts.delete.title'),
				subtitle: this.translate.instant('page.appDetails.finance.contracts.delete.subtitle'),
				warningMessage: this.translate.instant('page.appDetails.finance.contracts.delete.warningMessage'),
				deleteButton: this.translate.instant('page.appDetails.finance.contracts.delete.deleteButton')
			}})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.switchDeleting(contract.contractId)),
				switchMap(() => this.applicationContractService.deleteContract(this.tenantId, contract.application.id, contract.contractId)),
				switchMap(() => this.switchDeleting(contract.contractId)))
			.subscribe(() => this.organizationDetailService.refreshOrganization());
	}

	openContractDialog(contract: ContractTableData): void {
		of(this.organizationTree)
			.pipe(
				switchMap(organizationTree => !organizationTree
					? this.organizationService.getOrganizationTreeByTenantId(this.tenantId)
					: of(organizationTree)),
				tap(tree => this.organizationTree = tree),
				map(tree => this.buildContractFormDialogData(tree, contract)),
				switchMap(data => this.dialog.open(ApplicationContractFormDialogComponent, {position: { right:'162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(result => result))
			.subscribe(() => this.organizationDetailService.refreshOrganization());
	}

	private buildContractFormDialogData(tree: OrganizationTree, contract: ContractTableData): ContractFormDialogData {
		return {
			applicationId: contract.application.id,
			organization: tree,
			info: this.data!.find(info => info.contract.contractId === contract.contractId),
		}
	}

	sizePrettily(size: number | undefined): string {
		if (!size) return '';
		if (size < 1024) {
			return `${size} B`;
		} else if (size < 1024 * 1024) {
			return `${Math.round(size / 1024)} KB`;
		} else if (size < 1024 * 1024 * 1024) {
			return `${Math.round(size / (1024 * 1024))} MB`;
		} else {
			return `${Math.round(size / (1024 * 1024 * 1024))} GB`;
		}
	}

	downloadFile(upload: FileLink) {
		this.tenantFileService.downloadTenantFile(this.tenantId, upload.fileLinkId)
			.subscribe((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = upload.name;
				link.click();
			});
	}

	openLink(link: string) {
		window.open(link, '_blank');
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchDeleting(contractId: string): Observable<{}> {
		this._deleting = !this._deleting ? contractId : undefined;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}

interface ContractTableData {
	application: ApplicationGeneric;
	contractId: string;
	type: ContractType|ServiceType;
	status: 'active'|'expired'|'waiting';
	expiryDate: string|null;
	totalAmount: number|null;
	recurrencePeriod: DurationUnit|null;
	files: FileLink[];
	updatedAt: string;
}
