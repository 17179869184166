<div class="flex-column gap-S" style="padding-bottom: 5px" [class.opened]="openForm">
    <div class="flex-align-center gap-S pad-S c-p insert-button"
         (click)="setFormOpened()">
		<img src="assets/icons/add.svg" width="11" class="filter-accent" alt="">
        <span class="accent font-M">{{titlePath|translate}}</span>
    </div>
    <div *ngIf="openForm" class="flex-align-center p-relative">
		<div class="input-with-delete flex-align-center w-100 pad-S">
			<input #search [placeholder]="'global.writeHere' | translate" (keydown.enter)="onCreate()" class="input-no-style h-100" type="text" [formControl]="createForm">
			<div class="flex-align-center flex-justify-end p-absolute" style="width: 32px; right: 10px">
				<app-icon-button class="p-absolute" *ngIf="!saving" shape="square" [img]="'assets/icons/add.svg'" [size]="'xs'" type="flat"
								 [disabled]="!formValid" [focused]="formValid" [panelClass]="'img-size-50'"
								 (click)="onCreate()">
				</app-icon-button>
			</div>
		</div>
    </div>
	<div class="hr-document"></div>
</div>
