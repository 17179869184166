import {BezierCurve, DrawableObject, HoverableObject, Point} from "../diagram";
import {Layers} from "../layer";

type CurvedLineState = {};

export class CurvedLine implements DrawableObject<CurvedLineState>, HoverableObject {
	public type = 'curved';
	protected isActiveHover: boolean = false;
	protected animationStartedAt: number = new Date().getTime() + Math.floor(Math.random() * 5000);
	protected lastAnimationUpdate= 0;
	protected animationPosition: number = 0;
	protected dataBubbleCoordinates: Point;
	protected dataBubbleWidth?: number;
	protected dataBubbleHeight?: number;

	constructor(
		protected ctx: any,
		protected from: Point,
		protected to: Point,
		protected sourceId: string,
		protected targetId: string,
		protected data: string[] = []) {}

	protected getLeadingCoefficient(): number {
		if (this.to.x === this.from.x) {
			return 0;
		}
		return (this.to.y - this.from.y) / (this.to.x - this.from.x);
	}

	getSourceId(): string {
		return this.sourceId;
	}

	getTargetId(): string {
		return this.targetId;
	}

	private active: boolean = true;

	isActive(): boolean {
		return this.active;
	}

	activate() : void {
		this.active = true;
		this.isActiveHover = true;
	}

	deactivate() : void {
		this.active = false;
		this.isActiveHover = false;
	}

	protected bezierCurveParameters(): { p1: Point, p2: Point } {
		const dx = this.to.x - this.from.x;
		const dy = this.to.y - this.from.y;
		const isHorizontal = Math.abs(dy) < 1;

		if (isHorizontal) {
			const offsetX = Math.min(Math.abs(dx) * 0.25, 80);
			const sign = dx >= 0 ? 1 : -1;
			return {
				p1: { x: this.from.x + sign * offsetX, y: this.from.y },
				p2: { x: this.to.x - sign * offsetX, y: this.to.y }
			};
		} else {
			const leadingCoefficient = this.getLeadingCoefficient();
			const a = (this.to.x - this.from.x) < 0 ? -160 : 160;

			if (leadingCoefficient !== 0) {
				return { p1: { x: this.from.x + a, y: this.from.y }, p2: { x: this.to.x - a, y: this.to.y } };
			} else {
				return { p1: { x: this.from.x + 100, y: this.from.y }, p2: { x: this.to.x + 100, y: this.to.y  } };
			}
		}
	}

	noDataImage?: HTMLImageElement;

	update(state: CurvedLineState = {}) {
		// Update bubble animation
		if (this.lastAnimationUpdate + 10 < new Date().getTime()) {
			this.animationPosition = this.animationPosition + 1;
			this.lastAnimationUpdate = new Date().getTime();
		}

		if (!this.noDataImage) {
			this.noDataImage = new Image();
			this.noDataImage.src = 'assets/icons/flow-no-data.svg';
		}
	}

	draw(layers: Layers): void {
		const {p1, p2} = this.bezierCurveParameters();
		layers.getLayer(1).addObject(this.drawDeactivated(p1, p2));
		layers.getLayer(3).addObject(this.drawActivated(p1, p2));
		layers.getLayer(6).addObject(this.drawDataBubble(p1, p2));
	}

	protected drawDeactivated(p1: Point, p2: Point) : ()=>void {
		if (!this.isActiveHover) {
			return () => {
				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(0, 0, 0, 0.05)';
				this.ctx.lineWidth = 4;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();
				this.ctx.lineWidth = 1;
			}
		} else {
			return () => {};
		}
	}

	protected drawActivated(p1: Point, p2: Point): ()=>void {
		if (this.isActiveHover) {
			return () => {
				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(54, 176, 235, 0.3)';
				this.ctx.lineWidth = 8;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();

				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(54, 176, 235, 1)';
				this.ctx.lineWidth = 1;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();

				if (this.animationStartedAt < new Date().getTime()) {
					const t = ((this.animationPosition) % 1000) / 1000;
					const bubble = this.getBezierXY(t, this.from.x, this.from.y, p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);

					this.ctx.beginPath();
					this.ctx.arc(bubble.x, bubble.y, 4, 0, 2 * Math.PI);
					this.ctx.fillStyle = 'rgba(54, 176, 235, 1)';
					this.ctx.fill();
				}
			}
		} else {
			return () => {};
		}
	}

	protected drawDataBubble(p1: Point, p2: Point) : ()=>void {
		if (this.isActiveHover) {
			return () => {
				let center = this.getBezierXY(0.5, this.from.x, this.from.y, p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				if (this.data.length > 0) {
					const dataBubbleContent = this.data.length > 1 ? this.data[0]  + ' (+' + (this.data.length - 1) + ')' : this.data[0];
					this.drawDataTooltip(dataBubbleContent, center, this.isActiveHover ? "rgba(54, 176, 235, 1)" : "rgba(0, 0, 0, 0.1)");
				} else {
					this.drawNoDataTooltip(center, this.isActiveHover ? "rgba(54, 176, 235, 1)" : "rgba(0, 0, 0, 0.1)");
				}
			}
		} else {
			return () => {};
		}
	}

	protected drawDataTooltip(content: string, coordinate: Point, color: string): void {
		let fontSize = Math.max(Math.min(24, 24 * 1/this.ctx.getTransform().d), 14);
		this.ctx.font = "bold "+ (fontSize > 14 ? fontSize : 14) + "px proxima-nova";

		const metrics = this.ctx.measureText(content);

		let fontHeight;
		if (metrics.fontBoundingBoxDescent && metrics.fontBoundingBoxAscent) {
			fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
		} else {
			fontHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
		}

		// Tooltip background
		this.ctx.beginPath()
		this.ctx.fillStyle = color;
		const width = metrics.width + 40;
		const height = 30;
		this.ctx.roundRect(coordinate.x - width / 2, coordinate.y - height / 2, width, height, 15);
		this.ctx.fill()

		this.ctx.beginPath();
		this.ctx.textAlign = "center";
		this.ctx.fillStyle = "white";
		this.ctx.fillText(content, coordinate.x, coordinate.y + fontHeight / 5);

		// Reset text
		this.ctx.textAlign = "left";

		// calculate coordinates for the data bubble
		this.dataBubbleCoordinates = {
			x: coordinate.x - width / 2,
			y: coordinate.y - height / 2
		};
		this.dataBubbleWidth = width;
		this.dataBubbleHeight = height;
	}

	protected drawNoDataTooltip(coordinate: Point, color: string): void {
		// Tooltip background
		this.ctx.beginPath()
		this.ctx.fillStyle = color;
		const width = 32;
		const height = 32;
		this.ctx.roundRect(coordinate.x - width / 2, coordinate.y - height / 2, width, height, 30);
		this.ctx.fill()

		this.ctx.beginPath();
		if (this.noDataImage) {
			this.ctx.drawImage(this.noDataImage, coordinate.x - 10, coordinate.y - 10, 20, 20);
		}

		this.dataBubbleCoordinates = {
			x: coordinate.x - width / 2,
			y: coordinate.y - height / 2
		};
		this.dataBubbleWidth = width;
		this.dataBubbleHeight = height;
	}

	protected getBezierXY(t: number, sx: number, sy: number, cp1x: number, cp1y: number, cp2x: number, cp2y: number, ex: number, ey: number): Point {
		return {
			x: Math.pow(1-t,3) * sx + 3 * t * Math.pow(1 - t, 2) * cp1x
				+ 3 * t * t * (1 - t) * cp2x + t * t * t * ex,
			y: Math.pow(1-t,3) * sy + 3 * t * Math.pow(1 - t, 2) * cp1y
				+ 3 * t * t * (1 - t) * cp2y + t * t * t * ey
		};
	}

	isAtCoordinates(mouseCoordinates: Point): boolean {
		if (!this.dataBubbleCoordinates || !this.dataBubbleWidth || !this.dataBubbleHeight || !this.isActiveHover)
			return false;
		return  mouseCoordinates.x >= this.dataBubbleCoordinates.x && mouseCoordinates.x <= this.dataBubbleCoordinates.x + this.dataBubbleWidth &&
			mouseCoordinates.y >= this.dataBubbleCoordinates.y && mouseCoordinates.y <= this.dataBubbleCoordinates.y + this.dataBubbleHeight;
	}

	getBottomMiddleBubblePosition(): Point {
		if (!this.dataBubbleCoordinates || !this.dataBubbleWidth || !this.dataBubbleHeight)
			return { x: 0, y: 0 };
		return { x: this.dataBubbleCoordinates.x + this.dataBubbleWidth / 2, y: this.dataBubbleCoordinates.y + this.dataBubbleHeight };
	}
}
