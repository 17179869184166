import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartData, ChartOptions} from 'chart.js';
import {ColorEnum} from 'src/_variables';
import {BaseChartDirective} from 'ng2-charts';
import {Cost} from 'src/app/services/model/application-contract.model';
import {finalize, forkJoin, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {QueryRangeType, TenantFinanceService} from 'src/app/services/back/tenant-finance.service';
import {OrganizationType} from 'src/app/services/model/autodiscover.model';
import {map} from 'rxjs/operators';

@Component({
	selector: 'app-most-expensive-doughnut',
	templateUrl: './most-expensive-doughnut.component.html',
	styleUrls: ['./most-expensive-doughnut.component.scss']
})
export class MostExpensiveDoughnutComponent implements OnInit, OnDestroy {

	@Input() filter: Subject<Organization|null|undefined>;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	totalCost?: number|null;
	data: MostExpensiveDoughnutData[] = [];
	doughnutChartData: ChartData<'doughnut'>;
	chartOptions: ChartOptions<'doughnut'>;
	year: number = new Date().getFullYear();

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private tenantFinanceService: TenantFinanceService) {
	}

	ngOnInit() {
		this.setDefaultDoughnutData();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	initialize(org?: Organization|null): void {
		this.clearDoughnutColor();
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					(!org || org.type === OrganizationType.AFFILIATE
						? this.tenantFinanceService.getAllContractOrganizationCost(this.tenantId, org?.organizationId, QueryRangeType.CURRENT_YEAR)
							.pipe(map(data => data.map((c): MostExpensiveDoughnutData => ({ id: c.organization.organizationId, name: c.organization.name, total: c.cost.value ?? 0 }))))
						: this.tenantFinanceService.getAllContractApplicationCategoryCost(this.tenantId, org?.organizationId, QueryRangeType.CURRENT_YEAR)
							.pipe(map(data => data.map((c): MostExpensiveDoughnutData => ({ id: c.category.categoryId, name: c.category.name, total: c.cost.value ?? 0 }))))),
					this.tenantFinanceService.getContractTotalCostByTenantId(this.tenantId, org?.organizationId, QueryRangeType.CURRENT_YEAR)
				])),
				tap(([data, cost]) => this.buildDoughnutChartData(data, cost)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private buildDoughnutChartData(data: MostExpensiveDoughnutData[], totalCost: Cost): void {
		this.data = data;
		this.totalCost = totalCost.value ?? 0;
		this.data.sort((a, b) => b.total - a.total);

		if (this.data.length > 3) {
			this.data = this.data.slice(0, 3);
		}
		const othersTotal: number = this.totalCost - this.data.map((item) => item.total).reduce((a, b) => a + b, 0);
		if (othersTotal > 0) this.data.push({id: null, name: "Others", total: othersTotal});

		let colors: string[] = [];
		this.data.forEach((item, index) => {
			item.percentage = this.totalCost === 0 || Math.round(item.total / this.totalCost! * 100) <= 1 ? 1 : Math.round(item.total / this.totalCost! * 100)
			if (item.total > 0) {
				if (index == 0) colors.push(ColorEnum.doughnut_colors_0);
				if (index == 1) colors.push(ColorEnum.doughnut_colors_1);
				if (index == 2) colors.push(ColorEnum.doughnut_colors_2);
				if (index == 3) colors.push(ColorEnum.doughnut_colors_3);
				// interlacing values with transparent values to give the doughnut spacing between each arcs
				if (index < this.data.length) {
					colors.push(ColorEnum.transparent);
				}
			}
		});

		const interleave = (arr: MostExpensiveDoughnutData[], x: MostExpensiveDoughnutData) => arr.flatMap(e => [e, x]).slice(0, -1)
		// subjective value for spacing between arcs
		const spacing: number = this.totalCost / 90;
		this.data = interleave(this.data, {id: undefined, name: "", total: spacing, percentage: 0});
		if (this.data.length > 1) {
			this.data.push({id: undefined, name: "", total: spacing, percentage: 0});
		}

		this.doughnutChartData.datasets[0].data = this.data.map((item) => item.total);
		this.doughnutChartData.datasets[0].backgroundColor = colors;
		this.doughnutChartData.datasets[0].borderColor = colors;

		if (this.totalCost === 0) {
			this.doughnutChartData.datasets[0].data.push(1);
			this.addDoughnutColor(ColorEnum.medium_grey);
		}
	}

	get listFiltered(): MostExpensiveDoughnutData[] {
		return this.data.filter((item) => !this.isUndefined(item.id));
	}

	get listLength(): number {
		return this.data.filter((item) => !this.isUndefined(item.id)).length;
	}

	private setDefaultDoughnutData(): void {
		this.doughnutChartData = {
			labels: [],
			datasets: [{
				data: [ 0, 0 ],
				backgroundColor: [ColorEnum.accent, ColorEnum.chart_yellow],
				borderColor: [ColorEnum.accent, ColorEnum.chart_yellow],
				borderRadius: _ => {
					return this.data.length > 1 ? 5 : 0;
				}
			}]
		};
		this.chartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			events: [],
			cutout: '65%',
		};
	}

	private addDoughnutColor(color:string) {
		// @ts-ignore
		this.doughnutChartData.datasets[0]?.backgroundColor?.push(color);
		// @ts-ignore
		this.doughnutChartData.datasets[0]?.borderColor?.push(color);
	}

	private clearDoughnutColor() {
		// @ts-ignore
		this.doughnutChartData.datasets[0].backgroundColor = [];
		// @ts-ignore
		this.doughnutChartData.datasets[0].borderColor = [];
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}

	public isUndefined(val: any): boolean { return typeof val === 'undefined'; }
}

interface MostExpensiveDoughnutData {
	id: string|null|undefined;
	name: string;
	total: number;
	percentage?: number;
}
