<div class="flex-column gap-widget">
	<div class="operationWrapper pb-m">
		<div *ngIf="_initializing || _loading" class="pl-m pr-m pt-m pb-m">
			<app-linechart-loader></app-linechart-loader>
		</div>
		<div *ngIf="!_initializing && !_loading && (health === null || health === undefined)">
			<div class="disp-f a-i-c j-c-sb pl-m pr-m pt-m pb-m">
				<span class="font-MM fw-700">{{'global.health'|translate}}</span>
			</div>
			<div class="flex-column flex-center grey gap-S" style="height: 100px;">
				<div class="flex-center grey">
					<img src="assets/icons/info-circle.svg">
				</div>
				<div class="text-center w-80">
					{{'page.appDetails.usage.tooltipHistogramDeactivated1'|translate}}
					<a class="accent text-underline"
					   (click)="navigateToUsageParameter()">{{'page.appDetails.usage.settings'|translate}}</a>
					{{'page.appDetails.usage.tooltipHistogramDeactivated2'|translate}}
				</div>
			</div>
		</div>

		<app-health-graph *ngIf="!_initializing && !_loading && health !== null && health !== undefined" [data]="graph" [height]="180" [health]="health" [fromDrawer]="true"></app-health-graph>
	</div>

	<div class="operationWrapper pl-m pr-m pt-m pb-m" >
		<app-linechart-loader *ngIf="_initializing || _loading"></app-linechart-loader>
		<app-performance-chart *ngIf="!_initializing && !_loading" style="height: 180px"
							   [data]="performanceGraph"
		></app-performance-chart>
	</div>

	<div class="bg-rounded pad-M">
		<app-dashboard-page-loader *ngIf="_initializing || _loading"></app-dashboard-page-loader>
		<app-application-contact-preview *ngIf="!_initializing && !_loading"></app-application-contact-preview>
	</div>

	<div class="bg-rounded" style="min-height: 320px;">
		<app-dashboard-page-loader *ngIf="_initializing || _loading"></app-dashboard-page-loader>
		<app-announcements-widget *ngIf="!_initializing && !_loading" [data]="announcements" [tenantId]="tenantId" [fromApp]="application" [limit]="3"></app-announcements-widget>
	</div>
</div>
