import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UsageHistory, UsageService} from "src/app/services/usage.service";
import {BaseChartDirective} from "ng2-charts";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {ColorEnum} from "src/_variables";
import {TranslateService} from "@ngx-translate/core";
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-usage-graph',
  templateUrl: './usage-graph.component.html',
  styleUrls: ['./usage-graph.component.scss']
})
export class UsageGraphComponent implements OnInit, OnDestroy {

	@Input() height: number = 320;
	@Input() widgetTitle: string = '';
	@Input() displayRange: boolean = true;
	@Input() filter?: Subject<Organization|null|undefined>;
	lastFilter?: Organization|null;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	chartData: UsageLineChartData[] = [];
	onRangeChange: Subject<void> = new Subject<void>();

	rangeControl: FormControl<QueryRangeType> = new FormControl<QueryRangeType>(QueryRangeType.PAST_1_MONTH, { nonNullable: true });

	tooltip = {
		display: "none",
		top: "0px",
		left: "0px",
		hour: 0,
		day: 0,
		month: 0,
		value: 0
	}
	barChartType: ChartType = 'line';
	lineChartData: ChartData<'line', {date: Date, amount: number} []> = {
		labels: [],
		datasets: []
	};
	barOptions: ChartOptions = {
		interaction: {
			intersect: false,
			mode: 'index',
		},
		animation: false,
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false,
				external: context => {
					const {chart, tooltip} = context;

					if (tooltip.opacity === 0) {
						if (this.tooltip.display !== "none") {
							this.tooltip.display = "none";
							this.cdr.detectChanges();
						}
						return
					}
					const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

					const canvasWidth = chart.canvas.getBoundingClientRect().width;

					const canvasHeight = chart.canvas.getBoundingClientRect().height;

					let xPosition = positionX + tooltip.caretX;
					if (xPosition + 200 > canvasWidth) {
						xPosition = positionX + tooltip.caretX + 200 - canvasWidth
						xPosition = positionX + tooltip.caretX - xPosition
					}

					let yPosition = positionY + tooltip.caretY;
					if (yPosition > canvasHeight) {
						yPosition = positionY + tooltip.caretY - canvasHeight
						yPosition = positionY + tooltip.caretY - yPosition
					}

					// @ts-ignore
					const date: Date  = tooltip.dataPoints[0].raw.date

					const month = date.getMonth() + 1

					const day = date.getDate()

					const hour = date.getHours()

					// @ts-ignore
					const value = tooltip.dataPoints[0].raw.amount;

					this.tooltip = {
						display: "block",
						left: xPosition + 'px',
						top: yPosition + 'px',
						month: month,
						day: day,
						hour: hour,
						value: value,
					}
					this.cdr.detectChanges();
				},
			},

		},
		scales: {
			y: {
				suggestedMin: 0,
				suggestedMax: 2,
				display: true,
				grid: {
					lineWidth: 1,
					borderDash: [5,5],
					drawTicks: false,
					drawBorder: false,
					color: ColorEnum.medium_grey,
				},
				ticks: {
					autoSkip: true,
					maxTicksLimit: 5,
					crossAlign:"center",
					callback: (value) => {if (typeof value == "number" && value % 1 === 0) {return value;} else {return undefined}},
					padding: 6,
					font: {
						family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
					},
					color: "#1D3B58"
				}
			},
			x: {
				ticks: {
					font: {
						family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
						size: 9
					},
					color: "#B5B5C3"
				},
				grid: {
					lineWidth: 0,
					drawBorder: false
				}
			},

		}
	};

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private translateService: TranslateService,
							private usageService: UsageService,
							private cdr: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		if (this.filter) {
			this.subscription.add(this.filter
				.subscribe(filter => this.initialize(filter)));
		}
		this.subscription.add(this.onRangeChange
			.subscribe(() => this.initialize(this.lastFilter)));
		this.subscription.add(this.rangeControl.valueChanges
			.subscribe(() => this.onRangeChange.next()));
	}

	initialize(org?: Organization|null): void {
		this.lastFilter = org;
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.usageService.getAllUsageHistoryByTenantId(this.tenantId, org?.organizationId, this.rangeControl.value)),
				tap(data => this.setUsageHistory(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setUsageHistory(data: UsageHistory[]): void {
		this.chartData = data
			.map(history => ({day: new Date(history.date), amount: history.usage.value ?? 0}));
		this.onUpdateStatisticsData();
	}

	private onUpdateStatisticsData() {
		if (this.lineChartData.datasets && this.lineChartData.datasets[0]) {
			this.lineChartData.datasets = [];
			this.lineChartData.labels = [];
		}
		if (this.chartData.length > 0) {
			if (this.rangeControl.value === QueryRangeType.PAST_1_YEAR) {
				this.lineChartData.labels = this.chartData
					.map(day => {
						const month = day.day.getMonth() + 1
						return this.translateService.instant('global.month.' + month)
					});
			} else if (this.rangeControl.value === QueryRangeType.PAST_1_MONTH) {
				this.lineChartData.labels = this.chartData
					.map(day => day.day.getDate());
			} else {
				this.lineChartData.labels = this.chartData
					.map(day => day.day.getHours());
			}
			this.lineChartData.datasets.push({
					data: this.chartData
						.map(data => ({ date: data.day, amount: data.amount })),
					backgroundColor: (context) => {
						const chart = context.chart;
						const {ctx, chartArea} = chart;

						if (!chartArea) {
							return;
						}
						return this.getGradient(ctx, chartArea);
					},
					parsing: {
						xAxisKey: 'date',
						yAxisKey: 'amount'
					},
					tension: 0.1,
					borderColor: "#3DC0FF",
					hoverBackgroundColor: "#3DC0FF",
					fill: true,
					pointStyle: "circle",
					pointRadius: 0.01,
					pointBorderColor: "#FFFFFF",
					pointBorderWidth: 1.5,
					pointBackgroundColor: "#FFFFFF",
					pointHoverBackgroundColor: "#3DC0FF",
					pointHoverRadius: 6,
					pointHoverBorderColor: "#FFFFFF",
					pointHoverBorderWidth: 2,
					borderWidth: 1.5,
					cubicInterpolationMode: "monotone"
			});
		}
		if (this.chart) {
			this.chart.update();
		}
	}

	private getGradient(ctx: any, chartArea: any) {
		let width, height, gradient;
		const chartWidth = chartArea.right - chartArea.left;
		const chartHeight = chartArea.bottom - chartArea.top;
		if (gradient === null || width !== chartWidth || height !== chartHeight) {
			gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
			gradient.addColorStop(0, 'rgba(61, 192, 255, 0)');
			gradient.addColorStop(0.5, 'rgba(61, 192, 255, 0.2)');
			gradient.addColorStop(1, 'rgba(61, 192, 255, 0.6)');
		}
		return gradient;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}

	protected readonly QueryRangeType = QueryRangeType;
}

export interface UsageLineChartData {
	day: Date;
	amount: number;
}
