import {NgModule} from "@angular/core";
import {UsageTabComponent} from "src/app/modules/home/applications/application-detail/usage-tab/usage-tab.component";
import {CommonModule} from "@angular/common";
import {MiniButtonModule} from "../../../../global/button/mini-button/mini-button.module";
import {TeamCardModule} from "./team-card/team-card.module";
import {TranslateModule} from "@ngx-translate/core";
import {DeckContentLoaderModule} from "../../../../global/title-content-loader/deck-content-loader.module";
import {TableContentLoaderModule} from "../../../../global/table-content-loader/table-content-loader.module";
import {AddTeamDialogModule} from "./add-team-dialog/add-team-dialog.module";
import {MatDialogModule} from "@angular/material/dialog";
import {UsageHistogramComponent} from './usage-histogram/usage-histogram.component';
import {NgChartsModule} from "ng2-charts";
import {ApplicationMiniWidgetModule} from "../../../../global/application-mini-widget/application-mini-widget.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
	declarations: [
    UsageTabComponent,
    UsageHistogramComponent
	],
  imports: [
    CommonModule,
    MiniButtonModule,
    TeamCardModule,
    TranslateModule,
    DeckContentLoaderModule,
    TableContentLoaderModule,
    AddTeamDialogModule,
    MatDialogModule,
    NgChartsModule,
    ApplicationMiniWidgetModule,
    DesignSystemModule,
    ReactiveFormsModule
  ],
  exports: [
    UsageTabComponent,
    UsageHistogramComponent
  ]
})
export class UsageTabModule {}
