import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {SecurityUtils} from "./security.utils";
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {Usage} from 'src/app/services/model/application-contract.model';
import {OrganizationIcon} from "./organization.service";

@Injectable()
export class UsageService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/usage`;

	constructor(protected httpClient: HttpClient) {
	}

	getApplicationUsage(tenantId: string, applicationId: string): Observable<ApplicationUsageDetail> {
		return this.httpClient.get<ApplicationUsageDetail>(`${this.serviceUrl(tenantId)}/applications/${applicationId}/detail`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllUsageHistoryByTenantId(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<UsageHistory[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (range) params = params.append('range', range);
		return this.httpClient.get<UsageHistory[]>(`${this.serviceUrl(tenantId)}/histories`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getUsageByTenantId(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<Usage> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (range) params = params.append('range', range);
		return this.httpClient.get<Usage>(`${this.serviceUrl(tenantId)}`, {
				headers: SecurityUtils.getAuthHttpHeaders(),
				params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getSatisfactionRatingByTenantId(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<Rating> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (range) params = params.append('range', range);
		return this.httpClient.get<Rating>(`${this.serviceUrl(tenantId)}/satisfaction`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllMostUsedApplicationByTenantId(tenantId: string, organizationId?: string, limit?: number, range?: QueryRangeType): Observable<ApplicationUsageRating[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (limit) params = params.append('limit', limit);
		if (range) params = params.append('range', range);
		return this.httpClient.get<ApplicationUsageRating[]>(`${this.serviceUrl(tenantId)}/most-used-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getAllWorstNotedApplicationByTenantId(tenantId: string, organizationId?: string, limit?: number, range?: QueryRangeType): Observable<ApplicationUsageRating[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (limit) params = params.append('limit', limit);
		if (range) params = params.append('range', range);
		return this.httpClient.get<ApplicationUsageRating[]>(`${this.serviceUrl(tenantId)}/worst-noted-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getUsageRepartitionByTenantId(tenantId: string, organizationId?: string, limit?: number, range?: QueryRangeType): Observable<UsageRepartition[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (limit) params = params.append('limit', limit);
		if (range) params = params.append('range', range);
		return this.httpClient.get<UsageRepartition[]>(`${this.serviceUrl(tenantId)}/repartition`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getExtensionCountByTenantId(tenantId: string, organizationId?: string): Observable<number> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		return this.httpClient.get<number>(`${this.serviceUrl(tenantId)}/extension-count`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getHourlyAmplitudeByTenantId(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<HourlyAmplitude> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (range) params = params.append('range', range);
		return this.httpClient.get<HourlyAmplitude>(`${this.serviceUrl(tenantId)}/hourly-amplitude`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getDiscoveredTeamCountByApplicationId(tenantId: string, applicationId: string, range?: QueryRangeType): Observable<number> {
		let params: HttpParams = new HttpParams();
		if (range) params = params.append('range', range);
		return this.httpClient.get<number>(`${this.serviceUrl(tenantId)}/applications/${applicationId}/discovered-team-count`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}
}

export interface ApplicationUsageDetail {
	// TODO @TAN Team[] -> Organization[]
	teams: Team[];
	satisfaction: Satisfaction;
	usage: Usage;
	usagePerMonth: UsageHistory[];
	usagePerDay: UsageHistory[];
	usagePerHour: UsageHistory[];
	activityTimeAverage: Usage;
	incognito: boolean;
}

export interface Rating {
	value: number|null;
	outOf: number;
	queryType: QueryRangeType;
}

export interface Satisfaction {
	rating: Rating;
	votersCount: number;
}

export interface UsageHistory {
	date: string;
	usage: Usage;
}

export interface Team {
	// TODO @TAN ApplicationOrganizationUsage {
	//    organization: Organization;
	//    userCount?: number;
	//    userTotalCount?: number;
	//    autodiscovered: boolean;
	//  }
	structure: string
	structureName: string
	businessUnit: string
	businessUnitName: string
	userCount?: number
	userTotalCount?: number
	icon?: OrganizationIcon
	autodiscovered: boolean
	users: User[],
	opened?: boolean
}

export interface User {
	userId: string,
	userName?: string,
	deviceName?: string,
	lastActivity: Date,
}

export interface ApplicationUsageRating {
	application: ApplicationGeneric;
	usage: Usage;
	rating: Rating;
}

export interface UsageRepartition {
	id?: string;
	name?: string;
	usage: number;
	percentage?: number; // TODO @TAN Remove FAKE
}

export interface HourlyAmplitude {
	beginning: Moment|null;
	end: Moment|null;
}

export interface ApplicationHourlyAmplitude {
	application: ApplicationGeneric;
	minutes: number;
}

export interface Moment {
	hour: number;
	minute: number;
}
