import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContractType, Usage} from "../../../../../services/model/application-contract.model";
import {
	ApplicationHourlyAmplitude,
	HourlyAmplitude,
	Satisfaction,
	UsageHistory
} from "../../../../../services/usage.service";
import {Organization, OrganizationService} from "../../../../../services/organization.service";
import {TendencyWidgetContent} from "../../../../global/application-mini-widget/application-mini-widget.component";
import {finalize, Observable, of, Subscription, switchMap} from "rxjs";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../../services/front/organization-detail.service";
import {tap} from "rxjs/operators";
import {QueryRangeType} from "../../../../../services/back/tenant-finance.service";
import {OrganizationFinanceData} from "../organization-finance-tab/organization-finance-tab.component";
import {ApplicationMiniWidgetModule} from "../../../../global/application-mini-widget/application-mini-widget.module";
import {DeckContentLoaderModule} from "../../../../global/title-content-loader/deck-content-loader.module";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {FormsModule} from "@angular/forms";
import {MiniButtonModule} from "../../../../global/button/mini-button/mini-button.module";
import {TeamCardModule} from "../../../applications/application-detail/usage-tab/team-card/team-card.module";
import {TranslateModule} from "@ngx-translate/core";
import {UsageTabModule} from "../../../applications/application-detail/usage-tab/usage-tab.module";
import {CostPipe} from "../../../../../pipes/number/cost.pipe";
import {
	OrganizationHourlyAmplitudeDonutComponent
} from "./organization-hourly-amplitude-donut/organization-hourly-amplitude-donut.component";

@Component({
  selector: 'app-organization-usage-tab',
  standalone: true,
	imports: [CommonModule, ApplicationMiniWidgetModule, DeckContentLoaderModule, DesignSystemModule, FormsModule, MiniButtonModule, TeamCardModule, TranslateModule, UsageTabModule, CostPipe, OrganizationHourlyAmplitudeDonutComponent],
  templateUrl: './organization-usage-tab.component.html',
  styleUrl: './organization-usage-tab.component.scss'
})
export class OrganizationUsageTabComponent {
	tenantId: string;
	isEditor: boolean;
	organization: Organization;
	isTenantInactivePlan: boolean;

	_initializing: boolean;
	_loading: boolean = false;
	initialized: boolean = true;

	usageWidget: TendencyWidgetContent|undefined;
	remoteWorkingWidget: TendencyWidgetContent|undefined;
	satisfactionWidget: TendencyWidgetContent|undefined;

	data?: OrganizationUsageData;

	subscription: Subscription = new Subscription();

	constructor(private organizationDetailService: OrganizationDetailService,
				private organizationService: OrganizationService) {
	}

	ngOnInit() {
		this.subscription.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap((data) => this.setOrganizationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.organizationService.getOrganizationUsageData(this.tenantId, this.organization.organizationId)),
				tap((data) => this.setData(data)),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	private setOrganizationDetailData(data: OrganizationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.organization = data.organization;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	setData(data: OrganizationUsageData): void {
		this.data = data;
		this.usageWidget = {
			value: data.usage.value,
			day: data.usage.queryType
		};
		this.remoteWorkingWidget = undefined;
		this.satisfactionWidget = {
			value: data.satisfaction.rating.value,
			day: data.satisfaction.rating.queryType
		};
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

export interface OrganizationUsageData {
	usage: Usage,
	monthlyUsage: UsageHistory[],
	dailyUsage: UsageHistory[],
	hourlyUsage: UsageHistory[],
	satisfaction: Satisfaction,
	applicationsHourlyAmplitude: ApplicationHourlyAmplitude[],
	hourlyAmplitude?: HourlyAmplitude
}
