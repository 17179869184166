import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {delay, finalize, forkJoin, Observable, of, Subscription, switchMap} from 'rxjs';
import {CriticalityLevel, DiagramApplicationMatrix, DiagramApplicationMatrixFilterForm, TenantService} from 'src/app/services/tenant.service';
import {Organization, OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {TranslateService} from '@ngx-translate/core';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {map, tap} from 'rxjs/operators';
import {ApplicationCategoryService} from 'src/app/services/back/application-category.service';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {Category} from 'src/app/services/model/application-category.model';
import {ApplicationDetailComponent, ApplicationDetailInput} from 'src/app/modules/home/applications/application-detail/application-detail.component';
import {FormControl, FormGroup} from '@angular/forms';
import jsPDF from 'jspdf';
import {ApplicationGeneric, ApplicationStatus} from 'src/app/services/model/new-application.model';
import {PdfService} from 'src/app/services/front/pdf.service';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {CatalogTag} from 'src/app/services/model/catalog-tag.model';

@Component({
	selector: 'app-diagram-app-matrix',
	templateUrl: './diagram-app-matrix.component.html',
	styleUrls: ['./diagram-app-matrix.component.scss']
})
export class DiagramAppMatrixComponent implements OnInit, OnDestroy {

	@ViewChild('pdfContent') pdfContent: ElementRef;
	@ViewChild('scrollContainer') scrollContainer: ElementRef;

	tenantId: string;
	organization: OrganizationTree;

	_initializing: boolean;
	_loading: boolean;
	_refreshing: boolean;
	_exporting: boolean;

	applications: Record<string, Record<string, ApplicationGeneric[]>> = {};
	displayedOrganizations: OrganizationTree[] = [];
	displayedCategories: Category[] = [];

	filterForm: FormGroup;
	searchOrganizationControl: FormControl;
	organizations: OrganizationTree[] = [];
	searchCategoryControl: FormControl;
	categories: Category[] = [];
	filterData: {
		organization: OrganizationTree[],
		criticality: CriticalityLevel[],
		category: Category[],
		status: ApplicationStatus[],
		tag: CatalogTag[],
	};
	formName: typeof Form = Form;
	level: typeof CriticalityLevel = CriticalityLevel;

	isEmpty: boolean|undefined = undefined;
	activeFilters: number = 0;
	unit: number = 1;
	readonly INITIAL_ZOOM: number = 2;

	subscription: Subscription = new Subscription();

	constructor(private applicationCategoryService: ApplicationCategoryService,
							private applicationService: NewApplicationService,
							private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private rightSliderService: RightSliderService,
							private pdfService: PdfService,
							private tenantService: TenantService,
							private translate: TranslateService,
							private renderer: Renderer2) {
	}

	ngOnInit() {
		this.createFilterForm();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.renderer.addClass(document.body, 'disable-two-finger-back');
	}

	private createFilterForm(): void {
		this.filterForm = new FormGroup({
			[Form.organization]: new FormControl([]),
			[Form.criticality]: new FormControl([]),
			[Form.category]: new FormControl([]),
			[Form.hideEmptyCategory]: new FormControl(true),
			[Form.status]: new FormControl([]),
			[Form.tag]: new FormControl([]),
		});
		this.subscription.add(this.filterForm.valueChanges
			.pipe(
				tap(() => this.setActiveFilters()),
				switchMap(() => this.fetchDiagramApplicationMatrix()))
			.subscribe());
		this.searchOrganizationControl = new FormControl('');
		this.subscription.add(this.searchOrganizationControl.valueChanges
			.subscribe(search => this.searchOrganizationFilter(search)));
		this.searchCategoryControl = new FormControl('');
		this.subscription.add(this.searchCategoryControl.valueChanges
			.subscribe(search => this.searchCategoryFilter(search)));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				map(() => this.buildDiagramApplicationMatrixFilterForm()),
				switchMap(form => forkJoin([
					this.tenantService.getAllDiagramApplicationMatrix(this.tenantId, form),
					this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId),
					this.organizationService.getOrganizationTreeByTenantId(this.tenantId),
					this.applicationService.getAllApplicationTagByTenantId(this.tenantId)
				])),
				tap(([apps, categories, organization, tags])=> this.setDiagramApplicationMatrix(apps, categories, organization)),
				tap(([_, categories, organization, tags])=> this.setFilterData(organization, categories, tags)),
				tap(() => this.filterRowAndColumn()),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	fetchDiagramApplicationMatrix(): Observable<{}> {
		return this.switchRefreshing().pipe(
			map(() => this.buildDiagramApplicationMatrixFilterForm()),
			switchMap(form => this.tenantService.getAllDiagramApplicationMatrix(this.tenantId, form)),
			tap(apps => this.setDiagramApplicationMatrix(apps, this.categories, this.organization)),
			tap(() => this.filterRowAndColumn()),
			finalize(() => this.switchRefreshing()));
	}

	private buildDiagramApplicationMatrixFilterForm(): DiagramApplicationMatrixFilterForm {
		return {
			organizations: this.organizationFormValue.map(o => o.organizationId),
			categories: this.categoryFormValue.map(c => c.categoryId),
			criticality: this.criticalityFormValue,
			status: this.statusFormValue,
			tag: this.tagFormValue.map(t => t.tagId)
		}
	}

	private setDiagramApplicationMatrix(apps: DiagramApplicationMatrix[], categories: Category[], organization: OrganizationTree): void {
		this.organization = organization;
		this.applications = {};
		categories.forEach(cat => {
			this.applications[cat.categoryId] = {};
			organization.children
				.flatMap(o => o.children)
				.map(o => o.organization)
				.forEach(team => this.applications[cat.categoryId][team.organizationId] = apps
					.filter(app => app.categoryId === cat.categoryId && app.organizationIds.includes(team.organizationId))
					.map(app => app.application)
					.sort(this.sortApplications));
		});
		if (this.isEmpty === undefined) this.isEmpty = apps.length === 0;
	}

	sortApplications = (app1: ApplicationGeneric, app2: ApplicationGeneric): number => {
		if (app1.criticality === app2.criticality) return app1.name.localeCompare(app2.name);
		if (!app1.criticality && app2.criticality) return 1;
		if (app1.criticality && !app2.criticality) return -1;
		if (app1.criticality === CriticalityLevel.HIGH) return -1;
		if (app2.criticality === CriticalityLevel.HIGH) return 1;
		if (app1.criticality === CriticalityLevel.MEDIUM) return -1;
		return 1;
	};

	filterRowAndColumn(): void {
		this.displayedOrganizations = JSON.parse(JSON.stringify(this.organizations));
		this.displayedOrganizations
			.forEach(o => o.children = o.children
				.filter(c => !this.organizationFormValue.length || this.organizationFormValue.map(o => o.organizationId).includes(c.organization.organizationId)));
		this.displayedOrganizations = this.displayedOrganizations.filter(o => o.children.length > 0);
		this.displayedCategories = this.categories
			.filter(c => !this.categoryFormValue.length || this.categoryFormValue.map(c => c.categoryId).includes(c.categoryId))
			.filter(c => this.categoryFormValue.length || !this.hideEmptyCategoryFormValue || Object.values(this.applications[c.categoryId]).flat().length > 0);
	}

	openApplicationDetail(applicationId: string): void {
		const data: ApplicationDetailInput = {
			applicationId: applicationId
		};
		this.rightSliderService.openComponent(ApplicationDetailComponent, data)
			.subscribe(() => this.fetchDiagramApplicationMatrix());
	}

	private setFilterData(organization: OrganizationTree, categories: Category[], tags: CatalogTag[]): void {
		this.organizations = organization.children
			.filter(c => c.children.length > 0)
			.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		this.organizations
			.forEach(o => o.children.sort((a, b) => a.organization.name.localeCompare(b.organization.name)));
		this.categories = categories
			.sort((a, b) => a.name.localeCompare(b.name));
		this.filterData = {
			organization: this.organizations,
			category: this.categories,
			criticality: Object.values(CriticalityLevel),
			status: Object.values(ApplicationStatus),
			tag: tags
		};
	}

	private searchOrganizationFilter(search?: string): void {
		if (!search) {
			this.filterData.organization = this.organizations;
		} else {
			const lowercaseValue = search.toLowerCase();
			this.filterData.organization = this.organizations
				.reduce((acc: OrganizationTree[], org) => {
					const matchingChildren = org.children.filter(team =>
						team.organization.name.toLowerCase().includes(lowercaseValue)
					);

					if (org.organization.name.toLowerCase().includes(lowercaseValue) || matchingChildren.length > 0) {
						acc.push({
							...org,
							children: org.organization.name.toLowerCase().includes(lowercaseValue)
								? org.children
								: matchingChildren
						});
					}
					return acc;
				}, [])
				.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		}
	}

	private searchCategoryFilter(search?: string): void {
		this.filterData.category = this.categories.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()));
	}

	exportToPdf(): void {
		this.switchExporting()
			.pipe(
				tap(() => this.scrollContainer.nativeElement.scroll(0, 0)),
				delay(100),
				switchMap(() => this.pdfService.buildFromHtml('pdf-content', this.translate.instant('page.diagram.applicationMatrix.pdf.title'), this.buildPdfSubtitle(), 'assets/icons/diagram-app-matrix.png')),
				finalize(() => this.switchExporting()))
			.subscribe(pdf => this.savePdf(pdf));
	}

	private buildPdfSubtitle(): string {
		return !this.organizationFormValue.length
			? this.organization.organization.name
			: this.organizations
				.filter(parent => parent.children.some(c => this.organizationFormValue.map(o => o.organizationId).includes(c.organization.organizationId)))
				.map(parent => parent.organization.name
					+ ' ('
					+ parent.children
						.filter(c => this.organizationFormValue.map(o => o.organizationId).includes(c.organization.organizationId))
						.map(c => c.organization.name)
						.join(', ')
					+ ')')
				.join(' - ');
	}

	private savePdf(pdf: jsPDF|null): void {
		if (pdf) {
			const titleDate: string = new Date().toISOString().split('T')[0];
			pdf.save('matrix-' + titleDate + '.pdf');
		}
	}

	onZoom(zoomLevel: number): void {
		this.unit = zoomLevel / this.INITIAL_ZOOM;
	}

	setActiveFilters(): void {
		this.activeFilters = this.organizationFormValue.length
			+ this.criticalityFormValue.length
			+ this.categoryFormValue.length
			+ (this.hideEmptyCategoryFormValue ? 0 : 1)
			+ this.statusFormValue.length
			+ this.tagFormValue.length;
	}

	filterOutCategory(categoryId: string): void {
		const categories: Category[] = this.displayedCategories
			.filter(c => c.categoryId !== categoryId);
		this.filterForm.get(Form.category)!.setValue(categories);
	}

	filterOutTeam(organizationId: string): void {
		const teams: Organization[] = this.displayedOrganizations
			.flatMap(o => o.children)
			.filter(o => o.organization.organizationId !== organizationId)
			.map(o => o.organization);
		this.filterForm.get(Form.organization)!.setValue(teams);
	}

	resetFilters(): void {
		this.filterForm.setValue({
			[Form.organization]: [],
			[Form.criticality]: [],
			[Form.category]: [],
			[Form.hideEmptyCategory]: true,
			[Form.status]: [],
			[Form.tag]: []
		});
		this.filterForm.markAsPristine();
	}

	get organizationFormValue(): Organization[] {
		return this.filterForm.get(Form.organization)!.value;
	}

	get criticalityFormValue(): CriticalityLevel[] {
		return this.filterForm.get(Form.criticality)!.value;
	}

	get categoryFormValue(): Category[] {
		return this.filterForm.get(Form.category)!.value;
	}

	get hideEmptyCategoryFormValue(): boolean {
		return this.filterForm.get(Form.hideEmptyCategory)!.value;
	}

	get statusFormValue(): ApplicationStatus[] {
		return this.filterForm.get(Form.status)!.value;
	}

	get tagFormValue(): CatalogTag[] {
		return this.filterForm.get(Form.tag)!.value;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchRefreshing(): Observable<{}> {
		this._refreshing = !this._refreshing;
		return of({});
	}

	private switchExporting(): Observable<{}> {
		this._exporting = !this._exporting;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.renderer.removeClass(document.body, 'disable-two-finger-back');
	}
}

enum Form {
	organization = 'organization',
	criticality = 'criticality',
	category = 'category',
	hideEmptyCategory = 'hideEmptyCategory',
	status = 'status',
	tag = 'tag'
}
