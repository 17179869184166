<div class="bg-rounded pad-M" style="min-height: 231px;">
	<div class="flex-align-center flex-space-between" style="height: 34px;">
		<div class="font-MM fw-700">{{ 'page.appDetails.finance.contracts.contracts' | translate }}</div>
	</div>
	<div *ngIf="!data" style="height: 191px;">
		<list-content-loader></list-content-loader>
	</div>
	<div *ngIf="data">
		<div *ngIf="contracts.length <= 0" class="flex-column flex-center gap-M" style="height: 191px;">
			<div class="flex-center grey">
				<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.87533 16.9993L14.6253 21.7493L24.1253 12.2493M32.8337 16.9993C32.8337 25.7439
						25.7448 32.8327 17.0003 32.8327C8.25582 32.8327 1.16699 25.7439 1.16699 16.9993C1.16699
						8.25484 8.25582 1.16602 17.0003 1.16602C25.7448 1.16602 32.8337 8.25484 32.8337 16.9993Z"
						  stroke="#B5B5C3"
						  stroke-width="2"
						  stroke-linecap="round"
						  stroke-linejoin="round"/>
				</svg>
			</div>
			<div class="font-M fw-500 grey">{{ 'page.appDetails.finance.contracts.noContract' | translate }}</div>
		</div>
		<div *ngIf="contracts.length > 0">
			<div class="flex-column gap-S mt-s">
				<div class="drawer">
					<table class="w-100" aria-describedby="list-of-contracts">
						<tr class="t-bottom-border grey">
							<th class="w-20" style="text-align: left; font-weight: 500; padding: 0 0 4px 0;">{{ 'page.appDetails.finance.contracts.application' | translate }}</th>
							<th class="w-20" style="text-align: left; font-weight: 500; padding: 0 0 4px 0;">{{ 'page.appDetails.finance.contracts.contractType' | translate }}</th>
							<th class="w-20" style="text-align: left; font-weight: 500; padding: 0 0 4px 0;">{{ 'page.appDetails.finance.contracts.status' | translate }}</th>
							<th class="w-20" style="text-align: left; font-weight: 500; padding: 0 0 4px 0;">{{ 'page.appDetails.finance.contracts.amount' | translate }}</th>
							<th class="w-20" style="text-align: right; font-weight: 500; padding: 0 0 4px 0;">{{ 'page.appDetails.finance.contracts.actions' | translate }}</th>
						</tr>
						<tr *ngFor="let contract of contracts; let i = index" [ngClass]="{'t-bottom-border': i < contracts.length - 1}">
							<td style="text-align: left; padding: 10px 0;">
								<app-display-application [application]="contract.application"></app-display-application>
							</td>
							<td style="text-align: left; padding: 10px 0;">
								{{ 'page.appDetails.finance.contracts.' + contract.type | translate }}
							</td>
							<td style="text-align: center; padding: 10px 0;">
								<div class="flex-justify-start">
									<div [class]="'chips-' + contract.status">
										{{ 'page.appDetails.finance.contracts.' + contract.status | translate }}
									</div>
								</div>
							</td>
							<td style="text-align: left; padding: 10px 0;">
								<div>
									{{contract.totalAmount|cost:'full':contract.recurrencePeriod}}
								</div>
							</td>
							<td style="text-align: right; padding: 10px 0;">
								<div class="flex-align-center justify-flex-end">
									<div *ngIf="contract.files && contract.files.length > 1">
										<app-icon-button [size]="'s'" [img]="'assets/icons/download-01.svg'"
														 [panelClass]="'img-size-70'"
														 [tooltip]="'button.download' | translate"
														 [disabled]="_initializing || _loading || !!_deleting"
														 [dropdownTrigger]="downloadDropdown">
										</app-icon-button>
										<app-dropdown #downloadDropdown>
											<div style="width: 300px">
												<div *ngFor="let file of contract.files; let index = index">
													<div *ngIf="file.type === fileType.FILE" class="uploading-container" [ngClass]="{'mb-l': index < contract.files.length - 1}">
														<div class="flex gap-M w-100">
															<div class="icon-bg" style="background-color: #ECF9FF">
																<img ngSrc="assets/icons/document-outline.svg" class="filter-accent" height="24" width="24" alt="">
															</div>
															<div class="flex-column flex-justify-center a-i-b w-100">
																<div class="flex-align-center flex-space-between" style="max-width: 136px;">
																	<span class="text-overflow-ellipsis">{{ file.name }}</span>
																</div>
																<span *ngIf="file.size" class="font-S fw-500 grey">{{ sizePrettily(file.size) }}</span>
															</div>
														</div>
														<!-- DOWNLOAD BUTTON -->
														<div class="flex-center radius-half pb-s pt-s pr-s pl-s">
															<app-icon-button [size]="'s'" [img]="'assets/icons/download-01.svg'"
																			 [panelClass]="'img-size-70'"
																			 (click)="downloadFile(file)"></app-icon-button>
														</div>
													</div>
													<div *ngIf="file.type === fileType.LINK" class="uploading-container" [ngClass]="{'mb-l': index < contract.files.length - 1}">
														<div class="flex gap-M w-100">
															<div class="icon-bg" style="background-color: #ECF9FF">
																<img ngSrc="assets/icons/link.svg" class="filter-accent" height="20" width="20" alt="">
															</div>
															<div class="flex-column flex-justify-center w-100">
																<div class="flex-align-center flex-space-between" style="max-width: 200px;">
																	<a class="text-overflow-ellipsis text-underline accent" target="_blank"
																	   [href]="file.link">{{ file.name }}</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</app-dropdown>
									</div>
									<div *ngIf="contract.files && contract.files.length === 1">
										<app-icon-button [size]="'s'" [img]="contract.files[0].type == fileType.FILE ? 'assets/icons/download-01.svg' : 'assets/icons/link.svg'"
														 [panelClass]="'img-size-70'"
														 [tooltip]="contract.files[0].type == fileType.FILE ? ('button.downloadOneFile' | translate) : ('button.openLink' | translate)"
														 [disabled]="_initializing || _loading || !!_deleting"
														 (click)="contract.files[0].type == fileType.FILE ? downloadFile(contract.files[0]) : openLink(contract.files[0].link!)">
										</app-icon-button>
									</div>
									<app-icon-button color="accent" size="s" [img]="'assets/icons/edit.svg'" [tooltip]="'button.edit' | translate" [panelClass]="'fix-edit-margin'"
													 [disabled]="_initializing || _loading || !!_deleting"
													 (click)="openContractDialog(contract)">
									</app-icon-button>
									<app-icon-button color="danger" size="s" [img]="'assets/icons/trash.svg'" [tooltip]="'button.delete' | translate"
													 [disabled]="_initializing || _loading || !!_deleting" [loading]="_deleting === contract.contractId"
													 (click)="deleteContract(contract)">
									</app-icon-button>
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
