import {Component, OnDestroy, OnInit} from '@angular/core';
import {TenantExtensionSettings, TenantOverview, TenantService} from 'src/app/services/tenant.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {FormControl, Validators} from "@angular/forms";
import {HOME_URL} from "src/app/models/home/navigation.model";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {Subscription, switchMap, tap} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {DeleteDialogComponent} from "../../../design-system/delete-dialog/delete-dialog.component";
import {filter} from "rxjs/operators";
import {Currency} from 'src/app/services/model/application-contract.model';
import {CurrencyService, TenantCurrencyForm} from 'src/app/services/back/currency.service';
import {RoverSettings} from "../../../../services/rover.service";

@Component({
	selector: 'app-settings-tenant',
	templateUrl: './tenant.component.html',
	styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit, OnDestroy {

	constructor(protected currentTenantService: CurrentTenantService,
							protected translateService: TranslateService,
							protected currencyService: CurrencyService,
							protected tenantService: TenantService,
							protected snackBar: SnackbarService,
							protected dialog: MatDialog,
							protected router: Router) {
	}

	tenant: TenantOverview;

	isLoading: boolean = false;

	nameControl: FormControl<string|null>;
	incognitoControl: FormControl<boolean|null>;
	currencyControl: FormControl<Currency|null>;
	currencies: Currency[] = [];
	roverSettings: TenantExtensionSettings;

	subscription: Subscription = new Subscription();

	ngOnInit(): void {
		this.buildForm();
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(tap(tenant => this.setTenant(tenant)))
			.subscribe(() => this.initialize()));
	}

	buildForm(): void {
		this.nameControl = new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(256)]);
		this.currencyControl = new FormControl(null, Validators.required);
		this.incognitoControl = new FormControl(null, Validators.required);
		this.subscription.add(this.currencyControl.valueChanges
			.subscribe(value => this.updateTenantCurrency(value!)));
		this.subscription.add(this.incognitoControl.valueChanges
			.subscribe(value => {
				const form = {
					displayExtensionCheck: this.roverSettings.displayRover,
					supportUrlCheck: this.roverSettings.supportUrlActivated,
					supportUrl: this.roverSettings.supportUrl,
					feedbackCheck: this.roverSettings.satisfactionActivated,
					feedback: this.roverSettings.satisfactionFrequency,
					incognito: value !== true
				}

				this.tenantService.updateTenantExtensionSettings(this.tenant.configuration.id, form)
					.subscribe({
						next: () => this.snackBar.show(this.translateService.instant('page.application.detail.update.success'))
					});
			}));
	}

	initialize(): void {
		this.fetchCurrency();
		this.fetchRoverSettings();
	}

	setTenant(tenant: TenantOverview): void {
		this.tenant = tenant;
		this.nameControl.setValue(tenant.configuration.name);
		if (tenant.configuration.role !== 'admin') {
			this.nameControl.disable();
		} else {
			this.nameControl.enable();
		}
	}

	private fetchCurrency(): void {
		this.currencyService.getAllCurrency(this.tenant.configuration.id).subscribe(currencies => {
			this.currencies = currencies;
			this.currencyControl.setValue(this.currencies.find(c => c.code === this.tenant.configuration.currency.code)!, {emitEvent: false});
		});
	}

	private fetchRoverSettings(): void {
		this.tenantService.getTenantExtensionSettings(this.tenant.configuration.id).subscribe(settings => {
			this.roverSettings = settings;
			this.incognitoControl.setValue(!settings.incognito, {emitEvent: false});
		});
	}

	updateTenantCurrency(currency: Currency): void {
		const form: TenantCurrencyForm = {
			currencyCode: currency.code
		};
		this.currencyService.updateTenantCurrency(this.tenant.configuration.id, form)
			.pipe(
				filter(result => result),
				tap(() => this.snackBar.show(this.translateService.instant('page.application.detail.update.success'))))
			// TODO @TAN refresh tenant itself not all
			.subscribe(() => this.currentTenantService.initialize(this.tenant.configuration.id, false));
	}

	onDeleteTenant(): void {
		this.dialog.open(DeleteDialogComponent, {
			width: '475px',
			data: {
				icon: '/assets/illustrations/delete/account.svg',
				title: this.translateService.instant('page.settings.tenant.delete.title'),
				subtitle: this.translateService.instant('page.settings.tenant.delete.subtitle', { tenant: this.tenant.configuration.name }),
				warningMessage: this.translateService.instant('page.settings.tenant.delete.warningMessage'),
				deleteButton: this.translateService.instant('page.settings.tenant.delete.deleteButton')
			}})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.tenantService.deleteTenant(this.tenant.configuration.id)),
			).subscribe({
			next: () => {
				this.currentTenantService.initialize();
				this.router.navigate([HOME_URL])
					.then(() => {
						window.location.reload();
					});
			},
			error: () => {
				this.snackBar.show(this.translateService.instant('page.tenant.deleteFailed'), 'danger-snack');
			}
		});
	}

	onChangeTenantName(): void {
		if (this.nameControl.valid && this.nameControl.dirty) {
			const newName = this.nameControl.value as string;
			this.tenantService.updateTenantName(this.tenant.configuration.id, newName).subscribe({
				next: () => this.snackBar.show(this.translateService.instant('page.tenant.changedName'), 'success-snack'),
				error: () => this.snackBar.show(this.translateService.instant('page.tenant.changeNameFailed'), 'danger-snack')
			});
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
