<div *ngIf="!tenant?.plan?.isTrialPlan() && !tenant?.plan?.isActivePlan()" class="pl-l pr-l mt-m">
	<app-chips color="error">{{'page.dashboard.limited.badge' | translate}}</app-chips>
	<div class="font-S fw-500 grey mt-xs">{{'page.dashboard.limited.headline' | translate}}</div>
	<div class="font-S fw-500 grey">{{'page.dashboard.limited.link.0' | translate}} <a [routerLink]="[settingsBillingUrl]" [queryParams]="activatedRoute.snapshot.queryParams"> {{'page.dashboard.limited.link.1' | translate}} </a> {{'page.dashboard.limited.link.2' | translate}}</div>
</div>
<div class="main-container pt-xl pb-l">
	<div class="ml-xl disp-g-3 mr-xl semi-responsive" style="grid-column-gap: 20px; grid-row-gap: 20px">
		<div style="grid-column: span 8;">
			<app-filter [organizationTree]="organizationTree"
						[filtersLoading]="_initializing || _loadingOrganization"
						(changes)="refreshWithFilters($event)">
			</app-filter>
		</div>
		<div class="widget widget-mini bg-rounded" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="applicationWidget" [responsive]="true"></app-mini-widget>
		</div>
		<div class="widget widget-mini bg-rounded" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="resourceWidget" [responsive]="true"></app-mini-widget>
		</div>
		<div class="widget widget-mini bg-rounded" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="mappingWidget" [responsive]="true"></app-mini-widget>
		</div>
		<div class="widget widget-mini bg-rounded" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="documentWidget" [responsive]="true"></app-mini-widget>
		</div>
		<div class="widget" style="grid-column: span 2;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="flex-column h-100 w-100" style="gap: 20px;">
				<div class="flex bg-rounded" style="height: 93px;">
                    <app-system-age-average class="w-100" [averageAge]="averageAge" [loading]="_initializing || _loadingOrganization || _loading"></app-system-age-average>
                </div>
				<div class="flex bg-rounded h-100">
                    <app-system-age-distribution class="w-100" [filter]="filter"></app-system-age-distribution>
				</div>
			</div>
		</div>
		<div class="widget" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
            <div class="flex bg-rounded" style="height: calc(100% - 1px);">
                <app-most-used-technology class="w-100" [filter]="filter"></app-most-used-technology>
            </div>
		</div>

		<div class="widget" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="flex bg-rounded" style="height: calc(100% - 1px);">
                <app-most-complex-application class="w-100" [filter]="filter" [limit]="6"></app-most-complex-application>
            </div>
		</div>
		<div class="widget widget-semi" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
            <div class="flex bg-rounded" style="height: calc(100% - 1px);">
                <app-application-type-distribution class="w-100" [filter]="filter"></app-application-type-distribution>
            </div>
		</div>
		<div class="widget widget-semi" style="grid-column: span 2;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
            <div class="flex bg-rounded" style="height: calc(100% - 1px);">
                <app-application-document-average class="w-100" [filter]="filter"></app-application-document-average>
            </div>
		</div>
		<div class="widget widget-semi" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled flex-align-end flex-justify-center"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="flex bg-rounded" style="height: calc(100% - 1px);">
                <app-most-obsolete-application class="w-100" [filter]="filter" [limit]="5"></app-most-obsolete-application>
            </div>
		</div>
	</div>
</div>
