import {Point} from "jspdf";
import {CanvasHandler} from "../../common/canvas-handler";
import {ApplicationCard} from "../../common/shapes/application-card";
import {ApplicationGroup} from "./application-group";
import {CurvedLine} from "../../common/shapes/curved-line";
import {
	ApplicationOverview,
	ClickableObject,
	FlowOverview,
	HoverableObject,
	HoverableObjectStacked
} from "../../common/diagram";
import {ApplicationGroupOrganizer, GroupMap} from "./application-group-organizer";
import {Layers} from "../../common/layer";
import {PointUtils} from "../../common/geometry";
import {BaseEngine} from "../../common/base-engine";
import {Queue} from 'src/app/lang/queue';
import {FlowDirection, StackedCurvedLine} from "../../common/shapes/stacked-curved-line";

export class FlowDiagramEngine extends BaseEngine {
	protected applications: ApplicationCard[] = [];
	protected applicationGroups: ApplicationGroup[] = [];
	protected lines: Array<CurvedLine> = [];
	protected stackedLines: Array<StackedCurvedLine> = [];
	protected isFolded = false;
	protected updateQueue = new Queue<{previouslySelectedApplication?: ApplicationOverview, flowsHasChanges: boolean}>();

	constructor(
		protected context2D: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
		protected canvas:HTMLCanvasElement,
		protected applicationOverviews: ApplicationOverview[] = [],
		protected flowsOverviews: FlowOverview[] = [],
		protected height: number,
		protected width: number
		) {
		super(context2D, canvas, height, width);
	}

	protected groupHasNotChanged(group1: ApplicationGroup, group2: ApplicationGroup) {
		return group1.getApplications().length === group2.getApplications().length
		&& group1.getPosition().coordinates.x === group2.getPosition().coordinates.x
		&& group1.getPosition().coordinates.y === group2.getPosition().coordinates.y
	}

	override onInit() {
		this.updateQueue.enqueue({flowsHasChanges: true});
		this.identifyNewGroups();
		this.applicationGroups.forEach(applicationGroup => applicationGroup.update());
		this.applications = this.applicationGroups.map((applicationGroup: ApplicationGroup) => applicationGroup.getApplicationCards()).flat();
		this.identifyNewLines();
	}

	update(isFolded: boolean, applicationOverviews?: ApplicationOverview[], flowsOverviews?: FlowOverview[], previouslySelectedApplication?: ApplicationOverview, focusId?: string): void {
		this.isFolded = isFolded;

		if (applicationOverviews) {
			this.applicationOverviews = applicationOverviews;
		}

		this.identifyNewGroups();
		this.applicationGroups.forEach(applicationGroup => applicationGroup.update({isDragging: this.renderer.isDragging()}));
		this.applications = this.applicationGroups.map((applicationGroup: ApplicationGroup) => applicationGroup.getApplicationCards()).flat();

		if (flowsOverviews) {
			this.flowsOverviews = flowsOverviews;
			this.updateQueue.enqueue({previouslySelectedApplication: previouslySelectedApplication, flowsHasChanges: true});
			this.identifyNewLines();
		}
		if (focusId) {
			const app: ApplicationCard|undefined = this.applications.find(application => application.getId() === focusId);
			if (app) {
				const point: Point = {
					x: app.coordinates.x + ApplicationCard.WIDTH / 2,
					y: app.coordinates.y + ApplicationCard.HEIGHT / 2
				};
				setTimeout(() => this.onClick(point), 50);
				setTimeout(() => this.onClick(point), 100);
			}
		}
	}

	protected identifyNewGroups() {
		const newGroup = this.buildGroupGrid(this.applicationOverviews)

		// Save size
		this.setCurrentHeight(newGroup.height );
		this.setCurrentWidth(newGroup.width);

		// Remove deleted groups
		this.applicationGroups = this.applicationGroups.filter(applicationGroup => newGroup.groups.some(group => group.getName() === applicationGroup.getName()));

		// Change position of updated groups
		newGroup.groups.forEach(group => {
			const foundGroupPosition = this.applicationGroups.findIndex(applicationGroup => applicationGroup.getName() === group.getName())
			if (foundGroupPosition >= 0) {
				if (!this.groupHasNotChanged(this.applicationGroups[foundGroupPosition], group)) {
					this.applicationGroups[foundGroupPosition].update({isDragging: this.renderer.isDragging(), position: group.getPosition().coordinates, applications: group.getApplications()});
				} else {
					// Nothing to do, group is still the same
				}
			} else {
				this.applicationGroups.push(group);
			}
		})
	}

	protected identifyNewLines() {
		// Play updates events
		const newFlow = this.updateQueue.dequeue();
		if (newFlow && newFlow.previouslySelectedApplication) {
			this.applications.find(application => application.getId() === newFlow.previouslySelectedApplication?.applicationId)?.select();
		}

		if (newFlow && newFlow.flowsHasChanges) {
			this.lines = [];
			this.stackedLines = [];

			const stackedLinesArray: StackedCurvedLine[] = []

			for (let flowOverview of this.flowsOverviews) {
				const stackedLine = new StackedCurvedLine(this.context2D, {x: 0, y: 0}, {x: 0, y: 0}, FlowDirection.TARGET_TO_SOURCE, []);
				for (let flowOverview2 of this.flowsOverviews) {
					if ((flowOverview.source === flowOverview2.source && flowOverview.target === flowOverview2.target) || (flowOverview.source === flowOverview2.target && flowOverview.target === flowOverview2.source)) {
						const sourceCard = this.applications.find(application => application.getId() === flowOverview2.source);
						const targetCard = this.applications.find(application => application.getId() === flowOverview2.target);
						if (sourceCard && targetCard) {
							stackedLine.addData({ sourceId: sourceCard.getId(), targetId: targetCard.getId(), data: flowOverview2.data, flowId: flowOverview2.flowId });
						}
					}
				}
				if (stackedLine.getData().length > 1) {
					const sourceCard = this.applications.find(application => application.getId() === flowOverview.source);
					const targetCard = this.applications.find(application => application.getId() === flowOverview.target);
					if (sourceCard && targetCard) {
						if (PointUtils.isVerticallyAligned(sourceCard.getRectangle().topLeft, targetCard.getRectangle().topLeft)) {
							sourceCard.activateRightAnchor();
							targetCard.activateRightAnchor();
							stackedLine.setFrom(sourceCard.getRightAnchor());
							stackedLine.setTo(targetCard.getRightAnchor());
							stackedLinesArray.push(stackedLine);
						} else {
							if (PointUtils.isRightOf(sourceCard.getRectangle().topLeft, targetCard.getRectangle().topLeft)) {
								// Enable anchors
								sourceCard.activateRightAnchor();
								targetCard.activateLeftAnchor();
								stackedLine.setFrom(sourceCard.getRightAnchor());
								stackedLine.setTo(targetCard.getLeftAnchor());
								stackedLinesArray.push(stackedLine);
							} else {
								// Enable anchors
								sourceCard.activateLeftAnchor();
								targetCard.activateRightAnchor();
								stackedLine.setFrom(sourceCard.getLeftAnchor());
								stackedLine.setTo(targetCard.getRightAnchor());
								stackedLinesArray.push(stackedLine);
							}
						}
					}
				}
			}

			// remove duplicates from stackedLinesArray
			this.stackedLines = stackedLinesArray.filter((line, index, self) =>
				index === self.findIndex((t) => (
					(t.getSourceId() === line.getSourceId() && t.getTargetId() === line.getTargetId()) || (t.getSourceId() === line.getTargetId() && t.getTargetId() === line.getSourceId())
				))
			)

			// set flow direction for each stacked line (if data.sourceId is the same as another data.targetId, then the flow is bidirectional)
			this.stackedLines.forEach(stackedLine => {
				const sourceId = stackedLine.getData()[0].sourceId;
				const targetId = stackedLine.getData()[0].targetId;
				const isBidirectional = stackedLine.getData().some(data => data.sourceId === targetId && data.targetId === sourceId);
				const isSourceToTarget = stackedLine.getData().some(data => data.sourceId === sourceId && data.targetId === targetId);
				if (isBidirectional) {
					stackedLine.setFlowDirection(FlowDirection.BIDIRECTIONAL);
				} else if (isSourceToTarget) {
					stackedLine.setFlowDirection(FlowDirection.SOURCE_TO_TARGET);
				} else {
					stackedLine.setFlowDirection(FlowDirection.TARGET_TO_SOURCE);
				}
			})

			const newFlows = this.flowsOverviews.filter(flowOverview => !this.stackedLines.some(stackedLine => (stackedLine.getSourceId() === flowOverview.source && stackedLine.getTargetId() === flowOverview.target) || (stackedLine.getSourceId() === flowOverview.target && stackedLine.getTargetId() === flowOverview.source)));

			newFlows.forEach(linkOverview => {
				const sourceCard = this.applications.find(application => application.getId() === linkOverview.source);
				const targetCard = this.applications.find(application => application.getId() === linkOverview.target);
				if (sourceCard && targetCard) {
					if (PointUtils.isVerticallyAligned(sourceCard.getRectangle().topLeft, targetCard.getRectangle().topLeft)) {
						sourceCard.activateRightAnchor();
						targetCard.activateRightAnchor();
						this.lines.push(new CurvedLine(this.context2D, sourceCard.getRightAnchor(), targetCard.getRightAnchor(), sourceCard.getId(), targetCard.getId(), linkOverview.data));
					} else {
						if (PointUtils.isRightOf(sourceCard.getRectangle().topLeft, targetCard.getRectangle().topLeft)) {
							// Enable anchors
							sourceCard.activateRightAnchor();
							targetCard.activateLeftAnchor();
							// Create orthogonal line
							this.lines.push(new CurvedLine(this.context2D, sourceCard.getRightAnchor(), targetCard.getLeftAnchor(), sourceCard.getId(), targetCard.getId(), linkOverview.data));
						} else {
							// Enable anchors
							sourceCard.activateLeftAnchor();
							targetCard.activateRightAnchor();
							// Create orthogonal line
							this.lines.push(new CurvedLine(this.context2D, sourceCard.getLeftAnchor(), targetCard.getRightAnchor(), sourceCard.getId(), targetCard.getId(), linkOverview.data));
						}
					}
				}
			});

			// Select updates lines if needed
			if (newFlow?.previouslySelectedApplication) {
				this.lines.forEach(line => {
					if (line.getSourceId() === newFlow.previouslySelectedApplication?.applicationId || line.getTargetId() === newFlow.previouslySelectedApplication?.applicationId) {
						line.activate();
					}
				})

				this.stackedLines.forEach(stackedLine => {
					if (stackedLine.getSourceId() === newFlow.previouslySelectedApplication?.applicationId || stackedLine.getTargetId() === newFlow.previouslySelectedApplication?.applicationId) {
						stackedLine.activate();
					}
				})
			}
		}
	}

	protected override onUpdate() {
		this.applicationGroups.forEach(applicationGroup => applicationGroup.update({isDragging: this.renderer.isDragging()}));
		this.lines.forEach(line => line.update());
		this.stackedLines.forEach(stackedLine => stackedLine.update());
	}

	protected buildGroupGrid(applicationOverviews: ApplicationOverview[]): GroupMap {
		const applicationGroup: ApplicationGroup[] = [];

		const groupMap = new Map<string, {groupName: string, applications: ApplicationOverview[]}>();

		applicationOverviews.forEach(applicationOverview => {
			const groupExist = groupMap.get(applicationOverview.groupId)
			if (groupExist) {
				const tmp = {applications: groupExist.applications.concat(applicationOverview), groupName: groupExist.groupName}
				groupMap.set(applicationOverview.groupId, tmp)
			} else {
				groupMap.set(applicationOverview.groupId, {applications: [applicationOverview], groupName: applicationOverview.groupName})
			}
		});

		groupMap.forEach((value, key) => {
			applicationGroup.push(new ApplicationGroup(this.context2D, key, value.groupName, value.applications));
		});

		return new ApplicationGroupOrganizer().organize(applicationGroup, this.isFolded);
	}

	getClickableObjects(): ClickableObject[] {
		return this.applications;
	}

	protected getHoverableObjects(): HoverableObject[] {
		return this.lines;
	}

	protected getStackedHoverableObjects(): HoverableObjectStacked[] {
		return this.stackedLines;
	}

	protected override onDraw() {
		const layers = new Layers();

		this.renderer.draw();
		if (!this.isFolded) {
			this.lines.forEach(line => line.draw(layers));
			this.stackedLines.forEach(stackedLine => stackedLine.draw(layers));
		}

		this.applicationGroups.forEach(applicationGroup => applicationGroup.draw(layers));

		layers.draw();
	}

	// User input handlers

	onClick(point: Point) {
		if (this.isFolded) {
			const hasSelectedApp = this.applications.map(application => {
				if (application.isAtCoordinates(point) && !application.selected()) {
					this.canvas.style.cursor = 'pointer'
					application.select();
					application.setActive();
					const dpi = Math.max(window.devicePixelRatio, 2);
					const coordinates = CanvasHandler.canvasToWindowCoordinates(this.context2D, dpi, application.getBottomMiddlePosition());
					this.lastObjectSelected = { event: {id: application.getId(), position: coordinates}, originalCoordinates: application.getBottomMiddlePosition() };
					this.objectClickSubject.next({id: application.getId(), position: coordinates});
					return true;
				}  else {
					application.unselect();
					return false;
				}
			}).some(isSelected => isSelected);

			if (hasSelectedApp) {
				this.applications.forEach(application => {
					if (!application.selected()) {
						application.setUnactive();
					}
				})
			} else {
				this.applications.forEach(application => {
					application.setActive();
					application.unselect();
					this.lastObjectSelected = undefined;
					this.objectClickSubject.next(undefined);
				})
			}
		} else {
			this.applications.forEach(application => {
				if (!application.isAtCoordinates(point)) {
					application.unselect();
				}
			})

			const hasSelectedApp = this.applications.map(application => {
				if (application.isAtCoordinates(point) && application.selected()) {
					application.unselect();
					this.lines.forEach(line => {
						if (line.getSourceId() === application.getId() || line.getTargetId() === application.getId()) {
							line.deactivate();
						}
					})
					this.stackedLines.forEach(stackedLine => {
						if (stackedLine.getSourceId() === application.getId() || stackedLine.getTargetId() === application.getId()) {
							stackedLine.deactivate();
						}
					})
					return false;
				} else if (application.isAtCoordinates(point) && !application.selected()) {
					this.canvas.style.cursor = 'pointer'
					application.select();

					// Enable corresponding lines
					this.lines.forEach(line => {
						if (line.getSourceId() === application.getId() || line.getTargetId() === application.getId()) {
							line.activate();
							// Enable corresponding targets
							this.applications.forEach(innerApplication => {
								if (line.getSourceId() === innerApplication.getId() || line.getTargetId() === innerApplication.getId()) {
									innerApplication.select();
								}
							});
						} else {
							line.deactivate();
						}
					})
					this.stackedLines.forEach(stackedLine => {
						if (stackedLine.getSourceId() === application.getId() || stackedLine.getTargetId() === application.getId()) {
							stackedLine.activate();
							// Enable corresponding targets
							this.applications.forEach(innerApplication => {
								if (stackedLine.getSourceId() === innerApplication.getId() || stackedLine.getTargetId() === innerApplication.getId()) {
									innerApplication.select();
								}
							});
						} else {
							stackedLine.deactivate();
						}
					})
					const dpi = Math.max(window.devicePixelRatio, 2);
					const coordinates = CanvasHandler.canvasToWindowCoordinates(this.context2D, dpi, application.getBottomMiddlePosition());

					this.lastObjectSelected = { event: {id: application.getId(), position: coordinates}, originalCoordinates: application.getBottomMiddlePosition() };
					this.objectClickSubject.next({id: application.getId(), position: coordinates});
					return true;
				} else {
					return false;
				}
			}).some(isSelected => isSelected);

			if (hasSelectedApp) {
				this.applications.forEach(application => {
					if (!application.selected()) {
						application.setUnactive();
					}
				})
			} else {
				this.lastObjectSelected = undefined;
				this.objectClickSubject.next(undefined);

				this.applications.forEach(application => {
					application.setActive();
					this.lines.forEach(line => line.deactivate());
					this.stackedLines.forEach(stackedLine => stackedLine.deactivate());
				})
			}
		}
	}

	protected onMouseMove(point: Point) {
		this.applicationGroups.forEach(applicationGroup => {
			if (applicationGroup.isAtCoordinates(point)) {
				applicationGroup.setMouseOver();
			} else {
				applicationGroup.unsetMouseOver();
			}
		});

		this.lines.forEach(line => {
			if (line.isAtCoordinates(point)) {
				const dpi = Math.max(window.devicePixelRatio, 2);
				const coordinates = CanvasHandler.canvasToWindowCoordinates(this.context2D, dpi, line.getBottomMiddleBubblePosition());
				this.lastObjectHovered = { event: {sourceId: line.getSourceId(), targetId: line.getTargetId(), position: coordinates}, originalCoordinates: line.getBottomMiddleBubblePosition() };
				this.objectHoveredSubject.next({sourceId: line.getSourceId(), targetId: line.getTargetId(), position: coordinates});
			}
		})

		this.stackedLines.forEach(stackedLine => {
			const isAt = stackedLine.isAtCoordinates(point);
			const isActive = stackedLine.isActive();
			if (isAt.isAt && isAt.flowId && isActive) {
				const dpi = Math.max(window.devicePixelRatio, 2);
				const coordinates = CanvasHandler.canvasToWindowCoordinates(this.context2D, dpi, stackedLine.getBottomMiddleBubblePosition());
				this.lastObjectHoveredStacked = { event: {sourceId: stackedLine.getSourceId(), targetId: stackedLine.getTargetId(), flowId: isAt.flowId, position: coordinates}, originalCoordinates: stackedLine.getBottomMiddleBubblePosition() };
				this.objectHoveredStackedSubject.next({sourceId: stackedLine.getSourceId(), targetId: stackedLine.getTargetId(), flowId: isAt.flowId, position: coordinates});
			}
		})

		// On mouse over app card set mouse cursor to pointer
		this.applications.forEach(application => {
			application.unsetMouseOver();
			if (application.isAtCoordinates(point)) {
				this.canvas.style.cursor = 'pointer';
				application.setMouseOver();
			}
		})
	}
}
