import {FileLink} from 'src/app/services/model/tenant-file.model';

export interface ApplicationDocument {
  fileLink: FileLink;
  documentType: DocumentType;
  title: string;
  description: string|null;
  published: boolean;
}

export interface FileLinkApplicationForm {
  fileLinkId: string;
  documentType: DocumentType;
  title: string;
  description?: string;
  published: boolean;
}

export enum DocumentType {
  TECHNICAL_DOCUMENTS = 'technical_documents',
  SECURITY_POLICY = 'security_policy',
  DATA_POLICY = 'data_policy'
}
