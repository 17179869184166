import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ApplicationStatus} from "../../../services/model/new-application.model";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-display-app-status',
  standalone: true,
	imports: [CommonModule, TranslateModule],
  templateUrl: './display-app-status.component.html',
  styleUrl: './display-app-status.component.scss'
})
export class DisplayAppStatusComponent {
	@Input() appStatus: ApplicationStatus;
	protected readonly ApplicationStatus = ApplicationStatus;
}
