import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
	OrganizationCostDistributionComponent
} from "./organization-cost-distribution/organization-cost-distribution.component";
import {ApplicationMiniWidgetModule} from "../../../../global/application-mini-widget/application-mini-widget.module";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {Organization, OrganizationService} from "../../../../../services/organization.service";
import {TendencyWidgetContent} from "../../../../global/application-mini-widget/application-mini-widget.component";
import {finalize, Observable, of, Subscription, switchMap} from "rxjs";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../../services/front/organization-detail.service";
import {tap} from "rxjs/operators";
import {
	ContractApplicationInfo,
	ContractType,
	ContractTypeCost, Usage
} from "../../../../../services/model/application-contract.model";
import {QueryRangeType} from "../../../../../services/back/tenant-finance.service";
import {
	OrganizationContractPreviewComponent
} from "./organization-contract-preview/organization-contract-preview.component";

@Component({
  selector: 'app-organization-finance-tab',
  standalone: true,
	imports: [CommonModule, OrganizationCostDistributionComponent, ApplicationMiniWidgetModule, MatIconModule, TranslateModule, OrganizationContractPreviewComponent],
  templateUrl: './organization-finance-tab.component.html',
  styleUrl: './organization-finance-tab.component.scss'
})
export class OrganizationFinanceTabComponent implements OnInit, OnDestroy {
	tenantId: string;
	isEditor: boolean;
	organization: Organization;
	isTenantInactivePlan: boolean;

	_initializing: boolean;
	_loading: boolean = false;
	initialized: boolean = true;

	licenceCostWidget: TendencyWidgetContent|undefined;
	serviceCostWidget: TendencyWidgetContent|undefined;
	costPerUserWidget: TendencyWidgetContent|undefined;

	data?: OrganizationFinanceData;

	subscription: Subscription = new Subscription();

	constructor(private organizationDetailService: OrganizationDetailService,
				private organizationService: OrganizationService) {
	}

	ngOnInit() {
		this.subscription.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap((data) => this.setOrganizationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.organizationService.getOrganizationFinanceData(this.tenantId, this.organization.organizationId)),
				tap((data) => this.setData(data)),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	private setOrganizationDetailData(data: OrganizationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.organization = data.organization;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	setData(data: OrganizationFinanceData): void {
		this.data = data;
		this.licenceCostWidget = {
			value: (data.costs.find(cost => cost.contractType === ContractType.LICENSE)?.cost.value || 0) + (data.costs.find(cost => cost.contractType === ContractType.SUBSCRIPTION)?.cost.value || 0),
			day: QueryRangeType.CURRENT_YEAR
		};
		this.serviceCostWidget = {
			value: (data.costs.find(cost => cost.contractType === ContractType.SERVICE)?.cost.value || 0),
			day: QueryRangeType.CURRENT_YEAR
		};
		const total = data.costs.reduce((acc, cost) => acc + (cost.cost.value || 0), 0);
		this.costPerUserWidget = {
			value: Math.round(total / Math.max(data.usage.value || 0, 1)),
			day: QueryRangeType.CURRENT_YEAR
		}
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

export interface OrganizationFinanceData {
	costs: ContractTypeCost[];
	contracts: ContractApplicationInfo[];
	usage: Usage;
}
