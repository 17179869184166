<div class="flex-column w-100">
	<label [ngClass]="{'invalid-input': input.dirty && input.invalid}">{{ label }}</label>
	<div class="text-input flex-align-center w-100">
		<div class="flex-center icon-prefix"><ng-content select="[icon-prefix]"></ng-content></div>
		<input [class]="panelClass" [ngClass]="{'text-input-placeholder': !panelClass, 'big-text-input-placeholder': panelClass === 'big-input', 'little-text-input-placeholder': panelClass === 'little-input', 'invalid-input': input.invalid && input.dirty, 'disabled-input': input.disabled}"
               [formControl]="input" [type]="type" [placeholder]="placeholder || ''"
               (blur)="blur.emit()" (keyup.enter)="enter.emit()" #inputElement
		       [style.border-bottom-left-radius]="!leftBorder ? '0px' : undefined"
			   [style.border-top-left-radius]="!leftBorder ? '0px' : undefined">
		<div class="flex-center icon-suffix"><ng-content select="[icon-suffix]"></ng-content></div>
	</div>
</div>
