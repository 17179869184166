import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {RadarLoaderComponent} from "../../../design-system/radar-loader/radar-loader.component";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {Subscription, switchMap, timer} from "rxjs";
import {ApplicationAutodiscoverService} from "../../../../services/back/application-autodiscover.service";
import {CurrentTenantService} from "../../../../services/front/current-tenant.service";
import {tap} from "rxjs/operators";
import {ApplicationAutodiscover} from "../../../../services/model/autodiscover.model";
import {ApplicationIconModule} from "../../../global/application-icon/application-icon.module";
import {animate, style, transition, trigger} from "@angular/animations";
import {TenantService} from "../../../../services/tenant.service";
import {ApplicationCreation, HostingType} from "../../../../services/model/new-application.model";

@Component({
  selector: 'app-onboarding-step-6',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule, RadarLoaderComponent, ApplicationIconModule],
  templateUrl: './onboarding-step-6.component.html',
  styleUrl: '../onboarding.component.scss',
	animations: [
		trigger('listAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('300ms', style({ opacity: 1 }))
			]),
			transition(':leave', [
				animate('300ms', style({ opacity: 0 }))
			])
		])
	]
})
export class OnboardingStep6Component {
	constructor(private breakpointObserver: BreakpointObserver,
				private autoDiscoverService: ApplicationAutodiscoverService,
				private currentTenantService: CurrentTenantService,
				private tenantService: TenantService) {
	}

	@Input() isMobile: boolean;

	@Output() onNextClick: EventEmitter<string|void> = new EventEmitter<string|void>();
	@Output() onDismiss = new EventEmitter<void>();

	closeLoading = false;
	submitLoading = false;
	panelClass: string = '';

	subscription: Subscription = new Subscription()

	applications: OnboardingApplication[] = []

	tenantId: string

	ngOnInit() {
		this.subscription.add(this.breakpointObserver
			.observe(['(max-width: 1280px)'])
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
					this.panelClass = ''
				} else {
					this.panelClass = 'big-button'
				}
			}));

		this.subscription.add(this.currentTenantService.getCurrentTenant()
			.pipe(tap((tenant) => this.tenantId = tenant.configuration.id))
			.subscribe(tenant => {
				this.subscription.add(timer(0, 2000)
					.pipe(
						switchMap(() => this.autoDiscoverService.getAllApplicationAutodiscover(tenant.configuration.id)),
						tap((applications) => this.setApplications(applications))
					)
					.subscribe())
			}))
	}

	setApplications(applications: ApplicationAutodiscover[]) {
		applications.forEach((application) => {
			const app = this.applications.find(a => a.application.application.catalogId === application.application.catalogId)
			if (!app) {
				this.applications.push({ application, deleted: false })
			}
		})
	}

	onDelete(application: OnboardingApplication) {
		application.deleted = true
	}

	get notDeletedApplications() {
		return this.applications
			.filter(a => !a.deleted)
	}

	addTenantApplications() {
		if (this.notDeletedApplications.length > 0) {
			this.submitLoading = true

			const request: ApplicationCreation[] =
				this.notDeletedApplications
					.slice(0, 3)
					.map(a => ({
					application: {
						catalogId: a.application.application.catalogId,
						categoryId: a.application.application.categoryId || undefined,
						name: a.application.application.name,
						description: a.application.application.description,
						hostingType: HostingType.SAAS,
						accessUrl: undefined,
						iconUrl: a.application.application.logo,
						tileBgColor: undefined,
						tileFontColor: undefined,
						autoDiscoverId: a.application.autodiscoverId

					}
				}))

			this.tenantService.addOnboardingApplicationsToTenant(this.tenantId, request)
				.subscribe(() => {
					this.onNextClick.emit("apps")
				})
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe()
	}
}

export interface OnboardingApplication {
	application: ApplicationAutodiscover,
	deleted: boolean
}
