import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChipsModule} from "../../../../global/chips/chips/chips.module";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {SimpleTextInputModule} from "../../../../global/input/simple-text-input/simple-text-input.module";
import {TranslateModule} from "@ngx-translate/core";
import {OrganizationType} from "../../../../../services/model/autodiscover.model";
import {CreateOrganizationForm, Organization, OrganizationIcon} from "../../../../../services/organization.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {OrganizationCreationPopupData} from "../organization-list.component";

@Component({
  selector: 'app-create-team',
  standalone: true,
	imports: [CommonModule, ChipsModule, DesignSystemModule, SimpleTextInputModule, TranslateModule],
  templateUrl: './create-team.component.html',
  styleUrl: './create-team.component.scss'
})
export class CreateTeamComponent implements OnInit, OnDestroy {
	constructor(public dialogRef: MatDialogRef<CreateTeamComponent>,
				@Inject(MAT_DIALOG_DATA) public data: OrganizationCreationPopupData) {
	}

	iconChoices: IconChoice[] = [];

	iconSelection: boolean = false;

	teamGroupList: Organization[] = [];
	filteredTeamGroups: Organization[] = [];

	searchTeamGroupControl: FormControl;

	_saving: boolean;

	formGroup: FormGroup;

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.createForm();
		this.buildSelectionList();
		this.teamGroupList = this.data.structures;
		this.searchTeamGroupControl = new FormControl();
		this.filteredTeamGroups = this.teamGroupList;

		this.searchTeamGroupControl = new FormControl('');
		this.subscription.add(this.searchTeamGroupControl.valueChanges.subscribe(value => {
			this.filteredTeamGroups = this.teamGroupList.filter(group => group.name.toLowerCase().includes(value.toLowerCase()));
		}));
	}

	private createForm() {
		this.formGroup = new FormGroup({
			[Form.icon]: new FormControl(undefined),
			[Form.name]: new FormControl(undefined, Validators.required),
		});
	}

	private buildSelectionList() {
		this.iconChoices = [
			{ id: OrganizationIcon.SHARE, name: 'share' },
			{ id: OrganizationIcon.CART, name: 'cart' },
			{ id: OrganizationIcon.FLASK, name: 'flask' },
			{ id: OrganizationIcon.PALETTE, name: 'palette' },
			{ id: OrganizationIcon.CHART_PIE, name: 'chart_pie' },
			{ id: OrganizationIcon.PRESENT, name: 'present' },
			{ id: OrganizationIcon.AWARD, name: 'award' },
			{ id: OrganizationIcon.DIAMOND, name: 'diamond' },
			{ id: OrganizationIcon.GLOB, name: 'glob' },
			{ id: OrganizationIcon.PROCESSOR, name: 'processor' },
			{ id: OrganizationIcon.GAMEPAD, name: 'gamepad' },
			{ id: OrganizationIcon.BOOKMARK, name: 'bookmark' },
			{ id: OrganizationIcon.INVOICE, name: 'invoice' },
			{ id: OrganizationIcon.EXPLORE, name: 'explore' },
			{ id: OrganizationIcon.MOON, name: 'moon' },
			{ id: OrganizationIcon.UNIVERSITY, name: 'university' },
			{ id: OrganizationIcon.LIGHTNING, name: 'lightning' },
			{ id: OrganizationIcon.CHECKBOX, name: 'checkbox' }
		];
	}

	onUpdateIcon(icon: IconChoice) {
		this.formGroup.get(Form.icon)!.setValue(icon.id);
		this.iconSelection = false;
	}

	onNoClick() {
		this.dialogRef.close();
	}

	onCreate() {
		if (this.formGroup.valid) {
			const form: CreateOrganizationForm = {
				name: this.nameFormValue!,
				type: OrganizationType.BUSINESS_UNIT,
				ascendant: this.data.parent.organizationId,
				icon: this.iconFormValue
			}
			this.dialogRef.close(form);
		}
	}

	get iconFormValue(): OrganizationIcon|undefined {
		return this.formGroup.get(Form.icon)!.value;
	}

	get nameFormControl(): FormControl {
		return this.formGroup.get(Form.name) as FormControl;
	}

	get nameFormValue(): string|undefined {
		return this.formGroup.get(Form.name)!.value;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

enum Form {
	icon = 'icon',
	name = 'name',
}

interface IconChoice {
	id: OrganizationIcon,
	name: string,
}
