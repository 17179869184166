<div class="flex gap-M pad-XL-XL-0 w-100 border-box">
    <app-diagram-breadcrumb class="w-100"></app-diagram-breadcrumb>

    <div class="filter-panel-container bkg-white b-radius-9">
        <app-icon-button color="accent" type="stroked" [img]="'assets/icons/settings.svg'" class="filter-panel-btn" panelClass="h-100-imp w-100-imp bkg-transparent"
                         [disabled]="_initializing" [loading]="_loading" [focused]="miniDrawer.opened()"
                         [miniDrawerTriggerFor]="miniDrawer" [matBadge]="activeFilters > 0 ? activeFilters : undefined" matBadgeColor="accent">
        </app-icon-button>
        <app-mini-drawer #miniDrawer>
            <div class="flex-column gap-M" *ngIf="!_loading">
                <div class="flex-column gap-S">
                    <div class="flex-align-center flex-space-between">
                        <span class="font-M fw-700">{{'page.diagram.filters.organization'|translate}}</span>
                        <span *ngIf="activeFilters > 0" class="font-M fw-500 danger underlined-hover c-p"
                              (click)="resetFilters()">{{'button.deleteFilters'|translate}}</span>
                    </div>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.organization))" multiple>
                        <app-select-trigger appearance="field">{{!organizationFormValue.length ? ('page.diagram.filters.allOrganization'|translate) : (organizationFormValue[0].name  + (organizationFormValue.length > 1 ? ' (+' + (organizationFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-search [searchControl]="searchOrganizationControl"></app-select-search>
                        <app-select-option-group *ngFor="let group of filterData.organization" [label]="group.organization.name" noOptionPrefix>
                            <app-select-option *ngFor="let org of group.children" [value]="org.organization">
                                <div class="flex-align-center gap-M">
                                    <img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
                                    <span>{{org.organization.name}}</span>
                                </div>
                            </app-select-option>
                        </app-select-option-group>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-column gap-S">
                    <span class="font-M fw-700">{{'page.diagram.filters.criticality'|translate}}</span>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.criticality))" multiple>
                        <app-select-trigger appearance="field">{{!criticalityFormValue.length ? ('page.diagram.filters.allCriticality'|translate) : ((('page.appDetails.businessCriticality.' + criticalityFormValue[0].toLowerCase())|translate) + (criticalityFormValue.length > 1 ? ' (+' + (criticalityFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-option *ngFor="let level of filterData.criticality" [value]="level">
                            <div class="flex-align-center gap-M">
                                <img src="assets/icons/criticality-{{level.toLowerCase()}}.svg" height="16" alt=""/>
                                <span>{{('page.appDetails.businessCriticality.' + level.toLowerCase())|translate}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-column gap-S">
                    <span class="font-M fw-700">{{'page.diagram.filters.category'|translate}}</span>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.category))" multiple>
                        <app-select-trigger appearance="field">{{!categoryFormValue.length ? ('page.diagram.filters.allCategory'|translate) : (categoryFormValue[0].name  + (categoryFormValue.length > 1 ? ' (+' + (categoryFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-search [searchControl]="searchCategoryControl"></app-select-search>
                        <app-select-option *ngFor="let category of filterData.category" [value]="category">
                            {{category.name}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
				<div class="flex-column gap-S">
					<span class="font-M fw-700">{{'page.application.table.filters.status'|translate}}</span>
					<app-multi-select [inputControl]="$any(filterForm.get(formName.status))" multiple>
						<app-select-trigger appearance="field">{{!$any(filterForm.get(formName.status)).value[0] ? '-' : ((('page.appDetails.lifeCycle.' + $any(filterForm.get(formName.status)).value[0])|translate) + ($any(filterForm.get(formName.status)).value.length > 1 ? ' (+' + ($any(filterForm.get(formName.status)).value.length - 1) + ')' : ''))}}</app-select-trigger>
						<app-select-option *ngFor="let appStatus of filterData.status" [value]="appStatus">
							<app-display-app-status [appStatus]="appStatus"></app-display-app-status>
						</app-select-option>
					</app-multi-select>
				</div>
				<div class="border-light-grey-top w-100"></div>
				<div class="flex-column gap-S">
					<span class="font-M fw-700">{{'page.application.table.filters.tags'|translate}}</span>
					<app-multi-select [inputControl]="$any(filterForm.get(formName.tag))" multiple>
						<app-select-trigger appearance="field">{{!$any(filterForm.get(formName.tag)).value[0] ? '-' : ($any(filterForm.get(formName.tag)).value[0].name  + ($any(filterForm.get(formName.tag)).value.length > 1 ? ' (+' + ($any(filterForm.get(formName.tag)).value.length - 1) + ')' : ''))}}</app-select-trigger>
						<app-select-option *ngFor="let tag of filterData.tag" [value]="tag">
							{{tag.name}}
						</app-select-option>
					</app-multi-select>
				</div>
				<div class="border-light-grey-top w-100"></div>
                <div class="flex-align-center flex-space-between gap-S">
                    <div class="font-M fw-700">{{'page.diagram.filters.hideEmptyCategories'|translate}}</div>
                    <app-toggle-button [toggleInput]="$any(filterForm.get(formName.hideEmptyCategory))"></app-toggle-button>
                </div>
            </div>
        </app-mini-drawer>
    </div>

    <div style="min-width: 100px;">
        <app-text-button type="flat" panelClass="h-100-imp" [tooltip]="'page.diagram.filters.exportButton'|translate"
                         [disabled]="_initializing || _loading || _refreshing || isEmpty === true" [loading]="_exporting"
                         (click)="exportToPdf()">
            <img src="assets/icons/share.svg" alt="share">
        </app-text-button>
    </div>
</div>

<div class="pad-left-XL mt-xl" style="height: calc(100% - 187px);">
    <div class="flex" style="overflow: auto; height: 100%;" [class.none-events]="_exporting" #scrollContainer
         appPinchZoom [scaleFactor]="0.1" [zoomThreshold]="3" [initialZoom]="INITIAL_ZOOM"
         (onZoom)="onZoom($event)">
        <div class="flex" style="height: max-content; width: max-content" id="pdf-content" #pdfContent>
            <div class="flex-column p-sticky" [class.bkg-light-grey-2]="!_exporting" style="left: 0; height: fit-content; z-index: 2;">
                <div class="flex pad-M p-sticky" [class.bkg-light-grey-2]="!_exporting" style="min-height: 30px; top: 0; min-width: 170px; max-width: 170px; height: 33px; z-index: 2;"></div>
                <div class="flex-column gap-M">
                    @for (category of displayedCategories; track category.categoryId) {
                        <div class="flex-space-between flex-align-start category-first pad-M" style="min-width: 169px; max-width: 169px;"
                             [style.height]="'calc(' + (168 * unit) + 'px - 30px)'"
                             [style.border-bottom-left-radius.px]="18 * unit" [style.border-top-left-radius.px]="18 * unit">
                            <span class="font-MM fw-700 accent-secondary">{{category.name}}</span>
                            <img src="assets/icons/minus-circle.svg" height="20" class="c-p filter-accent-secondary" style="visibility: hidden;" alt=""
                                 (click)="filterOutCategory(category.categoryId)">
                        </div>
                    }
                </div>
            </div>
            <div class="flex-column" style="height: fit-content; z-index: 1;">
                <div class="flex p-sticky" [class.bkg-light-grey-2]="!_exporting" style="min-height: 60px; top: 0; z-index: 2;">
                    @for (structure of displayedOrganizations; track structure.organization.organizationId) {
                        @for (team of structure.children; track team.organization.organizationId) {
                            <div class="flex-space-between flex-align-start border-grey-left team-header" style="height: 33px;"
                                 [style.min-width.px]="320 * unit" [style.max-width.px]="320 * unit"
                                 [style.padding-left.px]="15 * unit" [style.padding-right.px]="15 * unit">
                                <div class="flex-column flex-justify-center" style="width: calc(100% - 24px);">
                                    <span class="font-MM fw-700 text-overflow-ellipsis">{{ team.organization.name }}</span>
                                    <span class="font-M fw-500 text-overflow-ellipsis">{{ structure.organization.name }}</span>
                                </div>
                                <img src="assets/icons/minus-circle.svg" height="20" class="c-p filter-grey" style="visibility: hidden;" alt=""
                                     (click)="filterOutTeam(team.organization.organizationId)">
                            </div>
                        }
                    }
                </div>
                <div class="flex-column">
                    @for (category of displayedCategories; track category.categoryId; let last = $last) {
                        <div class="flex category-row" [style.height.px]="168 * unit" [style.border-bottom-right-radius.px]="18 * unit" [style.border-top-right-radius.px]="18 * unit">
                            @for (structure of displayedOrganizations; track structure.organization.organizationId) {
                                @for (team of structure.children; track team.organization.organizationId) {
                                    <div class="flex-wrap flex-align-start border-grey-left" style=""
                                         [style.min-width.px]="320 * unit" [style.max-width.px]="320 * unit"
                                         [style.padding-left.px]="15 * unit" [style.padding-right.px]="15 * unit"
                                         [style.padding-top.px]="25 * unit" [style.padding-bottom.px]="25 * unit" [style.gap.px]="15 * unit">
                                        @for (app of applications[category.categoryId][team.organization.organizationId]; track app.id; let i = $index; let last = $last) {
                                            @if (i < 9 || (i === 9 && last)) {
                                                <div class="flex-center bkg-white c-p" style="border: 1px solid #DADADA;"
                                                     [style.width]="'calc(' + (51 * unit) + 'px - 2px)'"
                                                     [style.height]="'calc(' + (51 * unit) + 'px - 2px)'"
                                                     [style.border-radius.px]="15 * unit"
                                                     [style.box-shadow]="_exporting ? 'none' : '4px 4px 4px 0 #0000001A'"
                                                     [tooltip]="app.name" tooltipPosition="top"
                                                     (click)="openApplicationDetail(app.id)">
                                                    <app-display-logo [logo]="app.logo" [size]="38 * unit"></app-display-logo>
                                                    @if (app.criticality) {
                                                        <div class="flex-center p-relative b-radius-half bkg-white border-danger"
                                                             [style.margin-top.px]="38 * unit" [style.margin-left.px]="-8 * unit" [style.margin-right.px]="-13 * unit" [style.width.px]="20 * unit" [style.height.px]="20 * unit"
                                                             [class.border-danger]="app.criticality === level.HIGH" [class.border-warning]="app.criticality === level.MEDIUM" [class.border-accent]="app.criticality === level.LOW">
                                                            @if (app.criticality === level.HIGH) {
                                                                <img src="assets/icons/criticality-high.svg" [height]="11 * unit" alt="">
                                                            } @else if (app.criticality === level.MEDIUM) {
                                                                <img src="assets/icons/criticality-medium.svg" [height]="11 * unit" alt="">
                                                            } @else if (app.criticality === level.LOW) {
                                                                <img src="assets/icons/criticality-low.svg" [height]="11 * unit" alt="">
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            } @else if (i === 9 && !last) {
                                                <div class="flex-center c-p app-cluster"
                                                     [style.width]="'calc(' + (51 * unit) + 'px - 2px)'"
                                                     [style.height]="'calc(' + (51 * unit) + 'px - 2px)'"
                                                     [style.border-radius.px]="15 * unit"
                                                     [matMenuTriggerFor]="clusterMenu">
                                                    <span class="fw-700 accent-secondary" [style.font-size.px]="14 * unit">+{{applications[category.categoryId][team.organization.organizationId].length - 9}}</span>
                                                </div>
                                                <mat-menu class="quadrant-mat-menu" #clusterMenu="matMenu">
                                                    <div class="flex-column" style="min-width: 200px;">
                                                        <div class="c-p app-option"
                                                             *ngFor="let app of applications[category.categoryId][team.organization.organizationId].slice(9, applications[category.categoryId][team.organization.organizationId].length)"
                                                             (click)="openApplicationDetail(app.id)">
                                                            <app-display-application [application]="app" criticality></app-display-application>
                                                        </div>
                                                    </div>
                                                </mat-menu>
                                            }
                                        }
                                    </div>
                                }
                            }
                        </div>
                        <div class="flex" *ngIf="!last">
                            @for (structure of displayedOrganizations; track structure.organization.organizationId) {
                                @for (team of structure.children; track team.organization.organizationId) {
                                    <div class="border-grey-left" style="height: 15px;"
                                         [style.min-width.px]="320 * unit" [style.max-width.px]="320 * unit"
                                         [style.padding-left.px]="15 * unit" [style.padding-right.px]="15 * unit"></div>
                                }
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
