import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {ApplicationDocument, FileLinkApplicationForm} from 'src/app/services/model/application-document.model';

@Injectable()
export class ApplicationDocumentService {

  private serviceUrl = (tenantId: string, applicationId: string) => `${environment.engineURL}/tenants/${tenantId}/applications/${applicationId}/documents`;

  constructor(protected httpClient: HttpClient) {
  }

  public getAllApplicationDocument(tenantId: string, applicationId: string): Observable<ApplicationDocument[]> {
    return this.httpClient.get<ApplicationDocument[]>(`${this.serviceUrl(tenantId, applicationId)}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  public createApplicationDocument(tenantId: string, applicationId: string, form: FileLinkApplicationForm): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.serviceUrl(tenantId, applicationId)}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  public updateApplicationDocument(tenantId: string, applicationId: string, fileLinkId: string, form: FileLinkApplicationForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId, applicationId)}/${fileLinkId}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  public deleteApplicationDocument(tenantId: string, applicationId: string, fileLinkId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId, applicationId)}/${fileLinkId}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }
}
