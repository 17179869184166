import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApplicationStatistic} from 'src/app/services/application-instance.service';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationUrlService} from 'src/app/services/front/application-url.service';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {ApplicationInstance} from 'src/app/services/model/new-application.model';
import {TendencyWidgetContent} from 'src/app/modules/global/application-mini-widget/application-mini-widget.component';
import {NewApplicationService} from 'src/app/services/back/new-application.service';

@Component({
	selector: 'app-overview-tab',
	templateUrl: './overview-tab.component.html',
	styleUrls: ['./overview-tab.component.scss']
})
export class OverviewTabComponent implements OnInit, OnDestroy {

	@Input() tabActive: boolean;

	tenantId: string;
	isEditor: boolean;
	application: ApplicationInstance;
	isTenantInactivePlan: boolean;

	_initializing: boolean;
	_loading: boolean;
	initialized: boolean = false;

	appOverviewDrawer: ApplicationStatistic;

	tcoWidget: TendencyWidgetContent|undefined;
	availabilityWidget: TendencyWidgetContent|undefined;
	applicationUsersWidget: TendencyWidgetContent|undefined;

	subscription: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationUrlService: ApplicationUrlService,
							private applicationService: NewApplicationService) {
	}

	ngOnInit() {
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.applicationService.getApplicationStatistic(this.tenantId, this.application.applicationId)),
				tap(appOverview => this.setWidgetData(appOverview)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.application = data.instance;
		this.isTenantInactivePlan = data.isInactivePlan;
	}


	private setWidgetData(data: ApplicationStatistic): void {
		this.appOverviewDrawer = data;
		this.availabilityWidget = {
			value: data.health.percent,
			day: data.health.queryType
		};
		this.applicationUsersWidget = {
			value: !this.application.usageActivated ? undefined : data.usage.value,
			day: data.usage.queryType
		};
		this.tcoWidget = {
			value: data.cost.value,
			day: data.cost.queryType
		};
	}

	navigateToUsageParameter(): void {
		if (!this.application.usageActivated) {
			this.applicationUrlService.navigateInAndOutOfParameter(true);
		}
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
