import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractType, ContractTypeCost} from "../../../../../../services/model/application-contract.model";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {CostPipe} from "../../../../../../pipes/number/cost.pipe";
import {BaseChartDirective, NgChartsModule} from "ng2-charts";
import {TranslateModule} from "@ngx-translate/core";
import {ChartData, ChartOptions} from "chart.js";
import {Subscription} from "rxjs";
import {ColorEnum} from "../../../../../../../_variables";

@Component({
  selector: 'app-organization-cost-distribution',
  standalone: true,
	imports: [CommonModule, ContentLoaderModule, CostPipe, NgChartsModule, TranslateModule],
  templateUrl: './organization-cost-distribution.component.html',
  styleUrl: './organization-cost-distribution.component.scss'
})
export class OrganizationCostDistributionComponent implements OnChanges {
	@Input() data: ContractTypeCost[] | undefined;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	doughnutChartData: ChartData<'doughnut'>;
	chartOptions: ChartOptions<'doughnut'>;

	subscriptions: Subscription = new Subscription();

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.currentValue) {
			this.setChartData();
			this.setYearlyFinancialCosts(this.data!);
		}
	}

	private setChartData() {
		this.doughnutChartData = {
			labels: [],
			datasets: [
				{
					data: [ 0, 0 ],
					backgroundColor: [ColorEnum.accent, ColorEnum.chart_yellow],
					borderColor: [ColorEnum.accent, ColorEnum.chart_yellow],
					borderRadius: 5,
					spacing: 2
				}
			],
		};
		this.chartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			events: [],
			cutout: '65%',
			elements: {
				arc: {
					borderWidth: 1,
				}
			}
		};
	}

	private setYearlyFinancialCosts(contractTypeCosts: ContractTypeCost[]): void {
		const licenseCost: number = (this.data!.find(cost => cost.contractType === ContractType.LICENSE)?.cost.value || 0) + (this.data!.find(cost => cost.contractType === ContractType.SUBSCRIPTION)?.cost.value || 0);
		const serviceCost: number = (this.data!.find(cost => cost.contractType === ContractType.SERVICE)?.cost.value || 0);
		const total = licenseCost + serviceCost;

		this.doughnutChartData.datasets[0].data = [];
		this.clearDoughnutColor();

		this.doughnutChartData.datasets[0].data.push(licenseCost);
		this.addDoughnutColor(ColorEnum.licence);

		this.doughnutChartData.datasets[0].data.push(serviceCost);
		this.addDoughnutColor(ColorEnum.service);

		if (total === 0) {
			this.doughnutChartData.datasets[0].data.push(1);
			this.addDoughnutColor(ColorEnum.medium_grey);
		}

		if (this.chart) {
			this.chart.update();
		}
	}

	private addDoughnutColor(color:string) {
		(this.doughnutChartData.datasets[0]?.backgroundColor as string[]).push(color);
		(this.doughnutChartData.datasets[0]?.borderColor as string[]).push(color);
	}

	private clearDoughnutColor() {
		this.doughnutChartData.datasets[0].backgroundColor = [];
		this.doughnutChartData.datasets[0].borderColor = [];
	}

	getTotalCost(data: ContractTypeCost[]): number {
		return data.reduce((acc, curr) => acc + (curr.cost.value || 0), 0);
	}

	getLicencePercentage(data: ContractTypeCost[]): number {
		const total = this.getTotalCost(data);
		const license = (data.find(c => c.contractType === ContractType.LICENSE)?.cost.value || 0) + (data.find(c => c.contractType === ContractType.SUBSCRIPTION)?.cost.value || 0);
		return Math.round(license / total * 100);
	}

	getServicePercentage(data: ContractTypeCost[]): number {
		const total = this.getTotalCost(data);
		const service = data.find(c => c.contractType === ContractType.SERVICE)?.cost.value || 0;
		return Math.round(service / total * 100);
	}

	get year() {
		return new Date().getFullYear();
	}
}
