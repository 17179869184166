import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from '@angular/forms';
import {distinctUntilChanged, finalize, forkJoin, interval, merge, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationElasticTableData, ApplicationExport, ApplicationSortColumn, ApplicationStatus, ApplicationTableData, ApplicationTableFilterForm, Health, HealthStatus, HostingType} from 'src/app/services/model/new-application.model';
import {ApplicationAutodiscover} from 'src/app/services/model/autodiscover.model';
import {ApplicationDetailComponent, ApplicationDetailInput} from 'src/app/modules/home/applications/application-detail/application-detail.component';
import {AutodiscoverComponent, AutoDiscoverComponentData} from 'src/app/modules/home/applications/autodiscover/autodiscover.component';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {ApplicationAutodiscoverService} from 'src/app/services/back/application-autodiscover.service';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {debounceTime, filter, first, map, take} from 'rxjs/operators';
import {CatalogComponent, CatalogDialogResult} from 'src/app/modules/home/applications/catalog-v2/catalog.component';
import {MatDialog} from '@angular/material/dialog';
import {CriticalityLevel} from 'src/app/services/tenant.service';
import {Category} from 'src/app/services/model/application-category.model';
import {Organization, OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';
import {ApplicationCategoryService} from 'src/app/services/back/application-category.service';
import {TenantAccount} from 'src/app/services/model/account.model';
import {ActivatedRoute, Router} from '@angular/router';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {Usage} from 'src/app/services/model/application-contract.model';
import {CatalogTag} from 'src/app/services/model/catalog-tag.model';
import {ExportService, ExportType} from 'src/app/services/front/export.service';

@Component({
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent implements OnInit, OnDestroy {

  tenantId: string;

  _initializing: boolean;
  _loading: boolean;
  _loadingFilter: boolean;
  _loadingAutoDiscover: boolean;
  _exporting: boolean;

  elasticData: ApplicationElasticTableData[] = [];
  applicationRows: ApplicationRow[] = [];
  autodiscoverList: ApplicationAutodiscover[] = [];
  accountList: TenantAccount[] = [];
  organizationList: OrganizationTree[] = [];
  categoryList: Category[] = [];
  globalAppCount?: number;

  openSearch: boolean = false;
  openFilters: boolean = false;
  filterForm: FormGroup;
  list: {
    criticality: CriticalityLevel[],
    organization: OrganizationTree[],
    account: TenantAccount[],
    hostingType: HostingType[],
    category: Category[],
    tag: CatalogTag[],
    status: ApplicationStatus[]
  };
  activeFilters: ApplicationFilter[] = [];
  form: typeof Form = Form;
  status: typeof ApplicationStatus = ApplicationStatus;
  column: typeof ApplicationSortColumn = ApplicationSortColumn;
  direction: typeof SortDirection = SortDirection;

  readonly FIRST_SIZE: number = 20;
  readonly MAX_SIZE: number = 1000;

  refreshSub: Subscription = new Subscription();
  loadingSub: Subscription = new Subscription();
  subscription: Subscription = new Subscription();

  constructor(private applicationDiscoveredService: ApplicationAutodiscoverService,
              private applicationCategoryService: ApplicationCategoryService,
              private currentTenantService: CurrentTenantService,
              private organizationService: OrganizationService,
              private applicationService: NewApplicationService,
              private rightSliderService: RightSliderService,
              private changeDetectorRef: ChangeDetectorRef,
              private tenantAccountService: TenantAccountService,
              private activatedRoute: ActivatedRoute,
              private exportService: ExportService,
              private topbarService: TopbarService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit(): void {
    this.setAppCount();
    this.createForm();
    this.subscription.add(this.currentTenantService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
      .pipe(tap(tenantId => this.tenantId = tenantId))
      .subscribe(() => this.initialize()));
    this.subscription.add(this.currentTenantService.getApplicationCountChanges()
      .subscribe(count => this.setAppCount(count)));
    this.subscription.add(this.activatedRoute.queryParams
      .pipe(filter(params => params?.applicationId), first())
      .subscribe(params => this.openApplicationDrawer(params.applicationId)));
  }

  private setAppCount(count?: number): void {
	  this.globalAppCount = count;
    this.topbarService.onTitleChange(
      this.translate.instant('menu.applications'),
      this.translate.instant('menu.subtitle.applications'),
      count !== undefined && count > 0 ? count.toString() : '-'
    );
  }

  private createForm(): void {
    this.filterForm = new FormGroup({
      [Form.name]: new FormControl(''),
      [Form.criticality]: new FormControl([]),
      [Form.hostingType]: new FormControl([]),
      [Form.status]: new FormControl([]),
      [Form.tag]: new FormControl([]),
      [Form.organization]: new FormControl([]),
      [Form.searchOrganization]: new FormControl(''),
      [Form.account]: new FormControl([]),
      [Form.searchAccount]: new FormControl(''),
      [Form.category]: new FormControl([]),
      [Form.searchCategory]: new FormControl(''),
      [Form.sort]: new FormControl({ column: ApplicationSortColumn.NAME, direction: SortDirection.ASC }),
    });
    this.subscription.add(merge(
      this.filterForm.get(Form.name)!.valueChanges
        .pipe(debounceTime(200), distinctUntilChanged()),
      this.filterForm.get(Form.criticality)!.valueChanges,
      this.filterForm.get(Form.status)!.valueChanges,
      this.filterForm.get(Form.tag)!.valueChanges,
      this.filterForm.get(Form.organization)!.valueChanges,
      this.filterForm.get(Form.account)!.valueChanges,
      this.filterForm.get(Form.hostingType)!.valueChanges,
      this.filterForm.get(Form.category)!.valueChanges,
      this.filterForm.get(Form.sort)!.valueChanges)
      .pipe(
        debounceTime(100),
        tap(() => this.setActiveFilters()),
        switchMap(() => this.fetchAllApplicationByFilter()))
      .subscribe());
    this.subscription.add(this.filterForm.get(Form.searchCategory)!.valueChanges
      .subscribe(search => this.searchCategoryFilter(search)));
    this.subscription.add(this.filterForm.get(Form.searchAccount)!.valueChanges
      .subscribe(search => this.searchAccountFilter(search)));
    this.subscription.add(this.filterForm.get(Form.searchOrganization)!.valueChanges
      .subscribe(search => this.searchOrganizationFilter(search)));
  }

  initialize(): void {
    this.applicationRows = [];
    this.clearFilters(false);
    this.fetchElasticTableData();
    this.fetchProgressiveApplicationTableData();
    this.fetchFilterPanelData();
    this.fetchAutodiscoverList();
  }

  private fetchElasticTableData(): void {
    this.subscription.add(this.applicationService.getAllApplicationElasticTableData(this.tenantId)
      .subscribe(elasticData => this.setElasticData(elasticData)));
  }

  private fetchProgressiveApplicationTableData(): void {
    this.loadingSub = this.setLoading(true)
      .pipe(
        map(() => this.buildApplicationTableFilterForm(this.FIRST_SIZE)),
        switchMap(form => this.applicationService.getAllApplicationTableData(this.tenantId, form)),
        tap(applications => this.setApplicationRows(applications)),
        map(() => this.buildApplicationTableFilterForm(this.MAX_SIZE, this.FIRST_SIZE)),
        switchMap(form => this.applicationService.getAllApplicationTableData(this.tenantId, form)),
        tap(applications => this.pushApplicationRows(applications)),
        finalize(() => this.setLoading(false)))
      .subscribe();
  }

  fetchAllApplicationByFilter(loading = true): Observable<{}> {
    this.loadingSub.unsubscribe();
    return this.setLoading(loading).pipe(
      map(()=> this.buildApplicationTableFilterForm()),
      switchMap(form => this.applicationService.getAllApplicationTableData(this.tenantId, form)),
      tap(applications => this.setApplicationRows(applications)),
      switchMap(() => this.setLoading(false)));
  }

  private buildApplicationTableFilterForm(limit: number = this.MAX_SIZE, offset: number = 0): ApplicationTableFilterForm {
    return {
      name: this.filterForm.get(Form.name)!.value?.trim().length > 0
        ? this.filterForm.get(Form.name)!.value : null,
      category: (this.filterForm.get(Form.category)!.value as Category[])
        .map(c => c.categoryId),
      criticality: (this.filterForm.get(Form.criticality)!.value as CriticalityLevel[]),
      organization: (this.filterForm.get(Form.organization)!.value as Organization[])
        .map(o => o.organizationId),
      responsible: (this.filterForm.get(Form.account)!.value as TenantAccount[])
        .map(r => r.account.accountId),
      hostingType: (this.filterForm.get(Form.hostingType)!.value as HostingType[]),
      status: (this.filterForm.get(Form.status)!.value as ApplicationStatus[]),
      tag: (this.filterForm.get(Form.tag)!.value as CatalogTag[])
        .map(t => t.tagId),
      sortDirection: this.sortFormValue.direction,
      sortColumn: this.sortFormValue.column,
      limit: limit,
      offset: offset
    };
  }

  private setElasticData(elasticData: ApplicationElasticTableData[]): void {
    this.elasticData = elasticData;
    if (this.applicationRows.length > 0) {
      this.applicationRows = this.applicationRows
        .map(app => this.buildApplicationRows(app));
      this.applicationRows.sort(this.sortData);
    }
  }

  private setApplicationRows(applications: ApplicationTableData[]): void {
    this.applicationRows = applications
      .map(app => this.buildApplicationRows(app));
    this.applicationRows.sort(this.sortData);
  }

  private pushApplicationRows(applications: ApplicationTableData[]): void {
    this.applicationRows.push(...applications
      .map(app => this.buildApplicationRows(app)));
    this.applicationRows.sort(this.sortData);
  }

  private buildApplicationRows(app: ApplicationTableData): ApplicationRow {
    const elasticData: ApplicationElasticTableData|undefined = this.elasticData.find(d => d.applicationId === app.instance.applicationId);
    return {
      instance: app.instance,
      category: app.category,
      cost: app.cost,
      usage: !app.instance.usageActivated ? undefined : elasticData?.usage,
      health: !app.instance.usageActivated ? undefined : (elasticData?.health ?? { percent: 100, status: HealthStatus.GOOD, queryType: QueryRangeType.PAST_1_MONTH }),
    };
  }

  private fetchFilterPanelData(): void {
    this.subscription.add(this.switchLoadingFilter()
      .pipe(
        switchMap(() => forkJoin([
          this.organizationService.getOrganizationTreeByTenantId(this.tenantId),
          this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId),
          this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId),
          this.applicationService.getAllApplicationTagByTenantId(this.tenantId)
        ])),
        tap(([o, u, c, t]) => this.buildFilterPanel(o, u, c, t)),
        finalize(() => this.switchLoadingFilter()))
      .subscribe());
  }

  private buildFilterPanel(organization: OrganizationTree, users: TenantAccount[], categories: Category[], tags: CatalogTag[]): void {
    this.accountList = users;
    this.organizationList = organization.children
      .filter(c => c.children.length > 0)
      .sort((a, b) => a.organization.name.localeCompare(b.organization.name));
    this.organizationList.forEach(o => o.children.sort((a, b) => a.organization.name.localeCompare(b.organization.name)));
    this.categoryList = categories
      .sort((a, b) => a.name.localeCompare(b.name));
    this.list = {
      criticality: Object.values(CriticalityLevel),
      organization: this.organizationList,
      account: this.accountList,
      hostingType: Object.values(HostingType),
      category: this.categoryList,
      tag: tags,
      status: Object.values(ApplicationStatus)
    }
  }

  private fetchAutodiscoverList(): void {
    this.subscription.add(this.switchLoadingAutodiscover()
      .pipe(
        switchMap(() => this.applicationDiscoveredService.getAllApplicationAutodiscover(this.tenantId)),
        tap(autodiscoverList => this.autodiscoverList = autodiscoverList),
        finalize(() => this.switchLoadingAutodiscover()))
      .subscribe());
  }

  sortBy(column: ApplicationSortColumn): void {
    const sort: ApplicationSort = {
      column: column,
      direction: this.sortFormValue.column === column && this.sortFormValue.direction === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    }
    if (!this._loading || column === ApplicationSortColumn.USAGE || column === ApplicationSortColumn.HEALTH) {
      this.filterForm.get(Form.sort)!.setValue(sort, {emitEvent: false});
      this.applicationRows = this.applicationRows.sort(this.sortData);
    } else {
      this.filterForm.get(Form.sort)!.setValue(sort);
    }
  }

  private sortData = (data1: ApplicationRow, data2: ApplicationRow): number => {
    const direction: number = this.sortFormValue.direction === SortDirection.ASC ? 1 : -1;
    switch (this.sortFormValue.column) {
      case ApplicationSortColumn.NAME:
        return data1.instance.name.localeCompare(data2.instance.name) * direction;
      case ApplicationSortColumn.CATEGORY:
        return ((data1.category?.name ?? 'zzzzz').localeCompare(data2.category?.name ?? 'zzzzz') * direction);
      case ApplicationSortColumn.CRITICALITY:
        const order: (CriticalityLevel|null|undefined)[] = [CriticalityLevel.HIGH, CriticalityLevel.MEDIUM, CriticalityLevel.LOW, null, undefined];
        return (order.indexOf(data2.instance.criticality) - order.indexOf(data1.instance.criticality)) * direction;
      case ApplicationSortColumn.USAGE:
        return data1.usage?.value !== data2.usage?.value
          ? ((data1.usage?.value ?? -1) > (data2.usage?.value ?? -1) ? 1 : -1) * direction
          : (data1.instance.name.localeCompare(data2.instance.name));
      case ApplicationSortColumn.HEALTH:
        return ((data1.health?.percent ?? -1) > (data2.health?.percent ?? -1) ? 1 : -1) * direction;
      case ApplicationSortColumn.COST:
        return ((data1.cost.value ?? -1) > (data2.cost.value ?? -1) ? 1 : -1) * direction;
      default:
        return 0;
    }
  }

  get sortFormValue(): ApplicationSort {
    return this.filterForm.get(Form.sort)!.value;
  }

  private setActiveFilters(): void {
    this.activeFilters = [
      ...(this.filterForm.get(Form.criticality)!.value as CriticalityLevel[])
        .map(c => ({form: Form.criticality, id: c, value: this.translate.instant('page.appDetails.businessCriticality.' + c.toLowerCase())})),
      ...(this.filterForm.get(Form.organization)!.value as Organization[])
        .map(c => ({form: Form.organization, id: c.organizationId, value: c.name})),
      ...(this.filterForm.get(Form.account)!.value as TenantAccount[])
        .map(c => ({form: Form.account, id: c.account.accountId, value: c.account.lastName + ' ' + c.account.firstName})),
      ...(this.filterForm.get(Form.hostingType)!.value as HostingType[])
        .map(c => ({form: Form.hostingType, id: c, value: this.translate.instant('page.dashboard.typology.' + c)})),
      ...(this.filterForm.get(Form.category)!.value as Category[])
        .map(c => ({form: Form.category, id: c.categoryId, value: c.name})),
      ...(this.filterForm.get(Form.status)!.value as ApplicationStatus[])
        .map(c => ({form: Form.status, id: c, value: this.translate.instant('page.appDetails.lifeCycle.' + c)})),
      ...(this.filterForm.get(Form.tag)!.value as CatalogTag[])
        .map(c => ({form: Form.tag, id: c.tagId, value: c.name})),
    ];
    if (this.activeFilters.length <= 0) {
      this.openFilters = false;
    }
  }

  removeFilter(filter: ApplicationFilter) {
    switch (filter.form) {
      case Form.category:
        const categoryList: Category[] = (this.filterForm.get(Form.category)!.value as Category[])
          .filter(c => c.categoryId !== filter.id)
        this.filterForm.get(Form.category)!.setValue(categoryList);
        break;
      case Form.criticality:
        const criticalityList: CriticalityLevel[] = (this.filterForm.get(Form.criticality)!.value as CriticalityLevel[])
          .filter(c => c !== filter.id)
        this.filterForm.get(Form.criticality)!.setValue(criticalityList);
        break;
      case Form.hostingType:
        const typeList: HostingType[] = (this.filterForm.get(Form.hostingType)!.value as HostingType[])
          .filter(c => c !== filter.id)
        this.filterForm.get(Form.hostingType)!.setValue(typeList);
        break;
      case Form.status:
        const statusList: ApplicationStatus[] = (this.filterForm.get(Form.status)!.value as ApplicationStatus[])
          .filter(s => s !== filter.id)
        this.filterForm.get(Form.status)!.setValue(statusList);
        break;
      case Form.tag:
        const tagList: CatalogTag[] = (this.filterForm.get(Form.tag)!.value as CatalogTag[])
          .filter(t => t.tagId !== filter.id)
        this.filterForm.get(Form.tag)!.setValue(tagList);
        break;
      case Form.organization:
        const organizationList: Organization[] = (this.filterForm.get(Form.organization)!.value as Organization[])
          .filter(c => c.organizationId !== filter.id)
        this.filterForm.get(Form.organization)!.setValue(organizationList);
        break;
      case Form.account:
        const responsibleList: TenantAccount[] = (this.filterForm.get(Form.account)!.value as TenantAccount[])
          .filter(c => c.account.accountId !== filter.id)
        this.filterForm.get(Form.account)!.setValue(responsibleList);
        break;
      default:
        break;
    }
    this.forceRefresh();
  }

  clearFilters(emitEvent: boolean = true): void {
    this.openSearch = false;
    this.openFilters = false;
    this.filterForm.setValue({
      [Form.name]: '',
      [Form.criticality]: [],
      [Form.hostingType]: [],
      [Form.status]: [],
      [Form.tag]: [],
      [Form.organization]: [],
      [Form.searchOrganization]: '',
      [Form.account]: [],
      [Form.searchAccount]: '',
      [Form.category]: [],
      [Form.searchCategory]: '',
      [Form.sort]: this.sortFormValue
    }, {emitEvent: emitEvent});
  }

  openSearchPanel(input: HTMLInputElement): void {
    this.openSearch = !this.openSearch;
    if (this.openSearch) {
      input.focus();
    } else {
      this.filterForm.get(Form.name)!.setValue('');
    }
    this.forceRefresh();
  }

  clearInput() {
	  this.filterForm.get(Form.name)!.setValue('');
  }

  openAutoDiscoverDrawer(): void {
    const data: AutoDiscoverComponentData = {
      discoveredApplications: this.autodiscoverList
    }
    this.rightSliderService.openComponent(AutodiscoverComponent, data)
      .pipe(switchMap(() => this.fetchAllApplicationByFilter()))
      .subscribe();
  }

  openCreateApplication(): void {
    this.dialog.open<CatalogComponent, any, CatalogDialogResult>(CatalogComponent).afterClosed()
      .pipe(
        filter(result => !!result?.success),
        switchMap(() => this.fetchAllApplicationByFilter()))
      .subscribe();
  }

  resetQueryParams(): Promise<boolean> {
	  return this.router.navigate([], {
		  queryParams: {
			  'selectedApplicationTab': null,
			  'settings': null,
		  },
		  queryParamsHandling: 'merge'
	  })
  }

  exportCsv = () => this.export(ExportType.CSV);
  exportXlsx = () => this.export(ExportType.XLSX);
  private export(type: ExportType): void {
    this.switchExporting()
      .pipe(
        map(() => this.applicationRows.map(a => a.instance.applicationId)),
        switchMap(ids => this.applicationService.getAllApplicationExportDataByIds(this.tenantId, ids)),
        map(data => this.buildApplicationExportData(data)),
        tap(data => this.exportService.export(type, data, 'applications')),
        finalize(() => this.switchExporting()))
      .subscribe();
  }

  private buildApplicationExportData(data: ApplicationExport[]): ApplicationExportCsv[] {
    return data.map(app => ({
      name: app.instance.name,
      description: app.instance.description ?? '',
      criticality: app.instance.criticality ? this.translate.instant('page.appDetails.businessCriticality.' + app.instance.criticality.toLowerCase()) : '',
      category: app.category?.name ?? '',
      health: app.health.status ? this.translate.instant('page.dashboard.typology.' + app.health.status) : '',
      usage: app.usage.value ? app.usage.value.toString() : '',
      cost: app.cost.value ? app.cost.value.toString() + ' ' + localStorage.getItem('currency') : '',
      supportEmail: app.instance.supportEmail ?? '',
      supportPhone: app.instance.supportPhone ?? '',
      url: app.instance.accessUrl ?? '',
      servers: app.servers.map(s => s.name).join(', '),
      technologies: app.technologies.map(t => t.technology.name).join(', ')
    }));
  }

  openApplicationDrawer(applicationId: string): void {
    const data: ApplicationDetailInput = {
      applicationId: applicationId
    };
    this.resetQueryParams()
      .then(() => this.rightSliderService.openComponent(ApplicationDetailComponent, data)
        .pipe(switchMap(() => this.fetchAllApplicationByFilter(false)))
        .subscribe());
  }

  get pendingAutodiscoverAppCount(): number {
	  	return this.autodiscoverList.filter(a => a.status === 'active').length;
  }

  private searchCategoryFilter(search?: string): void {
    this.list.category = this.categoryList.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()));
  }

  private searchAccountFilter(search?: string): void {
    this.list.account = this.accountList.filter(c => !search || c.account.lastName.toLowerCase().includes(search.toLowerCase()) || c.account.firstName.toLowerCase().includes(search.toLowerCase()));
  }

  private searchOrganizationFilter(search?: string): void {
	  if (!search) {
		  this.list.organization = this.organizationList;
	  } else {
		  const lowercaseValue = search.toLowerCase();
		  this.list.organization = this.organizationList
			  .reduce((acc: OrganizationTree[], org) => {
				  const matchingChildren = org.children.filter(team =>
					  team.organization.name.toLowerCase().includes(lowercaseValue)
				  );

				  if (org.organization.name.toLowerCase().includes(lowercaseValue) || matchingChildren.length > 0) {
					  acc.push({
						  ...org,
						  children: org.organization.name.toLowerCase().includes(lowercaseValue)
							  ? org.children
							  : matchingChildren
					  });
				  }
				  return acc;
			  }, [])
			  .sort((a, b) => a.organization.name.localeCompare(b.organization.name));
	  }
  }

  private setLoading(loading: boolean): Observable<{}> {
    this._loading = loading;
    return of({});
  }

  private switchLoadingFilter(): Observable<{}> {
    this._loadingFilter = !this._loadingFilter;
    return of({});
  }

  private switchLoadingAutodiscover(): Observable<{}> {
    this._loadingAutoDiscover = !this._loadingAutoDiscover;
    return of({});
  }

  private switchExporting(): Observable<{}> {
    this._exporting = !this._exporting;
    return of({});
  }

  private forceRefresh(): void {
    this.refreshSub.unsubscribe();
    this.refreshSub = interval(10)
      .pipe(take(100))
      .subscribe(() => this.changeDetectorRef.detectChanges());
  }

  get searchValue(): string {
	  return this.filterForm.get(Form.name)!.value;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.loadingSub.unsubscribe();
    this.refreshSub.unsubscribe();
  }
}

interface ApplicationFilter {
  form: Form;
  id: string;
  value: string;
}

enum Form {
  name = 'name',
  criticality = 'criticality',
  hostingType = 'hostingType',
  status = 'status',
  tag = 'tag',
  organization = 'organization',
  searchOrganization = 'searchOrganization',
  account = 'account',
  searchAccount = 'searchAccount',
  category = 'category',
  searchCategory = 'searchCategory',
  sort = 'sort',
}

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

interface ApplicationSort {
  column: ApplicationSortColumn;
  direction: SortDirection;
}

interface ApplicationRow extends ApplicationTableData {
  usage?: Usage;
  health?: Health;
}

interface ApplicationExportCsv {
  name: string;
  description: string;
  criticality: string;
  category: string;
  health: string;
  usage: string;
  cost: string;
  supportEmail: string;
  supportPhone: string;
  url: string;
  servers: string;
  technologies: string;
}
