import {Routes} from '@angular/router';
import {AuthGuard} from '../../guards/auth.guard';
import {OrganizationListComponent} from "../../modules/home/organization/organization-list/organization-list.component";
import {OperationDashboardComponent} from "../../modules/home/operation-dashboard/operation-dashboard.component";
import {FinanceDashboardComponent} from "../../modules/home/finance-dashboard/finance-dashboard.component";
import {UsageDashboardComponent} from "../../modules/home/usage-dashboard/usage-dashboard.component";
import {HomePageComponent} from "../../modules/home/home-page/home-page.component";
import {INFRA_INSTANCES_URL, MainMenuPath, SETTINGS_ACCOUNT_URL} from "../../models/home/navigation.model";
import {ArchitectureDashboardComponent} from 'src/app/modules/home/architecture-dashboard/architecture-dashboard.component';
import {ApplicationListComponent} from 'src/app/modules/home/applications/application-list/application-list.component';
import {ExtensionComponent} from "../../modules/home/extension/extension.component";
import {DemoComponent} from 'src/app/modules/home/demo/demo.component';

export const homeRoutes: Routes = [
	{
		path: '',
		redirectTo: MainMenuPath.HOME,
		pathMatch: 'full'
	},
	{
		path: MainMenuPath.DASHBOARD,
		redirectTo: MainMenuPath.HOME,
		pathMatch: 'full'
	},
	{
		path: MainMenuPath.HOME,
		canActivate: [AuthGuard],
		component: HomePageComponent
	},
	{
		path: MainMenuPath.ORGANIZATION,
		canActivate: [AuthGuard],
		component: OrganizationListComponent
	},
	{
		path: MainMenuPath.INFRASTRUCTURE,
		redirectTo: INFRA_INSTANCES_URL,
		pathMatch: 'full'
	},
	{
		path: MainMenuPath.INFRASTRUCTURE,
		canActivate: [AuthGuard],
		loadChildren: () => import('../../modules/home/infrastructure/infrastructure.module').then(m => m.InfrastructureModule)
	},
	{
		path: MainMenuPath.SETTINGS,
		redirectTo: SETTINGS_ACCOUNT_URL,
		pathMatch: 'full'
	},
	{
		path: MainMenuPath.SETTINGS,
		canActivate: [AuthGuard],
		loadChildren: () => import('../../modules/home/settings/settings.module').then(m => m.SettingsModule)
	},
	{
		path: MainMenuPath.APPLICATIONS,
		canActivate: [AuthGuard],
		component: ApplicationListComponent
	},
	{
		path: MainMenuPath.OPERATION,
		canActivate: [AuthGuard],
		component: OperationDashboardComponent
	},
	{
		path: MainMenuPath.FINANCE,
		canActivate: [AuthGuard],
		component: FinanceDashboardComponent
	},
	{
		path: MainMenuPath.USAGE,
		canActivate: [AuthGuard],
		component: UsageDashboardComponent
	},
	{
		path: MainMenuPath.ARCHITECTURE,
		canActivate: [AuthGuard],
		component: ArchitectureDashboardComponent
	},
	{
		path: MainMenuPath.DIAGRAMS,
		canActivate: [AuthGuard],
		loadChildren: () => import('src/app/modules/home/diagrams/diagrams.module').then(m => m.DiagramsModule)
	},
	{
		path: MainMenuPath.EXTENSION,
		canActivate: [AuthGuard],
		component: ExtensionComponent
	},
	{
		path: 'demo',
		canActivate: [AuthGuard],
		component: DemoComponent
	},
]
