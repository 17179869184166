<div class="flex-column gap-S">
	<div class="flex-space-between flex-align-center w-100">
		<div class="font-MM fw-700">
			{{ 'page.appDetails.usage.histogram.title' | translate }}
		</div>
		<app-multi-select *ngIf="application?.usageActivated || !fromApplication" class="w-100 w-fit-content" [inputControl]="rangeControl">
			<app-select-trigger [minHeight]="31" [border]="'0.5px solid #dcdce9'" appearance="field">
				<span>{{ 'global.queryTypeSelector.' + rangeControl.value | translate }}</span>
			</app-select-trigger>
			<app-select-option [value]="QueryRangeType.PAST_1_DAY">
				<span>{{ 'global.queryTypeSelector.past_1_day' | translate }}</span>
			</app-select-option>
			<app-select-option [value]="QueryRangeType.PAST_1_MONTH">
				<span>{{ 'global.queryTypeSelector.past_1_month' | translate }}</span>
			</app-select-option>
			<app-select-option [value]="QueryRangeType.PAST_1_YEAR">
				<span>{{ 'global.queryTypeSelector.past_1_year' | translate }}</span>
			</app-select-option>
		</app-multi-select>
	</div>
	<div class="w-100" style="max-height: 216px; position: relative;"
		 (mouseout)="tooltip.display = 'none'">
		<div *ngIf="!application?.usageActivated && fromApplication" class="flex-column flex-center grey gap-S pad-left-L pad-right-L" style="height: 216px;">
			<div class="flex-center grey">
				<img src="assets/icons/info-circle.svg">
			</div>
			<div class="text-center">
				{{'page.appDetails.usage.tooltipHistogramDeactivated1' | translate}}
				<a class="accent text-underline"
				   (click)="navigateToUsageParameter()">{{ 'page.appDetails.usage.settings' | translate }}</a>
				{{ 'page.appDetails.usage.tooltipHistogramDeactivated2' | translate }}
			</div>
		</div>
		<canvas *ngIf="application?.usageActivated || !fromApplication"
				[height]="216"
				baseChart
				[data]="chartData"
				[type]="chartType"
				[options]="chartOptions"
		></canvas>
		<div class="tooltip" style="padding: 3px 7px; pointer-events: none;" [ngStyle]="{'top': tooltip.top, 'left': tooltip.left, 'display': tooltip.display}" >
			<div class="flex-space-between font-M fw-700">
				<span *ngIf="rangeControl.value === QueryRangeType.PAST_1_MONTH">{{ tooltip.day }} {{ 'global.monthShort.' + tooltip.month | translate }} {{ tooltip.year }}</span>
				<span *ngIf="rangeControl.value === QueryRangeType.PAST_1_YEAR">{{'global.month.' + tooltip.month | translate}}</span>
				<span *ngIf="rangeControl.value === QueryRangeType.PAST_1_DAY">{{tooltip.hour}}h</span>
			</div>
			<div class="font-S fw-700 lowercase">
				{{ tooltip.value }} {{'global.users' | translate}}
			</div>
		</div>
	</div>
</div>
