import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ChipsModule} from "../../../../global/chips/chips/chips.module";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {CreateOrganizationForm} from "../../../../../services/organization.service";
import {OrganizationType} from "../../../../../services/model/autodiscover.model";
import {OrganizationCreationPopupData} from "../organization-list.component";

@Component({
  selector: 'app-create-team-group',
  standalone: true,
	imports: [CommonModule, ChipsModule, DesignSystemModule, TranslateModule],
  templateUrl: './create-team-group.component.html',
  styleUrl: './create-team-group.component.scss'
})
export class CreateTeamGroupComponent implements OnInit, OnDestroy {
	constructor(public dialogRef: MatDialogRef<CreateTeamGroupComponent>,
				@Inject(MAT_DIALOG_DATA) public data: OrganizationCreationPopupData) {
	}

	formGroup: FormGroup;

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.formGroup = new FormGroup({
			[Form.name]: new FormControl(undefined, Validators.required),
		});
	}

	onNoClick() {
		this.dialogRef.close();
	}

	onCreate() {
		if (this.formGroup.valid) {
			const form: CreateOrganizationForm = {
				name: this.nameFormValue!,
				type: OrganizationType.AFFILIATE,
				ascendant: this.data.parent.organizationId,
				icon: undefined
			}
			this.dialogRef.close(form);
		}
	}

	get nameFormControl(): FormControl {
		return this.formGroup.get(Form.name) as FormControl;
	}

	get nameFormValue(): string|undefined {
		return this.nameFormControl.value;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

enum Form {
	name = 'name'
}
