<div class="pl-xl pr-xl disp-f a-i-c j-c-sb mt-l">
	<div class="flex-column" style="gap: 7px">
		<div *ngIf="title" class="flex-align-end gap-S">
            <span class="font-XXL fw-700 primary">{{ title }}</span>
            <div *ngIf="indicator" class="flex-center border-primary pad-0-S" style="height: 24px; min-width: 24px; border-radius: 12px;">
                <span class="font-L fw-700 primary">{{ indicator }}</span>
            </div>
		</div>
		<div *ngIf="subtitle" class="font-S fw-500 grey" style="line-height: unset;">
			{{ subtitle }}
		</div>
	</div>
	<div>
		<div class="disp-f a-i-c j-c-c gap-S">
			<div style="position: relative">
				<div *ngIf="unread > 0" class="unread-notifications"></div>
				<button class="disp-f a-i-c j-c-c c-p hover" style="width: 52px; height: 52px;" #notificationToggle
                        (click)="toggleNotificationMenu()">
					<img ngSrc="assets/icons/bell.svg" height="24" width="24" alt="">
				</button>
			</div>
			<button class="disp-f a-i-c j-c-c c-p hover" style="width: 52px; height: 52px;" #accountToggle
                    [disabled]="!currentUser"
                    (click)="toggleAccountMenu();">
				<app-display-user-logo [user]="currentUser"></app-display-user-logo>
			</button>
		</div>
	</div>
	<div class="top-bar-menu account pl-m pr-m pt-m pb-m" [class.closed]="!isAccountMenuOpen" [class.opened]="isAccountMenuOpen" #accountMenu>
		<div class="disp-f gap-S a-i-c mb-s" style="line-height: normal">
            <app-display-user [user]="currentUser" [width]="'150px'" bold email></app-display-user>
		</div>
		<div class="grey-hr"></div>
		<div class="mb-xs">
			<button class="pl-m pr-m pt-s pb-s disp-f a-i-c j-c-sb c-p w-100 tenant-button selected">
				<div class="text-start text-overflow-ellipsis">
					{{ tenant.configuration.name }}
				</div>
				<div class="disp-f a-i-c j-c-c">
					<img ngSrc="assets/icons/blue-check.svg" alt="" height="16" width="16">
				</div>
			</button>
		</div>
		<div *ngFor="let myTenant of tenants | slice:0:end" class="mb-xs">
			<button (click)="selectTenant(myTenant)" class="pl-m pr-m pt-s pb-s disp-f a-i-c j-c-sb c-p w-100 tenant-button">
				<div class="text-start text-overflow-ellipsis">
					{{ myTenant.configuration.name }}
				</div>
			</button>
		</div>
		<div class="text-center" *ngIf="tenants.length > 2">
			<span class="c-p font-S fw-500 grey" [hidden]="tenants.length <= end" (click)="end = tenants.length;">Voir plus</span>
			<span class="c-p font-S fw-500 grey" [hidden]="tenants.length > end" (click)="end = 2;">Voir moins</span>
		</div>
		<button (click)="onCreateNewTenant()" class="pl-m pr-m pt-m pb-m c-p menu-item">
			<span>{{ 'button.createTenant' | translate }}</span>
		</button>
		<div class="grey-hr"></div>
		<button [routerLink]="[settingsAccountUrl]" [queryParams]="activatedRoute.snapshot.queryParams" class="pl-m pr-m pt-m pb-m c-p menu-item" (click)="isAccountMenuOpen = false;">
			{{ 'menu.accountSettings' | translate }}
		</button>
		<button class="pl-m pr-m pt-m pb-m c-p menu-item text-red" (click)="logout()">
			{{ 'button.logout' | translate }}
		</button>
	</div>
	<div class="top-bar-menu notification" [class.closed]="!isNotificationMenuOpen" [class.opened]="isNotificationMenuOpen" #notificationMenu>
		<div *ngIf="notifications && notifications.length <= 0" class="disp-f j-c-c a-i-c gap-S" style="height: 100%">
			<img ngSrc="assets/icons/coming-soon.svg" height="18" width="19" alt="">
			<span class="grey">{{ 'global.noNotification' | translate }}</span>
		</div>
		<div *ngIf="notifications && notifications.length > 0">
			<div *ngFor="let notification of notifications" class="notification-row" (click)="redirectToNotificationEntity(notification)">
				<div *ngIf="notification.status == 'unread'" class="notif-bubble"></div>
				<div class="single-notification disp-f a-i-c j-c-sb" [ngClass]="{'unread': notification.status === 'unread' }">
					<div class="disp-f a-i-c gap-S pl-l" style="width: 70%;">
						<div class="avatar disp-f a-i-c j-c-c">
							<span class="font-M fw-500">{{ notification.sender.firstName.charAt(0) }}{{ notification.sender.lastName.charAt(0) }}</span>
						</div>
						<div class="font-S fw-500">
							<span [innerHTML]="'global.notifications.' + notification.type | translate: { sender: notification.sender.firstName + ' ' + notification.sender.lastName, application: notification.applicationName }"></span>
						</div>
					</div>
					<div class="font-S grey text-end pr-l" style="width: 30%;">{{ notification.date.toLocaleDateString() }} {{ notification.date.toLocaleTimeString() }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
