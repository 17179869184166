import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input, OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {MatMenuPanel, MatMenuTrigger} from "@angular/material/menu";
import {FormControl, Validators} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {TenantAccount} from 'src/app/services/model/account.model';

@Component({
  selector: 'app-select-or-invite-user-input',
  templateUrl: './select-or-invite-user-input.component.html',
  styleUrl: './select-or-invite-user-input.component.scss'
})
export class SelectOrInviteUserInputComponent implements OnInit, OnChanges, OnDestroy {

	constructor(protected translate: TranslateService) {}

	@Input() values?: TenantAccount[];
	@Input() list: TenantAccount[];
	@Input() searchPlaceholder: string = this.translate.instant('global.search');
	@Input() disabled: boolean = false;
	@Input() multiselect: boolean = false;
	@Input() limit: number = 4;

	@Output() onSelect = new EventEmitter<TenantAccount>();
	@Output() onAdd = new EventEmitter<TenantAccount>();
	@Output() onRemove = new EventEmitter<TenantAccount>();
	@Output() onInvite = new EventEmitter<string>();

	@ViewChild('menuTrigger', { static: false}) menuTrigger: MatMenuTrigger;

	@ViewChild('displayListMenu') matMenu: MatMenuPanel;

	userElements: UserElement[];
	showInviteUser: boolean = true;
	showUserList: boolean = true;
	searchFormControl = new FormControl('');
	emailFormControl = new FormControl();
	subscription: Subscription = new Subscription();

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.values) {
			this.ngOnInit();
		}
	}

	ngOnInit() {
		this.emailFormControl.setValidators([Validators.email, Validators.required])
		this.emailFormControl.updateValueAndValidity();
		this.userElements = this.list.map(tenantAccount => ({
			tenantAccount: tenantAccount,
			name: tenantAccount.pending ? tenantAccount.account.email : tenantAccount.account.firstName + ' ' + tenantAccount.account.lastName,
			selected: tenantAccount.account.accountId === this.values?.find(a => a.account.accountId === tenantAccount.account.accountId)?.account.accountId,
		}));
	}

	filteredItems(): UserElement[] {
		return this.userElements
			.filter(item => !this.searchFormControl.value || item.name.toLowerCase().includes(this.searchFormControl.value!.toLowerCase()))
			//sort by selected first, then pending, then alphabetically
			.sort((a, b) => {
				if (this.multiselect) {
					return a.name.localeCompare(b.name);
				}

				if (a.selected && !b.selected) {
					return -1;
				} else if (!a.selected && b.selected) {
					return 1;
				} else if (a.tenantAccount.pending && !b.tenantAccount.pending) {
					return 1;
				} else if (!a.tenantAccount.pending && b.tenantAccount.pending) {
					return -1;
				} else {
					return a.name.localeCompare(b.name);
				}
			});
	}

	filteredItemsSelected(): UserElement[] {
		return this.filteredItems().filter(item => item.selected);
	}

	onSelectUser(user: UserElement) {
		this.userElements.forEach(u => u.selected = false);
		user.selected = true;
		this.onSelect.emit(this.list.find(a => a.account.accountId === user.tenantAccount.account.accountId));
		this.menuTrigger.closeMenu();
	}

	update(user: UserElement, checked: boolean) {
		if (this.multiselect && (this.limit > this.filteredItemsSelected().length || !checked)) {
			if (checked) {
				this.values?.push(user.tenantAccount);
				this.userElements.find(u => u.tenantAccount.account.accountId === user.tenantAccount.account.accountId)!.selected = true;
				this.onAdd.emit(user.tenantAccount);
			} else {
				this.values = this.values?.filter(a => a.account.accountId !== user.tenantAccount.account.accountId);
				this.userElements.find(u => u.tenantAccount.account.accountId === user.tenantAccount.account.accountId)!.selected = false;
				this.onRemove.emit(user.tenantAccount);
			}

			if (this.limit === this.filteredItemsSelected().length) {
				this.menuTrigger.closeMenu();
			}
		}
	}

	onInviteUser() {
		if (this.emailFormControl.valid) {
			this.onInvite.emit(this.emailFormControl.value);
			this.menuTrigger.closeMenu();
		}
	}

	onClose() {
		this.emailFormControl.reset();
		this.searchFormControl.reset();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

interface UserElement {
	tenantAccount: TenantAccount;
	name: string;
	selected: boolean;
}
