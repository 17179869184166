import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {TenantAccount} from "../../../../../../services/model/account.model";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {
	ApplicationDetailData,
	ApplicationDetailService
} from "../../../../../../services/front/application-detail.service";
import {
	ApplicationInstance, ApplicationResponsible, ApplicationResponsibleForm,
} from "../../../../../../services/model/new-application.model";
import {TenantAccountService} from "../../../../../../services/back/tenant-account.service";
import {NewApplicationService} from "../../../../../../services/back/new-application.service";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {filter, map} from "rxjs/operators";
import {TenantService} from "../../../../../../services/tenant.service";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {ContentLoaderModule} from "@ngneat/content-loader";

@Component({
  selector: 'app-application-responsible',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule, ContentLoaderModule],
  templateUrl: './application-responsible.component.html',
  styleUrl: './application-responsible.component.scss'
})
export class ApplicationResponsibleComponent implements OnInit, OnDestroy {
	subscription: Subscription = new Subscription();

	application: ApplicationInstance;
	tenantId: string;

	hovered: boolean = false;

	applicationResponsibles: ApplicationResponsibleData = {
		tenantUsers: [],
		selected: []
	};

	initialized: boolean = false;
	_initializing: boolean;
	_loading: boolean;

	constructor(private applicationDetailService: ApplicationDetailService,
				private tenantAccountService: TenantAccountService,
				private newApplicationService: NewApplicationService,
				private tenantService: TenantService,
				private snackBarService: SnackbarService,
				private translateService: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.application = data.instance;
		this.tenantId = data.tenantId;
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId),
					this.newApplicationService.getApplicationResponsibles(this.tenantId, this.application.applicationId)
				])),
				tap(([usersList, responsibles]) => this.setData(usersList, responsibles)),
				finalize(() => this.switchLoading())
			).subscribe(() => this.initialized = true);
	}

	setData(usersList: TenantAccount[], responsibles: TenantAccount[]): void {
		this.applicationResponsibles = {
			tenantUsers: usersList,
			selected: responsibles
		};
	}

	onInviteResponsible(email: string): void {
		const existingUser = this.applicationResponsibles.tenantUsers.find(user => user.account.email === email);
		if (!existingUser) {
			this.tenantService.inviteUsers(this.tenantId, [email])
				.pipe(
					switchMap(() => this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId)),
					tap(users => this.applicationResponsibles.tenantUsers = users
						.sort((a, b) => `${a.account.firstName} ${a.account.lastName}`.localeCompare(`${b.account.firstName} ${b.account.lastName}`))
						.sort((a, b) => a.pending ? 1 : -1)),
					map(() => this.applicationResponsibles.tenantUsers.find(user => user.account.email === email)),
					filter(user => !!user),
					tap(user => this.addResponsible(user!, true)))
				.subscribe();
		} else {
			this.addResponsible(existingUser, true);
		}
	}

	addResponsible(account: TenantAccount, push: boolean = false): void {
		const form: ApplicationResponsibleForm = {
			accountId: account.account.accountId
		};
		this.newApplicationService.addApplicationResponsible(this.tenantId, this.application.applicationId, form)
			.pipe(
				filter(success => success),
				tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success')))
			).subscribe(() => {
				if (push) {
					this.applicationResponsibles.selected.push(account);
				}
			});
	}

	removeResponsible(user: TenantAccount): void {
		const form: ApplicationResponsibleForm = {
			accountId: user.account.accountId
		};
		this.newApplicationService.deleteApplicationResponsible(this.tenantId, this.application.applicationId, form)
			.pipe(
				filter(success => success),
				tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success')))
			).subscribe(() => this.applicationResponsibles.selected = this.applicationResponsibles.selected.filter(responsible => responsible.account.accountId !== user.account.accountId));
	}

	getInvertedIndex(index: number): number {
		const maxIndex = this.applicationResponsibles.selected.length - 1;
		return maxIndex - index;
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

export interface ApplicationResponsibleData {
	tenantUsers: TenantAccount[];
	selected: TenantAccount[];
}
