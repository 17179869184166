import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UsageHistory} from "src/app/services/usage.service";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {ColorEnum} from "src/_variables";
import {BaseChartDirective} from "ng2-charts";
import {TranslateService} from "@ngx-translate/core";
import {ApplicationUrlService} from "src/app/services/front/application-url.service";
import {ApplicationInstance} from 'src/app/services/model/new-application.model';
import {FormControl} from "@angular/forms";
import {QueryRangeType} from "../../../../../../services/back/tenant-finance.service";
import {Subject, Subscription} from "rxjs";

@Component({
  selector: 'app-usage-histogram',
  templateUrl: './usage-histogram.component.html',
  styleUrls: ['./usage-histogram.component.scss']
})
export class UsageHistogramComponent implements OnInit {

	@Input() usagePerMonth: UsageHistory[];
	@Input() usagePerDay: UsageHistory[];
	@Input() usagePerHour: UsageHistory[];
	@Input() application: ApplicationInstance|undefined;
	@Input() fromApplication: boolean = true;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	tooltip = { display: "none", top: "0px", left: "0px", day: 0, month: 0, year: 0, hour: 0, value: 0 };

	onRangeChange: Subject<void> = new Subject<void>();
	rangeControl: FormControl<QueryRangeType> = new FormControl(QueryRangeType.PAST_1_MONTH, { nonNullable: true })

	subscription: Subscription = new Subscription()

	public chartType: ChartType = 'line';
	public chartOptions: ChartOptions = {
		interaction: {
			intersect: false,
			mode: 'index',
		},
		parsing: {
			xAxisKey: 'id',
			yAxisKey: 'value'
		},
		animation: false,
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false,
				external: context => {

					const {chart, tooltip} = context;

					if (tooltip.opacity === 0) {
						if (this.tooltip.display !== "none") {
							this.tooltip.display = "none"
							this.cdr.detectChanges()
						}
						return
					}

					const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;



					const canvasWidth = chart.canvas.getBoundingClientRect().width;

					let xPosition = positionX + tooltip.caretX;

					if (xPosition + 180 > canvasWidth) {
						xPosition = positionX + tooltip.caretX + 180 - canvasWidth
						xPosition = positionX + tooltip.caretX - xPosition
					}

					// @ts-ignore
					const date: Date  = tooltip.dataPoints[0].raw.date

					const hour = date.getHours()

					const day = date.getDate()

					const month = date.getMonth() + 1

					const year = date.getFullYear()

					// @ts-ignore
					const value = /*Math.floor(tooltip.dataPoints[0].parsed.y * 100) / 100//*/tooltip.dataPoints[0].raw.value;

					this.tooltip = {
						display: "block",
						left: xPosition + 'px',
						top: positionY + tooltip.caretY + 6 + 'px',
						day: day,
						month: month,
						year: year,
						hour: hour,
						value: value
					}

					this.cdr.detectChanges()
				},
			}
		},
		scales: {
			y: {
				suggestedMin: 0,
				suggestedMax: 4,
				display: true,
				grid: {
					lineWidth: 2,
					borderDash: [2,2],
					drawTicks: false,
					drawBorder: false,
					color: ColorEnum.medium_grey,
				},
				ticks: {
					crossAlign:"center",
					callback: function(value) {if (typeof value == "number" && value % 1 === 0) {return value} else {return undefined}},
					padding: 6,
					font: {
						family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
					},
					color: "#1D3B58"
				}
			},
			x: {
				ticks: {
					font: {
						family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
						size: 9
					},
					color: "#B5B5C3"
				},
				grid: {
					lineWidth: 0,
					drawBorder: false
				}
			},

		}
	};
	public chartData: ChartData<'line', {date: Date, value: number}[]> = {
		labels: [],
		datasets: []
	};

	constructor(private translate: TranslateService,
              private applicationUrlService: ApplicationUrlService,
              private cdr: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.histogramDataPreparation(this.rangeControl.value);
		this.subscription.add(this.rangeControl.valueChanges.subscribe((value) => {
			this.histogramDataPreparation(value);
		}))
	}

	getGradient(ctx: any, chartArea: any): any {
		let width, height, gradient;
		const chartWidth = chartArea.right - chartArea.left;
		const chartHeight = chartArea.bottom - chartArea.top;
		if (gradient === null || width !== chartWidth || height !== chartHeight) {
			gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
			gradient.addColorStop(0, 'rgba(61, 192, 255, 0)');
			gradient.addColorStop(0.5, 'rgba(61, 192, 255, 0.2)');
			gradient.addColorStop(1, 'rgba(61, 192, 255, 0.6)');
		}

		return gradient;
	}

	histogramDataPreparation(range: QueryRangeType): void {
		this.chartData.datasets = [];
		this.chartData.labels = [];

		if (range == QueryRangeType.PAST_1_YEAR) {
			this.chartData.labels = this.usagePerMonth
				.map(usage => {
					const month = new Date(usage.date).getMonth() + 1
					return this.translate.instant('global.month.' + month)
				});
		} else if (range == QueryRangeType.PAST_1_MONTH) {
			this.chartData.labels = this.usagePerDay
				.map(usage => new Date(usage.date).getDate());
		} else if (range == QueryRangeType.PAST_1_DAY) {
			this.chartData.labels = this.usagePerHour
				.map(usage => new Date(usage.date).getHours());
		}

		this.chartData.datasets.push({
			data: range == QueryRangeType.PAST_1_YEAR ? this.usagePerMonth.map(it => ({date: new Date(it.date), value: it.usage.value ?? 0})) : range == QueryRangeType.PAST_1_MONTH ? this.usagePerDay.map(it => ({date: new Date(it.date), value: it.usage.value ?? 0})) : this.usagePerHour.map(it => ({date: new Date(it.date), value: it.usage.value ?? 0})),
			backgroundColor: (context) => {
				const chart = context.chart;
				const {ctx, chartArea} = chart;

				if (!chartArea) {
					return;
				}
				return this.getGradient(ctx, chartArea);
			},
			parsing: {
				xAxisKey: 'date',
				yAxisKey: 'value'
			},
			tension: 0.3,
			borderColor: "#3DC0FF",
			hoverBackgroundColor: "#3DC0FF",
			fill: true,
			pointStyle: "circle",
			pointRadius: 0.01,
			pointBorderColor: "#3DC0FF",
			pointBorderWidth: 1.5,
			pointBackgroundColor: "#FFFFFF",
			pointHoverBackgroundColor: "#3DC0FF",
			pointHoverRadius: 6,
			pointHoverBorderColor: "#3DC0FF",
			borderWidth: 1.5,
			cubicInterpolationMode: "monotone"
		})

		if (this.chart) this.chart.update();
	}

	navigateToUsageParameter(): void {
		if (!this.application?.usageActivated && this.fromApplication) {
			this.applicationUrlService.navigateInAndOutOfParameter(true);
		}
	}

	protected readonly QueryRangeType = QueryRangeType;
}
