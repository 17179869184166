import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TenantAccount} from "../../../../../../services/model/account.model";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {Subscription, switchMap, tap} from "rxjs";
import {filter, map} from "rxjs/operators";
import {TenantService} from "../../../../../../services/tenant.service";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../../../services/front/organization-detail.service";
import {
	Organization,
	OrganizationService
} from "../../../../../../services/organization.service";
import {TenantAccountService} from "../../../../../../services/back/tenant-account.service";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {TranslateModule, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-team-responsible',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, DeckContentLoaderModule, ContentLoaderModule, TranslateModule],
  templateUrl: './team-responsible.component.html',
  styleUrl: './team-responsible.component.scss',
})
export class TeamResponsibleComponent implements OnInit, OnDestroy {
	@Input() data: TeamResponsibleData|undefined;

	subscription: Subscription = new Subscription();

	_initializing: boolean;

	hovered: boolean = false;

	tenantId: string;
	isEditor: boolean;
	organization: Organization;
	isTenantInactivePlan: boolean;

	constructor(protected tenantService: TenantService,
				protected organizationDetailService: OrganizationDetailService,
				protected tenantAccountService: TenantAccountService,
				protected organizationService: OrganizationService,
				protected snackbar: SnackbarService,
				protected translate: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap((data) => this.setOrganizationDetailData(data)))
			.subscribe());
	}

	private setOrganizationDetailData(data: OrganizationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.organization = data.organization;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	addResponsible(user: TenantAccount): void {
		this.organizationService.addOrganizationResponsible(this.tenantId, this.organization.organizationId, user.account.accountId)
			.pipe(
				filter(success => success),
				tap(() => this.snackbar.show(this.translate.instant('page.organization.overview.teamManagerAdded'))),
			).subscribe(() => this.organizationDetailService.refreshOrganization())
	}

	removeResponsible(user: TenantAccount): void {
		this.organizationService.removeOrganizationResponsible(this.tenantId, this.organization.organizationId, user.account.accountId)
			.pipe(
				filter(success => success),
				tap(() => this.snackbar.show(this.translate.instant('page.organization.overview.teamManagerRemoved'))),
			).subscribe(() => this.organizationDetailService.refreshOrganization())
	}

	onInviteResponsible(email: string): void {
		const existingUser = this.data?.data.find(user => user.account.email === email);
		if (!existingUser) {
			this.tenantService.inviteUsers(this.tenantId, [email])
				.pipe(
					switchMap(() => this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId)),
					tap(users => {
						if (this.data !== undefined) {
							this.data.data = users
								.sort((a, b) => `${a.account.firstName} ${a.account.lastName}`.localeCompare(`${b.account.firstName} ${b.account.lastName}`))
								.sort((a, b) => a.pending ? 1 : -1)
						}
					}),
					map(() => this.data?.data.find(user => user.account.email === email)),
					filter(user => !!user),
					tap(user => this.addResponsible(user!)))
				.subscribe();
		} else {
			this.addResponsible(existingUser);
		}
	}

	getInvertedIndex(index: number): number {
	    const maxIndex = this.data!.selected.length - 1;
		return maxIndex - index;
	}
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

export interface TeamResponsibleData {
	data: TenantAccount[];
	selected: TenantAccount[];
}
