import {
	Component, ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormControl, FormGroup} from "@angular/forms";
import {Organization, OrganizationService, OrganizationTree} from "../../../../services/organization.service";
import {
	CriticalityLevel,
	DiagramApplicationQuadrant, DiagramApplicationQuadrantFilterForm,
	TenantService
} from "../../../../services/tenant.service";
import {Category} from "../../../../services/model/application-category.model";
import {CurrentTenantService} from "../../../../services/front/current-tenant.service";
import {MenuStateService} from "../../../../services/front/menu-state.service";
import {TranslateModule} from "@ngx-translate/core";
import {finalize, forkJoin, Observable, of, Subscription, switchMap} from "rxjs";
import {map, tap} from "rxjs/operators";
import {ApplicationCategoryService} from "../../../../services/back/application-category.service";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {DiagramBreadcrumbModule} from "../diagram-breadcrumb/diagram-breadcrumb.module";
import {MatRippleModule} from "@angular/material/core";
import {MatBadgeModule} from "@angular/material/badge";
import {MatMenuModule} from "@angular/material/menu";
import {CostPipe} from "../../../../pipes/number/cost.pipe";
import {ApplicationGeneric, ApplicationStatus} from "../../../../services/model/new-application.model";
import {NewApplicationService} from "../../../../services/back/new-application.service";
import {
	ApplicationDetailComponent,
	ApplicationDetailInput
} from "../../applications/application-detail/application-detail.component";
import {RightSliderService} from "../../../../services/front/right-slider.service";
import {Router} from "@angular/router";
import {APPLICATIONS_URL} from "../../../../models/home/navigation.model";
import {DisplayAppStatusComponent} from "../../../design-system/display-app-status/display-app-status.component";
import {CatalogTag} from "../../../../services/model/catalog-tag.model";

@Component({
	selector: 'app-diagram-magic-quadrant',
	standalone: true,
	imports: [CommonModule, DesignSystemModule, DiagramBreadcrumbModule, MatRippleModule, TranslateModule, MatBadgeModule, MatMenuModule, NgOptimizedImage, CostPipe, DisplayAppStatusComponent],
	templateUrl: './diagram-magic-quadrant.component.html',
	styleUrl: './diagram-magic-quadrant.component.scss'
})
export class DiagramMagicQuadrantComponent implements OnInit, OnDestroy {

	tenantId: string;
	_initializing: boolean;
	_loading: boolean;
	_refreshing: boolean;

	filterForm: FormGroup;
	searchOrganizationControl: FormControl;
	organizations: OrganizationTree[] = [];
	searchCategoryControl: FormControl;
	applications: ApplicationGeneric[] = [];
	searchApplicationControl: FormControl;
	categories: Category[] = [];
	filterData: {
		organization: OrganizationTree[],
		criticality: CriticalityLevel[],
		category: Category[],
		applications: ApplicationGeneric[],
		status: ApplicationStatus[],
		tag: CatalogTag[],
	};
	formName: typeof Form = Form;

	isEmptyWithFilters: boolean = false;
	isEmptyWithCurrentAxis: boolean = false;
	isEmpty: boolean = false;

	displayProgressBar: boolean = true;
	topOrganizationName: string;
	menuWidth: number = 90;

	diagramApplications?: DiagramApplicationQuadrant[];

	displayedApplicationsCluster: DisplayedApplicationCluster[] = [];

	displayedApplications: DisplayedApplication[] = [];

	activeFilters: number = 0;

	yAxisMetric: AxisChoice = AxisChoice.usage;
	xAxisMetric: AxisChoice = AxisChoice.cost;

	metricChoices = [
		{value: AxisChoice.cost, label: 'Cost'},
		{value: AxisChoice.usage, label: 'Usage'},
		{value: AxisChoice.performance, label: 'Performance'},
		{value: AxisChoice.health, label: 'Health'}
	];

	@ViewChild('container') container: ElementRef;

	subscription: Subscription = new Subscription();


	constructor(private applicationCategoryService: ApplicationCategoryService,
				private currentTenantService: CurrentTenantService,
				private organizationService: OrganizationService,
				private menuStateService: MenuStateService,
				private tenantService: TenantService,
				private applicationService: NewApplicationService,
				private rightSliderService: RightSliderService,
				private router: Router) {}

	ngOnInit() {
		this.createFilterForm();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.menuStateService.onMenuStateChange()
			.pipe(tap(state => this.menuWidth = state.width))
			.subscribe(() => setTimeout(() => this.onResize({}))));

	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				map(() => this.buildDiagramMagicQuadrantFilterForm()),
				switchMap(form => forkJoin([
					this.tenantService.getAllDiagramApplicationQuadrant(this.tenantId, form),
					this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId),
					this.organizationService.getOrganizationTreeByTenantId(this.tenantId),
					this.applicationService.getAllApplication(this.tenantId),
					this.applicationService.getAllApplicationTagByTenantId(this.tenantId)
				])),
				tap(([apps])=> this.setDiagramMagicQuadrant(apps ,this.xAxisMetric, this.yAxisMetric)),
				tap(([_, categories, organization, applications, tags])=> this.setFilterData(organization, categories, applications, tags)),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	private buildDiagramMagicQuadrantFilterForm(): DiagramApplicationQuadrantFilterForm {
		return {
			organizations: this.organizationFormValue.map(o => o.organizationId),
			categories: this.categoryFormValue.map(c => c.categoryId),
			criticality: this.criticalityFormValue,
			applications: this.applicationFormValue.map(a => a.id),
			status: this.statusFormValue,
			tag: this.tagFormValue.map(t => t.tagId)
		}
	}

	changeAxisMetric(axis: 'x' | 'y', metric: AxisChoice): void {
		if (axis === 'x') {
			this.xAxisMetric = metric;
		} else {
			this.yAxisMetric = metric;
		}
		this.fetchDiagramMagicQuadrant(this.xAxisMetric, this.yAxisMetric).subscribe();
	}

	private setDiagramMagicQuadrant(apps: DiagramApplicationQuadrant[], xMetric: AxisChoice, yMetric: AxisChoice): void {
		const filteredApps = apps.filter(app => {
			const nonNullMetricsCount: number = [AxisChoice.cost, AxisChoice.usage, AxisChoice.performance, AxisChoice.health]
				.map(metric => this.getAxisValue(app, metric))
				.filter(value => value !== null && value !== 0)
				.length
			return nonNullMetricsCount >= 2;
		});

		this.isEmptyWithFilters = filteredApps.length === 0 && this.activeFilters > 0;

		const xValues = filteredApps.map(app => this.getAxisValue(app, xMetric));
		const yValues = filteredApps.map(app => this.getAxisValue(app, yMetric));
		const xValuesFiltered = xValues.filter(v => v !== null && v !== 0);
		const yValuesFiltered = yValues.filter(v => v !== null && v !== 0);
		this.isEmptyWithCurrentAxis = (xValuesFiltered.length === 0 || yValuesFiltered.length === 0) && !this.isEmptyWithFilters;

		this.isEmpty = filteredApps.length === 0 && !this.isEmptyWithFilters && !this.isEmptyWithCurrentAxis;

		this.diagramApplications = filteredApps;

		this.updateElementsPositioning(xMetric, yMetric);
		this.createClusters();
	}

	private getAxisValue(app: DiagramApplicationQuadrant, metric: AxisChoice): number | null {
		switch (metric) {
			case AxisChoice.cost:
				return app.cost || null;
			case AxisChoice.usage:
				return app.usage || null;
			case AxisChoice.performance:
				if (!app.fcp && !app.lcp) {
					return null;
				}
				const fcp = app.fcp || 0;
				const lcp = app.lcp || 0;
				return fcp + lcp;
			case AxisChoice.health:
				return app.health || null;
			default:
				return null;
		}
	}

	private shouldInvertAxis(metric: AxisChoice): boolean {
		return metric === AxisChoice.performance;
	}

	updateElementsPositioning(xMetric: AxisChoice, yMetric: AxisChoice): void {
		if (!this.diagramApplications || this.diagramApplications.length === 0) {
			this.displayedApplications = [];
			return;
		}

		const getAxisValue = (app: DiagramApplicationQuadrant, metric: AxisChoice): number => {
			const value = this.getAxisValue(app, metric);
			return value !== null ? value : 0;
		};

		const xValues = this.diagramApplications
			.filter(app => this.getAxisValue(app, xMetric) !== null && this.getAxisValue(app, yMetric) !== null)
			.map(app => getAxisValue(app, xMetric));

		const yValues = this.diagramApplications
			.filter(app => this.getAxisValue(app, xMetric) !== null && this.getAxisValue(app, yMetric) !== null)
			.map(app => getAxisValue(app, yMetric));

		const minX = Math.min(...xValues);
		const maxX = Math.max(...xValues);
		const minY = Math.min(...yValues);
		const maxY = Math.max(...yValues);

		const normalizePosition = (value: number, min: number, max: number): number => {
			if (min === max) return 0.5;
			return (value - min) / (max - min);
		};

		const maxWidth = this.container.nativeElement.clientWidth - 79;
		const maxHeight = this.container.nativeElement.clientHeight - 104;

		this.displayedApplications = this.diagramApplications
			.filter(app => this.getAxisValue(app, xMetric) !== null && this.getAxisValue(app, yMetric) !== null)
			.map(app => {
				const xValue = getAxisValue(app, xMetric);
				const yValue = getAxisValue(app, yMetric);

				let xPosition = normalizePosition(xValue, minX, maxX) * maxWidth;
				let yPosition = normalizePosition(yValue, minY, maxY) * maxHeight;

				if (this.shouldInvertAxis(xMetric)) {
					xPosition = maxWidth - xPosition;
				}
				if (this.shouldInvertAxis(yMetric)) {
					yPosition = maxHeight - yPosition;
				}

				xPosition = Math.max(20, Math.min(maxWidth - 20, xPosition));
				yPosition = Math.max(60, Math.min(maxHeight - 20, yPosition));

				return {
					application: app,
					xLabel: xMetric,
					xValue,
					yLabel: yMetric,
					yValue,
					xPosition,
					yPosition
				};
			});

		this.displayedApplicationsCluster = [];
	}

	createClusters(): void {
		let remainingApps = [...this.displayedApplications];
		while (remainingApps.length > 0) {
			const app = remainingApps.shift()!;
			const nearbyApps = remainingApps.filter(a =>
				Math.abs(a.xPosition - app.xPosition) < 60 &&
				Math.abs(a.yPosition - app.yPosition) < 60
			);

			if (nearbyApps.length > 0) {
				const cluster: DisplayedApplicationCluster = {
					applications: [app, ...nearbyApps],
					elements: [this.container],
					xPosition: app.xPosition,
					yPosition: app.yPosition
				};
				this.displayedApplicationsCluster.push(cluster);
				remainingApps = remainingApps.filter(a => !nearbyApps.includes(a));
			} else {
				this.displayedApplications = [...this.displayedApplications, app];
			}
		}

		this.displayedApplications = this.displayedApplications.filter(app =>
			!this.displayedApplicationsCluster.some(cluster =>
				cluster.applications.includes(app)
			)
		);
	}

	private setFilterData(organization: OrganizationTree, categories: Category[], applications: ApplicationGeneric[], tags: CatalogTag[]): void {
		this.topOrganizationName = organization.organization.name;
		this.organizations = organization.children
			.filter(c => c.children.length > 0)
			.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		this.organizations.forEach(o => o.children.sort((a, b) => a.organization.name.localeCompare(b.organization.name)));
		this.categories = categories
			.sort((a, b) => a.name.localeCompare(b.name));
		this.applications = applications;
		this.filterData = {
			organization: this.organizations,
			category: this.categories,
			criticality: [CriticalityLevel.HIGH, CriticalityLevel.MEDIUM, CriticalityLevel.LOW],
			applications: this.applications,
			tag: tags,
			status: Object.values(ApplicationStatus)
		};
	}

	private searchOrganizationFilter(search?: string): void {
		if (!search) {
			this.filterData.organization = this.organizations;
		} else {
			const lowercaseValue = search.toLowerCase();
			this.filterData.organization = this.organizations
				.reduce((acc: OrganizationTree[], org) => {
					const matchingChildren = org.children.filter(team =>
						team.organization.name.toLowerCase().includes(lowercaseValue)
					);

					if (org.organization.name.toLowerCase().includes(lowercaseValue) || matchingChildren.length > 0) {
						acc.push({
							...org,
							children: org.organization.name.toLowerCase().includes(lowercaseValue)
								? org.children
								: matchingChildren
						});
					}
					return acc;
				}, [])
				.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		}
	}

	private searchCategoryFilter(search?: string): void {
		this.filterData.category = this.categories.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()));
	}

	private searchApplicationFilter(search?: string): void {
		this.filterData.applications = this.applications.filter(a => !search || a.name.toLowerCase().includes(search.toLowerCase()));
	}

	private createFilterForm(): void {
		this.filterForm = new FormGroup({
			[Form.organization]: new FormControl([]),
			[Form.criticality]: new FormControl([]),
			[Form.category]: new FormControl([]),
			[Form.application]: new FormControl([]),
			[Form.status]: new FormControl([]),
			[Form.tag]: new FormControl([])
		});
		this.subscription.add(this.filterForm.valueChanges
			.pipe(
				tap(() => this.setActiveFilters()),
				switchMap(() => this.fetchDiagramMagicQuadrant(this.xAxisMetric, this.yAxisMetric)))
			.subscribe());
		this.searchOrganizationControl = new FormControl('');
		this.subscription.add(this.searchOrganizationControl.valueChanges
			.subscribe(search => this.searchOrganizationFilter(search)));
		this.searchCategoryControl = new FormControl('');
		this.subscription.add(this.searchCategoryControl.valueChanges
			.subscribe(search => this.searchCategoryFilter(search)));
		this.searchApplicationControl = new FormControl('');
		this.subscription.add(this.searchApplicationControl.valueChanges
			.subscribe(search => this.searchApplicationFilter(search)));
	}

	fetchDiagramMagicQuadrant(xMetric: AxisChoice, yMetric: AxisChoice): Observable<{}> {
		return this.switchRefreshing().pipe(
			map(() => this.buildDiagramMagicQuadrantFilterForm()),
			switchMap(form => this.tenantService.getAllDiagramApplicationQuadrant(this.tenantId, form)),
			tap(apps => this.setDiagramMagicQuadrant(apps, xMetric, yMetric)),
			finalize(() => this.switchRefreshing()));
	}

	setActiveFilters(): void {
		this.activeFilters = this.organizationFormValue.length
			+ this.criticalityFormValue.length
			+ this.categoryFormValue.length
			+ this.applicationFormValue.length
			+ this.statusFormValue.length
			+ this.tagFormValue.length;
	}

	resetFilters(): void {
		this.filterForm.setValue({
			[Form.organization]: [],
			[Form.criticality]: [],
			[Form.category]: [],
			[Form.application]: [],
			[Form.status]: [],
			[Form.tag]: []
		});
		this.filterForm.markAsPristine();
	}

	openApplicationDetails(application: ApplicationGeneric): void {
		const data: ApplicationDetailInput = {
			applicationId: application.id
		};
		this.rightSliderService.openComponent(ApplicationDetailComponent, data)
			.subscribe();
	}

	navigateToApps(): void {
		this.router.navigate([APPLICATIONS_URL]);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any): void {
		if (!this.container) return;
		this.updateElementsPositioning(this.xAxisMetric, this.yAxisMetric);
		this.createClusters();
	}

	get organizationFormValue(): Organization[] {
		return this.filterForm.get(Form.organization)!.value;
	}

	get criticalityFormValue(): CriticalityLevel[] {
		return this.filterForm.get(Form.criticality)!.value;
	}

	get categoryFormValue(): Category[] {
		return this.filterForm.get(Form.category)!.value;
	}

	get applicationFormValue(): ApplicationGeneric[] {
		return this.filterForm.get(Form.application)!.value;
	}

	get statusFormValue(): ApplicationStatus[] {
		return this.filterForm.get(Form.status)!.value;
	}

	get tagFormValue(): CatalogTag[] {
		return this.filterForm.get(Form.tag)!.value;
	}

	get metricXChoices(): {value: AxisChoice, label: string}[] {
		return this.metricChoices.filter(m => m.value !== this.yAxisMetric);
	}

	get metricYChoices(): {value: AxisChoice, label: string}[] {
		return this.metricChoices.filter(m => m.value !== this.xAxisMetric);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchRefreshing(): Observable<{}> {
		this._refreshing = !this._refreshing;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly AxisChoice = AxisChoice;
}

interface DisplayedApplicationCluster {
	applications: DisplayedApplication[];
	elements: ElementRef[];
	xPosition: number;
	yPosition: number;
}

interface DisplayedApplication {
	application: DiagramApplicationQuadrant;
	xLabel: AxisChoice;
	xValue: number;
	yLabel: AxisChoice;
	yValue: number;
	xPosition: number;
	yPosition: number;
}

enum Form {
	organization = 'organization',
	criticality = 'criticality',
	category = 'category',
	application = 'application',
	status = 'status',
	tag = 'tag'
}

enum AxisChoice {
	cost = 'cost',
	usage = 'usage',
	performance = 'performance',
	health = 'health'
}
