import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {MatRippleModule} from '@angular/material/core';
import {DirectivesModule} from 'src/app/directives/directives.module';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownTriggerDirective} from 'src/app/modules/design-system/dropdown/dropdown-trigger.directive';
import {DiagramLifeCycleComponent} from 'src/app/modules/home/diagrams/diagram-life-cycle/diagram-life-cycle.component';
import {ApplyScalePipe} from 'src/app/modules/home/diagrams/diagram-life-cycle/apply-scale.pipe';
import {DiagramBreadcrumbModule} from 'src/app/modules/home/diagrams/diagram-breadcrumb/diagram-breadcrumb.module';
import {CustomDatePipe} from 'src/app/pipes/date/custom-date.pipe';
import {MatBadgeModule} from '@angular/material/badge';
import {DisplayAppStatusComponent} from "../../../design-system/display-app-status/display-app-status.component";

@NgModule({
  imports: [
    CommonModule,
    DesignSystemModule,
    MatRippleModule,
    DirectivesModule,
    NgOptimizedImage,
    TranslateModule,
    DropdownTriggerDirective,
    DiagramBreadcrumbModule,
    CustomDatePipe,
    MatBadgeModule,
    DisplayAppStatusComponent,
  ],
  declarations: [
    DiagramLifeCycleComponent,
    ApplyScalePipe,
  ],
  exports: [
    DiagramLifeCycleComponent
  ]
})
export class DiagramLifeCycleModule {}
