import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Organization} from 'src/app/services/organization.service';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ExportType} from 'src/app/services/front/export.service';

@Component({
  templateUrl: './worst-noted-apps-drawer.component.html',
  styleUrls: ['./worst-noted-apps-drawer.component.scss']
})
export class WorstNotedAppsDrawerComponent {

  @Input() data: WorstNotedAppsDrawerData;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  search: FormControl = new FormControl();
  export: Subject<ExportType> = new Subject<ExportType>();
  type: typeof ExportType = ExportType;

  close(): void {
    this.onClose.emit('');
  }

	clearInput(): void {
		this.search.setValue('');
	}

	get searchValue(): string {
		return this.search.value;
	}
}

export interface WorstNotedAppsDrawerData {
  filter: Organization|null|undefined;
}
