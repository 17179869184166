import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Organization} from "../../../../../services/organization.service";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatMenuModule} from "@angular/material/menu";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ExportService, ExportType} from "../../../../../services/front/export.service";
import {DeckContentLoaderModule} from "../../../../global/title-content-loader/deck-content-loader.module";
import {ApplicationDocumentation, TenantService} from "../../../../../services/tenant.service";
import {
	ApplicationDetailComponent,
	ApplicationDetailInput
} from "../../../applications/application-detail/application-detail.component";
import {RightSliderService} from "../../../../../services/front/right-slider.service";
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {CurrentTenantService} from "../../../../../services/front/current-tenant.service";
import {ColumnDefinition, TableComponent} from "../../../../design-system/table/table.component";

@Component({
  selector: 'app-application-document-average-drawer',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, FormsModule, MatMenuModule, TranslateModule, ReactiveFormsModule, DeckContentLoaderModule, TableComponent],
  templateUrl: './application-document-average-drawer.component.html',
  styleUrl: './application-document-average-drawer.component.scss'
})
export class ApplicationDocumentAverageDrawerComponent implements OnInit, OnDestroy {
	constructor(private currentTenantService: CurrentTenantService,
				private tenantService: TenantService,
				private exportService: ExportService,
				private translate: TranslateService,
				private rightSliderService: RightSliderService) {}

	@Input() data: DocumentAverageDrawerData;

	@Output() onClose: EventEmitter<any> = new EventEmitter();

	search: FormControl = new FormControl();

	_initializing: boolean;
	_loading: boolean;

	tenantId: string;

	applications: ApplicationDocumentation[];
	displayedApplications: ApplicationDocumentation[];

	@ViewChild('applicationTemplate', { static: true }) applicationTemplate: TemplateRef<any>;
	@ViewChild('documentationTemplate', { static: true }) documentationTemplate: TemplateRef<any>;
	@ViewChild('lastDocumentationDateTemplate', { static: true }) lastDocumentationDateTemplate: TemplateRef<any>;

	columnDefinitions: ColumnDefinition<ApplicationDocumentation>[];
	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.setColumnDefinitions();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.search.valueChanges
			.subscribe(search => this.filterApplications(search)));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getAllApplicationDocumentationByTenantId(this.tenantId, this.data.filter?.organizationId)),
				tap(applications => this.applications = applications),
				tap(() => this.displayedApplications = this.applications),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	setColumnDefinitions(): void {
		this.columnDefinitions = [
			{
				name: 'application',
				label: 'global.table.application.name',
				template: this.applicationTemplate,
				sortFunction: (a, b) => a.application.name.localeCompare(b.application.name),
				width: 40
			},
			{
				name: 'documentationPercent',
				label: 'global.table.application.documentationPercent',
				template: this.documentationTemplate,
				width: 30
			},
			{
				name: 'lastDocumentationDate',
				label: 'global.table.application.lastDocumentationDate',
				template: this.lastDocumentationDateTemplate,
				width: 30
			}
		];
	}

	onSorted(sortedData: ApplicationDocumentation[]): void {
		this.displayedApplications = sortedData;
	}

	onRowClick(application: ApplicationDocumentation): void {
		const data: ApplicationDetailInput = {
			applicationId: application.application.id
		};
		this.subscription.add(this.rightSliderService.openComponent(ApplicationDetailComponent, data)
			.subscribe());
	}

	getDaysDiff(date: Date): string {
		const d1 = new Date();
		const d2 = new Date(date);

		d1.setHours(0, 0, 0, 0);
		d2.setHours(0, 0, 0, 0);

		const diffMs = Math.abs(d2.getTime() - d1.getTime());

		const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

		if (diffDays === 0) {
			return this.translate.instant('page.architectureDashboard.appDocumentAverage.today');
		}
		if (diffDays === 1) {
			return this.translate.instant('page.architectureDashboard.appDocumentAverage.yesterday');
		}
		return this.translate.instant('page.architectureDashboard.appDocumentAverage.sinceXDays', {days: diffDays});
	}

	getParsedDate(date: Date): string {
		return new Date(date).toLocaleDateString();
	}

	filterApplications(search: string): void {
		this.displayedApplications = this.applications.filter(app => app.application.name.toLowerCase().includes(search.toLowerCase()));
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	export(type: ExportType): void {
		const rows: ApplicationDocumentationCsv[] = this.displayedApplications.map(a => ({
			name: a.application.name.replace(/;/g, ''),
			documentationPercent: a.documentationPercent + '%',
			lastDocumentationDate: a.lastDocumentationDate.toString()
		}));
		this.exportService.export(type, rows, 'application-documentation');
	}

	close(): void {
		this.onClose.emit('');
	}

	clearInput(): void {
		this.search.setValue('');
	}

	get searchValue(): string {
		return this.search.value;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly ExportType = ExportType;
}

export interface DocumentAverageDrawerData {
	filter: Organization|null|undefined;
}

interface ApplicationDocumentationCsv {
	name: string;
	documentationPercent: string;
	lastDocumentationDate: string;
}
