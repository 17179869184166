import {Component, Input, OnChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {User} from "../../../../../../services/usage.service";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {
	APPLICATIONS_URL,
	SETTINGS_ACCOUNT_URL,
	SETTINGS_TENANT_URL, SETTINGS_URL
} from "../../../../../../models/home/navigation.model";

@Component({
  selector: 'app-organization-users-list',
  standalone: true,
	imports: [CommonModule, TranslateModule, DeckContentLoaderModule, DesignSystemModule, ReactiveFormsModule],
  templateUrl: './organization-users-list.component.html',
  styleUrl: './organization-users-list.component.scss'
})
export class OrganizationUsersListComponent implements OnChanges {
	constructor(private router: Router) {
	}
	@Input() users: User[] | undefined;
	@Input() incognito: boolean | undefined;

	filteredUsers: User[] = [];

	subscriptions: Subscription = new Subscription();

	openSearch: boolean = false;

	searchUserFormControl: FormControl = new FormControl();

	ngOnChanges(): void {
		this.filteredUsers = this.users || [];
	}

	ngOnInit(): void {
		this.subscriptions.add(this.searchUserFormControl.valueChanges
			.subscribe((value) => this.filterUsers(value)));
	}

	filterUsers(search?: string): void {
		if (!search || search === '') {
			this.filteredUsers = this.users || [];
			return;
		}
		this.filteredUsers = this.users?.filter(user => {
			return user.userName?.toLowerCase().includes(search?.toLowerCase() || '') ||
				user.deviceName?.toLowerCase().includes(search?.toLowerCase() || '');
		}) || [];
	}

	openSearchPanel(input: HTMLInputElement): void {
		this.openSearch = !this.openSearch;
		if (this.openSearch) {
			input.focus();
		} else {
			this.clearInput();
		}
	}

	clearInput() {
		this.searchUserFormControl.setValue('');
	}

	get searchValue(): string {
		return this.searchUserFormControl.value;
	}

	getUsersCount(users: User[] | undefined): number {
		return users?.filter(user => {
			const lastActivity = new Date(user.lastActivity);
			return lastActivity && new Date().getTime() - lastActivity.getTime() < 30 * 24 * 60 * 60 * 1000
		}).length || 0;
	}

	isInactiveFor30Days(date: Date): boolean {
		return new Date().getTime() - new Date(date).getTime() > 30 * 24 * 60 * 60 * 1000;
	}

	isInactiveFor10Days(date: Date): boolean {
		return new Date().getTime() - new Date(date).getTime() > 10 * 24 * 60 * 60 * 1000 && new Date().getTime() - new Date(date).getTime() < 30 * 24 * 60 * 60 * 1000;
	}

	getDiffDays(date: Date): number {
		return Math.floor((new Date().getTime() - new Date(date).getTime()) / (24 * 60 * 60 * 1000));
	}

	redirectToDoc(): void {
		window.open('https://help.kabeen.io/fr/', '_blank', 'noopener');
	}

	goToSettings(): void {
		this.router.navigate([SETTINGS_URL], {queryParams: {tab: 'tenant'}});
	}
}
