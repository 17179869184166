@if (!loading && style === 'full-widget') {
	<table *ngIf="!isEmpty"
		   class="w-100"
		   [ngClass]="{ 'bkg-white': type === 'drawer', 'border-grey': type === 'drawer', 'b-radius-14': type === 'drawer' }"
		   style="border-spacing: 0; border-width: 0.5px !important; table-layout: fixed;">
		<colgroup>
			<col *ngFor="let column of columnDefinitions" [style.width.%]="column.width ? column.width : columnWidth">
		</colgroup>
		<thead style="height: 40px;" [ngClass]="{'header-row': type === 'widget'}">
			<tr>
				@for (column of columnDefinitions; track $index) {
					<th (click)="sortBy(column.name)"
						class="text-overflow-ellipsis font-M fw-500 grey"
						[ngClass]="getHeaderColumnClass($last, $first, column.name)"
						[style.border-top-left-radius.px]="$first ? 14 : 0"
						[style.border-top-right-radius.px]="$last ? 14 : 0">
						<span>{{ column.label | translate }}</span>
						<span *ngIf="type === 'drawer'" [style.visibility]="sortColumn === column.name ? 'visible' : 'hidden'" [ngClass]="{'asc': sortDirection === 'asc', 'desc': sortDirection === 'desc'}">
							<img src="assets/icons/arrow-right-black.svg" class="filter-grey ml-s" height="10" alt="" [ngStyle]="{'transform': 'rotate(' + (sortDirection === 'desc' && sortColumn === column.name ? '90' : '-90') + 'deg)'}">
						</span>
					</th>
				}
			</tr>
		</thead>
		<tbody>
			<tr class="spacer-row"><td colspan="100%"></td></tr>
			@for (row of sortedData; track $index;) {
				<tr (click)="rowClick.emit(row)"
					class="greyed-hover-table-row-full-widget c-p"
				    [style.height.px]="type === 'drawer' ? 50 : 44">
					@for (column of columnDefinitions; track $index; let lastColumn = $last) {
						<td [ngClass]="getColumnClass(lastColumn)">
							<div class="flex-align-center">
								<div class="w-100" [ngClass]="{'text-overflow-ellipsis': !column.template}">
									<ng-container *ngIf="column.template; else defaultTemplate">
										<ng-container *ngTemplateOutlet="column.template; context: { $implicit: row }"></ng-container>
									</ng-container>
									<ng-template #defaultTemplate>
										<span>{{ getNestedValue(row, column.name) }}</span>
									</ng-template>
								</div>
								<img *ngIf="lastColumn && type === 'drawer'" src="assets/icons/arrow-right.svg" class="ml-l" height="12" alt="">
							</div>
						</td>
					}
				</tr>
			}
			<tr class="spacer-row"><td colspan="100%"></td></tr>
		</tbody>
	</table>
	<div *ngIf="isEmpty && type === 'drawer'" class="w-100 bkg-white border-grey b-radius-14" style="border-width: 0.5px !important; height: calc(100vh - 215px); min-height: 300px;">
		<div class="flex-column flex-center gap-L m-auto h-100">
			<img src="assets/illustrations/table-no-data.svg" alt=""/>
			<div class="font-M fw-500 grey">{{ noDataMessage }}</div>
		</div>
	</div>
	<div *ngIf="isEmpty && type === 'widget'" class="w-100" [style.height]="height">
		<div class="flex-column flex-center gap-M m-auto pad-L" [style.height]="height">
			<img src="assets/icons/new-checkmark.svg" height="34" width="34" alt=""/>
			<div class="font-M fw-500 grey">{{ noDataMessage }}</div>
		</div>
	</div>
}

@if (!loading && style === 'only-table') {
	<table *ngIf="!isEmpty"
		   class="w-100"
		   [ngClass]="{ 'bkg-white': type === 'drawer'}"
		   style="border-spacing: 0; border-width: 0.5px !important; table-layout: fixed;">
		<colgroup>
			<col *ngFor="let column of columnDefinitions" [style.width.%]="column.width ? column.width : columnWidth">
		</colgroup>
		<thead style="height: 40px;" [ngClass]="{'header-row': type === 'widget'}">
			<tr>
				@for (column of columnDefinitions; track $index) {
					<th [style.padding-right.px]="$last && deleteRow ? 50 : undefined" style="padding-left: 0" (click)="sortBy(column.name)"
						class="text-overflow-ellipsis font-M fw-500 grey"
						[ngClass]="getHeaderColumnClass($last, $first, column.name)">
						<span>{{ column.label | translate }}</span>
						<span *ngIf="type === 'drawer'" [style.visibility]="sortColumn === column.name ? 'visible' : 'hidden'" [ngClass]="{'asc': sortDirection === 'asc', 'desc': sortDirection === 'desc'}">
							<img src="assets/icons/arrow-right-black.svg" class="filter-grey ml-s" height="10" alt="" [ngStyle]="{'transform': 'rotate(' + (sortDirection === 'desc' && sortColumn === column.name ? '90' : '-90') + 'deg)'}">
						</span>
					</th>
				}
			</tr>
		</thead>
		<tbody>
		<tr class="spacer-row"><td colspan="100%"></td></tr>
			@for (row of sortedData; track $index;) {
				<tr (click)="rowClick.emit(row)"
					class="greyed-hover-table-row-only-table c-p"
					[style.height.px]="type === 'drawer' ? 50 : 44">
					@for (column of columnDefinitions; track $index; let lastColumn = $last) {
						<td style="padding-left: 0; padding-right: 0;" [ngClass]="getColumnClass(lastColumn)">
							<div class="flex-align-center" style="gap: 30px">
								<div class="w-100" [ngClass]="{'text-overflow-ellipsis': !column.template}">
									<ng-container *ngIf="column.template; else defaultTemplate">
										<ng-container *ngTemplateOutlet="column.template; context: { $implicit: row }"></ng-container>
									</ng-container>
									<ng-template #defaultTemplate>
										<span>{{ getNestedValue(row, column.name) }}</span>
									</ng-template>
								</div>
								<app-icon-button *ngIf="lastColumn && type === 'drawer' && this.deleteRow" color="danger" size="m" [img]="'assets/icons/trash.svg'" [tooltip]="'button.delete' | translate"
												 (click)="$event.stopPropagation(); delete.emit(row)">
								</app-icon-button>
							</div>
						</td>
					}
				</tr>
			}
		<tr class="spacer-row"><td colspan="100%"></td></tr>
		</tbody>
	</table>
	<div *ngIf="isEmpty && type === 'drawer'" style="height: calc(100vh - 400px); min-height: 300px;">
		<div class="flex-column flex-center gap-L m-auto h-100">
			<img src="assets/illustrations/table-no-data.svg" alt=""/>
			<div class="font-M fw-500 grey">{{ noDataMessage }}</div>
		</div>
	</div>
	<div *ngIf="isEmpty && type === 'widget'" class="w-100" [style.height]="height">
		<div class="flex-column flex-center gap-M m-auto pad-L" [style.height]="height">
			<img src="assets/icons/new-checkmark.svg" height="34" width="34" alt=""/>
			<div class="font-M fw-500 grey">{{ noDataMessage }}</div>
		</div>
	</div>
}

@if (loading && type === 'drawer') {
	@if (style === 'full-widget') {
		<div class="border-box-full border-grey b-radius-14" style="border-width: 0.5px !important;">
			<app-dashboard-page-loader></app-dashboard-page-loader>
		</div>
	}

	@if (style === 'only-table') {
		<div style="height: 350px">
			<app-dashboard-page-loader></app-dashboard-page-loader>
		</div>
	}
}

@if (loading && type === 'widget') {
	<app-dashboard-page-loader></app-dashboard-page-loader>
}
