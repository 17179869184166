import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export const URL_REGEXP: RegExp  = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
export const PHONE_REGEXP: RegExp = /^\+[1-9]\d{10,14}$/;
// '*://'
export const PREFIX_REGEXP: RegExp = /:\/\//;
// ':*'
export const SUFFIX_PORT_REGEXP: RegExp = /:\w+$/;
// '.*'
export const SUFFIX_DOMAIN_REGEXP: RegExp = /\./;

export const PORTS_RANGE_REGEXP: RegExp = /^(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|\d)(?:,(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|\d))*$|^(?:(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|\d)-(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|\d))$/;

export const IP_ADDRESS_REGEXP: RegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export function prefixValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		return PREFIX_REGEXP.test(control.value) ? null : {prefix: true};
	};
}

export function suffixDomainValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		if (control.value.endsWith('.')) {
			return {suffixDomain: true};
		}

		return SUFFIX_DOMAIN_REGEXP.test(control.value) ? null : {suffixDomain: true};
	};
}

export function suffixPortValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		const port: string | null = control.value.split('?')[0].split('/').slice(0, 3).join('/').split(':')[2];

		if (port == '') {
			return {suffixPort: true};
		}

		if (!port && port != '') {
			return null;
		}

		return SUFFIX_PORT_REGEXP.test(control.value.split('?')[0].split('/').slice(0, 3).join('/')) ? null : {suffixPort: true};
	};
}

export function urlValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		return URL_REGEXP.test(control.value) ? null : {url: true};
	};
}

