import {Observable, Subject} from "rxjs";
import {
	DrawableObject,
	Point,
	RendererClickPressEvent,
	RendererClickReleaseEvent, RendererIsDraggingEvent,
	RendererMouseMoveEvent
} from "./diagram";

type CanvasHandlerState = {};
type RendererEvent = RendererClickPressEvent | RendererClickReleaseEvent | RendererMouseMoveEvent | RendererIsDraggingEvent;

export class CanvasHandler implements DrawableObject<CanvasHandlerState> {

	constructor(protected canvas: HTMLCanvasElement, protected ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D) {
		window.onkeyup = (event) => {
			if (event.ctrlKey || event.key === 'Meta') {
				this.controlKeyActive = false;
			}
		}

		window.onkeydown = (event) => {
			if (event.ctrlKey || event.key === 'Meta') {
				this.controlKeyActive = true;
			}
		}

		let previousTouch = {x: 0, y: 0};

		this.canvas.ontouchstart = (event) => {
			const rect = this.canvas.getBoundingClientRect();

			previousTouch = {x:((event.touches[0].clientX - rect.left) / this.getTransform().d) * this.dpi, y:  ((event.touches[0].clientY - rect.top) / this.getTransform().d) * this.dpi};
		};

		this.canvas.ontouchmove = (event) => {
			event.preventDefault();

			if (event.touches.length === 1) {
				const rect = this.canvas.getBoundingClientRect();
				const x = ((event.touches[0].clientX - rect.left) / this.getTransform().d) * this.dpi;
				const y = ((event.touches[0].clientY - rect.top) / this.getTransform().d) * this.dpi;
				const w = CanvasHandler.windowToCanvasCoordinates(this.ctx, this.dpi, {x, y});

				this.ctx.translate(x - previousTouch.x, y - previousTouch.y);
				this.subject.next({type: 'isDragging', coordinates: w, originalWindowCoordinates: {x: x, y}})
				previousTouch = {x, y};
			}

			if (event.touches.length === 2) {
				// Touch zoom not implemented yet
			}
		}

		this.canvas.onmousedown = (event) => {
			if (event.button === 0) {
				const rect = this.canvas.getBoundingClientRect();
				const x = event.clientX - rect.left;
				const y = event.clientY - rect.top;
				const w = CanvasHandler.windowToCanvasCoordinates(this.ctx, this.dpi, {x, y});

				this.subject.next({type: 'clickPress', coordinates: w, originalWindowCoordinates: {x: x, y}})
			}
		}

		this.canvas.onmousemove = (event) => {
			event.preventDefault();

			const rect = this.canvas.getBoundingClientRect();
			const x = event.clientX - rect.left;
			const y = event.clientY - rect.top;
			const w = CanvasHandler.windowToCanvasCoordinates(this.ctx, this.dpi, {x, y});

			if (this._isDragging) {
				this.ctx.translate(w.x - this.previousMouseX, w.y - this.previousMouseY );
				this.subject.next({type: 'isDragging', coordinates: w, originalWindowCoordinates: {x: x, y}})

			} else {
				this.subject.next({type: 'mouseMove', coordinates: w, originalWindowCoordinates: {x: x, y}})
			}
		}

		this.canvas.addEventListener( 'wheel', (event) => {
			event.preventDefault();

			const rect = this.canvas.getBoundingClientRect();

			const mouseX = event.clientX - rect.left;
			const mouseY = event.clientY - rect.top;

			this.onWheelMove({x: event.deltaX, y: event.deltaY}, {x: mouseX, y: mouseY}, event.ctrlKey);

			const x = event.clientX - rect.left;
			const y = event.clientY - rect.top;
			const w = CanvasHandler.windowToCanvasCoordinates(this.ctx, this.dpi, {x, y});

			this.subject.next({type: 'mouseMove', coordinates: w, originalWindowCoordinates: {x: x, y}})

		}, false)

		this.canvas.addEventListener( 'click', (event) => {
			const rect = this.canvas.getBoundingClientRect();
			const x = event.clientX - rect.left;
			const y = event.clientY - rect.top;
			const w = CanvasHandler.windowToCanvasCoordinates(this.ctx, this.dpi, {x, y});

			this.subject.next({type: 'clickRelease', coordinates: w, originalWindowCoordinates: {x: x, y}})
		})

		this.initialize();
	}

	protected static MAX_ZOOM = 10
	protected static MIN_ZOOM = 0.1
	protected controlKeyActive = false;
	protected subject= new Subject<RendererEvent>();
	protected dpi = 1;

	initialize(): void {
		const dpi = Math.max(window.devicePixelRatio, 2);
		this.dpi = dpi;
		let rect = this.canvas.getBoundingClientRect();

		const windowHeight = rect.height * dpi;
		const windowWidth = rect.width * dpi;

		this.canvas.width = windowWidth
		this.canvas.height = windowHeight
		this.ctx.scale(dpi, dpi);

		this.canvas.style.width = rect.width + 'px';
		this.canvas.style.height = rect.height + 'px';
	}

	onResize(height: number, width: number): void {
		const savedTransform = this.ctx.getTransform();

		this.canvas.width = width * this.dpi
		this.canvas.height = height * this.dpi

		this.ctx.setTransform(savedTransform);

		this.canvas.style.width = width + 'px';
		this.canvas.style.height = height + 'px';
	}

	draw() {
		this.ctx.save();
		// Use the identity matrix while clearing the canvas
		this.ctx.setTransform(1, 0, 0, 1, 0, 0);
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
		// Restore the transform
		this.ctx.restore();
	}

	subscribesToEvents(): Observable<RendererEvent> {
		return this.subject.asObservable();
	}

	protected onWheelMove(mouseDelta: Point, mousePosition: Point, forceCtrKey: boolean | undefined) {

		// Zoom
		if (forceCtrKey || this.controlKeyActive) {
			const transform = this.ctx.getTransform();

			const wheel = ((-mouseDelta.y) / 120);
			const zoom = Math.pow(1.0 + Math.abs(wheel), wheel > 0 ? 1 : -1);

			if (transform.d * zoom < CanvasHandler.MAX_ZOOM && transform.d * zoom > CanvasHandler.MIN_ZOOM) {
				const canvasRealPosition = CanvasHandler.windowToCanvasCoordinates(this.ctx, this.dpi, mousePosition)
				this.ctx.translate(canvasRealPosition.x, canvasRealPosition.y)
				this.ctx.scale(zoom, zoom)
				this.ctx.translate(-canvasRealPosition.x, -canvasRealPosition.y)
			}
		} else {
			// Move
			const scale = (1/this.ctx.getTransform().d)*2;
			this.ctx.translate( -mouseDelta.x  * scale,-mouseDelta.y  * scale )
		}
	}

	static windowToCanvasCoordinates(ctx: any, dpi:number, coordinates: Point): Point {
		const transform = ctx.getTransform();
		const scaleX = transform.a / dpi;
		const scaleY = transform.d / dpi;

		const transformedX =  (coordinates.x  / scaleX) - ((transform.e / dpi)  / scaleX);
		const transformedY = (coordinates.y / scaleY) - ((transform.f / dpi) / scaleY);
		return { x: transformedX, y: transformedY };
	}

	static canvasToWindowCoordinates(ctx: any, dpi:number, coordinates: Point): Point {
		const transform = ctx.getTransform()
		// Calculate the inverse transformation matrix
		const inverseTransform = new DOMMatrix();
		inverseTransform.setMatrixValue(transform.inverse());

		// Calculate the scale factors
		const scaleX = inverseTransform.a * dpi;
		const scaleY = inverseTransform.d * dpi;

		// Apply the inverse transformation to the coordinates
		const transformedX = (coordinates.x  / scaleX) - ((inverseTransform.e  / scaleX ));
		const transformedY = (coordinates.y / scaleY) - ((inverseTransform.f / scaleY ));
		return { x: transformedX, y: transformedY };
	}

	protected doZoom(zoomFactor: number): void {
		const transform = this.ctx.getTransform();

		const zoom = Math.pow(1.0 + Math.abs(zoomFactor) / 2.0 , zoomFactor > 0 ? 1 : -1);

		if (transform.d * zoom < CanvasHandler.MAX_ZOOM && transform.d * zoom > CanvasHandler.MIN_ZOOM) {
			this.ctx.scale(zoom , zoom)
		}
	}

	zoomOut(): void {
		this.doZoom(-1)
	}

	zoomIn(): void {
		this.doZoom(1)
	}

	recenter(): void {
		const transform = this.ctx.getTransform();
		this.ctx.setTransform(transform.a, transform.b, transform.c, transform.d, 100, 100)
	}

	getZoomPercent(): number {
		return (this.ctx.getTransform().d / this.dpi) * 100;
	}

	getTransform(): DOMMatrix {
		return this.ctx.getTransform();
	}

	setTransform(transform: DOMMatrix): void {
		this.ctx.setTransform(transform.a, transform.b, transform.c, transform.d, transform.e, transform.f)
	}

	onFocusOut() {
		this.controlKeyActive = false;
	}

	update(state: CanvasHandlerState = {}): void {
		// Do nothing
	}

	protected _isDragging = false;
	protected previousMouseX = 0;
	protected previousMouseY = 0;

	canDrag(value: boolean, point?: Point): void {
		this._isDragging  = value
		if (value && point) {
			this.previousMouseX = point.x;
			this.previousMouseY = point.y;
		}
	}

	isDragging(): boolean {
		return this._isDragging;
	}
}
