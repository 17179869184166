import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {distinctUntilChanged, finalize, mergeMap, Observable, of, startWith, Subscription, takeWhile, tap} from 'rxjs';
import {EXTENSION_URL, SETTINGS_ROVERS_URL} from 'src/app/models/home/navigation.model';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {filter, first, map} from 'rxjs/operators';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {Router} from '@angular/router';
import {ApplicationSettingUsageForm, ApplicationInstance} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {RightSliderService} from "../../../../../../services/front/right-slider.service";

@Component({
	selector: 'app-application-setting-usage',
	templateUrl: './application-setting-usage.component.html',
	styleUrls: ['../application-settings.component.scss']
})
export class ApplicationSettingUsageComponent implements OnInit, OnDestroy {

	tenantId: string;
	isEditor: boolean;
	application: ApplicationInstance;

	_initializing: boolean;
	_loading: boolean;
	initialized = false;

	usageForm: FormGroup;
	updateUsageOnBlur = new EventEmitter<Form>();

	formName = Form;

	subscription: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationService: NewApplicationService,
							private translate: TranslateService,
							private snackBar: SnackbarService,
							private router: Router,
				private rightSliderService: RightSliderService) {
	}

	ngOnInit() {
		this.buildForm();
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.setDefaultData()),
				tap(() => this.fixMarkAsTouched()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private buildForm(): void {
		this.usageForm = new FormGroup({
			[Form.usagePath]: new FormControl(undefined, []),
			[Form.usageDomain]: new FormControl(undefined, []),
			[Form.usageCheck]: new FormControl(undefined, {}),
			[Form.desktopApplicationNames]: new FormArray([], [Validators.required, Validators.minLength(1)])
		});
		this.usageForm.disable();

		this.subscription.add(this.usageForm.get(Form.usageDomain)!.valueChanges
			.pipe(distinctUntilChanged())
			.subscribe(() => this.checkUsageFormDisabled()));

		this.subscription.add(this.usageForm.get(Form.usageCheck)!.valueChanges
			.pipe(filter((value) => value !== undefined && value !== null && value !== this.application.usageActivated))
			.subscribe(() => this.updateUsageOnBlur.emit(Form.usageCheck)));

		this.subscription.add(this.updateUsageOnBlur
			.pipe(filter(() => !this.isUsageFormValid()))
			.subscribe(() => this.setUsageFormDefaultData(this.application, false)));

		this.updateUsageOnBlur
			.pipe(
				filter(formName => this.isUsageFormValid() && this.checkUsageFormChanged(formName)),
				map(() => this.buildApplicationSettingUsageForm()),
				mergeMap(form => this.applicationService.updateApplicationSettingUsage(this.tenantId, this.application.applicationId, form)),
				tap(updateSucceed => this.displayUpdatedSucceed(updateSucceed)),
				tap(() => this.applicationDetailService.refreshApplicationInstance()),
				takeWhile(() => !this.subscription.closed))
			.subscribe();
	}

	private fixMarkAsTouched(): void {
		Object.values(Form)
			.map(name => this.usageForm.get(name)!)
			.filter(control => !!control.validator)
			.forEach(control => control.valueChanges.pipe(first()).subscribe(() => control.markAsTouched()));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.application = data.instance;
	}

	private setDefaultData(): void {
		this.setUsageFormDefaultData(this.application);
		this.checkUsageFormDisabled();
	}

	private setUsageFormDefaultData(data: ApplicationInstance, updateDesktopAppNames: boolean = true): void {
		this.usageForm.get(Form.usagePath)!.setValue(data.usagePath);
		this.usageForm.get(Form.usageDomain)!.setValue(data.usageDomain);
		this.usageForm.get(Form.usageCheck)!.setValue(data.usageActivated, { emitEvent: false });
		if (updateDesktopAppNames) {
			(this.usageForm.get(Form.desktopApplicationNames) as FormArray).clear({ emitEvent: false });
			data.desktopApplicationNames.forEach(name => (this.usageForm.get(Form.desktopApplicationNames) as FormArray).push(new FormControl(name, [Validators.required])));
		}
	}

	checkUsageFormDisabled(): void {
		if (!this.isEditor) {
			this.usageForm.disable();
		} else {
			this.usageForm.enable();
			if (!this.usageForm.get(Form.usageDomain)!.value || !this.usageForm.get(Form.usageDomain)!.valid) {
				this.usageForm.get(Form.usagePath)!.disable();
				this.usageForm.get(Form.usagePath)!.setValue(undefined);
			} else {
				this.usageForm.get(Form.usagePath)!.enable();
			}
		}
	}

	isUsageFormValid(): boolean {
		return this.usageForm.dirty && this.usageForm.valid && this.usageForm.enabled;
	}

	checkUsageFormChanged(formName?: Form): boolean {
		return !formName
			?  Object.values(Form).map(formName => this.checkUsageForm(formName)).reduce((a, b) => a || b)
			: this.checkUsageForm(formName);
	}

	private checkUsageForm(formName: Form): boolean {
		switch (formName) {
			case Form.usageCheck: return this.usageForm.get(Form.usageCheck)!.value !== this.application.usageActivated;
			case Form.usageDomain: return this.usageForm.get(Form.usageDomain)!.value !== this.application.usageDomain;
			case Form.usagePath: return this.usageForm.get(Form.usagePath)!.value !== this.application.usagePath;
			case Form.desktopApplicationNames: return this.desktopApplicationNamesValues.join() !== this.application.desktopApplicationNames.join();
		}
	}

	private buildApplicationSettingUsageForm(): ApplicationSettingUsageForm {
		const nonEmptyDesktopApplicationNames = this.desktopApplicationNamesValues.filter(name => !!name);
		return {
			usageActivated: this.usageForm.get(Form.usageCheck)!.value,
			usageDomain: this.usageForm.get(Form.usageDomain)!.value,
			usagePath: this.usageForm.get(Form.usagePath)!.value,
			desktopApplicationNames: nonEmptyDesktopApplicationNames
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackBar.show(this.translate.instant('page.application.detail.update.success'));
		} else {
			this.snackBar.show(this.translate.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	goToSettings() {
		this.router.navigate([EXTENSION_URL], { queryParams: { tenant: this.tenantId } }).then(() => this.rightSliderService.close());
	}

	removeDesktopApplicationName(control: FormControl): void {
		const nonEmptyDesktopApplicationNames = this.desktopApplicationNamesValues.filter(name => !!name);
		if (nonEmptyDesktopApplicationNames.length > 1 || control.value === '' || control.value === undefined || control.value === null) {
			const index = this.desktopApplicationNamesControl.indexOf(control);
			(this.usageForm.get(Form.desktopApplicationNames) as FormArray).removeAt(index);
			this.usageForm.markAsDirty();
			this.updateUsageOnBlur.emit(Form.desktopApplicationNames);
		}
	}

	addNewDesktopApplicationName(): void {
		(this.usageForm.get(Form.desktopApplicationNames) as FormArray).push(new FormControl(undefined, []));
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	get usageCheckControl(): FormControl {
		return this.usageForm.get(Form.usageCheck) as FormControl;
	}

	get usageDomainControl(): FormControl {
		return this.usageForm.get(Form.usageDomain) as FormControl;
	}

	get usagePathControl(): FormControl {
		return this.usageForm.get(Form.usagePath) as FormControl;
	}

	get desktopApplicationNamesControl(): FormControl[] {
		return (this.usageForm.get(Form.desktopApplicationNames) as FormArray).controls as FormControl[];
	}

	get desktopApplicationNamesValues(): string[] {
		return this.desktopApplicationNamesControl.map(control => control.value);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly Form = Form;
}

enum Form {
	usageDomain = 'usageDomain',
	usagePath = 'usagePath',
	usageCheck = 'usageCheck',
	desktopApplicationNames = 'desktopApplicationNames'
}
