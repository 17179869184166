import {NgModule} from "@angular/core";
import {DiagramAppMatrixComponent} from "src/app/modules/home/diagrams/diagram-app-matrix/diagram-app-matrix.component";
import {CommonModule} from "@angular/common";
import {MatRippleModule} from "@angular/material/core";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonModule} from "@angular/material/button";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {DeckContentLoaderModule} from 'src/app/modules/global/title-content-loader/deck-content-loader.module';
import {DiagramToolbarComponent} from 'src/app/modules/home/diagrams/diagram-toolbar/diagram-toolbar.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DropdownTriggerDirective} from 'src/app/modules/design-system/dropdown/dropdown-trigger.directive';
import {DiagramBreadcrumbModule} from 'src/app/modules/home/diagrams/diagram-breadcrumb/diagram-breadcrumb.module';
import {MatBadgeModule} from '@angular/material/badge';
import {DisplayAppStatusComponent} from "../../../design-system/display-app-status/display-app-status.component";
import {DirectivesModule} from 'src/app/directives/directives.module';
import {CostPipe} from 'src/app/pipes/number/cost.pipe';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
	declarations: [
		DiagramAppMatrixComponent
	],
  imports: [
    CommonModule,
    MatRippleModule,
    DeckContentLoaderModule,
    ContentLoaderModule,
    TranslateModule,
    RouterModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    DiagramToolbarComponent,
    DesignSystemModule,
    MatProgressSpinnerModule,
    DropdownTriggerDirective,
    DiagramBreadcrumbModule,
    MatBadgeModule,
    DisplayAppStatusComponent,
    DirectivesModule,
    CostPipe,
    MatMenuModule,
  ],
	exports: [
		DiagramAppMatrixComponent
	]
})
export class DiagramAppMatrixModule {
}
