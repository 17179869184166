import {ActivatedRoute, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {
	OrganizationSettingsMode
} from "../../modules/home/organization/organization-detail/organization-settings/organization-settings.component";

@Injectable()
export class OrganizationUrlService {
	constructor(protected activatedRoute: ActivatedRoute, protected router: Router) {
	}

	public getCurrentOrganizationTab(): number {
		let index = this.activatedRoute.snapshot.queryParamMap.get('selectedOrganizationTab');
		if (index != null)
			return parseInt(index)
		return 0;
	}
	public getParameter(): number | null {
		let index = this.activatedRoute.snapshot.queryParamMap.get('settings');
		if (index != null)
			return parseInt(index)
		return null
	}

	public changeCurrentOrganizationTab(index: number | null) {
		this.router.navigate([this.router.url.split('?')[0]], {queryParams: {'selectedOrganizationTab': index}, queryParamsHandling: "merge"})
	}

	public getCurrentOrganization(): string | null {
		return this.activatedRoute.snapshot.queryParamMap.get('organizationId');
	}

	public changeCurrentOrganization(id: string | null) {
		if (id !== null)
			this.router.navigate([], {queryParams: {organizationId: id}, queryParamsHandling: "merge"})
		else
			this.router.navigate([], {queryParams: {organizationId: null, selectedOrganizationTab: null, settings: null}, queryParamsHandling: "merge"})
	}

	navigateInAndOutOfParameter(parameter: OrganizationSettingsMode | null) {
		this.navigateInAndOut(parameter);
	}

	clearSettingParameter() {
		this.navigateInAndOut(null);
	}

	private navigateInAndOut(setting: OrganizationSettingsMode|null): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: { settings: setting },
			queryParamsHandling: 'merge'
		});
	}
}
