<div *ngIf="!tenant?.plan?.isTrialPlan() && !tenant?.plan?.isActivePlan()" class="pl-l pr-l mt-m">
	<app-chips color="error">{{'page.dashboard.limited.badge' | translate}}</app-chips>
	<div class="font-XS grey mt-xs">{{'page.dashboard.limited.headline' | translate}}</div>
	<div class="font-XS grey">{{'page.dashboard.limited.link.0' | translate}} <a [routerLink]="[settingsBillingUrl]" [queryParams]="activatedRoute.snapshot.queryParams"> {{'page.dashboard.limited.link.1' | translate}} </a> {{'page.dashboard.limited.link.2' | translate}}</div>
</div>

<div class="main-container pt-xl pb-l pl-xl pr-xl disp-f gap-L">
	<div class="w-100 disp-f f-d-col gap-L overflow-hidden left-extension-panel" style="flex: 4; height: 100%; min-width: 550px">
		<div class="w-100 h-100 widget" style="width: calc(100% - 2px); height: 319px;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-dashboard-page-loader *ngIf="_initializing || _loading"></app-dashboard-page-loader>
			<app-usage-graph [height]="250" [displayRange]="false" [widgetTitle]="'page.extension.extensionsDeployed'|translate"></app-usage-graph>
		</div>
		<div class="w-100 h-100 widget" style="width: calc(100% - 2px); height: 660px;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-dashboard-page-loader *ngIf="_initializing || _loading"></app-dashboard-page-loader>
			<app-announcements-widget [limit]="7" *ngIf="!_initializing && !_loading" [noAnnouncementWidth]="280" [data]="announcementList" [tenantId]="tenant.configuration.id"></app-announcements-widget>
		</div>
	</div>
	<div class="w-100 widget" style="min-width: 600px; flex: 3; height: 980px">
		<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
		<app-dashboard-page-loader *ngIf="_initializing"></app-dashboard-page-loader>
		<app-extension-settings></app-extension-settings>
	</div>
</div>
