import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {finalize, mergeMap, Observable, of, Subscription, takeWhile, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {filter, first, map} from 'rxjs/operators';
import {FormControl, FormControlName, FormGroup, Validators} from '@angular/forms';
import {PHONE_REGEXP, URL_REGEXP} from 'src/app/utils/forms.utils';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {ApplicationInstance, ApplicationSettingGeneralForm} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';

@Component({
	selector: 'app-application-setting-general',
	templateUrl: './application-setting-general.component.html',
	styleUrls: ['../application-settings.component.scss']
})
export class ApplicationSettingGeneralComponent implements OnInit, OnDestroy {

	tenantId: string;
	isEditor: boolean;
	application: ApplicationInstance;
	uploadImage = false;
	uploading = false;

	_initializing: boolean;
	_loading: boolean;
	initialized = false;

	generalForm: FormGroup;
	updateGeneralOnBlur = new EventEmitter<Form>();

	formName = Form;

	subscription: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationService: NewApplicationService,
							private translate: TranslateService,
							private snackBar: SnackbarService) {
	}

	ngOnInit() {
		this.buildForm();
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.setDefaultData()),
				tap(() => this.fixMarkAsTouched()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private buildForm(): void {
		this.generalForm = new FormGroup({
			[Form.name]: new FormControl(undefined, [Validators.required]),
			[Form.description]: new FormControl(undefined, []),
			[Form.accessUrl]: new FormControl(undefined, [Validators.pattern(URL_REGEXP)]),
			[Form.supportPhone]: new FormControl(undefined, []),
			[Form.supportEmail]: new FormControl(undefined, []),
			[Form.supportUrl]: new FormControl(undefined, [])
		});
		this.generalForm.disable();
		this.subscription.add(this.updateGeneralOnBlur
			.pipe(filter(() => !this.isGeneralFormValid()))
			.subscribe(() => this.setGeneralFormDefaultData(this.application)));
		this.updateGeneralOnBlur
			.pipe(
				filter(formName => this.isGeneralFormValid() && this.checkGeneralFormChanged(formName)),
				map(() => this.buildApplicationSettingGeneralForm()),
				mergeMap(form => this.applicationService.updateApplicationSettingGeneral(this.tenantId, this.application.applicationId, form)),
				tap(updateSucceed => this.displayUpdatedSucceed(updateSucceed)),
				tap(() => this.applicationDetailService.refreshApplicationInstance()),
				takeWhile(() => !this.subscription.closed))
			.subscribe();
	}

	private fixMarkAsTouched(): void {
		Object.values(Form)
			.map(name => this.generalForm.get(name)!)
			.filter(control => !!control.validator)
			.forEach(control => control.valueChanges.pipe(first()).subscribe(() => control.markAsTouched()));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.application = data.instance;
	}

	private setDefaultData(): void {
		this.setGeneralFormDefaultData(this.application);
		this.checkGeneralFormDisabled();
	}

	private setGeneralFormDefaultData(data: ApplicationInstance): void {
		this.generalForm.get(Form.name)!.setValue(data.name);
		this.generalForm.get(Form.description)!.setValue(data.description);
		this.generalForm.get(Form.accessUrl)!.setValue(data.accessUrl);
		this.generalForm.get(Form.supportPhone)!.setValue(!data.supportPhone ? data.catalog?.supportPhone : data.supportPhone);
		this.generalForm.get(Form.supportEmail)!.setValue(!data.supportEmail ? data.catalog?.supportEmail : data.supportEmail);
		this.generalForm.get(Form.supportUrl)!.setValue(!data.supportUrl ? data.catalog?.supportUrl : data.supportUrl);
	}

	checkGeneralFormDisabled(): void {
		if (!this.isEditor) {
			this.generalForm.disable();
		} else {
			this.generalForm.enable();
		}
	}

	isGeneralFormValid(): boolean {
		return this.generalForm.dirty && this.generalForm.valid && this.generalForm.enabled;
	}

	checkGeneralFormChanged(formName?: Form): boolean {
		return !formName
			?  Object.values(Form).map(formName => this.checkGeneralForm(formName)).reduce((a, b) => a || b)
			: this.checkGeneralForm(formName);
	}

	private checkGeneralForm(formName: Form): boolean {
		switch (formName) {
			case Form.accessUrl: return this.generalForm.get(Form.accessUrl)!.value !== this.application.accessUrl;
			case Form.description: return this.generalForm.get(Form.description)!.value !== this.application.description;
			case Form.name: return this.generalForm.get(Form.name)!.value !== this.application.name;
			case Form.supportEmail: return this.generalForm.get(Form.supportEmail)!.value !== this.application.supportEmail;
			case Form.supportPhone: return this.generalForm.get(Form.supportPhone)!.value !== this.application.supportPhone;
			case Form.supportUrl: return this.generalForm.get(Form.supportUrl)!.value !== this.application.supportUrl;
		}
	}

	private buildApplicationSettingGeneralForm(): ApplicationSettingGeneralForm {
		const supportPhone = this.generalForm.get(Form.supportPhone)!.value;
		if (!!supportPhone) {
			this.generalForm.get(Form.supportPhone)!.setValue(supportPhone.replace(/\s/g, ''), {emitEvent: false});
		}
		return {
			name: this.generalForm.get(Form.name)!.value,
			description: this.generalForm.get(Form.description)!.value,
			accessUrl: this.generalForm.get(Form.accessUrl)!.value,
			supportPhone: this.generalForm.get(Form.supportPhone)!.value,
			supportEmail: this.generalForm.get(Form.supportEmail)!.value,
			supportUrl: this.generalForm.get(Form.supportUrl)!.value,
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackBar.show(this.translate.instant('page.application.detail.update.success'));
		} else {
			this.snackBar.show(this.translate.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	selectFile() {
		if (!this.uploading) {
			const input: HTMLInputElement = document.createElement('input');
			input.type = 'file';
			input.accept = 'image/*';
			input.multiple = false;
			input.onchange = (event: Event) => {
				const target: HTMLInputElement = event.target as HTMLInputElement;
				if (target.files && target.files.length > 0) {
					Array.prototype.forEach.call(target.files, (file: File) => {
						this.uploadFile(file);
					});
				}
			};
			input.click();
		}
	}

	onDrop(event: any) {
		if (!this.uploading) {
			event.preventDefault();
			if (event.dataTransfer.files.length > 0) {
				Array.prototype.forEach.call(event.dataTransfer.files, (file) => {
					this.uploadFile(file);
				})
			}
		}
	}

	private uploadFile(file: File): void {
			if (
				file.type !== 'image/png'
				&& file.type !== 'image/jpeg'
				&& file.type !== 'image/jpg'
				&& file.type !== 'image/webp'
			) {
				this.snackBar.show(this.translate.instant('page.appDetails.settings.imageTypeNotSupported'), 'danger-snack');
			} else if (file.size / 1000000 > 10) {
				this.snackBar.show(this.translate.instant('page.appDetails.settings.imageTooBig'), 'danger-snack');
			}
			else {
				this.uploading = true;
				this.applicationService.uploadApplicationIcon(this.tenantId, this.application.applicationId, file)
					.subscribe({
						next: result => {
							if (result) this.snackBar.show(this.translate.instant('page.appDetails.settings.imageUploadSuccess'), 'success-snack');
							else this.snackBar.show(this.translate.instant('page.appDetails.settings.imageUploadError'), 'danger-snack');
							this.uploading = false;
							this.uploadImage = false;
							this.applicationDetailService.refreshApplicationInstance();
						},
						error: error => {
							this.uploading = false;
							this.snackBar.show(this.translate.instant('page.appDetails.settings.imageUploadError'), 'danger-snack');
						}
					});
			}
	}

	get nameFormControl(): FormControl {
		return this.generalForm.get(Form.name) as FormControl;
	}

	get descriptionFormControl(): FormControl {
		return this.generalForm.get(Form.description) as FormControl;
	}

	get accessUrlFormControl(): FormControl {
		return this.generalForm.get(Form.accessUrl) as FormControl;
	}

	get supportPhoneFormControl(): FormControl {
		return this.generalForm.get(Form.supportPhone) as FormControl;
	}

	get supportEmailFormControl(): FormControl {
		return this.generalForm.get(Form.supportEmail) as FormControl;
	}

	get supportUrlFormControl(): FormControl {
		return this.generalForm.get(Form.supportUrl) as FormControl;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

enum Form {
	name = 'name',
	description = 'description',
	accessUrl = 'accessUrl',
	supportPhone = 'phone',
	supportEmail = 'supportEmail',
	supportUrl = 'supportUrl'
}
