<dialog [@inOutAnimation] #dialogElement class="mobile-dialog">
	<div class="flex-justify-end">
		<app-icon-button (click)="dismissModal()" img='assets/icons/close.svg' size='s' color='accent' type='stroked'
						 shape='default' [disabled]='false' [focused]='false'
						 [background]='true' panelClass='img-size-30'></app-icon-button>
	</div>
	<div class="flex-align-center flex-column gap-XL" style="height: calc(100% - 84px); margin-top: 50px;">
		<img src="assets/illustrations/mobile-modal.svg" alt="modal" width="163">
		<span class="font-XL primary text-center fw-700">{{ 'global.mobileModal.hello' | translate: { firstName: currentUser?.firstName } }}</span>
		<span class="text-center font-MM primary fw-400 w-90" style="line-height: 23px;" [innerHTML]="'global.mobileModal.message' | translate"></span>
	</div>
</dialog>
<div *ngIf="!_initializing && isTrialPlan && (defaultTenant && defaultTenant.configuration.onboardingCompleted)" class="trial-top-banner">
	&nbsp;{{ 'page.home.trialPlan' | translate}}&nbsp;
	<a style="color: white!important; text-decoration: underline" [routerLink]="[settingsBillingUrl]" [queryParams]="activatedRoute.snapshot.queryParams">{{'page.home.trialPlanLink' | translate}}</a>
	&nbsp;{{ 'page.home.trialPlanDate' | translate}}&nbsp;
	{{ defaultTenant.plan.asTrialPlan().endAt | date: translateService.instant('global.date') }}.
</div>

<app-onboarding *ngIf="(isNoTenant || (defaultTenant && !defaultTenant.configuration.onboardingCompleted)) && !_initializing"></app-onboarding>

<app-alert [color]="'warning'" *ngIf="false"> {{ 'alert.warning.unknown_tenant_error' | translate }} <a href="/">{{ 'alert.warning.unknown_tenant_error_link' | translate }}</a></app-alert>
<app-alert [color]="'warning'" *ngIf="false">{{ 'alert.warning.loading_tenant_error' | translate }}</app-alert>

<div *ngIf="!_initializing && isInactivePlan" class="trial-top-banner danger">
	&nbsp;{{ 'page.home.expiredPlan' | translate}}&nbsp;
	<a style="color: white!important; text-decoration: underline" [routerLink]="[settingsBillingUrl]" [queryParams]="activatedRoute.snapshot.queryParams">{{'page.home.expiredPlanLink' | translate}}</a>
</div>

<mat-drawer-container class="mat-elevation-z0" hasBackdrop="true" (backdropClick)="onBackdropClose()">
	<mat-drawer class="mat-elevation-z0" mode="over" position="end" style="width: 800px; background-color: #FAFAFC;"
				 [disableClose]="true" [opened]="rightSliderService.open"
				 (mousedown)="$event.stopPropagation()">
		<ng-container #dynamicContainer></ng-container>
	</mat-drawer>

	<mat-drawer-content>
		<mat-sidenav-container autosize="true" *ngIf="(defaultTenant && defaultTenant.configuration.onboardingCompleted)" hasBackdrop="false" class="home-page" [class.has-banner]="!isActivePlan">

			<mat-sidenav style="overflow: visible" #drawer mode="side" [opened]="(menuServiceState$ | async)?.open ?? true" class="ng-deep-no-scroll-x overflow-visible" [style.min-width]="(menuServiceState$ | async)?.width  + 'px'" >
				<div (click)="toggleMenu(drawer)" [ngClass]="{ 'opened-sidenav-btn': (menuServiceState$|async)?.open, 'closed-sidenav-btn': !(menuServiceState$|async)?.open }">
					<img src="assets/icons/chevron-left-double.svg" height="19">
				</div>
				<div class="flex-column menu-container" style="gap: 34px;">
					<div class="disp-f a-i-c j-c-c logo-height pad-M-0" style="width: 60px; margin-left: 16px; margin-top: 5px">
						<img alt="application logo" [ngSrc]="environment.applicationLogo" width="34" height="34"/>
					</div>

					<app-main-menu class="h-100"
								   [menuGroups]="menuGroups"
								   [latestAtBottom]="true">
					</app-main-menu>
				</div>
			</mat-sidenav>

			<mat-sidenav-content [style.margin-left]="(menuServiceState$|async)?.width + 'px'" class="main-container">
				<app-topbar></app-topbar>
				<router-outlet></router-outlet>
			</mat-sidenav-content>

		</mat-sidenav-container>
	</mat-drawer-content>

</mat-drawer-container>
