import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {ApplicationMappingService} from 'src/app/services/back/application-mapping.service';
import {ApplicationMappingData, ApplicationMapping} from 'src/app/services/model/application-mapping.model';
import {MatDialog} from '@angular/material/dialog';
import {filter, map} from 'rxjs/operators';
import {ConfirmComponent, ConfirmModel} from 'src/app/modules/global/dialog/confirm/confirm.component';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {ApplicationMappingApplicationFormData} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-application-form/application-mapping-application-form.component';
import {ApplicationMappingApplicationFormDialogComponent} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-application-form/dialog/application-mapping-application-form-dialog.component';
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {FileLink, FileType} from "src/app/services/model/tenant-file.model";
import {TenantFileService} from "src/app/services/back/tenant-file.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
	selector: 'app-application-mapping-application-preview',
	templateUrl: './application-mapping-application-preview.component.html',
	styleUrls: ['./application-mapping-application-preview.component.scss'],
})
export class ApplicationMappingApplicationPreviewComponent implements OnInit, OnDestroy {

	@Output() updated = new EventEmitter<void>();

	tenantId: string;
	applicationId: string;
	isEditor: boolean = false;

	_initializing: boolean;
	_loading: boolean;
	_loadingDialog: boolean;
	_deleting: string|undefined;
	initialized: boolean = false;

	displayedTooltip: ApplicationMapping | undefined;

	applicationMappingList: ApplicationMapping[] = [];
	formData?: {
		applicationList: ApplicationGeneric[],
		mappingDataList: ApplicationMappingData[]
	};
	subscriptions: Subscription = new Subscription();

	constructor(private applicationMappingService: ApplicationMappingService,
							private applicationDetailService: ApplicationDetailService,
							private applicationService: NewApplicationService,
							private tenantFileService: TenantFileService,
							private translate: TranslateService,
							private snackBar: SnackbarService,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	displayTooltip(mapping: ApplicationMapping): void {
		this.displayedTooltip = mapping;
	}

	hideTooltip(): void {
		this.displayedTooltip = undefined;
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.applicationId = data.instance.applicationId;
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => this.applicationMappingService.getAllApplicationMappingByApplicationId(this.tenantId, this.applicationId)),
				tap(mappingList => this.setApplicationMappingList(mappingList)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private setApplicationMappingList(mappingList: ApplicationMapping[]): void {
		this.applicationMappingList = mappingList.sort((a, b) => {
			const otherAName = a.source.id === this.applicationId ? a.target.name : a.source.name;
			const otherBName = b.source.id === this.applicationId ? b.target.name : b.source.name;
			return otherAName.localeCompare(otherBName);
		});
	}

	getDataTooltip(mappingData: ApplicationMappingData[]): string {
		let str = '';
		mappingData.forEach(el => str += el.name + '\n');
		return str;
	}

	sizePrettily(size: number | undefined): string {
		if (!size) return '';
		if (size < 1024) {
			return `${size} B`;
		} else if (size < 1024 * 1024) {
			return `${Math.round(size / 1024)} KB`;
		} else if (size < 1024 * 1024 * 1024) {
			return `${Math.round(size / (1024 * 1024))} MB`;
		} else {
			return `${Math.round(size / (1024 * 1024 * 1024))} GB`;
		}
	}

	downloadFile(upload: FileLink) {
		this.tenantFileService.downloadTenantFile(this.tenantId, upload.fileLinkId)
			.subscribe((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = upload.name;
				link.click();
			});
	}

	openLink(link: string) {
		window.open(link, '_blank');
	}

	openApplicationMappingFormDialog(defaultData?: ApplicationMapping): void {
		this.switchLoadingDialog()
			.pipe(
				switchMap(() => this.getApplicationMappingFormData()),
				map(formData => this.buildApplicationMappingApplicationFormData(formData, defaultData)),
				switchMap(data => this.dialog.open(ApplicationMappingApplicationFormDialogComponent, { position: { right: '162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(updated => !!updated),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()),
				finalize(() => this.switchLoadingDialog()))
			.subscribe(() => this.initialize());
	}

	private getApplicationMappingFormData(): Observable<{ applicationList: ApplicationGeneric[], mappingDataList: ApplicationMappingData[] }> {
		return of(this.formData).pipe(
			switchMap(formData => !formData
				? forkJoin([
					this.applicationService.getAllApplication(this.tenantId),
					this.applicationMappingService.getAllApplicationMappingDataByTenantId(this.tenantId)])
					.pipe(map(([applicationList, mappingDataList]) => ({ applicationList, mappingDataList })))
				: of(formData)),
			tap(formData => this.formData = formData));
	}

	private buildApplicationMappingApplicationFormData(formData: { applicationList: ApplicationGeneric[], mappingDataList: ApplicationMappingData[] }, defaultData?: ApplicationMapping): ApplicationMappingApplicationFormData {
		return {
			tenantId: this.tenantId,
			applicationId: this.applicationId,
			defaultData: defaultData,
			preloaded: {
				applicationList: formData.applicationList,
				mappingDataList: formData.mappingDataList
			}
		}
	}

	deleteApplicationMapping(flowId: string): void {
		const data: ConfirmModel = {
			message: this.translate.instant('confirmModal.deleteFlow'),
			closeButton: this.translate.instant('button.cancel'),
			confirmButton: this.translate.instant('button.delete')
		};
		this.dialog.open<ConfirmComponent, any, boolean>(ConfirmComponent, { data: data })
			.afterClosed()
			.pipe(
				filter(result => !!result),
				tap(() => this.switchDeleting(flowId)),
				switchMap(() => this.applicationMappingService.deleteApplicationMapping(this.tenantId, flowId)),
				tap(() => this.switchDeleting(flowId)),
				filter(deleted => !!deleted),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()))
			.subscribe(() => this.initialize());
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchLoadingDialog(): Observable<{}> {
		this._loadingDialog = !this._loadingDialog;
		return of({});
	}

	private switchDeleting(flowId: string): void {
		this._deleting = !this._deleting ? flowId : undefined;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	protected readonly fileType = FileType;
}
