import {
	AfterViewInit, ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	DestroyRef, ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TenantOverview} from '../../services/tenant.service';
import {Subject, Subscription} from 'rxjs';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {SETTINGS_BILLING_URL} from "../../models/home/navigation.model";
import {menuGroups} from "../../modules/global/main-menu/main-menu.model";
import {CurrentTenantService} from "../../services/front/current-tenant.service";
import {MenuStateService} from "../../services/front/menu-state.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {take, tap} from "rxjs/operators";
import {MatDrawer} from "@angular/material/sidenav";
import {IntercomService} from "../../services/front/intercom.service";
import {NewApplicationService} from "../../services/back/new-application.service";
import * as Bowser from "bowser";
import {animate, style, transition, trigger} from "@angular/animations";
import {AuthenticationService, UserInfo} from "../../services/authentication.service";

@Component({
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	animations: [
		trigger(
			'inOutAnimation',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(150%)' }),
						animate('500ms 500ms ease-out',
							style({ transform: 'translateY(0)'}))
					]
				)
			]
		)
	],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

	constructor(
		private authenticationService: AuthenticationService,
		public translateService: TranslateService,
		public rightSliderService: RightSliderService,
		public menuStateService: MenuStateService,
		public activatedRoute: ActivatedRoute,
		private destroyRef: DestroyRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private currentTenantService: CurrentTenantService,
		private intercomService: IntercomService,
		private applicationService: NewApplicationService,
		private cdr: ChangeDetectorRef
	) {
		this.rightSliderService.close();
	}

	defaultTenant: TenantOverview;
	isNoTenant: boolean;

	_initializing = false;

	@ViewChild('dynamicContainer', {static: false, read: ViewContainerRef}) dynamicContainer: ViewContainerRef;
	private dynamicInstance: ComponentRef<any>;

	menuGroups = menuGroups;
	settingsBillingUrl = SETTINGS_BILLING_URL;
	menuServiceState$ = this.menuStateService.onMenuStateChange().pipe(takeUntilDestroyed(this.destroyRef))
	subscription: Subscription = new Subscription();

	currentUser: UserInfo | null;

	@ViewChild('dialogElement') dialogElement!: ElementRef<HTMLDialogElement>;

	ngOnInit(): void {
		this.currentTenantService.initialize();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getIsNoTenant()
			.subscribe(isNoTenant => this.isNoTenant = isNoTenant));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.subscribe(tenant => this.defaultTenant = tenant));
		this.subscription.add(this.currentTenantService.getApplicationCountChanges()
			.subscribe((count) => this.setApplicationCount(count)));
	}

	ngAfterViewInit(): void {
		this.intercomService.boot();
		this.rightSliderInit();
		this.currentUser = this.authenticationService.getUserInfo();
		this.cdr.detectChanges();
		const isMobile = Bowser.getParser(window.navigator.userAgent).getPlatform().type === 'mobile';
		if (isMobile) {
			const mobileAppBannerDismissed = sessionStorage.getItem('mobileAppBannerDismissed');
			if (!mobileAppBannerDismissed) {
				this.dialogElement.nativeElement.showModal();
			}
		}
	}

	dismissModal() {
		this.dialogElement.nativeElement.close();
		sessionStorage.setItem('mobileAppBannerDismissed', 'true');
	}

	get isTrialPlan() {
		return this.defaultTenant?.plan?.isTrialPlan();
	}

	get isActivePlan() {
		return this.defaultTenant?.plan?.isActivePlan();
	}

	get isInactivePlan() {
		return this.defaultTenant?.plan?.isInactivePlan();
	}

	toggleMenu(drawer: MatDrawer) {
		this.menuServiceState$
			.pipe(
				take(1),
				tap(state => state.open ? this.menuStateService.closeMenu() : this.menuStateService.openMenu()),
				tap(() => drawer.toggle())
			).subscribe()
	}

	setApplicationCount(count: number): void {
		this.menuGroups
			.flatMap(group => group.entries)
			.find(entry => entry.id === 3)!.indicator = count.toString();
	}

	protected backdropClose = new Subject<any>()

	onBackdropClose(): void {
		this.backdropClose.next({});
	}

	rightSliderInit(): void {
		// Right slide subscription
		let sub = this.rightSliderService.onComponentChange().subscribe(component => {
			// Destroy previous component.
			if (this.dynamicInstance) {
				this.dynamicInstance.destroy();
			}

			const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(component.component);
			this.dynamicInstance = this.dynamicContainer.createComponent(dynamicComponentFactory, undefined);

			// Set inputs
			this.dynamicInstance.instance.data = component.data;

			// Set outputs
			let outputEventSubscription: Subscription;
			if (this.dynamicInstance.instance.onOutputEvent) {
				outputEventSubscription = this.dynamicInstance.instance.onOutputEvent.subscribe((outputEvent: any) => component.onOutputEvent?.next(outputEvent));
			}

			const backdropSubscription = this.backdropClose.subscribe(() => {
				if (this.dynamicInstance.instance.onClose) {
					this.dynamicInstance.instance.onClose?.emit();
				} else {
					component.onClose.next({});
					component.onOutputEvent?.complete();

					this.dynamicInstance.destroy();
					this.rightSliderService.close();
				}

				backdropSubscription.unsubscribe();
				if (outputEventSubscription) {
					outputEventSubscription.unsubscribe();
				}

			});

			if (this.dynamicInstance.instance.onClose) {
				const onCloseSubscription: Subscription = this.dynamicInstance.instance.onClose.subscribe((data: any) => {
					component.onClose.next(data);
					component.onOutputEvent?.complete();

					this.rightSliderService.close();
					onCloseSubscription.unsubscribe();

					if (outputEventSubscription) {
						outputEventSubscription.unsubscribe();
					}
					this.dynamicInstance.destroy();

				});
			}

			this.dynamicInstance.onDestroy(() => component.onClose.complete());
		});

		this.subscription.add(sub);
	}



	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	protected readonly environment = environment;
}
