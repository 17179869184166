import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {distinctUntilChanged, Subscription} from 'rxjs';

@Component({
  selector: 'app-select-insert',
  templateUrl: './select-insert.component.html',
  styleUrl: '../multi-select.component.scss'
})
export class SelectInsertComponent implements OnInit, OnDestroy {

	constructor(private cdr: ChangeDetectorRef) {
	}

  @Input() titlePath: string = 'dropdown.defaultCreateFieldHeader';
  @Input() saving: boolean = false;

  @Output() create: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('search') searchElement: ElementRef;

  createForm: FormControl<string|null>;
  openForm: boolean = false;
  subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.createForm = new FormControl<string>('', [Validators.required, Validators.minLength(1)]);
    this.subscription.add(this.createForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => value?.trim()));
  }

  onCreate(): void {
    if (this.formValid) {
      this.create.emit(this.createForm.value!);
      this.createForm.reset('');
      this.openForm = false;
    }
  }

  setFormOpened(): void {
	  this.openForm = true;
	  this.cdr.detectChanges();
	  this.searchElement.nativeElement.focus();
  }

  get formValid(): boolean {
    return this.createForm.valid && this.createForm.dirty;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
