import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from '@angular/common';
import {ContractInfo, DurationUnit, ServiceType} from 'src/app/services/model/application-contract.model';
import {TempServiceFrom} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/application-contract-form-dialog.component';
import {Organization, OrganizationTree} from 'src/app/services/organization.service';

@Component({
	selector: 'app-service-form',
	templateUrl: './service-form.component.html',
	styleUrls: ['./service-form.component.scss']
})
export class ServiceFormComponent implements OnInit {

	@Input() organization: OrganizationTree;
	@Input() info?: ContractInfo;

	@Output() onChange: EventEmitter<TempServiceFrom> = new EventEmitter<TempServiceFrom>();

	serviceForm: FormGroup;
	serviceTypeChoices: ServiceChoice[] = [];
	recurrenceChoices: RecurrenceChoice[] = [];
	amortizationPeriodChoices: AmortizationChoice[] = [];
	filteredOrganizations: OrganizationTree[] = [];
	searchOrganizationControl: FormControl;
	currencySymbol: string;
	form: typeof Form = Form;
	subscription: Subscription = new Subscription();

	constructor(private translate: TranslateService,
							private datePipe: DatePipe) {
	}

	ngOnInit() {
		this.buildSelectionLists();
		this.buildForm();
		this.setDefaultData();
		this.currencySymbol = localStorage.getItem('currency')!;
	}

	private buildSelectionLists(): void {
		this.serviceTypeChoices = [
			{id: ServiceType.TRAINING, name: this.translate.instant('page.appDetails.finance.contracts.training')},
			{id: ServiceType.INTEGRATION, name: this.translate.instant('page.appDetails.finance.contracts.integration')},
			{id: ServiceType.DEVELOPMENT, name: this.translate.instant('page.appDetails.finance.contracts.development')},
			{id: ServiceType.MAINTENANCE, name: this.translate.instant('page.appDetails.finance.contracts.maintenance')},
			{id: ServiceType.SUPPORT, name: this.translate.instant('page.appDetails.finance.contracts.support')}
		];
		this.recurrenceChoices = [
			{id: null, name: '-'},
			{id: DurationUnit.MONTH, name: this.translate.instant('page.appDetails.finance.contracts.monthlyPeriod')},
			{id: DurationUnit.YEAR, name: this.translate.instant('page.appDetails.finance.contracts.annuallyPeriod')}
		];
		this.amortizationPeriodChoices = [
			{id: DurationUnit.MONTH, name: this.translate.instant('page.appDetails.finance.contracts.month')},
			{id: DurationUnit.YEAR, name: this.translate.instant('page.appDetails.finance.contracts.year')}
		];
		this.filteredOrganizations = this.organization.children;
	}

	private buildForm(): void {
		this.serviceForm = new FormGroup({
			[Form.serviceType]: new FormControl(undefined, Validators.required),
			[Form.organizations]: new FormControl([]),
			[Form.amount]: new FormControl(undefined, Validators.min(0)),
			[Form.recurrencePeriod]: new FormControl(undefined),
			[Form.beginningDate]: new FormControl(undefined, Validators.required),
			[Form.amortizationValue]: new FormControl(undefined, Validators.min(1)),
			[Form.amortizationPeriod]: new FormControl(undefined),
		});
		this.subscription.add(this.serviceForm.valueChanges
			.subscribe(() => this.emitChanges()));
		this.searchOrganizationControl = new FormControl('');
		this.subscription.add(this.searchOrganizationControl.valueChanges
			.subscribe(search => this.searchOrganizationFilter(search)));
	}

	setDefaultData(): void {
		if (this.info) {
			const organizations: string[] = this.info!.contract.organizations.map(o => o.organizationId)
			this.serviceForm.setValue({
				[Form.serviceType]: this.serviceTypeChoices
					.find(choice => choice.id === this.info!.serviceInfo!.serviceType)!,
				[Form.organizations]: this.organization.children
					.flatMap(group => group.children)
					.filter(team => organizations.includes(team.organization.organizationId))
					.map(team => team.organization),
				[Form.amount]: this.info.amount,
				[Form.recurrencePeriod]: this.recurrenceChoices
					.find(choice => (!choice.id && !this.info!.isRecurringPayment) || (choice.id === this.info!.paymentDurationUnit))!,
				[Form.beginningDate]: this.info.contract.startDate,
				[Form.amortizationValue]: this.info.isRecurringPayment
					? null
					: this.info.paymentDurationValue,
				[Form.amortizationPeriod]: !this.info!.isRecurringPayment
					? this.amortizationPeriodChoices.find(choice => choice.id === this.info!.paymentDurationUnit)!
					: this.amortizationPeriodChoices.find(choice => choice.id === DurationUnit.MONTH)!
			}, { emitEvent: false });
		} else {
			this.serviceForm.setValue({
				[Form.serviceType]: this.serviceTypeChoices
					.find(choice => choice.id === ServiceType.MAINTENANCE)!,
				[Form.organizations]: [],
				[Form.amount]: null,
				[Form.recurrencePeriod]: this.recurrenceChoices
					.find(choice => choice.id === DurationUnit.MONTH)!,
				[Form.beginningDate]: new Date(),
				[Form.amortizationValue]: null,
				[Form.amortizationPeriod]: this.amortizationPeriodChoices
					.find(choice => choice.id === DurationUnit.MONTH)!
			}, { emitEvent: false });
		}
		// Emit changes to set default values on parent component
		this.emitChanges();
	}

	private searchOrganizationFilter(search?: string): void {
		if (!search) {
			this.filteredOrganizations = this.organization.children;
		} else {
			const lowercaseValue = search.toLowerCase();
			this.filteredOrganizations = this.organization.children
				.reduce((acc: OrganizationTree[], org) => {
					const matchingChildren = org.children.filter(team =>
						team.organization.name.toLowerCase().includes(lowercaseValue)
					);

					if (org.organization.name.toLowerCase().includes(lowercaseValue) || matchingChildren.length > 0) {
						acc.push({
							...org,
							children: org.organization.name.toLowerCase().includes(lowercaseValue)
								? org.children
								: matchingChildren
						});
					}
					return acc;
				}, [])
				.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		}
	}

	emitChanges(): void {
		const form: TempServiceFrom = this.buildApplicationContractOfServiceForm();
		this.onChange.emit(form);
	}

	private buildApplicationContractOfServiceForm(): TempServiceFrom {
		const recurrence: DurationUnit|null = (this.serviceForm.get(Form.recurrencePeriod)!.value as RecurrenceChoice).id;
		return {
			amount: this.serviceForm.get(Form.amount)!.value,
			startDate: new Date(this.datePipe.transform(this.serviceForm.get(Form.beginningDate)!.value, "yyyy-MM-dd")!),
			paymentDurationValue: !recurrence ? this.serviceForm.get(Form.amortizationValue)!.value : 1,
			paymentDurationUnit: recurrence ?? (this.serviceForm.get(Form.amortizationPeriod)!.value as AmortizationChoice).id,
			isRecurringPayment: !!recurrence,
			serviceType: (this.serviceForm.get(Form.serviceType)!.value as ServiceChoice).id,
			expirationDate: undefined,
			organizationIds: (this.serviceForm.get(Form.organizations)!.value as Organization[]).map(o => o.organizationId),
		}
	}
}

interface ServiceChoice {
	id: ServiceType;
	name: string;
}

interface RecurrenceChoice {
	id: DurationUnit|null;
	name: string;
}

interface AmortizationChoice {
	id: DurationUnit;
	name: string;
}

enum Form {
	serviceType = 'serviceType',
	organizations = 'organizations',
	amount = 'amount',
	recurrencePeriod = 'recurrencePeriod',
	beginningDate = 'beginningDate',
	amortizationValue = 'amortizationValue',
	amortizationPeriod = 'amortizationPeriod',
}
