<div *ngIf="!widget" class="border-box-full">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>

<div *ngIf="!!widget" class="flex-column flex-space-between border-box-full pad-M">
	<div class="flex-align-center flex-space-between gap-S">
		<div class="flex-space-between font-MM fw-700">
			{{ title }}
		</div>
		<div class="font-XS fw-700 grey" *ngIf="subtitle">
			{{ subtitle }}
		</div>
	</div>

	<div class="flex-justify-end w-100">
		<div *ngIf="displayLoaderAsData"><app-spinner color="accent" [scale]="0.7" [tooltip]="'page.dashboard.mini-widget.dataLoading'|translate"></app-spinner></div>
		<div *ngIf="!displayLoaderAsData" class="flex-column flex-align-end gap-XS mr-xs" style="max-width: 150px;">
			@if (!!widget && widget.value !== undefined && widget.value !== null) {
                <div class="font-XL fw-500"
                     [class]="(type == 'performance' && widget.value == 1) ? 'success' : (type == 'performance' && widget!.value == 2) ? 'warning' : (type == 'performance' && widget!.value == 3) ? 'danger' : (type == 'availability' && widget!.value >= 99) ? 'success' : (type == 'availability' && widget!.value < 99 && widget!.value >= 95) ? 'warning': type == 'availability' ? 'danger' : ''">
					<div *ngIf="type == 'performance'">{{ getPerformance(widget!.value!) }}</div>
					<div *ngIf="type == 'availability'">{{ getAvailability(widget!.value!) }}</div>
                    <div *ngIf="type == 'tco'">{{widget!.value|cost}}</div>
                    <div *ngIf="type == 'users'">{{ getUsage(widget!.value!) }}</div>
                    <div *ngIf="type == 'satisfaction'" class="mt-l">{{widget!.value | number:'1.0-2'}} <span class="grey">/5</span></div>
                    <div *ngIf="type == 'activity'">{{ widget!.value / 60 | number: '1.0-0'}} minute<span *ngIf="widget!.value > 120">(s)</span></div>
                    <div *ngIf="type == 'neutralPercentage'" class="accent">{{ widget!.value }} %</div>
                </div>
            } @else {
                <div class="font-S fw-700 grey mt-xs">{{'global.noData' | translate}}</div>
            }
			<div class="font-XS fw-500 grey" *ngIf="!!widget.day" >{{('global.queryTypeHint.' + widget.day)|translate}}</div>
		</div>
	</div>
</div>
