import {Component, OnDestroy, OnInit} from "@angular/core";
import {TopbarService} from "src/app/services/front/topbar.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {
	CreateOrganizationForm,
	Organization,
	OrganizationService,
	OrganizationTreeStatistic
} from "src/app/services/organization.service";
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from "rxjs";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {MatDialog} from "@angular/material/dialog";
import {filter} from 'rxjs/operators';
import {OrganizationType} from "../../../../services/model/autodiscover.model";
import {CreateTeamComponent} from "./create-team/create-team.component";
import {CreateTeamGroupComponent} from "./create-team-group/create-team-group.component";
import {SnackbarModule} from "../../../global/snackbar/snackbar.module";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {
	OrganizationDetailComponent,
	OrganizationDetailInput
} from "../organization-detail/organization-detail.component";
import {SnackbarService} from "../../../../services/front/snackbar.service";

@Component({
	templateUrl: './organization-list.component.html',
	standalone: true,
	imports: [
		CommonModule,
		SnackbarModule,
		DesignSystemModule,
		TranslateModule,
		DeckContentLoaderModule,
		NgOptimizedImage
	],
	styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit, OnDestroy {
	constructor(private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private rightSliderService: RightSliderService,
							private activatedRoute: ActivatedRoute,
							private topbarService: TopbarService,
							private translate: TranslateService,
							private dialog: MatDialog,
							private router: Router,
							private snackBarService: SnackbarService) {
	}

	tenantId: string;
	isEditor: boolean = false;

	_initializing: boolean;
	_loading: boolean;
	_refreshing: boolean;

	drawerOpened: boolean = false;

	organizationTree: OrganizationTreeStatistic;
	openedOrganizations: OrganizationTreeStatistic[] = [];
	openedBusinessUnit: OrganizationTreeStatistic|undefined;

	onDrawerCloseEvent: Subject<any> = new Subject<any>();

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.topbarService.onTitleChange(this.translate.instant('menu.organization'), this.translate.instant('menu.subtitle.organization'));
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(
				tap(tenant => this.tenantId = tenant.configuration.id),
				tap(tenant => this.isEditor = tenant.configuration.role !== 'read_only'))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.onDrawerCloseEvent
			.subscribe(() => this.rightSliderService.close()));
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => this.fetchOrganizationTree()),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private fetchOrganizationTree(): Observable<{}> {
		return this.organizationService.getOrganizationTreeStatisticByTenantId(this.tenantId)
			.pipe(tap(tree => this.setOrganizationTree(tree)));
	}

	private setOrganizationTree(organizationTree: OrganizationTreeStatistic): void {
		this.organizationTree = organizationTree;
		this.organizationTree.children
			.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		this.organizationTree.children
			.forEach(structure => structure.children
				.sort((a, b) => a.organization.name.localeCompare(b.organization.name)));
	}

	onOpenOrganization(tree: OrganizationTreeStatistic): void {
		this.openedBusinessUnit = undefined;
		if (this.openedOrganizations.find(o => o.organization.organizationId === tree.organization.organizationId)) {
			this.openedOrganizations = this.openedOrganizations.filter(o => o.organization.organizationId !== tree.organization.organizationId);
		} else {
			this.openedOrganizations.push(tree);
		}
	}

	onRefresh() {
		return this.switchRefreshing()
			.pipe(
				switchMap(() => this.fetchOrganizationTree()),
				tap(() => this.checkOpenedOrganizations()),
				finalize(() => this.switchRefreshing()));

	}

	private checkOpenedOrganizations(): void {
		if (this.openedOrganizations) {
			this.openedOrganizations = this.openedOrganizations.filter(o => {
				const opened = this.organizationTree.children.find(r => r.organization.organizationId === o.organization.organizationId);
				return !!opened;
			});
		}
	}

	openOrganizationDrawer(organizationId: string, organizationType: OrganizationType): void {
		const data: OrganizationDetailInput = {
			organizationId: organizationId,
			type: organizationType
		};
		this.drawerOpened = true;
		this.resetQueryParams()
			.then(() => this.rightSliderService.openComponent(OrganizationDetailComponent, data)
				.pipe(
					tap(() => this.drawerOpened = false),
					switchMap(() => this.onRefresh())
				)
				.subscribe());
	}

	resetQueryParams(): Promise<boolean> {
		return this.router.navigate([], {
			queryParams: {
				'selectedOrganizationTab': null,
				'settings': null,
			},
			queryParamsHandling: 'merge'
		})
	}

	findStructureInOpenedOrganizations(structure: OrganizationTreeStatistic): boolean {
		return !!this.openedOrganizations.find(o => o.organization.organizationId === structure.organization.organizationId);
	}

	onCreate(organization: OrganizationTreeStatistic) {
		const data: OrganizationCreationPopupData = {
			parent: organization.organization,
			structures: this.organizationTree.children.map(c => c.organization),
			editor: this.isEditor
		};

		switch (data.parent.type) {
			case OrganizationType.ORGANIZATION:
				this.dialog.open(CreateTeamGroupComponent, { width: '450px', data: data })
					.afterClosed()
					.pipe(
						filter(result => !!result),
						switchMap((form: CreateOrganizationForm) => this.organizationService.create(this.tenantId, form)),
						tap(() => this.snackBarService.show(this.translate.instant('page.organization.creationSuccess'))),
						switchMap(() => this.onRefresh()))
					.subscribe();
				break;
			case OrganizationType.AFFILIATE:
				this.dialog.open(CreateTeamComponent, { width: '450px', data: data })
					.afterClosed()
					.pipe(
						filter(result => !!result),
						switchMap((form: CreateOrganizationForm) => this.organizationService.create(this.tenantId, form)),
						tap(() => this.snackBarService.show(this.translate.instant('page.organization.creationSuccess'))),
						switchMap(() => this.onRefresh()))
					.subscribe();
				break;
		}
	}

	getInvertedIndex(index: number, maxIndex: number): number {
		return maxIndex - index;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchRefreshing(): Observable<{}> {
		this._refreshing = !this._refreshing;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.onDrawerCloseEvent.complete();
		this.rightSliderService.close();
	}

	protected readonly OrganizationType = OrganizationType;
}

export interface OrganizationCreationPopupData {
	parent: Organization;
	structures: Organization[];
	editor: boolean;
}

