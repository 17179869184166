<div class="flex-column gap-widget">
	<div class="flex-space-between w-100 gap-L">
		<div class="w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="bg-rounded w-100 top-widget-height">
				<application-mini-widget
					[widget]="availabilityWidget"
					[type]="'availability'"
					[title]="'menu.applicationAvailability' | translate"
				></application-mini-widget>
			</div>
		</div>
		<div class="w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<div class="bg-rounded w-100 top-widget-height">
				<application-mini-widget
					[widget]="performanceWidget"
					[type]="'performance'"
					[title]="'menu.performance' | translate"
				></application-mini-widget>
			</div>
		</div>
		<div class="bg-rounded w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<application-mini-widget
				[widget]="tcoWidget"
				[type]="'tco'"
				[title]="'page.appDetails.overview.tco'|translate"
			></application-mini-widget>
		</div>
	</div>
	<div class="bg-rounded pad-M flex-column gap-M p-relative">
		<div class="flex-align-center flex-space-between">
			<div class="flex-align-center gap-XS">
				<span class="font-MM fw-700">{{ 'page.organization.apps.inventory' | translate }}</span>
				<div class="flex-center" style="height: 25px; width: 25px;"
					 [tooltip]="'page.organization.apps.tooltip'|translate" tooltipPosition="right">
					<img src="assets/icons/help-2.svg" width="14" height="14" alt=""/>
				</div>
			</div>
			<app-mini-button [tooltipOffset]="10" [tooltip]="organization.type === OrganizationType.BUSINESS_UNIT && autodiscoveredApplications.length > 0 ? ('page.organization.apps.youHaveAutodiscoveredApps' | translate) : undefined" tooltipPosition="left" *ngIf="organization.type === OrganizationType.BUSINESS_UNIT" size="s" color="accent"
							 (onClick)="openLinkAppToTeam()">
				<img src="assets/icons/add.svg" class="filter-accent" alt="">
			</app-mini-button>
		</div>
		<div class="auto-discover-badge flex-center" *ngIf="autodiscoveredApplications.length > 0 && organization.type === OrganizationType.BUSINESS_UNIT">
			<span class="font-S fw-500">{{ autodiscoveredApplications.length }}</span>
		</div>
		<app-table-component
			[loading]="_initializing || _loading"
			[columnDefinitions]="columnDefinitions"
			[data]="data?.applications || []"
			[height]="'calc(100% - 45.59px)'"
			[type]="'drawer'"
			[style]="'only-table'"
			[deleteRow]="organization.type === OrganizationType.BUSINESS_UNIT"
			(rowClick)="onRowClick($event)"
			(delete)="deleteAppFromTeam($event)">
			<!-- Custom templates -->
			<ng-template #applicationTemplate let-row>
				<app-display-application [application]="row.application"></app-display-application>
			</ng-template>
			<ng-template #healthTemplate let-row>
				<div class="flex-center">
					<app-chips-health [health]="row.health"></app-chips-health>
				</div>
			</ng-template>
			<ng-template #criticalityTemplate let-row>
				<div class="flex-center">
					<app-chips-criticality [short]="true" [criticality]="row.application.criticality"></app-chips-criticality>
				</div>
			</ng-template>
			<ng-template #satisfactionTemplate let-row>
				<div class="flex-align-center flex-justify-end">
					<app-chips-count [type]="'star'" [value]="row.satisfaction?.value"></app-chips-count>
				</div>
			</ng-template>
		</app-table-component>
	</div>
</div>
