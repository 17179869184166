import {Component, Input, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {ApplicationMapping} from "../../../../../../../services/model/application-mapping.model";

@Component({
  selector: 'app-mapping-drawer-tooltip',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './mapping-drawer-tooltip.component.html',
  styleUrl: './mapping-drawer-tooltip.component.scss'
})
export class MappingDrawerTooltipComponent {
	@Input() data: ApplicationMapping | undefined;
}


