<div class="flex delete-hover">
	<div class="flex-column pad-M gap-S border-light-grey radius-M" style="width: 193px;">
		<div class="flex gap-S">
			<div class="flex-center infrastructure-logo radius-S">
				<app-application-icon [calculateColor]="true" [tileWidth]="'38px'" [tileHeight]="'38px'" [logo]="getResourceIcon()"></app-application-icon>
			</div>
			<div class="flex-column flex-justify-center" style="width: calc(100% - 48px);">
				<div class="font-M fw-700 text-overflow-ellipsis">{{mapping.resource.name}}</div>
				<div class="font-S fw-500 text-overflow-ellipsis">{{mapping.resource.os || '-'}}</div>
			</div>
		</div>
		<div class="w-100 border-light-grey-bottom"></div>
		<div class="flex-space-between flex-align-center" *ngIf="mapping.resource.dataCollectionStatus">
			<span class="font-S fw-500">{{'Status'}}</span>
			<app-chips size="s" [color]="statusColor">{{mapping.resource.dataCollectionStatus.statusLabel|titlecase}}</app-chips>
		</div>
		<div class="flex-space-between flex-align-center" *ngIf="!mapping.resource.dataCollectionStatus">
			<span class="font-S fw-500">{{'Status'}}</span>
			<app-chips size="s" [color]="'grey'">{{'-'}}</app-chips>
		</div>
		<div class="w-100 border-light-grey-bottom"></div>
		<app-progress-bar-indicator [lowerBest]="true"
									[label]="'Charge'"
									[size]="'S'"
									[levelPercent]="mapping.resource.load">
		</app-progress-bar-indicator>
	</div>
	<app-icon-button *ngIf="!!delete" color="danger" shape="round" class="delete-btn h-100" size="xs" [panelClass]="'border-grey bkg-white'"
					 [img]="'assets/icons/trash.svg'" [disabled]="disabled"
					 (click)="delete(mapping.mappingId)">
	</app-icon-button>
</div>
