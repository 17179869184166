import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Organization, OrganizationService} from "../../../../services/organization.service";
import {OrganizationType} from "../../../../services/model/autodiscover.model";
import {
	OrganizationSettingsComponent,
	OrganizationSettingsMode
} from "./organization-settings/organization-settings.component";
import {Subscription} from "rxjs";
import {OrganizationUrlService} from "../../../../services/front/organization-url.service";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../services/front/organization-detail.service";
import {filter, tap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {DeleteDialogComponent} from "../../../design-system/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "../../../../services/front/snackbar.service";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {SnackbarModule} from "../../../global/snackbar/snackbar.module";
import {TabModule} from "../../../global/tabs/tab/tab.module";
import {TabsModule} from "../../../global/tabs/tabs.module";
import {TagsModule} from "../../../global/tags/tags.module";
import {UsageTabModule} from "../../applications/application-detail/usage-tab/usage-tab.module";
import {OrganizationOverviewTabComponent} from "./organization-overview-tab/organization-overview-tab.component";
import {
	OrganizationApplicationsTabComponent
} from "./organization-applications-tab/organization-applications-tab.component";
import {OrganizationFinanceTabComponent} from "./organization-finance-tab/organization-finance-tab.component";
import {OrganizationUsageTabComponent} from "./organization-usage-tab/organization-usage-tab.component";
import {ApplicationGeneric} from "../../../../services/model/new-application.model";

@Component({
  selector: 'app-organization-detail',
  standalone: true,
	imports: [CommonModule, DeckContentLoaderModule, DesignSystemModule, SnackbarModule, TabModule, TabsModule, TagsModule, TranslateModule, UsageTabModule, OrganizationOverviewTabComponent, OrganizationApplicationsTabComponent, OrganizationFinanceTabComponent, OrganizationUsageTabComponent, OrganizationSettingsComponent, OrganizationSettingsComponent],
  templateUrl: './organization-detail.component.html',
  styleUrl: './organization-detail.component.scss'
})
export class OrganizationDetailComponent implements OnInit, OnDestroy {
	@Input() data: OrganizationDetailInput;

	@Output() onClose = new EventEmitter<void>(); // Listening in home.component.ts

	tenantId: string;
	isEditor: boolean;

	organization: Organization;

	_initializing: boolean;
	_loading: boolean;
	initialized: boolean = false;

	sliderIndex: number = 1;

	lazyLoadedTabs: boolean[] = [false, false, false, false, false];

	settings: OrganizationSettingsMode | null = null;

	displayAutodiscoverApplicationsNotification: boolean = false;
	autodiscoveredApplicationsCount: number = 0;

	subscriptions: Subscription = new Subscription();

	constructor(private organizationUrlService: OrganizationUrlService,
				private organizationService: OrganizationService,
				private organizationDetailService: OrganizationDetailService,
				private activatedRoute: ActivatedRoute,
				private dialog: MatDialog,
				private translate: TranslateService,
				private snackBarService: SnackbarService) {
	}

	ngOnInit() {
		this.sliderIndex = this.organizationUrlService.getCurrentOrganizationTab();
		this.lazyLoadedTabs[this.sliderIndex] = true;

		this.organizationDetailService.initialize(this.data.organizationId)

		this.subscriptions.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));

		this.subscriptions.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap(data => this.setOrganizationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));

		this.subscriptions.add(this.activatedRoute.queryParams
			.subscribe(() => this.onQueryParamsChange()));
	}

	initialize(): void {
		this.subscriptions.add(this.organizationService.getOrganizationAutodiscoveredApplications(this.tenantId, this.organization.organizationId)
			.subscribe((applications) => this.setAutodiscoveredApplications(applications)));
	}

	setAutodiscoveredApplications(applications: ApplicationGeneric[]): void {
		this.autodiscoveredApplicationsCount = applications.length;
		this.setAutodiscoverNotificationDisplay();
	}

	setAutodiscoverNotificationDisplay(): void {
		const applicationsTeamsCount = localStorage.getItem('autodiscovered-applications-count-' + this.organization.organizationId);
		if (applicationsTeamsCount) {
			const diff = this.autodiscoveredApplicationsCount - parseInt(applicationsTeamsCount, 10);
			this.displayAutodiscoverApplicationsNotification = diff > 0;
		} else {
			this.displayAutodiscoverApplicationsNotification = this.autodiscoveredApplicationsCount > 0;
		}
	}

	onAutodiscoverApplicationsChanged(event: number) {
		this.autodiscoveredApplicationsCount = event;
	}

	setLocalStorageApplicationsCount(): void {
		localStorage.setItem('autodiscovered-applications-count-' + this.organization.organizationId, this.autodiscoveredApplicationsCount.toString());
		this.setAutodiscoverNotificationDisplay();
	}

	private setOrganizationDetailData(data: OrganizationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.organization = data.organization;
	}

	private onQueryParamsChange(): void {
		this.settings = this.organizationUrlService.getParameter();
	}

	onSliderChange(index: number): void {
		this.sliderIndex = index;
		this.lazyLoadedTabs[index] = true;
		this.organizationUrlService.changeCurrentOrganizationTab(index);
	}

	close(): void {
		this.onClose.emit();
	}

	onOpenDeleteOrganizationPopup(): void {
		if (this.organization.type === OrganizationType.BUSINESS_UNIT) {
			this.dialog.open(DeleteDialogComponent, {
				width: '475px',
				position: {
					right: '150px'
				},
				data: {
					icon: '/assets/illustrations/delete/organization.svg',
					title: this.translate.instant('page.organization.team.delete.title'),
					subtitle: this.translate.instant('page.organization.team.delete.subtitle'),
					warningMessage: this.translate.instant('page.organization.team.delete.warningMessage'),
					deleteButton: this.translate.instant('page.organization.team.delete.deleteButton')
				}})
				.afterClosed()
				.pipe(filter(result => result))
				.subscribe(() => this.onDeleteOrganization());
		} else if (this.organization.type === OrganizationType.AFFILIATE) {
			this.dialog.open(DeleteDialogComponent, {
				width: '475px',
				position: {
					right: '150px'
				},
				data: {
					icon: '/assets/illustrations/delete/organization.svg',
					title: this.translate.instant('page.organization.structure.delete.title'),
					subtitle: this.translate.instant('page.organization.structure.delete.subtitle'),
					warningMessage: this.translate.instant('page.organization.structure.delete.warningMessage'),
					deleteButton: this.translate.instant('page.organization.structure.delete.deleteButton')
				}})
				.afterClosed()
				.pipe(filter(result => result))
				.subscribe(() => this.onDeleteOrganization());
		}
	}

	onDeleteOrganization(): void {
		this.organizationService.delete(this.tenantId, this.organization.organizationId).subscribe({
			next: () => this.close(),
			error: () => this.snackBarService.show(this.translate.instant('confirmModal.deleteFail'), 'danger-snack')
		});
	}

	onOpenOrganizationSettings(): void {
		if (this.settings != null) {
			this.organizationUrlService.navigateInAndOutOfParameter(null);
		} else {
			this.organizationUrlService.navigateInAndOutOfParameter(OrganizationSettingsMode.ALL);
		}
	}

	onSaveSettings(): void {
		this.snackBarService.show(this.translate.instant('page.application.detail.update.success'));
		this.organizationUrlService.navigateInAndOutOfParameter(null);
	}

	ngOnDestroy() {
		this.organizationUrlService.changeCurrentOrganization(null);
		this.organizationDetailService.clearOrganizationDetailData();
		this.subscriptions.unsubscribe();
	}

	protected readonly OrganizationType = OrganizationType;
}

export interface OrganizationDetailInput {
	organizationId: string;
	type: OrganizationType;
}
