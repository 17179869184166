<div *ngIf="!tenant?.plan?.isTrialPlan() && !tenant?.plan?.isActivePlan()" class="pl-l pr-l mt-m">
	<app-chips color="error">{{'page.dashboard.limited.badge' | translate}}</app-chips>
	<div class="font-S fw-500 grey mt-xs">{{'page.dashboard.limited.headline' | translate}}</div>
	<div class="font-S fw-500 grey">{{'page.dashboard.limited.link.0' | translate}} <a [routerLink]="[settingsBillingUrl]" [queryParams]="activatedRoute.snapshot.queryParams"> {{'page.dashboard.limited.link.1' | translate}} </a> {{'page.dashboard.limited.link.2' | translate}}</div>
</div>

<div class="main-container pt-xl pb-l">
	<div class="ml-xl disp-g-3 mr-xl semi-responsive" style="grid-column-gap: 20px; grid-row-gap: 20px">
		<div style="grid-column: span 8;">
			<app-filter [organizationTree]="organizationTree"
                        [filtersLoading]="_initializing || _loadingOrganization"
                        (changes)="refreshWithFilters($event)">
			</app-filter>
		</div>
		<!-- Current usage widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="currentUsage" [responsive]="true"></app-mini-widget>
		</div>
		<!-- Satisfaction widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="satisfaction" [responsive]="true"></app-mini-widget>
		</div>
		<!-- Deployed extensions -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="allExtensionsCount" [responsive]="true"></app-mini-widget>
		</div>
		<!-- TCO by user widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="freshlyDiscoveredApps" [responsive]="true"></app-mini-widget>
		</div>
		<!-- Usage graph -->
		<div class="widget" style="grid-column: span 5;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-usage-graph [filter]="filter" [widgetTitle]="'page.usageDashboard.isActivity'|translate" [height]="290"></app-usage-graph>
		</div>
		<!-- Most used apps -->
		<div class="widget" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-most-used-apps [filter]="filter" [limit]="6"></app-most-used-apps>
		</div>
		<!-- Coming soon -->
		<div class="widget widget-semi" style="grid-column: span 2;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-hourly-amplitude-radial [filter]="filter"></app-hourly-amplitude-radial>
		</div>
		<!-- Doughnut -->
		<div class="widget widget-semi" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-usage-doughnut [filter]="filter" [limit]="4"></app-usage-doughnut>
		</div>
		<!-- Worst noted apps -->
		<div class="widget widget-semi" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-worst-noted-apps [filter]="filter" [limit]="5"></app-worst-noted-apps>
		</div>
	</div>
</div>
