import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {SecurityUtils} from "./security.utils";
import {Observable} from "rxjs";
import {ApplicationGeneric} from "./model/new-application.model";


@Injectable()
export class AnnouncementService {
	constructor(protected httpClient: HttpClient) {
	}

	public createAnnouncement(tenantId: string, announcement: PostAnnouncement): Observable<string> {
		return this.httpClient.post<string>(environment.engineURL  + '/tenants/' + tenantId + '/announcements',
			announcement,
			{headers: SecurityUtils.getAuthHttpHeaders()}
		)
	}

	public getAnnouncements(tenantId: string): Observable<Announcement[]> {
		return this.httpClient.get<Announcement[]>(environment.engineURL  + '/tenants/' + tenantId + '/announcements',
			{headers: SecurityUtils.getAuthHttpHeaders()}
		)
	}

	public updateAnnouncement(tenantId: string, announcement: PostAnnouncement, announcementId: string): Observable<void> {
		return this.httpClient.put<void>(environment.engineURL  + '/tenants/' + tenantId + '/announcements/' + announcementId,
			announcement,
			{headers: SecurityUtils.getAuthHttpHeaders()}
		)
	}

	public deleteAnnouncement(tenantId: string, announcementId: string): Observable<void> {
		return this.httpClient.delete<void>(environment.engineURL  + '/tenants/' + tenantId + '/announcements/' + announcementId,
			{headers: SecurityUtils.getAuthHttpHeaders()}
		)
	}

	public getApplicationAnnouncements(tenantId: string, applicationId: string): Observable<Announcement[]> {
		return this.httpClient.get<Announcement[]>(environment.engineURL  + '/tenants/' + tenantId + '/announcements/applications/' + applicationId,
			{headers: SecurityUtils.getAuthHttpHeaders()}
		)
	}
}

export interface PostAnnouncement {
	title: string,
	content: string,
	type: string,
	teams?: string[],
	app?: string,
	startDate: Date,
	endDate?: Date
}

export interface Announcement {
	id: string,
	title: string,
	content: string,
	type: string,
	teams?: {
		id: string,
      	name: string,
	  	icon?: string,
	  	parentId: string,
	  	parentName: string
	}[]
	app?: ApplicationGeneric,
	createdAt: Date,
	updatedAt: Date,
	seenCount: number,
  	teamsTooltip?: {
		id: string
		parentName: string
	  	teams: {
			name: string
        }[]
    }[],
	likeCount: number,
	dislikeCount: number,
	likeRatio?: number,
	startDate: Date,
	endDate?: Date,
}
