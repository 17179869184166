<div class="flex-column gap-M pad-L mt-xl">

    <!-- DEMO COMPONENT : app-icon-button -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-icon-button</span>
        <div class="flex-column pad-M gap-M">
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;" class="flex-space-around"><span>Type</span></div>
                <div style="width: 420px;" class="flex-space-around border-grey-left"><span>Color</span></div>
                <div style="width: 100px;" class="flex-space-around border-grey-left"><span>Shape</span></div>
                <div style="width: 240px;" class="flex-space-around border-grey-left"><span>State</span></div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;" class="flex-space-around"></div>
                <div style="width: 420px;" class="flex-space-around"><span class="primary">primary</span><span class="accent">accent</span><span class="accent-secondary">secondary</span><span class="warn">warn</span><span class="success">success</span><span class="warning">warning</span><span class="danger">danger</span><span class="transparent">transparent</span></div>
                <div style="width: 100px;" class="flex-space-around"><span class="grey">square</span><span class="grey">round</span></div>
                <div style="width: 240px;" class="flex-space-around"><span class="grey">background</span><span class="grey">focused</span><span class="grey">disabled</span><span class="grey">loading</span></div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;"><span class="font-M fw-500 grey">default</span></div>
                <div style="width: 420px;" class="flex-space-around">
                    <app-icon-button [type]="'default'" [color]="'primary'" [size]="'m'" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'accent'" [size]="'m'" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'accent-secondary'" [size]="'m'" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'warn'" [size]="'m'" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'success'" [size]="'m'" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'warning'" [size]="'m'" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'danger'" [size]="'m'" [img]="'assets/icons/trash.svg'"></app-icon-button>
					<app-icon-button [type]="'default'" [color]="'transparent'" [size]="'m'" [img]="'assets/icons/trash.svg'"></app-icon-button>
				</div>
                <div style="width: 100px;" class="flex-space-around">
                    <app-icon-button [type]="'default'" [color]="'accent'" [size]="'m'" [shape]="'square'" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'accent'" [size]="'m'" [shape]="'round'" [img]="'assets/icons/trash.svg'"></app-icon-button>
                </div>
                <div style="width: 240px;" class="flex-space-around">
                    <app-icon-button [type]="'default'" [color]="'accent'" [size]="'m'" background [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'accent'" [size]="'m'" [focused]="true" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'accent'" [size]="'m'" [disabled]="true" [img]="'assets/icons/trash.svg'"></app-icon-button>
                    <app-icon-button [type]="'default'" [color]="'accent'" [size]="'m'" [loading]="true" [img]="'assets/icons/trash.svg'"></app-icon-button>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;"><span class="font-M fw-500 grey">stroked</span></div>
                <div style="width: 420px;" class="flex-space-around">
                    <app-icon-button [type]="'stroked'" [color]="'primary'" [size]="'m'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'accent'" [size]="'m'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'accent-secondary'" [size]="'m'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'warn'" [size]="'m'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'success'" [size]="'m'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'warning'" [size]="'m'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'danger'" [size]="'m'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
					<app-icon-button [type]="'stroked'" [color]="'transparent'" [size]="'m'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
				</div>
                <div style="width: 100px;" class="flex-space-around">
                    <app-icon-button [type]="'stroked'" [color]="'accent'" [size]="'m'" [shape]="'square'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'accent'" [size]="'m'" [shape]="'round'" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                </div>
                <div style="width: 240px;" class="flex-space-around">
                    <app-icon-button [type]="'stroked'" [color]="'accent'" [size]="'m'" background [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'accent'" [size]="'m'" [focused]="true" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'accent'" [size]="'m'" [disabled]="true" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                    <app-icon-button [type]="'stroked'" [color]="'accent'" [size]="'m'" [loading]="true" [img]="'assets/icons/close-drawer.svg'"></app-icon-button>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;"><span class="font-M fw-500 grey">flat</span></div>
                <div style="width: 420px;" class="flex-space-around">
                    <app-icon-button [type]="'flat'" [color]="'primary'" [size]="'m'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'accent'" [size]="'m'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'accent-secondary'" [size]="'m'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'warn'" [size]="'m'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'success'" [size]="'m'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'warning'" [size]="'m'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'danger'" [size]="'m'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
					<app-icon-button [type]="'flat'" [color]="'transparent'" [size]="'m'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
				</div>
                <div style="width: 100px;" class="flex-space-around">
                    <app-icon-button [type]="'flat'" [color]="'accent'" [size]="'m'" [shape]="'square'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'accent'" [size]="'m'" [shape]="'round'" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                </div>
                <div style="width: 240px;" class="flex-space-around">
                    <app-icon-button [type]="'flat'" [color]="'accent'" [size]="'m'" background [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'accent'" [size]="'m'" [focused]="true" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'accent'" [size]="'m'" [disabled]="true" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'flat'" [color]="'accent'" [size]="'m'" [loading]="true" [img]="'assets/icons/comment-inactive.svg'"></app-icon-button>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;"><span class="font-M fw-500 grey">dashed</span></div>
                <div style="width: 420px;" class="flex-space-around">
                    <app-icon-button [type]="'dashed'" [color]="'primary'" [size]="'m'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'accent'" [size]="'m'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'accent-secondary'" [size]="'m'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'warn'" [size]="'m'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'success'" [size]="'m'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'warning'" [size]="'m'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'danger'" [size]="'m'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
					<app-icon-button [type]="'dashed'" [color]="'transparent'" [size]="'m'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
				</div>
                <div style="width: 100px;" class="flex-space-around">
                    <app-icon-button [type]="'dashed'" [color]="'accent'" [size]="'m'" [shape]="'square'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'accent'" [size]="'m'" [shape]="'round'" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                </div>
                <div style="width: 240px;" class="flex-space-around">
                    <app-icon-button [type]="'dashed'" [color]="'accent'" [size]="'m'" background [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'accent'" [size]="'m'" [focused]="true" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'accent'" [size]="'m'" [disabled]="true" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                    <app-icon-button [type]="'dashed'" [color]="'accent'" [size]="'m'" [loading]="true" [img]="'assets/icons/cog-inactive.svg'"></app-icon-button>
                </div>
            </div>
        </div>
        <div class="flex-column border-grey-top">
            <div class="flex-wrap">
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">img</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('img'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('img')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['add', 'edit', 'trash', 'close', 'close-drawer', 'comment-inactive', 'cog-inactive']" [value]="type">
                            <div class="flex-align-center gap-M"><img class="filter-grey" [src]="'assets/icons/' + type + '.svg'" width="12" height="12" alt=""/><span>{{type}}</span></div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">color</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('color'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('color')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['primary', 'accent', 'accent-secondary', 'warn', 'success', 'warning', 'danger', 'transparent']" [value]="type">
                            <div class="flex-align-center gap-M">
                                <div [class]="'bkg-'+type" style="height: 15px; width: 15px; border-radius: 50%;"></div>
                                <span>{{type}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">type</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('type'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('type')).value}}</app-select-trigger>
                        <app-select-option [value]="'default'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-white" style="width: 15px; height: 15px; border-radius: 4px;">
                                    <div class="bkg-grey" style="height: 3px; width: 3px; border-radius: 50%;"></div>
                                </div>
                                <span>{{'default'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'stroked'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-grey" style="width: 15px; height: 15px; border-radius: 4px;">
                                    <div class="bkg-grey" style="height: 3px; width: 3px; border-radius: 50%;"></div>
                                </div>
                                <span>{{'stroked'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'flat'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center bkg-grey border-white" style="width: 15px; height: 15px; border-radius: 4px;">
                                    <div class="bkg-light-grey-3" style="height: 3px; width: 3px; border-radius: 50%;"></div>
                                </div>
                                <span>{{'flat'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'dashed'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center bkg-light-grey-3" style="width: 15px; height: 15px; border-radius: 4px; border: 1px dashed #B5B5C3;">
                                    <div class="bkg-grey" style="height: 3px; width: 3px; border-radius: 50%;"></div>
                                </div>
                                <span>{{'dashed'}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">shape</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('shape'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('shape')).value}}</app-select-trigger>
                        <app-select-option [value]="'default'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-grey" style="width: 15px; height: 15px; border-radius: 4px;"></div>
                                <span>{{'default'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'square'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-grey" style="width: 15px; height: 15px; border-radius: 1px;"></div>
                                <span>{{'square'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'round'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-grey" style="width: 15px; height: 15px; border-radius: 50%;"></div>
                                <span>{{'round'}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">size</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('size'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('size')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['xs', 's', 'm', 'l', 'xl']" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">disabled</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('disabled'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('disabled')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">loading</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('loading'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('loading')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">focused</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('focused'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('focused')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">background</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('background'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('background')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">panelClass</span>
                    <app-multi-select [inputControl]="$any(iconButtonForm.get('panelClass'))">
                        <app-select-trigger appearance="field">{{$any(iconButtonForm.get('panelClass')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['', 'img-size-30', 'img-size-50', 'fix-edit-margin']" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
            </div>
            <div class="flex-center w-100" style="height: 100px;">
                <div class="flex-center w-25 pad-M">
                    <app-icon-button [img]="$any('assets/icons/' + $any(iconButtonForm.get('img')).value + '.svg')"
                                     [type]="$any(iconButtonForm.get('type')).value"
                                     [color]="$any(iconButtonForm.get('color')).value"
                                     [size]="$any(iconButtonForm.get('size')).value"
                                     [shape]="$any(iconButtonForm.get('shape')).value"
                                     [disabled]="$any(iconButtonForm.get('disabled')).value"
                                     [loading]="$any(iconButtonForm.get('loading')).value"
                                     [focused]="$any(iconButtonForm.get('focused')).value"
                                     [background]="$any(iconButtonForm.get('background')).value"
                                     [panelClass]="$any(iconButtonForm.get('panelClass')).value"
                    ></app-icon-button>
                </div>
                <div class="flex-center w-75 pad-M">
                    <app-copy-text [text]="'<app-icon-button img=\'assets/icons/'+$any(iconButtonForm.get('img')).value+'.svg\' size=\''+$any(iconButtonForm.get('size')).value+'\' color=\''+$any(iconButtonForm.get('color')).value+'\' type=\''+$any(iconButtonForm.get('type')).value+'\' shape=\''+$any(iconButtonForm.get('shape')).value+'\' [disabled]=\''+$any(iconButtonForm.get('disabled')).value+'\' [loading]=\''+$any(iconButtonForm.get('loading')).value+'\' [focused]=\''+$any(iconButtonForm.get('focused')).value+'\' [background]=\''+$any(iconButtonForm.get('background')).value+'\' panelClass=\''+$any(iconButtonForm.get('panelClass')).value+'\'></app-icon-button>'"></app-copy-text>
                </div>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-text-button -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-text-button</span>
        <div class="flex-column pad-M gap-M">
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;" class="flex-space-around"><span>Type</span></div>
                <div style="width: 560px;" class="flex-space-around border-grey-left"><span>Color</span></div>
                <div style="width: 140px;" class="flex-space-around border-grey-left"><span>Shape</span></div>
                <div style="width: 140px;" class="flex-space-around border-grey-left"><span>State</span></div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;" class="flex-space-around"></div>
                <div style="width: 560px;" class="flex-space-around"><span class="primary">primary</span><span class="accent">accent</span><span class="accent-secondary">secondary</span><span class="warn">warn</span><span class="success">success</span><span class="warning">warning</span><span class="danger">danger</span></div>
                <div style="width: 140px;" class="flex-space-around"><span class="grey">square</span><span class="grey">round</span></div>
                <div style="width: 140px;" class="flex-space-around"><span class="grey">disabled</span><span class="grey">loading</span></div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;"><span class="font-M fw-500 grey">default</span></div>
                <div style="width: 560px;" class="flex-space-around">
                    <app-text-button [type]="'default'" [color]="'primary'">test</app-text-button>
                    <app-text-button [type]="'default'" [color]="'accent'">test</app-text-button>
                    <app-text-button [type]="'default'" [color]="'accent-secondary'">test</app-text-button>
                    <app-text-button [type]="'default'" [color]="'warn'">test</app-text-button>
                    <app-text-button [type]="'default'" [color]="'success'">test</app-text-button>
                    <app-text-button [type]="'default'" [color]="'warning'">test</app-text-button>
                    <app-text-button [type]="'default'" [color]="'danger'">test</app-text-button>
                </div>
                <div style="width: 140px;" class="flex-space-around">
                    <app-text-button [type]="'default'" [color]="'accent'" [shape]="'square'">test</app-text-button>
                    <app-text-button [type]="'default'" [color]="'accent'" [shape]="'round'">test</app-text-button>
                </div>
                <div style="width: 140px;" class="flex-space-around">
                    <app-text-button [type]="'default'" [color]="'accent'" [disabled]="true">test</app-text-button>
                    <app-text-button [type]="'default'" [color]="'accent'" [loading]="true">test</app-text-button>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;"><span class="font-M fw-500 grey">stroked</span></div>
                <div style="width: 560px;" class="flex-space-around">
                    <app-text-button [type]="'stroked'" [color]="'primary'">test</app-text-button>
                    <app-text-button [type]="'stroked'" [color]="'accent'">test</app-text-button>
                    <app-text-button [type]="'stroked'" [color]="'accent-secondary'">test</app-text-button>
                    <app-text-button [type]="'stroked'" [color]="'warn'">test</app-text-button>
                    <app-text-button [type]="'stroked'" [color]="'success'">test</app-text-button>
                    <app-text-button [type]="'stroked'" [color]="'warning'">test</app-text-button>
                    <app-text-button [type]="'stroked'" [color]="'danger'">test</app-text-button>
                </div>
                <div style="width: 140px;" class="flex-space-around">
                    <app-text-button [type]="'stroked'" [color]="'accent'" [shape]="'square'">test</app-text-button>
                    <app-text-button [type]="'stroked'" [color]="'accent'" [shape]="'round'">test</app-text-button>
                </div>
                <div style="width: 140px;" class="flex-space-around">
                    <app-text-button [type]="'stroked'" [color]="'accent'" [disabled]="true">test</app-text-button>
                    <app-text-button [type]="'stroked'" [color]="'accent'" [loading]="true">test</app-text-button>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;"><span class="font-M fw-500 grey">flat</span></div>
                <div style="width: 560px;" class="flex-space-around">
                    <app-text-button [type]="'flat'" [color]="'primary'">test</app-text-button>
                    <app-text-button [type]="'flat'" [color]="'accent'">test</app-text-button>
                    <app-text-button [type]="'flat'" [color]="'accent-secondary'">test</app-text-button>
                    <app-text-button [type]="'flat'" [color]="'warn'">test</app-text-button>
                    <app-text-button [type]="'flat'" [color]="'success'">test</app-text-button>
                    <app-text-button [type]="'flat'" [color]="'warning'">test</app-text-button>
                    <app-text-button [type]="'flat'" [color]="'danger'">test</app-text-button>
                </div>
                <div style="width: 140px;" class="flex-space-around">
                    <app-text-button [type]="'flat'" [color]="'accent'" [shape]="'square'">test</app-text-button>
                    <app-text-button [type]="'flat'" [color]="'accent'" [shape]="'round'">test</app-text-button>
                </div>
                <div style="width: 140px;" class="flex-space-around">
                    <app-text-button [type]="'flat'" [color]="'accent'" [disabled]="true">test</app-text-button>
                    <app-text-button [type]="'flat'" [color]="'accent'" [loading]="true">test</app-text-button>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 50px;"><span class="font-M fw-500 grey">dashed</span></div>
                <div style="width: 560px;" class="flex-space-around">
                    <app-text-button [type]="'dashed'" [color]="'primary'">test</app-text-button>
                    <app-text-button [type]="'dashed'" [color]="'accent'">test</app-text-button>
                    <app-text-button [type]="'dashed'" [color]="'accent-secondary'">test</app-text-button>
                    <app-text-button [type]="'dashed'" [color]="'warn'">test</app-text-button>
                    <app-text-button [type]="'dashed'" [color]="'success'">test</app-text-button>
                    <app-text-button [type]="'dashed'" [color]="'warning'">test</app-text-button>
                    <app-text-button [type]="'dashed'" [color]="'danger'">test</app-text-button>
                </div>
                <div style="width: 140px;" class="flex-space-around">
                    <app-text-button [type]="'dashed'" [color]="'accent'" [shape]="'square'">test</app-text-button>
                    <app-text-button [type]="'dashed'" [color]="'accent'" [shape]="'round'">test</app-text-button>
                </div>
                <div style="width: 140px;" class="flex-space-around">
                    <app-text-button [type]="'dashed'" [color]="'accent'" [disabled]="true">test</app-text-button>
                    <app-text-button [type]="'dashed'" [color]="'accent'" [loading]="true">test</app-text-button>
                </div>
            </div>
        </div>
        <div class="flex-column border-grey-top">
            <div class="flex-wrap">
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">ng-content</span>
                    <app-text-input [input]="$any(textButtonForm.get('content'))">
                        <app-icon-button icon-suffix [img]="'assets/icons/dashboard.svg'" size="s" type="stroked"
                                         [focused]="$any(textButtonForm.get('contentIcon')).value"
                                         (click)="$any(textButtonForm.get('contentIcon')).value = !$any(textButtonForm.get('contentIcon')).value"></app-icon-button>
                    </app-text-input>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">color</span>
                    <app-multi-select [inputControl]="$any(textButtonForm.get('color'))">
                        <app-select-trigger appearance="field">{{$any(textButtonForm.get('color')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['primary', 'accent', 'accent-secondary', 'warn', 'success', 'warning', 'danger']" [value]="type">
                            <div class="flex-align-center gap-M">
                                <div [class]="'bkg-'+type" style="height: 15px; width: 15px; border-radius: 50%;"></div>
                                <span>{{type}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">type</span>
                    <app-multi-select [inputControl]="$any(textButtonForm.get('type'))">
                        <app-select-trigger appearance="field">{{$any(textButtonForm.get('type')).value}}</app-select-trigger>
                        <app-select-option [value]="'default'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-white" style="width: 15px; height: 15px; border-radius: 4px;">
                                    <div class="bkg-grey" style="height: 3px; width: 3px; border-radius: 50%;"></div>
                                </div>
                                <span>{{'default'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'stroked'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-grey" style="width: 15px; height: 15px; border-radius: 4px;">
                                    <div class="bkg-grey" style="height: 3px; width: 3px; border-radius: 50%;"></div>
                                </div>
                                <span>{{'stroked'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'flat'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center bkg-grey border-white" style="width: 15px; height: 15px; border-radius: 4px;">
                                    <div class="bkg-light-grey-3" style="height: 3px; width: 3px; border-radius: 50%;"></div>
                                </div>
                                <span>{{'flat'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'dashed'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center bkg-light-grey-3" style="width: 15px; height: 15px; border-radius: 4px; border: 1px dashed #B5B5C3;">
                                    <div class="bkg-grey" style="height: 3px; width: 3px; border-radius: 50%;"></div>
                                </div>
                                <span>{{'dashed'}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">shape</span>
                    <app-multi-select [inputControl]="$any(textButtonForm.get('shape'))">
                        <app-select-trigger appearance="field">{{$any(textButtonForm.get('shape')).value}}</app-select-trigger>
                        <app-select-option [value]="'default'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-grey" style="width: 15px; height: 15px; border-radius: 4px;"></div>
                                <span>{{'default'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'square'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-grey" style="width: 15px; height: 15px; border-radius: 1px;"></div>
                                <span>{{'square'}}</span>
                            </div>
                        </app-select-option>
                        <app-select-option [value]="'round'">
                            <div class="flex-align-center gap-M">
                                <div class="flex-center border-grey" style="width: 15px; height: 15px; border-radius: 50%;"></div>
                                <span>{{'round'}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box"></div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">disabled</span>
                    <app-multi-select [inputControl]="$any(textButtonForm.get('disabled'))">
                        <app-select-trigger appearance="field">{{$any(textButtonForm.get('disabled')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">loading</span>
                    <app-multi-select [inputControl]="$any(textButtonForm.get('loading'))">
                        <app-select-trigger appearance="field">{{$any(textButtonForm.get('loading')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">badge</span>
                    <app-text-input [input]="$any(textButtonForm.get('badge'))"></app-text-input>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">badgePosition</span>
                    <app-multi-select [inputControl]="$any(textButtonForm.get('badgePosition'))">
                        <app-select-trigger appearance="field">{{$any(textButtonForm.get('badgePosition')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['above', 'above after', 'above before', 'after', 'before', 'below', 'below after', 'below before']" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">panelClass</span>
                    <app-multi-select [inputControl]="$any(textButtonForm.get('panelClass'))">
                        <app-select-trigger appearance="field">{{$any(textButtonForm.get('panelClass')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['', 'b-radius-left-9', 'b-radius-right-9', 'uppercase']" [value]="type">
                            {{type}}
                        </app-select-option>
                    </app-multi-select>
                </div>
            </div>
            <div class="flex-center w-100" style="height: 100px;">
                <div class="flex-center w-25 pad-M">
                    <app-text-button [color]="$any(textButtonForm.get('color')).value"
                                     [type]="$any(textButtonForm.get('type')).value"
                                     [shape]="$any(textButtonForm.get('shape')).value"
                                     [disabled]="$any(textButtonForm.get('disabled')).value"
                                     [loading]="$any(textButtonForm.get('loading')).value"
                                     [badge]="$any(textButtonForm.get('badge')).value"
                                     [badgePosition]="$any(textButtonForm.get('badgePosition')).value"
                                     [panelClass]="$any(textButtonForm.get('panelClass')).value">
                        <div class="flex-align-center gap-S">
                            <img *ngIf="$any(textButtonForm.get('contentIcon')).value" src="assets/icons/dashboard.svg" width="12" alt=""/>
                            <span>{{$any(textButtonForm.get('content')).value}}</span>
                        </div>
                    </app-text-button>
                </div>
                <div class="flex-center w-75 pad-M">
                    <app-copy-text [text]="'<app-text-button color=\''+$any(textButtonForm.get('color')).value+'\' type=\''+$any(textButtonForm.get('type')).value+'\' shape=\''+$any(textButtonForm.get('shape')).value+'\' [disabled]=\''+$any(textButtonForm.get('disabled')).value+'\' [loading]=\''+$any(textButtonForm.get('loading')).value+'\' badge=\''+$any(textButtonForm.get('badge')).value+'\' badgePosition=\''+$any(textButtonForm.get('badgePosition')).value+'\' panelClass=\''+$any(textButtonForm.get('panelClass')).value+'\'>'+$any(textButtonForm.get('content')).value+'</app-text-button>'"></app-copy-text>
                </div>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-tooltip -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">[tooltip] / [tooltipTriggerFor] + app-tooltip</span>
        <div class="flex-column pad-M gap-XL">
            <span class="font-M fw-500">Simple text using directive [tooltip]</span>
            <div class="flex-space-between">
                <div [tooltip]="'tooltip test'" tooltipPosition="left">LEFT TOOLTIP</div>
                <div [tooltip]="'tooltip test'" tooltipPosition="right">RIGHT TOOLTIP</div>
                <div [tooltip]="'tooltip test'" [tooltipDisabled]="true">DISABLED TOOLTIP</div>
                <div [tooltip]="'<div class=\'accent\'>tooltip</div><br><strong>test<strong>'">HTML TOOLTIP</div>
                <div [tooltip]="'tooltip test'" tooltipPosition="top">TOP TOOLTIP</div>
                <div [tooltip]="'tooltip test'" tooltipPosition="bottom">BOTTOM TOOLTIP</div>
            </div>
            <span class="font-M fw-500">Custom app-tooltip using directive [tooltipTriggerFor]</span>
            <div class="flex-space-between">
                <div [tooltipTriggerFor]="tooltip1" tooltipPosition="left">LEFT TOOLTIP</div>
                <app-tooltip #tooltip1>
                    <div class="flex-column">
                        <span class="font-M fw-700">I am too long to be on the left, so I will be displayed on the right</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">I am too long to be on the left, so I will be displayed on the right</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">I am too long to be on the left, so I will be displayed on the right</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                    </div>
                </app-tooltip>
                <div [tooltipTriggerFor]="tooltip11" tooltipPosition="left">LEFT TOOLTIP</div>
                <app-tooltip #tooltip11>
                    <div class="flex-column">
                        <span class="font-M fw-700">I have enough space on the left</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">I have enough space on the left</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">I have enough space on the left</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                    </div>
                </app-tooltip>
                <div [tooltipTriggerFor]="tooltip2" tooltipPosition="right">RIGHT TOOLTIP</div>
                <app-tooltip #tooltip2>
                    <div class="flex-column">
                        <span class="font-M fw-700">I have enough space on the right</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">I have enough space on the right</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">I have enough space on the right</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                    </div>
                </app-tooltip>
                <div [tooltipTriggerFor]="tooltip22" tooltipPosition="right">RIGHT TOOLTIP</div>
                <app-tooltip #tooltip22>
                    <div class="flex-column">
                        <span class="font-M fw-700">I am too long to be on the right, so I will be displayed on the left</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">I am too long to be on the right, so I will be displayed on the left</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">I am too long to be on the right, so I will be displayed on the left</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the top or the bottom','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                    </div>
                </app-tooltip>
            </div>
            <div class="flex-space-between">
                <div [tooltipTriggerFor]="tooltip3" tooltipPosition="top">TOP TOOLTIP</div>
                <app-tooltip #tooltip3>
                    <div class="flex-column">
                        <span class="font-M fw-700">If I don't have enough space on the top, I will be displayed on the bottom</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">If I don't have enough space on the top, I will be displayed on the bottom</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">If I don't have enough space on the top, I will be displayed on the bottom</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                    </div>
                </app-tooltip>
                <div [tooltipTriggerFor]="tooltip33" tooltipPosition="top">TOP TOOLTIP</div>
                <app-tooltip #tooltip33>
                    <div class="flex-column">
                        <span class="font-M fw-700">If I don't have enough space on the top, I will be displayed on the bottom</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">If I don't have enough space on the top, I will be displayed on the bottom</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">If I don't have enough space on the top, I will be displayed on the bottom</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                    </div>
                </app-tooltip>
                <div [tooltipTriggerFor]="tooltip4" tooltipPosition="bottom">BOTTOM TOOLTIP</div>
                <app-tooltip #tooltip4>
                    <div class="flex-column">
                        <span class="font-M fw-700">If I don't have enough space on the bottom, I will be displayed on the top</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">If I don't have enough space on the bottom, I will be displayed on the top</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">If I don't have enough space on the bottom, I will be displayed on the top</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                    </div>
                </app-tooltip>
                <div [tooltipTriggerFor]="tooltip44" tooltipPosition="bottom">BOTTOM TOOLTIP</div>
                <app-tooltip #tooltip44>
                    <div class="flex-column">
                        <span class="font-M fw-700">If I don't have enough space on the bottom, I will be displayed on the top</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">If I don't have enough space on the bottom, I will be displayed on the top</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                        <span class="font-M fw-700">If I don't have enough space on the bottom, I will be displayed on the top</span>
                        <ul class="pl-l pr-l">
                            <li *ngFor="let fqdn of ['try to open me when I am','on the left or the right','of the page !']" class="font-S fw-500">{{ fqdn }}</li>
                        </ul>
                    </div>
                </app-tooltip>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-display-application -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-display-application / app-display-logo</span>
        <div class="flex-align-end pad-M gap-XL">
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">application</span>
                <app-multi-select [inputControl]="$any(displayApplicationForm.get('application'))" [disabled]="applications.length < 1">
                    <app-select-trigger appearance="field">{{$any(displayApplicationForm.get('application')).value?.name}}</app-select-trigger>
                    <app-select-option *ngFor="let app of applications" [value]="app">{{app.name}}</app-select-option>
                </app-multi-select>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">description</span>
                <app-text-input [input]="$any(displayApplicationForm.get('description'))"></app-text-input>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">size</span>
                <app-multi-select [inputControl]="$any(displayApplicationForm.get('size'))">
                    <app-select-trigger appearance="field">{{$any(displayApplicationForm.get('size')).value}}</app-select-trigger>
                    <app-select-option *ngFor="let type of ['S', 'M', 'L']" [value]="type">{{type}}</app-select-option>
                </app-multi-select>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">bold</span>
                <app-multi-select [inputControl]="$any(displayApplicationForm.get('bold'))">
                    <app-select-trigger appearance="field">{{$any(displayApplicationForm.get('bold')).value}}</app-select-trigger>
                    <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                </app-multi-select>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">criticality</span>
                <app-multi-select [inputControl]="$any(displayApplicationForm.get('criticality'))">
                    <app-select-trigger appearance="field">{{$any(displayApplicationForm.get('criticality')).value}}</app-select-trigger>
                    <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                </app-multi-select>
            </div>
        </div>
        <div class="flex-center pad-M gap-XL border-box" *ngIf="!$any(displayApplicationForm.get('refreshView')).value">
            <app-display-application *ngIf="$any(displayApplicationForm.get('application')).value" class="w-20"
                                     [application]="$any(displayApplicationForm.get('application')).value"
                                     [description]="$any(displayApplicationForm.get('description')).value"
                                     [criticality]="$any(displayApplicationForm.get('criticality')).value"
                                     [size]="$any(displayApplicationForm.get('size')).value"
                                     [bold]="$any(displayApplicationForm.get('bold')).value">
            </app-display-application>
            <app-display-logo [logo]="$any(displayApplicationForm.get('application')).value?.logo"></app-display-logo>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-display-user -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-display-user / app-display-user-logo</span>
        <div class="flex-align-end pad-M gap-XL">
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">user</span>
                <app-multi-select [inputControl]="$any(displayUserForm.get('user'))" [disabled]="users.length < 1">
                    <app-select-trigger appearance="field">{{$any(displayUserForm.get('user')).value?.account.lastName}}</app-select-trigger>
                    <app-select-option *ngFor="let user of users" [value]="user">{{user.account.lastName}}</app-select-option>
                </app-multi-select>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">email</span>
                <app-multi-select [inputControl]="$any(displayUserForm.get('email'))">
                    <app-select-trigger appearance="field">{{$any(displayUserForm.get('email')).value}}</app-select-trigger>
                    <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                </app-multi-select>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">size</span>
                <app-multi-select [inputControl]="$any(displayUserForm.get('size'))">
                    <app-select-trigger appearance="field">{{$any(displayUserForm.get('size')).value}}</app-select-trigger>
                    <app-select-option *ngFor="let type of ['S', 'M', 'L']" [value]="type">{{type}}</app-select-option>
                </app-multi-select>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">bold</span>
                <app-multi-select [inputControl]="$any(displayUserForm.get('bold'))">
                    <app-select-trigger appearance="field">{{$any(displayUserForm.get('bold')).value}}</app-select-trigger>
                    <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                </app-multi-select>
            </div>
        </div>
        <div class="flex-center w-100 pad-M gap-XL border-box">
            <app-display-user *ngIf="$any(displayUserForm.get('user')).value" class="w-20"
                              [user]="$any(displayUserForm.get('user')).value"
                              [email]="$any(displayUserForm.get('email')).value"
                              [size]="$any(displayUserForm.get('size')).value"
                              [bold]="$any(displayUserForm.get('bold')).value">
            </app-display-user>
            <app-display-user-logo [user]="$any(displayUserForm.get('user')).value"></app-display-user-logo>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-chips-button -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-chips-button</span>
        <div class="flex-align-end pad-M gap-XL">
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">ng-content</span>
                <app-text-input [input]="$any(chipsButtonForm.get('content'))"></app-text-input>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">disabled</span>
                <app-multi-select [inputControl]="$any(chipsButtonForm.get('disabled'))">
                    <app-select-trigger appearance="field">{{$any(chipsButtonForm.get('disabled')).value}}</app-select-trigger>
                    <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                </app-multi-select>
            </div>
            <div class="pad-M border-box">
                <app-chips-button [disabled]="$any(chipsButtonForm.get('disabled')).value">
                    {{$any(chipsButtonForm.get('content')).value}}
                </app-chips-button>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-dropdown -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-dropdown</span>
        <div class="flex-align-end pad-M gap-XL">
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">ng-content</span>
                <app-text-input [input]="$any(dropdownForm.get('content'))"></app-text-input>
            </div>
            <div class="w-20 pad-S border-box">
                <span class="font-M fw-500 grey">minimumMargin</span>
                <app-text-input type="number" [input]="$any(dropdownForm.get('minimumMargin'))"></app-text-input>
            </div>
            <div class="flex-space-between w-30 pad-M border-box">
                <div>
                    <app-text-button color="primary" [dropdownTrigger]="demoDropdown">Click me !</app-text-button>
                    <app-dropdown #demoDropdown
                                  (opened)="$any(dropdownForm.get('opened')).value = $any(dropdownForm.get('opened')).value + 1"
                                  (closed)="$any(dropdownForm.get('closed')).value = $any(dropdownForm.get('closed')).value + 1">
                        {{$any(dropdownForm.get('content')).value}}
                    </app-dropdown>
                </div>
                <div class="flex-column">
                    <div>Opened: {{$any(dropdownForm.get('opened')).value}}</div>
                    <div>Closed: {{$any(dropdownForm.get('closed')).value}}</div>
                </div>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-multi-select -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-multi-select (app-select-trigger / app-select-search / app-select-insert / app-select-option)</span>
        <div class="flex-column">
            <div class="flex-wrap">
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">multiple</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('multiple'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('multiple')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">disabled</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('disabled'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('disabled')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">loading</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('loading'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('loading')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">trigger</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('trigger'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('trigger')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['component', 'selector']" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box" [style.visibility]="$any(multiSelectForm.get('trigger')).value === 'component' ? 'visible' : 'hidden'">
                    <span class="font-M fw-500 grey">appearance</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('appearance'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('appearance')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['field', 'chips']" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">search</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('search'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('search')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [undefined, 'component', 'selector']" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">insert</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('insert'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('insert')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [undefined, 'component', 'selector']" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">grouped options</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('isGrouped'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('isGrouped')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box" [style.visibility]="$any(multiSelectForm.get('isGrouped')).value ? 'visible' : 'hidden'">
                    <span class="font-M fw-500 grey">no option prefix</span>
                    <app-multi-select [inputControl]="$any(multiSelectForm.get('noOptionPrefix'))">
                        <app-select-trigger appearance="field">{{$any(multiSelectForm.get('noOptionPrefix')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
            </div>
            <div class="flex-align-center w-100 pad-M gap-M border-box" style="height: 75px;">
                <span>Select something: </span>
                <app-multi-select [inputControl]="$any(multiSelectForm.get('inputControl'))" *ngIf="!$any(multiSelectForm.get('refreshView')).value" style="min-width: 150px; max-width: 300px;"
                                  [multiple]="$any(multiSelectForm.get('multiple')).value"
                                  [disabled]="$any(multiSelectForm.get('disabled')).value"
                                  [loading]="$any(multiSelectForm.get('loading')).value">
                    <!-- TRIGGER -->
                    <div select-trigger *ngIf="$any(multiSelectForm.get('trigger')).value === 'selector'">
                        < code your own select-trigger >
                    </div>
                    <app-select-trigger *ngIf="$any(multiSelectForm.get('trigger')).value === 'component'" [appearance]="$any(multiSelectForm.get('appearance')).value">
                        <span *ngIf="$any(multiSelectForm.get('inputControl')).value?.name">{{$any(multiSelectForm.get('inputControl')).value?.name}}</span>
                        <span *ngIf="$any(multiSelectForm.get('inputControl')).value && $any(multiSelectForm.get('inputControl')).value[0]">{{$any(multiSelectForm.get('inputControl')).value[0].name + ($any(multiSelectForm.get('inputControl')).value.length > 1 ? ' (+' + ($any(multiSelectForm.get('inputControl')).value.length - 1) + ')' : '')}}</span>
                        <span *ngIf="!$any(multiSelectForm.get('inputControl')).value">{{'-'}}</span>
                    </app-select-trigger>
                    <!-- SEARCH -->
                    <div select-search *ngIf="$any(multiSelectForm.get('search')).value === 'selector'" class="flex">
                        < code your own select-search >
                    </div>
                    <app-select-search *ngIf="$any(multiSelectForm.get('search')).value === 'component'" [searchControl]="$any(multiSelectForm.get('searchControl'))"></app-select-search>
                    <!-- INSERT -->
                    <div select-insert *ngIf="$any(multiSelectForm.get('insert')).value === 'selector'" class="flex">
                        < code your own select-insert >
                    </div>
                    <app-select-insert *ngIf="$any(multiSelectForm.get('insert')).value === 'component'" [saving]="$any(multiSelectForm.get('saving')).value"
                                       (create)="createTest($event)"></app-select-insert>
                    <!-- OPTION -->
                    <app-select-option [value]="null" *ngIf="!$any(multiSelectForm.get('multiple')).value">{{'-'}}</app-select-option>
                    @if (!$any(multiSelectForm.get('isGrouped')).value) {
                        <app-select-option *ngFor="let option of $any(multiSelectForm.get('filteredOptions')).value" [value]="option" [disabled]="option.name.toLowerCase() === 'test'">{{option.name}}</app-select-option>
                    } @else {
                        <app-select-option-group *ngFor="let group of $any(multiSelectForm.get('filteredOptionGroups')).value" [label]="group.organization.name"
                                                 [noOptionPrefix]="$any(multiSelectForm.get('noOptionPrefix')).value">
                            <app-select-option *ngFor="let option of group.children" [value]="option.organization" [disabled]="option.organization.name.toLowerCase() === 'test'">
                                @if (!$any(multiSelectForm.get('noOptionPrefix')).value) {
                                    <span>{{option.organization.name}}</span>
                                } @else {
                                    <div class="flex-align-center gap-M">
                                        <img [src]="!option.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + option.organization.icon + '.svg')" class="filter-accent" height="16px">
                                        <span>{{option.organization.name}}</span>
                                    </div>
                                }
                            </app-select-option>
                        </app-select-option-group>
                    }
                </app-multi-select>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-progress-bar-indicator -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-progress-bar-indicator</span>
        <div class="flex-column">
            <div class="flex-wrap">
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">label</span>
                    <app-text-input [input]="$any(progressBarIndicatorForm.get('label'))"></app-text-input>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">color</span>
                    <app-multi-select [inputControl]="$any(progressBarIndicatorForm.get('color'))">
                        <app-select-trigger appearance="field">{{$any(progressBarIndicatorForm.get('color')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['auto', 'accent']" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">size</span>
                    <app-multi-select [inputControl]="$any(progressBarIndicatorForm.get('size'))">
                        <app-select-trigger appearance="field">{{$any(progressBarIndicatorForm.get('size')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['XS', 'S', 'M', 'L', 'XL', 'XXL']" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">unit</span>
                    <app-multi-select [inputControl]="$any(progressBarIndicatorForm.get('unit'))">
                        <app-select-trigger appearance="field">{{$any(progressBarIndicatorForm.get('unit')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['percent', 'letter']" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">lowerBest</span>
                    <app-multi-select [inputControl]="$any(progressBarIndicatorForm.get('lowerBest'))">
                        <app-select-trigger appearance="field">{{$any(progressBarIndicatorForm.get('lowerBest')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">levelPercent</span>
                    <app-text-input type="number" [input]="$any(progressBarIndicatorForm.get('levelPercent'))"></app-text-input>
                </div>
            </div>
            <div class="flex-center w-100 pad-M border-box">
                <app-progress-bar-indicator class="w-60" [levelPercent]="$any(progressBarIndicatorForm.get('levelPercent')).value"
                                            [label]="$any(progressBarIndicatorForm.get('label')).value"
                                            [color]="$any(progressBarIndicatorForm.get('color')).value"
                                            [unit]="$any(progressBarIndicatorForm.get('unit')).value"
                                            [size]="$any(progressBarIndicatorForm.get('size')).value"
                                            [lowerBest]="$any(progressBarIndicatorForm.get('lowerBest')).value"
                ></app-progress-bar-indicator>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-progress-bar-loader -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-progress-bar-loader</span>
        <div class="flex-column">
            <div class="flex-wrap">
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">loadingTime</span>
                    <app-text-input type="number" [input]="$any(progressBarLoaderForm.get('loadingTime'))"></app-text-input>
                </div>
            </div>
            <div class="flex-center w-100 pad-M border-box">
                <div class="flex w-20">
                    <app-icon-button [img]="'assets/icons/refresh.svg'"
                                     (click)="$any(progressBarLoaderForm.get('loadingTime')).updateValueAndValidity()">
                    </app-icon-button>
                    <app-icon-button [img]="'assets/icons/close.svg'" panelClass="img-size-30" color="warning"
                                     [disabled]="!$any(progressBarLoaderForm.get('onStart')).value || $any(progressBarLoaderForm.get('onFinish')).value"
                                     (click)="$any(progressBarLoaderForm.get('onStart')).value?.complete()">
                    </app-icon-button>
                    <app-icon-button [img]="'assets/icons/warning-outline.svg'" color="danger"
                                     [disabled]="!$any(progressBarLoaderForm.get('onStart')).value || $any(progressBarLoaderForm.get('onFinish')).value"
                                     (click)="$any(progressBarLoaderForm.get('onStart')).value?.error()">
                    </app-icon-button>
                </div>
                <app-progress-bar-loader class="w-60" [loadingTime]="$any(progressBarLoaderForm.get('loadingTime')).value" *ngIf="!$any(progressBarLoaderForm.get('refreshView')).value"
                                         (onStart)="$any(progressBarLoaderForm.get('onStart')).setValue($event)"
                                         (onFinish)="$any(progressBarLoaderForm.get('onFinish')).setValue($event)"
                ></app-progress-bar-loader>
                <div class="w-20">
                    <span class="pad-0-XL font-M fw-500 grey">onFinish: {{$any(progressBarLoaderForm.get('onFinish')).value?.status}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-select-or-invite-user-input -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-select-or-invite-user-input</span>
        <div class="flex-column">
            <div class="flex-wrap">
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">searchPlaceholder</span>
                    <app-text-input [input]="$any(selectOrInviteUserForm.get('searchPlaceholder'))"></app-text-input>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">disabled</span>
                    <app-multi-select [inputControl]="$any(selectOrInviteUserForm.get('disabled'))">
                        <app-select-trigger appearance="field">{{$any(selectOrInviteUserForm.get('disabled')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
            </div>
            <div class="flex-center w-100 pad-M gap-XL border-box" *ngIf="$any(selectOrInviteUserForm.get('list')).value?.length > 0">
                <div class="flex-column w-30"></div>
                <app-select-or-invite-user-input class="w-20 radius-L pad-1" [ngClass]="{'c-p greyed-hover': !$any(selectOrInviteUserForm.get('disabled')).value}"
                                                 [values]="[$any(selectOrInviteUserForm.get('value')).value]"
                                                 [list]="$any(selectOrInviteUserForm.get('list')).value"
                                                 [searchPlaceholder]="$any(selectOrInviteUserForm.get('searchPlaceholder')).value"
                                                 [disabled]="$any(selectOrInviteUserForm.get('disabled')).value"
                                                 (onSelect)="$any(selectOrInviteUserForm.get('value')).setValue($event); $any(selectOrInviteUserForm.get('onSelect')).setValue($event.account.accountId)"
                                                 (onInvite)="$any(selectOrInviteUserForm.get('onInvite')).setValue($event)">
                    <app-display-user [user]="$any(selectOrInviteUserForm.get('value')).value"></app-display-user>
                </app-select-or-invite-user-input>
                <div class="flex-column w-30">
                    <div>onSelect: {{$any(selectOrInviteUserForm.get('onSelect')).value}}</div>
                    <div>onInvite: {{$any(selectOrInviteUserForm.get('onInvite')).value}}</div>
                </div>
            </div>
        </div>
    </div>

    <!-- DEMO COMPONENT : app-chips-count -->
    <div class="flex-column bkg-white gap-S b-radius-9 border-grey">
        <span class="font-L fw-700 border-grey-bottom pad-M">app-chips-count</span>
        <div class="flex-column pad-M gap-M">
            <div class="flex-align-center gap-XL">
                <div style="width: 150px;" class="flex-space-around"><span>Type</span></div>
                <div style="width: 820px;" class="flex-space-around border-grey-left"><span>Color</span></div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 150px;" class="flex-space-around"></div>
                <div style="width: 820px;" class="flex-space-around"><span class="primary">grey</span><span class="primary">primary</span><span class="accent">accent</span><span class="accent-secondary">secondary</span><span class="warn">warn</span><span class="success">success</span><span class="warning">warning</span><span class="danger">danger</span></div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 150px;"><span class="font-M fw-500 grey">user</span></div>
                <div style="width: 820px;" class="flex-space-around">
                    <app-chips-count [value]="5" type="user" color="grey"></app-chips-count>
                    <app-chips-count [value]="5" type="user" color="primary"></app-chips-count>
                    <app-chips-count [value]="5" type="user" color="accent"></app-chips-count>
                    <app-chips-count [value]="5" type="user" color="accent-secondary"></app-chips-count>
                    <app-chips-count [value]="5" type="user" color="warn"></app-chips-count>
                    <app-chips-count [value]="5" type="user" color="success"></app-chips-count>
                    <app-chips-count [value]="5" type="user" color="warning"></app-chips-count>
                    <app-chips-count [value]="5" type="user" color="danger"></app-chips-count>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 150px;"><span class="font-M fw-500 grey">application</span></div>
                <div style="width: 820px;" class="flex-space-around">
                    <app-chips-count [value]="null" type="application" color="grey"></app-chips-count>
                    <app-chips-count [value]="null" type="application" color="primary"></app-chips-count>
                    <app-chips-count [value]="null" type="application" color="accent"></app-chips-count>
                    <app-chips-count [value]="null" type="application" color="accent-secondary"></app-chips-count>
                    <app-chips-count [value]="null" type="application" color="warn"></app-chips-count>
                    <app-chips-count [value]="null" type="application" color="success"></app-chips-count>
                    <app-chips-count [value]="null" type="application" color="warning"></app-chips-count>
                    <app-chips-count [value]="null" type="application" color="danger"></app-chips-count>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 150px;"><span class="font-M fw-500 grey">flux</span></div>
                <div style="width: 820px;" class="flex-space-around">
                    <app-chips-count [value]="150" type="flux" color="grey"></app-chips-count>
                    <app-chips-count [value]="150" type="flux" color="primary"></app-chips-count>
                    <app-chips-count [value]="150" type="flux" color="accent"></app-chips-count>
                    <app-chips-count [value]="150" type="flux" color="accent-secondary"></app-chips-count>
                    <app-chips-count [value]="150" type="flux" color="warn"></app-chips-count>
                    <app-chips-count [value]="150" type="flux" color="success"></app-chips-count>
                    <app-chips-count [value]="150" type="flux" color="warning"></app-chips-count>
                    <app-chips-count [value]="150" type="flux" color="danger"></app-chips-count>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 150px;"><span class="font-M fw-500 grey">server</span></div>
                <div style="width: 820px;" class="flex-space-around">
                    <app-chips-count [value]="1000" type="server" color="grey"></app-chips-count>
                    <app-chips-count [value]="1000" type="server" color="primary"></app-chips-count>
                    <app-chips-count [value]="1000" type="server" color="accent"></app-chips-count>
                    <app-chips-count [value]="1000" type="server" color="accent-secondary"></app-chips-count>
                    <app-chips-count [value]="1000" type="server" color="warn"></app-chips-count>
                    <app-chips-count [value]="1000" type="server" color="success"></app-chips-count>
                    <app-chips-count [value]="1000" type="server" color="warning"></app-chips-count>
                    <app-chips-count [value]="1000" type="server" color="danger"></app-chips-count>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 150px;"><span class="font-M fw-500 grey">star</span></div>
                <div style="width: 820px;" class="flex-space-around">
                    <app-chips-count [value]="4.5" type="star" color="grey"></app-chips-count>
                    <app-chips-count [value]="4.5" type="star" color="primary"></app-chips-count>
                    <app-chips-count [value]="4.5" type="star" color="accent"></app-chips-count>
                    <app-chips-count [value]="4.5" type="star" color="accent-secondary"></app-chips-count>
                    <app-chips-count [value]="4.5" type="star" color="warn"></app-chips-count>
                    <app-chips-count [value]="4.5" type="star" color="success"></app-chips-count>
                    <app-chips-count [value]="4.5" type="star" color="warning"></app-chips-count>
                    <app-chips-count [value]="4.5" type="star" color="danger"></app-chips-count>
                </div>
            </div>
            <div class="flex-align-center gap-XL">
                <div style="width: 150px;"><span class="font-M fw-500 grey">visibility</span></div>
                <div style="width: 820px;" class="flex-space-around">
                    <app-chips-count [value]="0" type="visibility" color="grey"></app-chips-count>
                    <app-chips-count [value]="0" type="visibility" color="primary"></app-chips-count>
                    <app-chips-count [value]="0" type="visibility" color="accent"></app-chips-count>
                    <app-chips-count [value]="0" type="visibility" color="accent-secondary"></app-chips-count>
                    <app-chips-count [value]="0" type="visibility" color="warn"></app-chips-count>
                    <app-chips-count [value]="0" type="visibility" color="success"></app-chips-count>
                    <app-chips-count [value]="0" type="visibility" color="warning"></app-chips-count>
                    <app-chips-count [value]="0" type="visibility" color="danger"></app-chips-count>
                </div>
            </div>
        </div>
        <div class="flex-column border-grey-top">
            <div class="flex-wrap">
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">value</span>
                    <app-text-input type="number" [input]="$any(chipsCountForm.get('value'))">
                        <app-icon-button icon-suffix [img]="'assets/icons/close.svg'" size="s" type="stroked" panelClass="img-size-30"
                                         (click)="$any(chipsCountForm.get('value')).reset(undefined)"></app-icon-button>
                    </app-text-input>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">color</span>
                    <app-multi-select [inputControl]="$any(chipsCountForm.get('color'))">
                        <app-select-trigger appearance="field">{{$any(chipsCountForm.get('color')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of ['grey', 'primary', 'accent', 'accent-secondary', 'warn', 'success', 'warning', 'danger']" [value]="type">
                            <div class="flex-align-center gap-M">
                                <div [class]="'bkg-'+type" style="height: 15px; width: 15px; border-radius: 50%;"></div>
                                <span>{{type}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">type</span>
                    <app-multi-select [inputControl]="$any(chipsCountForm.get('type'))">
                        <app-select-trigger appearance="field">{{$any(chipsCountForm.get('type')).value}}</app-select-trigger>
                        <app-select-option [value]="'user'"><div class="flex-align-center gap-M"><img class="filter-grey" [src]="'assets/icons/user-2.svg'" width="12" height="12" alt=""/><span>user</span></div></app-select-option>
                        <app-select-option [value]="'application'"><div class="flex-align-center gap-M"><img class="filter-grey" [src]="'assets/icons/app-list.svg'" width="12" height="12" alt=""/><span>application</span></div></app-select-option>
                        <app-select-option [value]="'flux'"><div class="flex-align-center gap-M"><img class="filter-grey rotate-45" [src]="'assets/icons/flow.svg'" width="12" height="12" alt=""/><span>flux</span></div></app-select-option>
                        <app-select-option [value]="'server'"><div class="flex-align-center gap-M"><img class="filter-grey" [src]="'assets/icons/server-04.svg'" width="12" height="12" alt=""/><span>server</span></div></app-select-option>
                        <app-select-option [value]="'star'"><div class="flex-align-center gap-M"><img class="filter-grey" [src]="'assets/icons/star.svg'" width="12" height="12" alt=""/><span>server</span></div></app-select-option>
                        <app-select-option [value]="'visibility'"><div class="flex-align-center gap-M"><img class="filter-grey" [src]="'assets/icons/eye-active.svg'" width="12" height="12" alt=""/><span>visibility</span></div></app-select-option>
                    </app-multi-select>
                </div>
                <div class="w-20 pad-S border-box">
                    <span class="font-M fw-500 grey">bold</span>
                    <app-multi-select [inputControl]="$any(chipsCountForm.get('bold'))">
                        <app-select-trigger appearance="field">{{$any(chipsCountForm.get('bold')).value}}</app-select-trigger>
                        <app-select-option *ngFor="let type of [true, false]" [value]="type">{{type}}</app-select-option>
                    </app-multi-select>
                </div>
            </div>
        </div>
        <div class="flex-center w-100 pad-M border-box">
            <app-chips-count [value]="$any(chipsCountForm.get('value')).value" *ngIf="!$any(chipsCountForm.get('refreshView')).value"
                             [color]="$any(chipsCountForm.get('color')).value"
                             [type]="$any(chipsCountForm.get('type')).value"
                             [bold]="$any(chipsCountForm.get('bold')).value"
            ></app-chips-count>
        </div>
    </div>

	<!-- DEMO COMPONENT : app-dialog-placeholder-card -->
	<div class="flex-column bkg-white gap-S b-radius-9 border-grey">
		<span class="font-L fw-700 border-grey-bottom pad-M">app-dialog-placeholder-card</span>

		<div class="disp-f a-i-c j-c-c p-15 w-100">
			<div style="width: 500px" class="border-grey b-radius-14">
				<app-dialog-placeholder-card>
					<ng-container content>
						<img src="assets/illustrations/one-app-sugg.svg" width="158">

						<div class="disp-f f-d-col a-i-c j-c-c mt-xl">
							<div class="font-LL text-center fw-700">{{'Title'|translate}}</div>
							<div class="font-M text-center grey mt-m w-70">{{'Description'|translate}}</div>
						</div>
					</ng-container>

					<ng-container action-button>
						{{'Action button'}}
					</ng-container>
				</app-dialog-placeholder-card>
			</div>
		</div>
	</div>

	<!-- DEMO COMPONENT : app-mini-drawer -->
	<div class="flex-column bkg-white gap-S b-radius-9 border-grey">
		<span class="font-L fw-700 border-grey-bottom pad-M">app-mini-drawer</span>

		<div class="flex-center pad-M w-100">
            <app-text-button color="primary" [miniDrawerTriggerFor]="miniDrawer">Open Mini Drawer</app-text-button>
            <app-mini-drawer #miniDrawer>
                <div class="flex-center m-auto">
                    TEST
                </div>
            </app-mini-drawer>
		</div>
	</div>
</div>
