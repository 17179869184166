<div class="flex-column gap-L">
    <div class="flex-align-center gap-S">
        <div class="font-MM fw-700">{{'page.appDetails.overview.informations'|translate}}</div>
        <div *ngIf="advancementPercent < 100" [style.background-color]="advancementPercent > 0 ? '#F2F2F8' : '#DADADA'" style=" height: 5px; border-radius: 2px; width: 30px;">
            <div class="bkg-accent completion-anim" style="height: 5px; border-radius: 2px;" [style.width.%]="advancementPercent"></div>
        </div>
        <img [src]="advancementPercent >= 100 ? 'assets/icons/check-circle-green.svg' : 'assets/icons/check-circle.svg'" height="14px" alt="">
    </div>
    <div class="flex-column">
        <div class="flex-align-center border-light-grey-top" style="height: 50px;">
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img class="filter-grey" src="assets/icons/building.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{'page.appDetails.overview.vendor'|translate}} :</span>
				</div>
				<div class="flex-align-center w-60">
					<app-multi-select class="w-100" [inputControl]="vendorControl"
									  [disabled]="_initializing || _refreshing || _loading || _saving"
									  [listenToScroll]="true"
									  (bottomScroll)="onBottomScroll()"
									  [scrollUpEvent]="scrollUpEvent.asObservable()">
						@if (initialized && !vendorControl.value) {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
								<span class="font-M fw-500 grey">{{'page.appDetails.overview.addVendor'|translate}}</span>
							</app-chips-button>
						} @else if (initialized && !!vendorControl.value) {
							<app-select-trigger class="w-100">{{vendorControl.value!.name}}</app-select-trigger>
						} @else {
							<app-select-trigger class="w-100">-</app-select-trigger>
						}
						<app-select-search [searchControl]="vendorSearchControl"></app-select-search>
						<app-select-insert [titlePath]="'global.adds.vendor'" [saving]="_savingNewVendor" (create)="createVendor($event)"></app-select-insert>
						<app-select-option [value]="null">{{'-'}}</app-select-option>
						<app-select-option *ngFor="let vendor of filteredVendorList" [value]="vendor">{{vendor.name}}</app-select-option>
					</app-multi-select>
				</div>
			</div>
            <div class="border-light-grey-left" style="height: 25px"></div>
            <div class="flex-align-center pad-S w-50 border-box">
                <div class="flex-align-center gap-XS w-40">
                    <img src="assets/icons/package.svg" height="14px" alt="">
                    <span class="font-M fw-500 grey">{{'page.appDetails.overview.category'|translate}} :</span>
                </div>
                <div class="flex-align-center w-60">
                    <app-multi-select class="w-100" [inputControl]="categoryControl"
                                      [disabled]="_initializing || _refreshing || _loading || _saving">
                        @if (initialized && !categoryControl.value) {
                            <app-chips-button select-trigger>
                                <img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
                                <span class="font-M fw-500 grey">{{'page.appDetails.overview.addCategory'|translate}}</span>
                            </app-chips-button>
                        } @else if (initialized && !!categoryControl.value) {
                            <app-select-trigger class="w-100">{{categoryControl.value!.name}}</app-select-trigger>
                        } @else {
                            <app-select-trigger class="w-100">-</app-select-trigger>
                        }
                        <app-select-search [searchControl]="searchCategoryControl"></app-select-search>
                        <app-select-insert [titlePath]="'global.adds.category'" [saving]="_savingNewCategory" (create)="createCategory($event)"></app-select-insert>
                        <app-select-option [value]="null">{{'-'}}</app-select-option>
                        <app-select-option *ngFor="let category of filteredCategoryList" [value]="category">{{category.name}}</app-select-option>
                    </app-multi-select>
                </div>
            </div>
        </div>
        <div class="flex-align-center border-light-grey-top" style="height: 50px;">
            <div class="flex-align-center pad-S w-50 border-box">
                <div class="flex-align-center gap-XS w-40">
                    <img src="assets/icons/award-04.svg" height="14px" alt="">
                    <span class="font-M fw-500 grey">{{'page.appDetails.overview.criticality'|translate}} :</span>
                </div>
                <div class="flex-align-center w-60">
                    <app-multi-select style="width: 230px" [inputControl]="criticalityControl"
                                      [disabled]="_initializing || _refreshing || _loading || _saving">
                        @if (initialized && !criticalityControl.value) {
                            <app-chips-button select-trigger>
                                <img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
                                <span class="font-M fw-500 grey">{{'page.appDetails.overview.addCriticality'|translate}}</span>
                            </app-chips-button>
                        } @else if (initialized && !!criticalityControl.value) {
                            <app-select-trigger class="w-100">
                                <div class="flex-align-center">
                                    <img alt="Criticality icon" src="assets/icons/criticality-{{criticalityControl.value!.toLowerCase()}}.svg" height="14">
                                    <span class="ml-s">{{('page.appDetails.businessCriticality.' + criticalityControl.value!.toLowerCase())|translate}}</span>
                                </div>
                            </app-select-trigger>
                        } @else {
                            <app-select-trigger class="w-100">-</app-select-trigger>
                        }
                        <app-select-option [value]="null">{{'-'}}</app-select-option>
                        <app-select-option *ngFor="let level of criticalityList" [value]="level">
							<div class="flex-align-center" style="width: 210px;">
								<img alt="Criticality icon" src="assets/icons/criticality-{{level.toLowerCase()}}.svg" height="14">
								<span class="ml-m">{{('page.appDetails.businessCriticality.' + level.toLowerCase())|translate}}</span>
							</div>
						</app-select-option>
                    </app-multi-select>
                </div>
            </div>
            <div class="border-light-grey-left" style="height: 25px"></div>
            <div class="flex-align-center pad-S w-50 border-box">
                <div class="flex-align-center gap-XS w-40">
                    <img src="assets/icons/cube-outline.svg" height="14px" alt="">
                    <span class="font-M fw-500 grey">{{'page.appDetails.overview.type'|translate}} :</span>
                </div>
                <div class="flex-align-center w-60">
                    <app-multi-select class="w-100" [inputControl]="typeControl"
                                      [disabled]="_initializing || _refreshing || _loading || _saving">
                        @if (initialized && !typeControl.value) {
                            <app-chips-button select-trigger>
                                <img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
                                <span class="font-M fw-500 grey">{{'page.appDetails.overview.addType'|translate}}</span>
                            </app-chips-button>
                        } @else if (initialized && !!typeControl.value) {
                            <app-select-trigger class="w-100">{{('page.dashboard.typology.' + typeControl.value)|translate}}</app-select-trigger>
                        } @else {
                            <app-select-trigger class="w-100">-</app-select-trigger>
                        }
                        <app-select-option *ngFor="let type of typeList" [value]="type">{{('page.dashboard.typology.' + type)|translate}}</app-select-option>
                    </app-multi-select>
                </div>
            </div>
        </div>
        <div class="flex-align-center border-light-grey-top" style="height: 50px;">
            <div class="flex-align-center pad-S w-50 border-box">
                <div class="flex-align-center gap-XS w-40">
                    <img src="assets/icons/power-02.svg" height="14px" alt="">
                    <span class="font-M fw-500 grey">{{'page.appDetails.overview.status'|translate}} :</span>
                </div>
                <div class="flex-align-center w-60">
                    <div class="flex-align-center gap-S w-100 bkg-light-grey b-radius-14 pad-0-S" style="height: 28px;">
                        <!-- TODO component app-display-app-status ? -->
                        @if (appStatus === status.PHASE_IN) {
                            <div class="b-radius-half bkg-accent-secondary" style="height: 11px; width: 11px;"></div>
                            <span>{{'page.appDetails.lifeCycle.phase_in'|translate}}</span>
                        } @else if (appStatus === status.DEPLOYED) {
                            <div class="b-radius-half bkg-success" style="height: 11px; width: 11px;"></div>
                            <span>{{'page.appDetails.lifeCycle.deployed'|translate}}</span>
                        } @else if (appStatus === status.PHASE_OUT) {
                            <div class="b-radius-half bkg-warning" style="height: 11px; width: 11px;"></div>
                            <span>{{'page.appDetails.lifeCycle.phase_out'|translate}}</span>
                        } @else if (appStatus === status.RETIRED) {
                            <div class="b-radius-half bkg-danger" style="height: 11px; width: 11px;"></div>
                            <span>{{'page.appDetails.lifeCycle.retired'|translate}}</span>
                        } @else {
                            <div class="b-radius-half bkg-grey" style="height: 11px; width: 11px;"></div>
                            <span>-</span>
                        }
                    </div>
                </div>
            </div>
            <div class="border-light-grey-left" style="height: 25px"></div>
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img src="assets/icons/zap.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{'page.appDetails.overview.commissioning'|translate}} :</span>
				</div>
				<div class="flex-align-center w-60">
					@if (initialized && !dateControl.value) {
						<app-chips-button class="w-100" (click)="picker1.open()">
							<img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
							<span class="font-M fw-500 grey">{{'page.appDetails.overview.addDate'|translate}}</span>
						</app-chips-button>
					} @else if (initialized && !!dateControl.value) {
						<app-select-trigger class="w-100" (click)="picker1.open()">{{dateControl.value|date:'MMM y'}}</app-select-trigger>
					} @else {
						<app-select-trigger class="w-100">-</app-select-trigger>
					}
					<input class="hidden-input" [formControl]="dateControl" [matDatepicker]="picker1" [matDatepickerFilter]="filterDeployed">
					<mat-datepicker #picker1 startView="multi-year" panelClass="month-picker"
									(monthSelected)="dateControl.setValue($event); picker1.close();"></mat-datepicker>
				</div>
			</div>
        </div>
    </div>
</div>
