import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-select-trigger',
  templateUrl: './select-trigger.component.html',
  styleUrls: ['../multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTriggerComponent {
  @Input() appearance: 'chips'|'field' = 'chips';
  @Input() minHeight?: number;
  @Input() border?: string;
}
