import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {SecurityUtils} from "./security.utils";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationType} from 'src/app/services/model/autodiscover.model';
import {
	ApplicationGeneric,
	Health
} from 'src/app/services/model/new-application.model';
import {Cost, Usage} from 'src/app/services/model/application-contract.model';
import {
	OrganizationOverviewData
} from "../modules/home/organization/organization-detail/organization-overview-tab/organization-overview-tab.component";
import {
	OrganizationApplicationsData
} from "../modules/home/organization/organization-detail/organization-applications-tab/organization-applications-tab.component";
import {
	OrganizationFinanceData
} from "../modules/home/organization/organization-detail/organization-finance-tab/organization-finance-tab.component";
import {
	OrganizationUsageData
} from "../modules/home/organization/organization-detail/organization-usage-tab/organization-usage-tab.component";
import {catchError} from "rxjs/operators";
import {HttpClientUtils} from "../utils/http-client.utils";
import {TenantAccount} from "./model/account.model";

@Injectable({ providedIn: 'root' })
export class OrganizationService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/organizations`;

	constructor(protected httpClient: HttpClient, protected activatedRoute: ActivatedRoute, protected router: Router) {
	}

	get(tenantId: string, organizationId: string): Observable<Organization> {
		return this.httpClient.get<Organization>(`${this.serviceUrl(tenantId)}/${organizationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	updateOrganizationSettingGeneral(tenantId: string, organizationId: string, form: OrganizationSettingGeneralForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${organizationId}/setting-general`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	addOrganizationResponsible(tenantId: string, organizationId: string, accountId: string): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${organizationId}/add-responsible`, { accountId: accountId }, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	removeOrganizationResponsible(tenantId: string, organizationId: string, accountId: string): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${organizationId}/remove-responsible`, { accountId: accountId }, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getOrganizationOverviewData(tenantId: string, organizationId: string): Observable<OrganizationOverviewData> {
		return this.httpClient.get<OrganizationOverviewData>(`${this.serviceUrl(tenantId)}/${organizationId}/overview`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationResponsibles(tenantId: string, organizationId: string): Observable<string[]> {
		return this.httpClient.get<string[]>(`${this.serviceUrl(tenantId)}/${organizationId}/responsibles`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationAutodiscoveredApplications(tenantId: string, organizationId: string): Observable<ApplicationGeneric[]> {
		return this.httpClient.get<ApplicationGeneric[]>(`${this.serviceUrl(tenantId)}/${organizationId}/autodiscovered-applications`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationApplicationsData(tenantId: string, organizationId: string): Observable<OrganizationApplicationsData> {
		return this.httpClient.get<OrganizationApplicationsData>(`${this.serviceUrl(tenantId)}/${organizationId}/applications`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationFinanceData(tenantId: string, organizationId: string): Observable<OrganizationFinanceData> {
		return this.httpClient.get<OrganizationFinanceData>(`${this.serviceUrl(tenantId)}/${organizationId}/finance`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationUsageData(tenantId: string, organizationId: string): Observable<OrganizationUsageData> {
		return this.httpClient.get<OrganizationUsageData>(`${this.serviceUrl(tenantId)}/${organizationId}/usage`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationTreeByTenantId(tenantId: string): Observable<OrganizationTree> {
		return this.httpClient.get<OrganizationTree>(`${this.serviceUrl(tenantId)}/tree`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationTreeStatisticByTenantId(tenantId: string): Observable<OrganizationTreeStatistic> {
		return this.httpClient.get<OrganizationTreeStatistic>(`${this.serviceUrl(tenantId)}/tree-statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	create(tenantId: string, form: CreateOrganizationForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	delete(tenantId: string, organizationId: string): Observable<any> {
		return this.httpClient.delete(`${this.serviceUrl(tenantId)}/${organizationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	changeTeamApplications(tenantId: string, teamId: string, applications: { appId: string, checked: boolean }[]): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.serviceUrl(tenantId)}/${teamId}/applications`, applications, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	// Settings TODO @TAN remove this part from the service
	navigateInAndOutOfParameter(parameter: number | null) {
		/*
			Working the same way as chmod, each new value = sum of all previous value + 1
			sum result of the opening of the different value used in the sum
			null -> out
			nothing opened ->0
			general -> 1
		*/

		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {
				settings: parameter
			},
			queryParamsHandling: 'merge'
		});
	}

	public getParameter(): number | null {
		let index = this.activatedRoute.snapshot.queryParamMap.get('settings');
		if (index != null)
			return parseInt(index)
		return null
	}
}

export interface OrganizationTreeStatistic {
	organization: Organization;
	children: OrganizationTreeStatistic[];
	appCount: number;
	usage: number|null;
	satisfaction: number|null;
	responsibles: TenantAccount[];
	// TODO @TAN statistic: OrganizationStatistic
}

export interface CreateOrganizationForm {
	name: string;
	type: OrganizationType;
	icon?: OrganizationIcon;
	ascendant: string;
}

export interface UpdateOrganizationNameForm {
	name: string;
}

export interface UpdateOrganizationIconForm {
	icon?: OrganizationIcon|null;
}

export interface UpdateOrganizationDescriptionForm {
	description?: string|null;
}

export interface OrganizationStatistic {
	availability: Health;
	cost: Cost;
	usage: Usage;
}

export interface OrganizationTree {
	organization: Organization;
	children: OrganizationTree[];
}

// TODO @TAN parentOrganizationId & other model with parent ?
export interface Organization {
	organizationId: string;
	parentOrganization: Organization|null;
	name: string;
	type: OrganizationType;
	description: string|null;
	icon: OrganizationIcon|null;
}

export interface OrganizationSettingGeneralForm {
	name: string;
	icon?: OrganizationIcon;
}

export enum OrganizationIcon {
	// TODO @TAN move to other file
	SHARE = 'share',
	CART = 'cart',
	FLASK = 'flask',
	PALETTE = 'palette',
	CHART_PIE = 'chart_pie',
	PRESENT = 'present',
	AWARD = 'award',
	DIAMOND = 'diamond',
	GLOB = 'glob',
	PROCESSOR = 'processor',
	GAMEPAD = 'gamepad',
	BOOKMARK = 'bookmark',
	INVOICE = 'invoice',
	EXPLORE = 'explore',
	MOON = 'moon',
	UNIVERSITY = 'university',
	LIGHTNING = 'lightning',
	CHECKBOX = 'checkbox',
}
